import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles.css";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";

const Aboutus = () => {
  return (
    <div className="abt-wrap">
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <h2 className="text-center my-5">About Us</h2>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col md="6">
                <h2>Fly In Comfort</h2>
                <p className="font14">
                  We set out to do things differently. We wanted to show all
                  flight booking options with endless benefits in one place,
                  creating a simple alternative to the confusing travel booking
                  sites that make travel feel like hard work.
                </p>
              </Col>
              <Col md="6">
                <Image
                  className="abt-img"
                  src="https://d1mfzpdx6lagvf.cloudfront.net/og/w/img/Fly-in-comfort.jpg"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="aboutus-seperator my-md-5 my-4"></div>

      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col xs={{ order: 2 }} md={{ span: 6, order: 1 }}>
                <img
                  className="abt-img"
                  src="https://d1mfzpdx6lagvf.cloudfront.net/og/w/img/Your-travel-information.jpg"
                  alt=""
                />
              </Col>
              <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }}>
                <h2>Your Travel Information At Your Fingertips</h2>
                <p className="font14">
                  Whether it's a business trip, a family vacation or a quick
                  weekend away, Our competitive rates and exclusive offers are
                  what gives top-notch over our competitors and other travel
                  booking sites. We promise 'SECURE' services both in pricing
                  and quality on every flight booking.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="aboutus-seperator my-md-5 my-4"></div>

      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col md="6">
                <h2>Why OGENIE ?</h2>
                <p className="font14">
                  Go where you need to be, confidently. OGenie App has a smart
                  search filter, such as the number of stop and departure times,
                  to help you find the perfect flight. We make the entire
                  planning and booking experience easy, simple and enjoyable
                  from start to finish.{" "}
                </p>
                <p className="font14">
                  To make sure you get the most for your money, and from your
                  trip. And to make travel booking sites industries more
                  transparent and deserving of your trust. Additionally we have
                  heaps of tips and tricks to help you save more on flight
                  booking.Go where you need to be, confidently. OGenie App has a
                  smart search filter, such as the number of stop and departure
                  times, to help you find the perfect flight. We make the entire
                  planning and booking experience easy, simple and enjoyable
                  from start to finish.{" "}
                </p>
              </Col>
              <Col md="6">
                <Image
                  className="abt-img"
                  src={renderImage("Why-OGENIE.jpg")}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="aboutus-seperator my-md-5 my-4"></div>

      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col xs={{ order: 2 }} md={{ span: 6, order: 1 }}>
                <img
                  className="abt-img"
                  src="https://d1mfzpdx6lagvf.cloudfront.net/og/w/img/No-pressure.jpg"
                  alt=""
                />
              </Col>
              <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }}>
                <h2 className="font-italic about-heading">No Pressure</h2>
                <p className="font14">
                  We never use your data to bump up price or artificially create
                  demand. We believe the price you see should be the price you
                  pay.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="aboutus-seperator my-md-5 my-4"></div>

      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col md="6">
                <h2 className="font-italic about-heading">Our Mission</h2>
                <p className="font14">
                  We Keep Customers at the Centre of everything we do. OGenie
                  aims to cater to your official and leisure traveling needs
                  with every single click or minute you spend on our site.
                  Because it's your world and we'll help you to explore it by
                  giving you the best flight experience.
                </p>
              </Col>
              <Col md="6">
                <img
                  className="abt-img"
                  src="https://d1mfzpdx6lagvf.cloudfront.net/og/w/img/Our-Mission.jpg"
                  alt=""
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="aboutus-seperator my-md-5 my-4"></div>

      {/* <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col xs={{ order: 2 }} md={{ span: 6, order: 1 }}>
                <img
                  className="abt-img"
                  src="https://d1mfzpdx6lagvf.cloudfront.net/og/w/img/Step-into-life-of-privileged.jpg"
                  alt=""
                />
              </Col>
              <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }}>
                <h2 className="font-italic about-heading">
                  Step into life of privileged
                </h2>
                <p className="font14">
                  One subscription , Ample Benefits on OGenie Membership
                  Programme ELITE / PRO. It all started with a clear intention
                  of making travel around and across the world simple and easy
                  for all the people who by heart are travel junkies.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="aboutus-seperator my-md-5 my-4"></div> */}

      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col xs={{ order: 2 }} md={{ span: 6, order: 1 }}>
                <img
                  className="abt-img"
                  src="https://d1mfzpdx6lagvf.cloudfront.net/og/w/img/Hours-of-planning-sort-in-seconds-with-OGenie.jpg"
                  alt=""
                />
              </Col>
              <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }}>
                <h2 className="font-italic about-heading">
                  Hours Of Planning, Sort In Seconds With OGenie
                </h2>
                <p className="font14">
                  We are on a mission to help people and discover the real value
                  of traveling. OGenie Travels App is a new voice-activated
                  travel bot to help you fly effortlessly, and save money on
                  Plane tickets without wasting your time. We believe that
                  travel has the power to change us. And we have the power to
                  change travel.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="aboutus-seperator my-md-5 my-4"></div>

      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <Row className="d-flex align-items-center gx-5 gy-3">
              <Col md="6">
                <h2 className="font-italic about-heading">
                  Try OGenie For Your Next Trip
                </h2>
                <p className="font14">
                  If you have any travel plans in the near future, just give
                  OGenie Travels App a try and indulge yourself in the unmatched
                  flight booking experience offered by it. Quick AI-search and
                  booking, secure payment and fast refund process makes us stand
                  out from the crowd.
                </p>
              </Col>
              <Col md="6">
                <Image
                  src={renderImage("Try-OGenie-for-your-next-trip.jpg")}
                  className="abt-img"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Aboutus;
