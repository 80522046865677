import React, { useState } from "react";
import Image from "../../../commons/artefacts/elements/input/image";
import { Row, Col, Button } from "react-bootstrap";

import TextInput from "../../../commons/artefacts/elements/input/textInput";
import { renderImage } from "../../../commons/util/flightUtil";

const Transactions = () => {
  return (
    <>
      <div className="db-profile py-3">
        <div className="db-tab-container d-flex align-items-center justify-content-center">
          <div className="text-center p-5">
            <Image className="" src={renderImage("no-transaction.svg")} />

            <h2 className="Flight-font-bold Flight-font20 mt-3 text-gray2">
              No Transactions Found
            </h2>
            <p className="Flight-font-semibold text-gray2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <Button className="Flight-btn-purple px-5 mt-2">
              Book a Flight
            </Button>
          </div>
        </div>
        <div className="db-tab-container">
          <div className="container-fluid">
            <Row>
              <Col xl="8" lg="6" md="5" sm="6"></Col>
              <Col xl="4" lg="6" md="7" sm="6">
                <div className="form-group position-relative Flight-log-search">
                  <TextInput
                    className="form-control"
                    placeholder="Search by"
                    required
                  />
                  <Image
                    className="position-absolute img-fluid"
                    src={renderImage("search-icon.svg")}
                  ></Image>
                </div>
              </Col>
            </Row>
            <div className="booking-day py-1 px-3 my-2">
              <Row className="align-items-center">
                <Col className="Flight-font4 Flight-font-semibold">Today</Col>
                <Col className="text-end Flight-font12 Flight-txt-gray Flight-font-semibold">
                  Booking Amount
                </Col>
              </Row>
            </div>
            <ul className="p-0 m-0 booking-list">
              <li className="twoborder px-2">
                <Row>
                  <Col xs="9">
                    <h3 className="Flight-theme-txt Flight-font14 Flight-font-semibold mb-1">
                      Flight Booking{" "}
                      <Image
                        className="ms-1"
                        src={renderImage("flight-booking.svg")}
                      />
                    </h3>
                    <div className="Flight-txt-gray Flight-font12 Flight-font-semibold">
                      IDR to BOM | One Way Flight | Booking ID: GT0963352524 |
                      via NetBanking
                    </div>
                  </Col>
                  <Col xs="3" className="text-end Flight-ln">
                    <div className="Flight-font16  Flight-font-semibold">
                      ₹4,000
                    </div>
                    <span className="text-danger Flight-font12">Failed</span>
                  </Col>
                </Row>
              </li>
              <li className="twoborder px-2">
                <Row>
                  <Col xs="9">
                    <h3 className="Flight-theme-txt Flight-font14 Flight-font-semibold mb-1">
                      Flight Booking
                      <Image
                        className="ms-1"
                        src={renderImage("flight-booking.svg")}
                      />
                    </h3>
                    <div className="Flight-txt-gray Flight-font12 Flight-font-semibold">
                      IDR to BOM | One Way Flight | Booking ID: GT0963352524 |
                      via NetBanking
                    </div>
                  </Col>
                  <Col xs="3" className="text-end Flight-ln">
                    <div className="Flight-font16  Flight-font-semibold">
                      ₹4,000
                    </div>
                    <span className="text-warning Flight-font12">
                      in process
                    </span>
                  </Col>
                </Row>
              </li>
              <li className="twoborder px-2">
                <Row>
                  <Col xs="9">
                    <h3 className="Flight-theme-txt Flight-font14 Flight-font-semibold mb-1">
                      Flight Booking{" "}
                      <Image
                        className="ms-1"
                        src={renderImage("flight-booking.svg")}
                      />
                    </h3>
                    <div className="Flight-txt-gray Flight-font12 Flight-font-semibold">
                      IDR to BOM | One Way Flight | Booking ID: GT0963352524 |
                      via NetBanking
                    </div>
                  </Col>
                  <Col xs="3" className="text-end Flight-ln">
                    <div className="Flight-font16  Flight-font-semibold">
                      ₹10,000
                    </div>
                    <span className="text-success Flight-font12">
                      Successful
                    </span>
                  </Col>
                </Row>
              </li>
            </ul>
            <div className="booking-day py-1 px-3 my-2">
              <Row className="align-items-center">
                <Col className="Flight-font4 Flight-font-semibold">
                  Thu, 20 Jan 2023
                </Col>
              </Row>
            </div>
            <ul className="p-0 m-0 booking-list">
              <li className="twoborder px-2">
                <Row>
                  <Col xs="9">
                    <h3 className="Flight-theme-txt Flight-font14 Flight-font-semibold mb-1">
                      Flight Booking{" "}
                      <Image
                        className="ms-1"
                        src={renderImage("flight-booking.svg")}
                      />
                    </h3>
                    <div className="Flight-txt-gray Flight-font12 Flight-font-semibold">
                      IDR to BOM | One Way Flight | Booking ID: GT0963352524 |
                      via NetBanking
                    </div>
                  </Col>
                  <Col xs="3" className="text-end Flight-ln">
                    <div className="Flight-font16  Flight-font-semibold">
                      ₹4,000
                    </div>
                    <span className="text-danger Flight-font12">Failed</span>
                  </Col>
                </Row>
              </li>
              <li className="twoborder px-2">
                <Row>
                  <Col xs="9">
                    <h3 className="Flight-theme-txt Flight-font14 Flight-font-semibold mb-1">
                      Flight Booking
                      <Image
                        className="ms-1"
                        src={renderImage("flight-booking.svg")}
                      />
                    </h3>
                    <div className="Flight-txt-gray Flight-font12 Flight-font-semibold">
                      IDR to BOM | One Way Flight | Booking ID: GT0963352524 |
                      via NetBanking
                    </div>
                  </Col>
                  <Col xs="3" className="text-end Flight-ln">
                    <div className="Flight-font16  Flight-font-semibold">
                      ₹4,000
                    </div>
                    <span className="text-warning Flight-font12">
                      in process
                    </span>
                  </Col>
                </Row>
              </li>
              <li className="twoborder px-2">
                <Row>
                  <Col xs="9">
                    <h3 className="Flight-theme-txt Flight-font14 Flight-font-semibold mb-1">
                      Flight Booking{" "}
                      <Image
                        className="ms-1"
                        src={renderImage("flight-booking.svg")}
                      />
                    </h3>
                    <div className="Flight-txt-gray Flight-font12 Flight-font-semibold">
                      IDR to BOM | One Way Flight | Booking ID: GT0963352524 |
                      via NetBanking
                    </div>
                  </Col>
                  <Col xs="3" className="text-end Flight-ln">
                    <div className="Flight-font16  Flight-font-semibold">
                      ₹10,000
                    </div>
                    <span className="text-success Flight-font12">
                      Successful
                    </span>
                  </Col>
                </Row>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
