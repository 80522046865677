import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { Container, Row } from "react-bootstrap";
const PaymentGateway = () => {
  const { invoiceId } = useParams();
  const buttonRef = useRef(null);

  let paymentData;

  paymentData = JSON.parse(atob(invoiceId)); // Decode base64 and parse JSON
  useEffect(() => {
    // Trigger the click event on the button
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, []);

  return (
    <Container className="Flight-container ">
      <Row className="Flight-row">
        <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
          <input type="hidden" name="key_id" value={paymentData.rpKeyId} />
          <input type="hidden" name="amount" value={paymentData.amount} />
          <input type="hidden" name="order_id" value={paymentData.checkoutOrderId} />
          <input type="hidden" name="name" value="OGenie Travels" />
          {
            // <input type="hidden" name="description" value="Ogenie kal jaana"/>
          }
          <input type="hidden" name="image" value="https://ogenietravels.com/public/scripts/img/header-logo.svg" />
          {
            // <input type="hidden" name="prefill[name]" value="Gaurav Kumar"/>       
           // <input type="hidden" name="notes[shipping address]" value="L-16, The Business Centre, 61 Wellfield Road, New Delhi - 110001" />
          }
          <input type="hidden" name="prefill[contact]" value={paymentData.contact}/>
          <input type="hidden" name="prefill[email]" value={paymentData.mail}/>
          <input type="hidden" name="callback_url" value={`${paymentData.callbackUrl}${paymentData.checkoutOrderId}`} />
          <input type="hidden" name="cancel_url" value={`${paymentData.cancelUrl}`} />
          <button ref={buttonRef} style={{ display: 'none' }} >Submit</button>
        </form>
      </Row>
    </Container>
  );
};

export default PaymentGateway;
