import _ from "lodash";

const flightSort = (flights, sortColumn) => {
  const path =
    sortColumn.path === "name"
      ? "sI[0].fD.aI.name"
      : sortColumn.path === "dt"
      ? "sI[0].dt"
      : sortColumn.path === "TF"
      ? "minFare"
      : "";

  return _.orderBy(
    flights,
    (obj) => {
      if (sortColumn.path !== "duration")
        return _.get(
          obj,
          sortColumn.path === "at" ? `sI[${obj.sI.length - 1}].at` : path
        );
      else if (sortColumn.path === "duration")
        return obj.sI.reduce((acc, curr) => {
          acc += curr.duration;
          if ("cT" in curr) {
            acc += curr.cT;
          }
          return acc;
        }, 0);
    },
    [sortColumn.order]
  );
};

export default flightSort;
