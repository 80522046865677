import React from "react";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";
import { getDateByFormat } from "../../../commons/util/dateTimeUtil";

const SelectionBar = ({ depCity, depCode, arrCity, arrCode, date }) => {
  return (
    <div className="Flight-selection-bar">
      <div className="destination">
        <Image
          className="me-2 d-md-inline-block d-none"
          src={renderImage("ap-icon.svg")}
        />
        <span className="d-lg-inline d-none">{depCity}</span>
        <span className="d-lg-none">{depCode}</span>
        <span className="mx-2">
          <Image src={renderImage("oneway-arrow1.svg")} />
        </span>
        <span>
          <span className="d-lg-inline d-none">{arrCity}</span>
          <span className="d-lg-none">{arrCode}</span>
        </span>
      </div>
      <div className="details">
        <span>{getDateByFormat(date)}</span>
      </div>
    </div>
  );
};

export default SelectionBar;
