import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Popover } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../commons/artefacts/elements/input/image";
import ExternalsManager from "../../../commons/externals/ExternalsManager";
import string from "../../../commons/util/UtilManager";
import { renderImage } from "../../../commons/util/flightUtil";
import numberToIndCurr from "../../../commons/util/priceUtil";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  getDateByFormat,
  getTimeFromString,
} from "../../../commons/util/dateTimeUtil";

const PaymentStatus = () => {
  const history = useHistory();
  const { invoiceId } = useParams();
  const [currentWindow, setCurrentWindow] = useState(0);
  const [PNR, setPNR] = useState({});
  const [transactionId, setTransactionId] = useState("00000");
  const [errorMessage, setErrorMessage] = useState("");
  const [ticketData, setTicketData] = useState([]);

  const [orderId, setOrderId] = useState("");
  useEffect(() => {
    handleBooking();
  }, []);

  const handleBooking = async () => {
    let response = await ExternalsManager?.API.post("/bookflight", {
      invoiceNo: invoiceId,
    });
    if (response?.type === "success" && response?.message === "Ok") {
      // Extracting the bookingDetails object from the response
      const { bookingDetails } = response?.data;

      // Extracting the pnrDetails object from bookingDetails, or initializing an empty object if it doesn't exist
      let pnrObj = bookingDetails?.pnrDetails || {};

      // Converting the pnrObj object into an array of key-value pairs and updating the state with setPNR
      setPNR(pnrObj);

      // Setting the orderId state from the response data's _id property
      setOrderId(response?.data?._id);

      // Creating a deep copy of the bookingDetails object
      let tempBD = JSON.parse(JSON.stringify(bookingDetails));

      // Distributing the ssrValues to each sI (sub-item) in the tripInfos array
      tempBD.tripInfos.map((x, i) => {
        let allSiKeys = [];

        // Extracting all sI keys from the sI array in each tripInfo
        x.sI.forEach((y) => {
          allSiKeys.push(y.id);
        });

        // Creating a deep copy of the paxInfo array
        let paxCopy = tempBD.paxInfo.map((y) => ({ ...y }));

        // Updating ssrSeatInfos, ssrMealInfos, and ssrBaggageInfos for each passenger in sI[0]
        x.sI[0].paxInfo = paxCopy.map((y) => {
          // Filtering the ssrSeatInfos array to include only the ones with keys present in allSiKeys
          let tempSeatInfos = y.ssrSeatInfos.filter((seatSsr) =>
            allSiKeys.includes(seatSsr.key)
          );
          y.ssrSeatInfos = tempSeatInfos;

          // Filtering the ssrMealInfos array to include only the ones with keys present in allSiKeys
          let tempMealInfos = y.ssrMealInfos.filter((mealSsr) =>
            allSiKeys.includes(mealSsr.key)
          );
          y.ssrMealInfos = tempMealInfos;

          // Filtering the ssrBaggageInfos array to include only the ones with keys present in allSiKeys
          let tempBaggageInfos = y.ssrBaggageInfos.filter((bagSsr) =>
            allSiKeys.includes(bagSsr.key)
          );
          y.ssrBaggageInfos = tempBaggageInfos;

          return y;
        });

        return x;
      });

      // If tempBD exists, set the current window to 3
      if (tempBD) setCurrentWindow(3);

      // Set the ticketData state with the updated tempBD
      setTicketData(tempBD);
    } else if (
      response?.type === "success" &&
      response?.message === "Payment Failed"
    ) {
      setCurrentWindow(2);
    } else if (response?.type === "failure") {
      setCurrentWindow(4);
      setTransactionId(response?.transactionId);
      setOrderId(response?.message?.data?._id);
      setErrorMessage(
        response?.message?.errorMsg
          ? response?.message?.errorMsg
          : response?.message?.errorMessage
      );
    } else {
      setCurrentWindow(4);
      setTransactionId([]);
      setOrderId([]);
      setErrorMessage(
        response?.message?.errorMsg
          ? response?.message?.errorMsg
          : response?.message?.errorMessage
          ? response?.message?.errorMessage
          : response?.message
      );
    }
  };

  const { totalFareDetail, convFee, paxInfo } = ticketData || {};

  // Addons price calculation

  function calculatePrices(paxInfo) {
    const priceMapping = {}; // To store price for each passenger type

    paxInfo?.length > 0 &&
      paxInfo.forEach((passenger) => {
        const type = passenger.type;
        if (!priceMapping[type]) {
          priceMapping[type] = {
            count: 0,
            price: 0,
          };
        }
        priceMapping[type].count++;

        passenger.ssrSeatInfos?.forEach((seatInfo) => {
          priceMapping[type].price += seatInfo.amount;
        });

        passenger.ssrMealInfos?.forEach((mealInfo) => {
          priceMapping[type].price += mealInfo.amount;
        });

        passenger.ssrBaggageInfos?.forEach((baggageInfo) => {
          priceMapping[type].price += baggageInfo.amount;
        });
      });

    const obj = {};
    for (const [type, { count, price }] of Object.entries(priceMapping)) {
      obj[`${type}count`] = count;
      obj[`${type}price`] = price;
    }
    return obj;
  }

  const result = calculatePrices(paxInfo);

  let addOnPrice = (result?.ADULTprice || 0) + (result?.CHILDprice || 0);

  // Addons
  const feeBreakupFullForms = {
    //if extra keys updated by service provider then added here
    OT: "Other Charges",
    AGST: "Airline GST",
    YQ: "Fuel Surcharge",
    YR: "Carrier Misc Fee",
    BF: "Base Fare",
    TAF: "Taxes and Fees",
    CF: "Convenience Fee",
  };

  const handlePopoverClick = () => {
    if (totalFareDetail) {
      const popoverContent = (
        <div className="Flight-fd-table Flight-font14">
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <div className="Flight-font-medium me-3">Base Fare</div>
              <div className="text-end Flight-font-medium Flight-font-medium">
                {numberToIndCurr(totalFareDetail?.fC?.BF)}
              </div>
            </div>
            {Object.entries(totalFareDetail?.afC?.TAF).map(([key, value]) => {
              if (key !== "MF" && key !== "MFT") {
                return (
                  <div className="d-flex justify-content-between" key={key}>
                    <div className="Flight-font-medium me-3">
                      {feeBreakupFullForms[key]}
                    </div>
                    <div className="text-end Flight-font-medium Flight-font-medium">
                      {numberToIndCurr(value)}
                    </div>
                  </div>
                );
              }
            })}
            <div className="d-flex justify-content-between">
              <div className="Flight-font-medium me-3">Addons</div>
              <div className="text-end Flight-font-medium Flight-font-medium">
                {numberToIndCurr(addOnPrice ? addOnPrice : 0)}
              </div>
            </div>
          </div>
        </div>
      );

      const popover = (
        <Popover
          className="popover-dark"
          id="fee-breakup-popover"
          title="Fee & Surcharges Breakdown">
          {popoverContent}
        </Popover>
      );
      return popover;
    } else {
      return null;
    }
  };

  const sectorInfo = [];

  if (currentWindow === 0) {
    return (
      <div className="db-tab-container d-flex align-items-center justify-content-center">
        <Container className="mt-5 p-3 Flight-container">
          <Card className="text-center border-0 box-shadow-none">
            <Card.Body>
              <Image
                alt="calender"
                src={renderImage("under-process-booking.svg")}
              />
              <Card.Title className="mt-3">Booking Under Process</Card.Title>
              <p className="font14">
                Please do not refresh the page or click the back button while
                your booking is processing.
              </p>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  } else if (currentWindow === 1) {
    return (
      <Container className="Flight-container">
        <Row className="Flight-row">
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="booking-msg py-3 mx-md-5 text-center position-relative mt-5">
              <Image
                alt="booking"
                width={120}
                src={renderImage("booking-checked.svg")}
              />
              <div className="pmt-msg py-3">
                <h2 className="Flight-font20 mb-1 Flight-ln">
                  Payment Successful
                </h2>
                <h4 className="fw-normal pmt-pnr font13 Flight-ln">
                  PNR Number: <span>{PNR}</span>
                </h4>
                <p className="after-pmt-msg text-center font13  px-xl-5 px-lg-4 px-3 mx-3 mx-lg-5  mb-0">
                  Your Payment has successful complete. You can check your
                  booking in your registered email
                </p>
              </div>
              <div className="dshd-sprtr "></div>
              <div className="amt text-xl-center  px-xxl-5 px-xl-4 px-3  pt-3 mx-xxl-3">
                <Row className="amt-paid align-items-center justify-content-center Flight-row">
                  <Col xs="4">
                    <h5 className="font13 m-0 text-start mb-0 Flight-ln">
                      Amount Paid
                    </h5>
                  </Col>
                  <Col xs="1" className="p-0">
                    :
                  </Col>
                  <Col xs="7" className="text-start">
                    <p className="mb-0 font13">&#x20B9;33.995.00</p>
                  </Col>
                </Row>
                <Row className="amt-paid align-items-center justify-content-center Flight-row">
                  <Col xs="4">
                    <h5 className="font13 m-0 text-start mb-0 Flight-ln">
                      Payment Mode
                    </h5>
                  </Col>
                  <Col xs="1" className="p-0">
                    :
                  </Col>
                  <Col xs="7" className="text-start">
                    <p className="m-0 font13">
                      XXXX XXXX XXXX 1234{" "}
                      <span>
                        <Image
                          alt="cc"
                          className="img-fluid h-100 ms-2"
                          width={20}
                          src={renderImage("credit-card.png")}
                        />
                      </span>{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="text-center font14 mt-xl-4 mt-3">
              <p className="mb-0">
                If you have any queries or suggestion, please get in touch with
                us:{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="mailto:support@ogenietravels.com">
                  support@ogenietravels.com
                </a>{" "}
                or (WhatsApp Chat Only)
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+91-7898911333">
                  +91-7898911333
                </a>{" "}
                /{" "}
                {/* <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 22012001"
                >
                  080-22012001
                </a>{" "}
                /{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 66785555"
                >
                  080-66785555
                </a> */}
              </p>
              <Button className="btn  mt-4 Flight-search-flight-btn font20 text-white  border-0">
                Done
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else if (currentWindow === 2) {
    return (
      <Container className="Flight-container">
        <Row className="Flight-row">
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="booking-msg py-3 mx-md-5 text-center position-relative mt-5">
              <Image width={120} src={renderImage("booking-failed.svg")} />{" "}
              <h2 className="font20 mb-1 mt-3 Flight-ln">Payment Failed</h2>
              <p className="font13 mb-2">
                Transaction number : <span>{transactionId}</span>
                <br />
                Your ticket booking is failed please try again
              </p>
            </div>
            <div className="text-center font14 mt-xl-4 mt-3">
              <p className="mb-0">
                If you have any queries or suggestion, please get in touch with
                us:{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="mailto:support@ogenietravels.com">
                  support@ogenietravels.com
                </a>{" "}
                or (WhatsApp Chat Only)
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+91-78989113336">
                  +91-7898911333
                </a>{" "}
                /{" "}
                {/* <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 22012001"
                >
                  080-22012001
                </a>{" "}
                /{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 66785555"
                >
                  080-66785555
                </a> */}
              </p>
              <Button
                className="btn mt-4 Flight-search-flight-btn font20 text-white  border-0"
                onClick={() => history.push("/")}>
                Done
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else if (currentWindow === 3) {
    const { cabinClass, paxInfo, displayAmount } = ticketData;
    return (
      <>
        {/* <div className="black-bg py-4 mb-3 px-2">
          <Container>
            <h3 className="text-white Flight-font-bold Flight-ln">
              We've got your order, {paxInfo && paxInfo[0]?.fN}{" "}
              {paxInfo && paxInfo[0]?.lN}
            </h3>
            <div className="b-Id mt-4 rounded Flight-font-bold py-2 px-3">
              Order ID: {orderId && orderId}
            </div>
          </Container> 
        </div> */}
        <Container className="head-txt position-relative top-0 text-center mt-5 mb-3">
          <h1>Congratulations</h1>
          <h1>your flight ticket has been booked</h1>
        </Container>
        <Container className="Flight-container">
          <Row className="Flight-row">
            <Col xl="4" md="6" sm="8" className="m-auto">
              <div className="Flight-ticket-main py-3 position-relative">
                {(ticketData?.tripInfos || []).map((item, i) => {
                  const { sI } = item;
                  const sectrDtls = [];
                  for (let index = 0; index < sI.length; index++) {
                    const element = sI[index];
                    let sectorCode = `${element?.da?.code}-${element?.aa?.code}`;
                    let j = {
                      sector: sectorCode,
                      airline: `${element?.fD?.aI?.code}-${element?.fD?.fN}`,
                      pnr: PNR[sectorCode],
                      airlineCode: element?.fD?.aI?.code,
                    };
                    sectrDtls.push(j);
                  }

                  sectorInfo.push(sectrDtls);
                  return (
                    <>
                      <Container className="position-relative px-md-4 px-3 pt-2 pb-3 Flight-container">
                        <div className="text-left d-flex">
                          <div className="Flight-logo-sec me-2 multiairline-logo">
                            {sectorInfo[i].map((item, itemIndex) => (
                              <Image
                                alt="ak"
                                width={120}
                                src={renderImage(item?.airlineCode)}
                              />
                            ))}
                          </div>
                          <div>
                            <div className="d-flex align-items-center gap-2">
                              <h6 className="Flight-font16 Flight-font-semibold m-0 Flight-ln">
                                {sI[0]?.da?.cityCode}
                              </h6>
                              <Image
                                width={18}
                                src={renderImage("tck_plane.svg")}
                              />{" "}
                              <h6 className="Flight-font16 Flight-font-semibold m-0 Flight-ln">
                                {sI[sI?.length - 1]?.aa?.cityCode}
                              </h6>
                            </div>
                            <span className="Flight-font13 d-block font-semibold Flight-font-ocity08">
                              {getDateByFormat(sI[0]?.dt)}
                            </span>
                          </div>
                        </div>
                      </Container>
                      <div className="dshd-sprtr "></div>

                      <Container className="Flight-container px-md-4 px-3 py-3">
                        <Row className="Flight-row">
                          <Col>
                            <div className="d-block font-semibold Flight-font-ocity08">
                              Departure ({sI[0]?.da?.cityCode})
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {`${getTimeFromString(sI[0]?.dt)}`}{" "}
                              {getDateByFormat(sI[0]?.dt)}
                            </div>
                          </Col>
                          <Col>
                            <div className="d-block font-semibold Flight-font-ocity08">
                              Arrival ({sI[sI?.length - 1]?.aa?.cityCode})
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {sI?.length > 1
                                ? `${getTimeFromString(sI[sI?.length - 1]?.at)}`
                                : `${getTimeFromString(sI[0]?.at)}`}{" "}
                              {sI?.length > 1
                                ? getDateByFormat(sI[sI?.length - 1]?.at)
                                : getDateByFormat(sI[0]?.at)}
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <div className="dshd-sprtr"></div>
                      <div className="Flight-ticket-bg">
                        <Image
                          alt="ticket"
                          width={190}
                          src={renderImage("ticket-bg.png")}
                        />{" "}
                      </div>
                      <Container className="position-relative px-md-4 px-3 py-3 Flight-container">
                        <div className="Flight-box-stretch Flight-tick-stretch">
                          <div>
                            <div className="d-block font-semibold Flight-font-ocity08">
                              Class
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {string.string.transform.capitalizeEachWord(
                                cabinClass.replace("_", " ")
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="font-semibold Flight-font-ocity08">
                              Trip ID
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {orderId && orderId.slice(-5)}
                            </div>
                          </div>
                          <div>
                            <div className="d-block font-semibold Flight-font-ocity08">
                              Fare
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {item?.fare
                                ? string.string.transform.capitalizeEachWord(
                                    item?.fare?.replace(/_/g, " ")
                                  )
                                : "N/A"}
                            </div>
                          </div>

                          <div>
                            <div className="d-block font-semibold Flight-font-ocity08">
                              Sector
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {sectorInfo[i].map((item, itemIndex) => (
                                <div key={itemIndex}>
                                  <div className="Flight-font15 Flight-font-semibold">
                                    {item.sector}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div>
                            <div className="font-semibold Flight-font-ocity08">
                              Airline No
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {sectorInfo[i].map((item, itemIndex) => (
                                <div key={itemIndex}>
                                  <div className="Flight-font15 Flight-font-semibold">
                                    {item.airline}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div>
                            <div className="d-block font-semibold Flight-font-ocity08">
                              PNR
                            </div>
                            <div className="Flight-font15 Flight-font-semibold">
                              {sectorInfo[i].map((item, itemIndex) => (
                                <div key={itemIndex}>
                                  <div className="Flight-font15 Flight-font-semibold">
                                    {item.pnr || "NA"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="Flight-box-stretch Flight-tick-stretch mt-3">
                            <div className="font-semibold Flight-font-ocity08">
                              Traveller(s)
                            </div>
                            <div className="font-semibold Flight-font-ocity08">
                              Seat
                            </div>
                            <div className="font-semibold Flight-font-ocity08">
                              Meal
                            </div>
                          </div>
                          {item?.sI[0]?.paxInfo?.map((infos) => {
                            let isSeat = infos.ssrSeatInfos?.length > 0;
                            let isMeal = infos.ssrMealInfos?.length > 0;
                            // let isBaggage = infos?.ssrBaggageInfos.length > 0;
                            return (
                              <div
                                className="Flight-box-stretch Flight-tick-stretch Flight-font15 Flight-font-semibold"
                                key={infos._id}>
                                <div>
                                  {infos.ti + " " + infos.fN + " " + infos.lN}
                                </div>
                                <div>
                                  {isSeat
                                    ? infos.ssrSeatInfos.map(
                                        (seatInfo, index) => (
                                          <span key={seatInfo._id}>
                                            {index > 0 ? ", " : ""}
                                            {seatInfo.code}
                                          </span>
                                        )
                                      )
                                    : "N/A"}
                                </div>
                                <div>
                                  {isMeal
                                    ? infos.ssrMealInfos.map(
                                        (mealInfo, index) => (
                                          <span key={mealInfo._id}>
                                            {index > 0 ? ", " : ""}
                                            {mealInfo.code}
                                          </span>
                                        )
                                      )
                                    : "N/A"}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Container>
                      <div
                        className={
                          ticketData?.tripInfos.length === 2 && i === 0
                            ? "d-none"
                            : "dshd-sprtr "
                        }></div>
                      <Container
                        className={
                          ticketData?.tripInfos.length === 2 && i === 0
                            ? "d-none"
                            : "px-md-4 px-3 pb-0 pt-3 Flight-container"
                        }>
                        <Row className="Flight-row">
                          <Col>
                            <div className="Flight-font14 d-block font-semibold Flight-font-ocity08">
                              You Paid
                              <OverlayTrigger
                                trigger="click"
                                overlay={handlePopoverClick()}
                                rootClose>
                                <Image
                                  className=" mx-1 Fligh-gray-img op-2"
                                  src={renderImage(
                                    "search-result/info-icon.svg"
                                  )}
                                />
                              </OverlayTrigger>
                            </div>
                            <div className="Flight-font16 Flight-font-semibold">
                              {numberToIndCurr(displayAmount)}
                            </div>
                          </Col>
                          <Col className="text-end d-flex justify-content-end align-items-center">
                            <Button
                              variant="light"
                              onClick={() =>
                                history.push({
                                  pathname: "/dashboard",
                                  state: { ticketData },
                                })
                              }
                              className="btn Flight-btn Flight-btn-purple Flight-font14 border-0">
                              View Booking
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </>
                  );
                })}
              </div>
            </Col>
          </Row>

          <div className="my-3 text-center">
            <Button
              variant="light"
              className="btn Flight-btn Flight-btn-purple d-flex align-items-center justify-content-center mx-auto Flight-font14 border-0"
              onClick={() => history.push("/")}>
              <Image className="me-2" src={renderImage("home.svg")} />
              Back to Home
            </Button>
          </div>
        </Container>
      </>
    );
  } else if (currentWindow === 4) {
    return (
      <Container className="Flight-container">
        <Row className="Flight-row">
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="booking-msg py-3 mx-md-5 text-center position-relative mt-5">
              <Image
                alt="ticket"
                width={120}
                src={renderImage("booking-failed.svg")}
              />{" "}
              <h2 className="font20 mb-1 mt-3 Flight-ln">Booking Failed.</h2>
              <p className="font13 mb-2">
                {orderId.lenghth > 0 && (
                  <span>Order ID:{orderId.slice(-5)}</span>
                )}
                <br />
                Your ticket booking is failed please try again
              </p>
              <p className="font13 mb-2">
                {errorMessage?.includes("Duplicate Booking")
                  ? "Duplicate Booking."
                  : errorMessage}
              </p>
            </div>
            <div className="text-center font14 mt-xl-4 mt-3">
              <p className="mb-0">
                If you have any queries or suggestion, please get in touch with
                us:{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="mailto:support@ogenietravels.com">
                  support@ogenietravels.com
                </a>{" "}
                or (WhatsApp Chat Only)
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+91-7898911333">
                  +91-7898911333
                </a>{" "}
                /{" "}
                {/* <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 22012001"
                >
                  080-22012001
                </a>{" "}
                /{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 66785555"
                >
                  080-66785555
                </a> */}
              </p>
              <Button
                className="btn mt-4 Flight-search-flight-btn font20 text-white  border-0"
                onClick={() => history.push("/")}>
                Done
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  return null;
};

export default PaymentStatus;
