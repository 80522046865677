const numberToIndCurr = (number) => {
  return Math.max(0, Math.ceil(number)).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const calculateFare = (fare, { adult, child, infant }, mgtFee) => {
  const {
    fd: {
      ADULT: {
        fC: { TF: aTF },
      },
      CHILD: { fC: { TF: cTF = 0 } = {} } = {},
      INFANT: { fC: { TF: iTF = 0 } = {} } = {},
    },
  } = fare;
  if (mgtFee) {
    return adult * aTF + child * cTF + infant * iTF - mgtFee;
  } else {
    return adult * aTF + child * cTF + infant * iTF;
  }
};

export default numberToIndCurr;
