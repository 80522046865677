import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import OneWay from "../web/pages/one-way-search/OneWay";
import RoundTrip from "../web/pages/round-trip-search/RoundTrip";
import FlightDetail from "../web/pages/flight-details/FlightDetail";
import RoundTripInt from "../web/pages/round-trip-search/RoundTripInt";
import Booking from "../web/pages/Booking/Booking";
import Flights from "../web/pages/home/Flights";
import ReviewContextProvider from "../commons/context/reviewContext";
import Cancellation from "../web/pages/fight-cancellation/cancellation";
import VendorManagement from "../web/pages/vendorManagement/VendorManagement";
import SSR from "../web/pages/vendorManagement/SSR";
import Transactionlogs from "../web/pages/vendorManagement/Transactionlogs";
import Conveniencefees from "../web/pages/vendorManagement/Conveniencefees";
import RescheduleFlight from "../web/pages/vendorManagement/RescheduleFlight";
import PaymentStatus from "../web/pages/Booking/PaymentStatus";
import Dashboard from "../web/pages/dashboard/Dashboard";
import PaymentGateway from "../web/pages/Booking/PaymentGateway";

import Faqs from "../web/pages/StaticPages/Faqs";
import PrivacyPolicy from "../web/pages/StaticPages/PrivacyPolicy";
import RefundandCancellation from "../web/pages/StaticPages/RefundandCancellation";
import TermsandConditions from "../web/pages/StaticPages/TermsandConditions";
import WebCheckIn from "../web/pages/StaticPages/WebCheckIn";
import Aboutus from "../web/pages/StaticPages/Aboutus";

function RootSwitch() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/FlightList" component={OneWay} />
        <Route path="/FlightListRT" component={RoundTrip} />
        <Route path="/FlightListRTInt" component={RoundTripInt} />
        <Route
          path="/review"
          render={() => (
            <ReviewContextProvider>
              <FlightDetail />
            </ReviewContextProvider>
          )}
        />
        <Route path="/flights" component={Flights} />

        <Redirect exact from="/" to="/flights" />
        <Route path="/booking" component={Booking} />
       

       {/*  <Redirect exact from="/admin" to="/admin/vendors" />
        <Route path="/admin/vendors" component={VendorManagement} />
        <Route path="/admin/ssr" component={SSR} />
        <Route path="/admin/logs" component={Transactionlogs} />
        <Route path="/admin/fee" component={Conveniencefees} />
          <Route path="/admin/reschedule" component={RescheduleFlight} />  */}
        <Route path="/cancellation" component={Cancellation} />
        <Route path="/paymentstatus/:invoiceId" component={PaymentStatus} />
        <Route path="/paymentgateway/:invoiceId" component={PaymentGateway} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/aboutus" component={Aboutus} />
        <Route path="/termsandconditions" component={TermsandConditions} />
        <Route
          path="/refundandcancellation"
          component={RefundandCancellation}
        />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/webcheckin" component={WebCheckIn} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default RootSwitch;
