import React from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Col } from "react-bootstrap";
import Button from "../../elements/button/primary";
import Image from "../../elements/input/image";
import { renderImage } from "../../../util/flightUtil";

const DataNotFound = () => {
  const history = useHistory();

  return (
    <Col xl={{ span: 6, offset: 3 }}>
      <div className="Flight-search-inner">
        <Card className="text-center border-0 box-shadow-none">
          <Card.Body>
            <Image alt="calender" src={renderImage("flight-not-found.svg")} />
            <Card.Title className="mt-3">Sorry, Flights Not Found</Card.Title>
            <>
              <p className="Flight-font14">
                We could not find flights for this search. Please go back to
                make a different selection.
              </p>
              <Button
                className="btn Flight-btn Flight-btn-purple font20 text-white border-0"
                onClick={() => history.push("/flights")}
              >
                Go Back
              </Button>
            </>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default DataNotFound;
