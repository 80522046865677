import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="abt-wrap">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <h2 className="text-center my-lg-5 my-4">Privacy Policy</h2>
              <div className="single-line-tc mb-3">
                <span className="mallory-mediuam font12">
                  <b>Last Updated in Aug 2022</b>
                </span>
              </div>
              <div className="data-container">
                <div className="single-line-tc">
                  <h3>Introduction</h3>

                  <p>
                    At OGenie, we value your trust and respect your privacy. We
                    are committed to the protection of the personal information
                    provided by you to us. This privacy policy ("Privacy
                    Policy") provides you with details about the manner your
                    data is collected, stored, disclosed and used by us. Please
                    note that the Privacy Policy shall apply regardless of
                    whether you use a computer, mobile phone, tablet,
                    television, or other devices to access our Services. It is
                    important that you read the Privacy Policy before you use
                    our Services and consent to the practices we describe in our
                    Privacy Policy. All users of the Platform are advised to
                    read this Privacy Policy carefully. &nbsp;If you do not
                    agree to the terms of this Privacy Policy, please refrain
                    from using the Platform. When you browse through the
                    Platform and/or avail the Services, you agree to be bound by
                    this Privacy Policy. We ensure compliance with applicable
                    laws by periodically updating this Privacy Policy. Hence,
                    you are strongly advised to read this policy carefully from
                    time to time.
                  </p>
                </div>

                <div className="single-line-tc">
                  <h3>Regulatory Framework Scope &amp; Applicability</h3>

                  <p>
                    This Privacy Policy is published in compliance with, inter
                    alia: (i) the Information Technology Act, 2000; (ii)
                    Regulation 4 of the Information Technology (Reasonable
                    Security Practices and Procedures and Sensitive Personal
                    Information) Rules, 2011 ("
                    <b>Data Security Rules</b>"); and (iii) Regulation 3(1) of
                    the Information Technology (Intermediaries Guidelines)
                    Rules, 2011.
                  </p>

                  <p>
                    This Privacy Policy describes your privacy rights regarding
                    our collection, use, storage, sharing and protection of your
                    information including, your personal information. This
                    Privacy Policy applies to personal information collected by
                    us in connection with the Services. This includes
                    information collected offline through our customer
                    engagement centres, direct marketing campaigns, marketing
                    and advertising practices and online through our Platforms,
                    mobile applications, services, tools and branded pages on
                    third-party platforms and applications accessed or used
                    through such Platforms or third-party platforms ("
                    <b>Company Sites</b>") regardless of how you access or use
                    them.
                  </p>

                  <p>
                    This Privacy Policy also applies to our targeted content,
                    including offers and advertisements for Services, which we
                    (or a service provider acting on our behalf) may send to you
                    on third party Platforms, platforms and applications ("
                    <b>Third-Party Sites</b>"). These Third-Party Sites may have
                    their own privacy policies and terms and conditions. We
                    encourage you to read them before using those Third-Party
                    Sites.
                  </p>

                  <p>
                    This Privacy Policy is hereby incorporated into and forms
                    part of the{" "}
                    <a
                      className="Flight-theme-txt"
                      href="https://ogenietravels.com/terms_and_conditions"
                    >
                      Terms and Conditions
                    </a>{" "}
                    available. Capitalized terms used but not defined in this
                    Privacy Policy shall have the meaning attributed to it in
                    our Terms and Conditions.
                  </p>

                  <p>
                    This Privacy Policy states the following: (i) the type of
                    information collected from the Users, including Personal
                    Information (as defined below) and Sensitive Personal Data
                    or Information (as defined below); (ii) the purpose, means
                    and modes of collection, usage, processing, retention and
                    destruction of such information; and (iii) how and to whom
                    we will disclose such information.
                  </p>

                  <p>
                    The information collected from you by OGenie may constitute
                    'personal information' or 'sensitive personal data or
                    information' as defined under the Data Security Rules. "
                    <b>Personal Information</b>"&nbsp;is defined under the Data
                    Security Rules to mean any information that relates to a
                    natural person, which, either directly or indirectly, in
                    combination with other information available or likely to be
                    available to a body corporate, is capable of identifying
                    such person. The Data Security Rules further define "
                    <b>Sensitive Personal Data or Information</b>" of a person
                    to mean such personal information about that person which
                    consists of information relating to: (i) passwords; (ii)
                    financial information such as bank accounts, credit and
                    debit card details or other payment instrument details;
                    (iii) physical, physiological and mental health condition;
                    (iv) sexual orientation; (v) medical records and history;
                    (vi) biometric information; (vii) any detail relating to the
                    above clauses as provided to body corporate for providing
                    services; and (viii) any of the information received under
                    the above clauses by body corporate for processing, stored
                    or processed under lawful contract or otherwise.
                  </p>

                  <p>
                    Company may offer you the ability to connect with the
                    Services using a mobile device, either through a mobile
                    application or via a mobile optimized Platform. The
                    provisions of this Privacy Policy apply to all such mobile
                    access and use of mobile devices.
                  </p>

                  <p>
                    The Platform and our Services are designed and intended for
                    use by adults. If you are a minor, please do not share your
                    personal data or information. If we discover that we have
                    collected information from a minor, we will delete that
                    information as soon as possible. In no event shall the
                    Company be liable for any information provided by a minor.
                    We may use your personal information to carry out age
                    verification checks and enforce any age restrictions
                    applicable to the Account.
                  </p>

                  <p>
                    The general provisions as outlined in the Terms and
                    Conditions shall be applicable to this Privacy Policy as
                    well.
                  </p>
                </div>

                <div className="single-line-tc">
                  <h3>Your Consent</h3>

                  <p>
                    You specifically accept this Privacy Policy when you access
                    and/or use the Platform, the Services and the Company Sites.
                  </p>

                  <p>
                    By accessing, browsing and/or using the Platform, the
                    Services and the Company Sites, you are specifically
                    consenting to OGenie collecting, using, storing, sharing and
                    disclosing your personal information in accordance with this
                    Privacy Policy. If you do not agree to the collection, use,
                    storage, sharing and disclosure of your personal information
                    in this way, please do not use the Platform, the Services
                    and the Company Sites or otherwise provide us with your
                    personal information.
                  </p>

                  <p>
                    You acknowledge that this Privacy Policy is a part of the
                    Terms and Conditions of the Company Sites and the Services,
                    and you unconditionally agree that becoming a user of the
                    Company Sites and its Services signifies your: (i) assent to
                    this Privacy Policy, and (ii) consent to us collecting,
                    storing, processing and/or disclosing your Personal
                    Information and Sensitive Personal Data or Information in
                    the manner and for the purposes set out in this Privacy
                    Policy. Your visit to the Company Sites and use of the
                    Services is subject to your unconditional acceptance of this
                    Privacy Policy and the Terms and Conditions.
                  </p>

                  <p>
                    The collection, use and disclosure of information which has
                    been designated as Personal Information or Sensitive
                    Personal Data or Information under the Data Security Rules
                    require your express consent. By affirming your assent to
                    this Privacy Policy, you provide your consent to such use,
                    collection and disclosure in accordance with this Privacy
                    Policy.
                  </p>

                  <p>
                    The Platform uses artificial intelligence programmed to
                    learn your behavioural patterns to personalize and/or
                    improve the Services or suggest Products to you. We do not
                    use such automatically generated data for any other purpose
                    other than as mentioned in this Privacy Policy. You hereby
                    give us express consent to use such data in accordance with
                    the terms of this Privacy Policy and our Terms and
                    Conditions.
                  </p>
                </div>

                <div className="single-line-tc">
                  <h3>Collection Of Personal Information</h3>

                  <p>
                    OGenie works on artificial intelligence chatbots that are
                    suggestive, predictive and personalized and for this unique
                    feature, the application requires to collect as much as
                    information such as personal information, behaviour pattern,
                    certain choices and preferences in terms of products and
                    services which makes OGenie capable of executing various
                    transactions using the tie-ups with third-Party Providers.
                  </p>

                  <p>
                    When you access or use the Platform, the Services and the
                    Company Sites, we collect information about you through
                    various means as described below:
                  </p>

                  <h5>Information Provided By The User</h5>

                  <ul className="terms-innerconditions subfeature">
                    <li>
                      When you create an Account with OGenie, purchase a
                      Product, download a software update, [register for a class
                      at an OGenie store], contact us (through call, text, email
                      or any other mode) or participate in an online survey, we
                      may collect a variety of information, including your name,
                      mailing address, phone number, email address, nickname,
                      website, company/title, contact, demographic information
                      such as gender, age, occupation, household income and zip
                      code, personal calendar, credit/debit card information,
                      login information for Facebook, and Gmail account.
                      Similarly, we may also collect the information of your
                      family and friends when you share your content with the
                      intention to use this information to satisfy your request
                      and provide you with a better range of products and
                      services. OGenie, may from time to time, access your
                      contact list and/or SMS data on your mobile device to
                      collect, use your contact list or SMS data in accordance
                      with this Privacy Policy with the sole purpose of
                      providing a personalized experience on the Platform. We
                      may also require you to share your Government-issued ID
                      (such as Aadhaar card number, PAN number, etc.) in case
                      any circumstances complying under application of the law.
                    </li>
                  </ul>

                  <h5 className="mt-3">
                    Information Collected From The Use Of Our Services
                  </h5>
                  <ul className="terms-innerconditions subfeature">
                    <li>
                      In addition to the information you provide, we may collect
                      information about your use of our Services through
                      software on your device and other means, which includes
                      access to information which our application will require
                      you to provide including but not limited to access to
                      location, access to the communication information, access
                      to your log information, access to your voice information
                      (while your use of our Platform).
                    </li>
                    <li>
                      When you browse through our Platform, we may collect
                      information regarding the domain and host from which you
                      access the internet, the Internet Protocol (IP) address of
                      the computer or Internet service provider (ISP) you are
                      using, and anonymous site statistical data.
                    </li>
                    <li>
                      Some of our mobile Services may collect your unique mobile
                      device identifiers including but not limited to IMEI,
                      subscriber identity information, network service
                      provider-specific identifiers, network settings and other
                      such information.
                    </li>
                    <li>
                      The permissions to access such information can be obtained
                      from the user from time to time in accordance with the
                      Service requested by the user. For instance, location
                      information (precise location data) can be collected,
                      used, and shared, including the real-time geographic
                      location of your device in order to provide you with
                      enhanced location-based service.
                    </li>
                    <li>
                      It is important to note that GenieTalk Private Limited
                      will be authorized to store your credit card information
                      in an encrypted format once the company is PCI-DSS
                      certified. The Customer’s credit card details shall
                      continue to reside in GenieTalk Platform.
                    </li>
                    <li>
                      GenieTalk shall not be liable or responsible, should any
                      confidential or other information provided by or
                      pertaining to the Customer (including credit card numbers,
                      passwords, personal identification numbers, IDs,
                      transaction details, etc.) be intercepted or altered and
                      subsequently used by an unintended recipient.
                    </li>
                  </ul>

                  <h5 className="mt-3">Information From Third-party Source</h5>
                  <ul className="terms-innerconditions subfeature">
                    <li>
                      We may obtain information regarding the users through the
                      public and commercial sources (sources which are permitted
                      under the law) along with information from third-party
                      social networking services when you select to connect with
                      those services. Example of information we might receive
                      from other sources are IP address, websites visited, the
                      advertisements on those websites and the searches
                      performed using such engines along with their results.
                    </li>
                  </ul>
                </div>
                <div className="single-line-tc">
                  <h3>Cookies</h3>

                  <ul className="terms-innerconditions subfeature">
                    <li>
                      A "cookie" is a small piece of information stored by a web
                      server on a web browser so it can be later read back from
                      that browser. OGenie uses cookie and tracking technology
                      depending on the features offered. Cookies can remember
                      your sign-in credentials for further reference.
                    </li>
                    <li>
                      Cookies help us and third parties to understand the most
                      popular parts of the Services, the ads you have watched in
                      order to avoid repetition and to provide you with relevant
                      content and advertising by collecting information about
                      your use of our Platform, the Services, other websites and
                      apps. Through this kind of information, we are better able
                      to adapt the Services and provide you with a better
                      experience.
                    </li>
                    <li>
                      No personal information will be collected via cookies and
                      other tracking technology; however, if you previously
                      provided personal identifiable information, cookies may be
                      tied to such information. Aggregate cookie and tracking
                      information may be shared with third parties.
                    </li>
                    <li>
                      You can configure your browser to accept or reject all or
                      some cookies. However, some services may be designed to
                      work using the cookies and that disabling cookie may
                      affect your ability to use those services.
                    </li>
                  </ul>
                </div>

                <div className="single-line-tc">
                  <h3>Beacons</h3>

                  <p className="parg_space">
                    OGenie may accompany third parties in the use of
                    technologies known as beacons (or pixels) which enables
                    OGenie to collect information from your device to a server.
                    Beacons can be embedded in online content, videos, and
                    emails, and can allow a server to read certain types of
                    information from your device, know when you have viewed
                    particular content or particular email, message, and also
                    determine the time and date on which you viewed the beacon
                    and the IP address of your device. This technology is used
                    for various purposes along with analyzing the use of our
                    Services and offering content and advertisements which are
                    relevant to the user's lifestyle.
                  </p>
                </div>

                <div className="single-line-tc">
                  <h3>Use Of Information</h3>

                  <p className="parg_space">
                    Our primary purpose in collecting personal information is to
                    provide you with a secure, smooth, efficient, and customized
                    experience. We may use your personal information:
                  </p>

                  <ul className="terms-innerconditions subfeature">
                    <li>
                      to set-up your account and register you in order to
                      provide you with a large range of products and services
                      assistance.
                    </li>
                    <li>
                      to enable you to access the Platform and use the Services.
                    </li>
                    <li>
                      for the provision of seamless and efficient Services to
                      you, customised in accordance with User preference, i.e.
                      for performing our obligations towards you.
                    </li>
                    <li>
                      to provide you with services and products which you
                      explicitly requested for;
                    </li>
                    <li>
                      for business management, accounting and auditing purposes.
                    </li>
                    <li>to verify your identity.</li>
                    <li>
                      to provide you with the necessary details pertaining to
                      your Account and your license to use the Services,
                      including, sending notifications for termination of the
                      contract.
                    </li>
                    <li>
                      to accept payments from you for using the Services, and to
                      process and complete your transactions on the Platform and
                      to send you related information, including purchase
                      confirmation and invoices.
                    </li>
                    <li>
                      to send transactional communications, including responses
                      to your comments, questions and requests;
                    </li>
                    <li>
                      to contact you, through voice call, text messages, or
                      emails, as authorised by you, under the Terms and
                      Conditions.
                    </li>
                    <li>
                      to send you offers based on your previously ordered
                      products and services;
                    </li>
                    <li>
                      to inform you about offers, products, services and
                      updates.
                    </li>
                    <li>
                      to do such activities for which you have explicitly given
                      us consent.
                    </li>
                    <li>
                      to comply with applicable laws, rules and regulations, as
                      updated from time to time;
                    </li>
                    <li>
                      to detect, prevent or remedy any breach of the Terms and
                      Conditions.
                    </li>
                    <li>
                      to create, develop, operate, deliver, and improve our
                      products, services, content and advertising.
                    </li>
                    <li>
                      to comply with applicable security standards and to
                      prevent money laundering and/or fraud or any attempt
                      thereof.
                    </li>
                    <li>to protect you from loss and fraudulent activities.</li>
                    <li>
                      to protect the public interest or for official purposes,
                      if formally requested by any investigative or other
                      governmental authority.
                    </li>
                    <li>
                      in customising the Services which results in a better
                      experience, and for an appropriate response to the
                      instruction, requests and queries.
                    </li>
                    <li>
                      to resolve disputes, troubleshoot concerns and help
                      promote safe Services;
                    </li>
                    <li>to measure consumer interest in our Services.</li>
                    <li>
                      to send important notices, such as communications about
                      purchases, software update and changes to our terms,
                      conditions and policies.
                    </li>
                    <li>
                      to keep you informed with the latest product announcement,
                      upcoming events, and other promotional offers.
                    </li>
                    <li>
                      to help detect and protect against error, fraud and other
                      criminal activity.
                    </li>
                    <li>to deal with legal disputes, if any.</li>
                    <li>
                      to ensure network and information security, including
                      preventing unauthorised access to our computer and
                      electronic communications systems; to help us study and
                      track the purpose and manner the users prefer to use our
                      Services and update our Services and operate, evaluate and
                      improve our business.
                    </li>
                    <li>
                      to perform research, business and/or operational analysis.
                    </li>
                    <li>
                      to track user activity and behaviour pattern on the
                      Platform.
                    </li>
                    <li>
                      to generate internal reports about our user base and
                      service usage patterns, analyse the accuracy,
                      effectiveness and popularity of the Services; and
                    </li>
                    <li>
                      for internal purposes like auditing and data analyses for
                      business development.
                    </li>
                  </ul>
                </div>
                <div className="single-line-tc">
                  <h3>Disclosure</h3>

                  <p>
                    The personal information which you disclose to OGenie is not
                    shared unless it is required in order to provide you with
                    certain requested Services. However, we may disclose your
                    information for the following purposes:
                  </p>

                  <ul className="terms-innerconditions subfeature">
                    <li>
                      <b>Processing Of Transactions</b>: We share information,
                      including personal information, with our officers,
                      employees, consultants, agents, representatives,
                      subcontractors, service providers and business partners to
                      enable them to undertake, perform and deliver the Services
                      purchased by you.
                    </li>
                    <li>
                      <b>Affiliates</b>: OGenie may disclose personal
                      information to its Affiliates for better functioning and
                      providing a more satisfactory experience.
                    </li>
                    <li>
                      <b>Business Partners</b>: We may share your information
                      with business partners, including wireless carriers. These
                      entities may use your information to provide you with
                      services you request, make predictions about your
                      interests and may provide you with promotional material,
                      advertisements and other materials. OGenie shall not be
                      held liable for any acts or omissions committed by any of
                      its business partners.
                    </li>
                    <li>
                      <b>Service Providers</b>: OGenie may share the information
                      with the companies/entities that provide services for or
                      on behalf of OGenie. This includes the companies/entities
                      who provide services such as information processing,
                      extending credits, fulfilling customer orders, delivering
                      products, managing and enhancing customer data, providing
                      customer service, assessing your interest in our Products
                      and Services and conducting customer research or
                      satisfaction surveys.
                    </li>
                    <li>
                      <b>Third Parties</b>: OGenie may disclose a user's
                      information to a third party for performance for various
                      duties regarding the services to be provided. In certain
                      cases, the third party may obtain such information
                      directly from your device. OGenie shall not be held liable
                      for these third-party providers' privacy or security
                      practices. The privacy and security practices of the
                      relevant providers, including details on the information
                      they receive from us or collect about you, are subject to
                      the privacy statements of these parties, which we strongly
                      suggest you to review.
                    </li>
                    <li>
                      <b>Publishers And Advertisers</b>: We may share the
                      information with our publishers and advertisers to aid in
                      their understanding of how Users are using our apps and
                      engaging with ads.
                    </li>
                    <li>
                      <b>
                        Compliance With Laws And Law Enforcement Requests &amp;
                        Protection Of Our Rights
                      </b>
                      : We may also share your data with third parties where we
                      believe in good faith that such disclosure is necessary to
                      comply with any applicable law or to comply with legal
                      processes and to respond to requests from public and
                      government authorities, including public and government
                      authorities outside your country of residence, to prevent
                      imminent physical harm or financial loss, to enforce our
                      Terms and Conditions, including to meet national security
                      or law enforcement requirements. We may disclose personal
                      information to respond to subpoenas, court orders, or
                      legal process, or to establish or exercise our legal
                      rights or defend against legal claims. We may also share
                      such information if we believe it is necessary in order to
                      investigate, prevent, or take action regarding illegal
                      activities, suspected fraud, situations involving
                      potential threats to the physical safety of any person,
                      violations of our Terms and Conditions, or as otherwise
                      required by law. We may also share your information in
                      situations involving potential threats to the physical
                      safety of any person, violations of this Privacy Policy or
                      to respond to the claims of violation of the rights of
                      third parties and/or to protect the rights, property and
                      safety of the Company, our employees, users, or the public
                      and obtaining your consent is difficult. We, at our sole
                      discretion, shall have the right to disclose your personal
                      information to third parties, in the event we believe it
                      to be necessary or appropriate in connection with an
                      investigation of fraud, intellectual property
                      infringement, piracy, or any other unlawful activity.
                    </li>
                    <li>
                      <b>Other Parties</b>: OGenie may share information to such
                      other parties in cases where it is imperative to share in
                      order to protect our Services, when we believe disclosure
                      is necessary or appropriate to prevent physical or other
                      harm or financial loss.
                    </li>
                    <li>
                      <b>Business Transaction</b>: We may share your information
                      in connection with any corporate restructuring, including
                      a merger, demerger, amalgamation, reorganisation, sale of
                      some or all of OGenie's assets, a change in control
                      transaction or a financing or acquisition of all or a
                      portion of our business by another company.
                    </li>
                    <li>
                      <b>Consented Parties</b>: We may disclose your information
                      to a third party when you consent to or request such
                      sharing.
                    </li>
                    <li>
                      <b>Express Consent</b>: We may share your information in
                      any other circumstances where we have your express
                      consent.
                    </li>
                  </ul>
                </div>

                <div className="single-line-tc ">
                  <h3>Links To Third Party Sites And Services</h3>

                  <p className="parg_space">
                    The Platform may contain links to third-party Platforms,
                    including but not limited to, the payment gateway,
                    e-commerce Platforms, Third Party Providers, marketing
                    platforms and social media services. Your use of these
                    features may result in the collection, processing, use,
                    storage or sharing of information about you, including your
                    personal information, by these Platforms, depending on the
                    feature. Please be aware that we are not responsible for the
                    content or privacy practices of other platforms or services
                    that may be linked to our Services. We do not endorse or
                    make any representations about third-party platforms or
                    services. The information you choose to provide to or that
                    is collected by these third parties is not covered by our
                    Privacy Policy. We shall, at no time, be liable for any
                    unauthorised use of your personal information by these
                    third-party platforms. We strongly encourage you to read
                    such third parties' privacy policies.
                  </p>
                </div>

                <div className="single-line-tc">
                  <h3>Accessing Your Information</h3>

                  <p>
                    You may, under applicable law, have the right to access,
                    correct, update or delete the personal information OGenie
                    holds about you. In order to exercise your rights, to the
                    extent permitted and required of OGenie under applicable
                    law, please contact us.
                  </p>

                  <p>
                    You can help ensure that your contact information and
                    preferences are accurate, complete and up to date by logging
                    into your account and go to setting option on your mobile
                    application. If you desire to have access of your
                    information while your use of the Platform, in certain cases
                    you may be required to answer certain security questions.
                    Such practice is done only to ensure that no one has
                    unauthorized access to your information.
                  </p>

                  <p>
                    Under the laws for some jurisdictions, you may have the
                    right to request details about the information we collect
                    and to correct inaccuracies in your information. Any further
                    statutory user rights remain unaffected. If permitted by
                    law, we may charge you a small fee for providing with this
                    ability. We may decline requests that are unreasonably
                    repetitive, require disproportionate technical efforts,
                    jeopardize the privacy of others, are extremely impractical,
                    or for which access is not otherwise required by local laws.
                  </p>

                  <p>
                    For any questions or comments that you have about this
                    Privacy Policy or any practices of the Platform and you
                    would like to update or verify any personal information that
                    we already have then please use your login account details
                    or perform the activities or contact us.
                  </p>
                </div>

                <div className="single-line-tc " data-wow-delay="0.3s">
                  <h3>Information Security</h3>

                  <p>
                    We take appropriate security measures to protect against
                    unauthorized access, unauthorized alteration, disclosure,
                    destruction of data. We strive to attain the same by an
                    internal review of data collection, storage and processing
                    practices and other security measures, including appropriate
                    encryption and physical security measures to guard against
                    unauthorized access to the system where we store your
                    personal data.
                  </p>

                  <p>
                    All the information that we gather about you is securely
                    stored within our controlled database. Your personal data
                    and information are maintained by OGenie in electronic form
                    on its equipment, and on the equipment of its employees,
                    agents, representatives and sub-contractors. Such
                    information may also be converted to physical form time to
                    time. The database is protected by a multi-layer security
                    system, which strives to ensure the security and privacy of
                    your personal information against unauthorized access or
                    unauthorized alteration, disclosure or destruction. However,
                    no matter how effective our security measures are, no
                    security system is impenetrable.
                  </p>

                  <p>
                    Please note your access to the Platform or the Services will
                    be through a secured OTP sent to your registered mobile
                    number. Please do not share your OTP with anyone. Genie Talk
                    shall not be held liable for any fraudulent activities in
                    such cases.
                  </p>
                </div>

                <div className="single-line-tc " data-wow-delay="0.3s">
                  <h3>
                    Data retention, Deleting Your Information &amp; Opting Out
                  </h3>

                  <p>
                    We respect your communication preferences, if you no longer
                    wish to receive notifications via our Platform, you can
                    adjust your preferences. If you prefer not to receive ads
                    that are tailored to your interests, you can opt-out by
                    writing to us at{" "}
                    <a
                      className="Flight-theme-txt"
                      href="mailto:support@ogenietravels.com"
                    >
                      support@ogenietravels.com
                    </a>{" "}
                    at any time.
                  </p>

                  <p>
                    OGenie may use your information to contact you in the future
                    to inform you about the Services OGenie believes will be of
                    interest to you. If OGenie does so, each communication it
                    sends to you will contain instructions permitting you to
                    "opt-out" of receiving future communications. In addition,
                    if at any time you wish not to receive any future
                    communications or wish to have your name deleted from our
                    mailing lists, please contact us at{" "}
                    <a className="Flight-theme-txt" href="tel:+91-7780066666">
                      +91-7780066666
                    </a>
                    .
                  </p>

                  <p>
                    If you wish to opt-out of receiving non-essential
                    communications such as promotional and marketing-related
                    information regarding the Services, please send us an email
                    at{" "}
                    <a
                      className="Flight-theme-txt"
                      href="mailto:support@ogenietravels.com"
                    >
                      support@ogenietravels.com
                    </a>
                    .
                  </p>

                  <p>
                    Due to the nature of the Services, and the restricted access
                    to our Platform, the only way for you to opt-out of sharing
                    your personal information with us which are essential for
                    providing the Services is by deactivating your Account. If
                    you wish to cancel your account or request that we no longer
                    use your information to provide you services contact us.
                    Upon deletion of your Account, OGenie shall, at its
                    earliest, stop sharing your personal information in
                    accordance with this Privacy Policy. Any personal
                    information shared before such deactivation shall be
                    governed by this Privacy Policy. After a period of time,
                    your data may be anonymized and aggregated, and then may be
                    held by us as long as necessary for us to provide our
                    Services effectively, but our use of the anonymized data
                    will be solely for analytical purposes. Please note that
                    your withdrawal of consent or cancellation of account may
                    result in OGenie being unable to provide you with its
                    Services or to terminate any existing relationship OGenie
                    may have with you.
                  </p>

                  <p>
                    OGenie may, at any time, delete your information, including
                    but not limited to, your personal information, without any
                    prior notice to you. Upon the expiry of your license to use
                    the Services or deactivation and/or termination of your
                    Account, OGenie shall have the right to, immediately and
                    permanently, delete all stored messages, emails, contents
                    and all other records of your Account without any notice.
                  </p>

                  <p>
                    Except to the extent provided herein, we will destroy your
                    personal information upon the fulfilment of its purpose. We
                    may, however, retain your personal information for: (i)
                    accounting purposes for such time period as is prescribed
                    under applicable law; (ii) for legal purposes till the
                    completion of statutory limitation for bringing any suit, if
                    any; (iii) to resolve disputes; and (iv) to enforce
                    agreements.
                  </p>
                </div>

                <div className="single-line-tc " data-wow-delay="0.3s">
                  <h3>
                    Compliance and Cooperation With Regulatory Authorities
                  </h3>

                  <p>
                    We regularly review our compliance with the Privacy Policy.
                    The services which we provide at OGenie are in compliance
                    with the Payment Card Industry Data Security Standard (PCI
                    DSS) and Secure Sockets Layers (SSL). When we receive formal
                    written complaints, we will contact the person who made the
                    complaint to follow up. OGenie works with the appropriate
                    regulatory authorities, including local data protection
                    authorities, to resolve any complaints regarding the
                    transfer of personal data that we cannot resolve with our
                    users directly.
                  </p>
                </div>

                <div className="single-line-tc " data-wow-delay="0.3s">
                  <h3>Children's And Minor's Privacy</h3>

                  <p>
                    The Platform and our Services are designed and intended for
                    use by adults. If we discover that we have collected
                    information from a minor, we will delete that information as
                    soon as possible.
                  </p>

                  <p>
                    We strongly encourage parents and guardians to supervise the
                    online activities of their minors to help provide a
                    child-friendly online environment and prevent minors from
                    disclosing their name, address, and other personally
                    identifiable information online without parental permission.
                    Although the Services are not intended for use by minors, we
                    respect the privacy of minors who may inadvertently use the
                    internet or the mobile application.
                  </p>
                </div>

                <div className="single-line-tc " data-wow-delay="0.3s">
                  <h3>Disclaimer</h3>

                  <p>
                    We shall not be liable for disclosure of your username and
                    password and you shall be solely responsible for protecting
                    your username and password, at all times. You shall choose
                    your password carefully and shall not use other User's
                    username and password to log in to the Platform at any point
                    in time. You shall be solely liable for any unauthorised use
                    of the Platform through your Account. In the event of such
                    unauthorised usage, you shall notify us immediately and
                    reset your password accordingly.
                  </p>

                  <p>
                    While we give utmost importance to protecting User data, we
                    cannot ensure that all your personal information (including
                    sensitive information) will never be disclosed in ways not
                    otherwise described in this Privacy Policy. Therefore,
                    although we are committed to protecting your privacy, we do
                    not promise, and you should not expect, that your personal
                    information will always remain private. As a User of the
                    Platform, you understand and agree that you assume all
                    responsibility and risk for your use of the Platform, the
                    internet generally, and the documents you post or access and
                    for your conduct on and off the Platform.
                  </p>

                  <p>
                    There is no absolute security of the transmission over the
                    internet or any electronic storage and we try our best to
                    use technological means to protect and secure your personal
                    identifiable information, however we cannot guarantee and
                    also you cannot expect that there will be immunity from any
                    wrongdoings, malfunctions, unauthorized interceptions or
                    access, or other kinds of abuse and misuse.
                  </p>

                  <p>
                    Notwithstanding anything contained herein, OGenie shall not
                    be responsible for the confidentiality, security or
                    distribution of your personal information by our partners
                    and third parties outside the scope of our agreement with
                    such partners and third parties. Further, OGenie shall not
                    be responsible for any breach of security or for any actions
                    of any third parties or events that are beyond the
                    reasonable control of OGenie including but not limited to
                    computer hacking, unauthorised access to computer data and
                    storage device, computer crashes, breach of security and
                    encryption, poor quality of internet service or telephone
                    service of the user or other force majeure events.
                  </p>
                </div>

                <div className="single-line-tc " data-wow-delay="0.3s">
                  <h3>Contact Us At</h3>

                  <p>
                    Should you have questions about this Privacy Policy or our
                    information collection, use and disclosure practices, or if
                    you would like to make a complaint about a possible breach
                    of local privacy laws, you may contact the Grievance Officer
                    appointed by OGenie in accordance with the provisions of the
                    Data Security Rules. We will use reasonable efforts to
                    respond promptly to any requests, questions or concerns,
                    which you may have regarding our use of your personal
                    information. If you have any grievance with respect to our
                    use of your information, you may communicate such grievance
                    to the Grievance Officer mentioned below:
                  </p>

                  <p>
                    Registered Address: OGenie Pvt. Ltd., Technopark, 74/II, "C"
                    Cross Road, Opp Gate No 2, Seepz, Andheri East, Mumbai,
                    Maharashtra-400093
                  </p>

                  <p>
                    Email:{" "}
                    <a
                      className="Flight-theme-txt"
                      href="mailto:support@ogenietravels.com"
                    >
                      support@ogenietravels.com
                    </a>
                  </p>

                  <p>
                    Contact Number:{" "}
                    <a className="Flight-theme-txt" href="tel:+91-7780066666">
                      +91-7780066666
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
