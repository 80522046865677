import React, { useEffect, useRef, useState } from "react";
import { renderImage } from "../../../commons/util/flightUtil";
import Image from "../../../commons/artefacts/elements/input/image";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Button  from "../../../commons/artefacts/elements/button/primary";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import firebase from "../../../commons/util/firebase/firebase";

const LoginModal = ({ handleLoginModalClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showLogin, setShowLogin] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const recaptchaVerifiedRef = useRef(null);
  const [cR, setCR] = useState({});
  const [OTPError, setOTPError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [initCapReq, setInitCapReq] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loader
  const [resendCount, setResendCount] = useState(0);

  const handleLogin = () => {
    if (!initCapReq) generateRecaptcha();
    sendVerificationCode();
  };

  const handleOTP = () => {
    setShowLogin(true);
    setShowOTP(false);
    setOTPError("");
    setLoginError("");
    // Reset resendCount when switching to OTP screen
    setResendCount(0);
  };

  useEffect(() => {}, [phoneNumber]);

  useEffect(() => {
    if (countdown > 0 && showOTP) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, showOTP]);

  const generateRecaptcha = () => {
    setInitCapReq(true);
    const auth = getAuth(firebase);
    recaptchaVerifiedRef.current = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  };

  const handlePhoneNumberChange = (value, country) => {
    setLoginError("");
    setPhoneNumber(`${value}`);
  };

  const sendVerificationCode = () => {
    setIsLoading(true); // Start the loader

    const auth = getAuth(firebase);
    signInWithPhoneNumber(auth, `+${phoneNumber}`, recaptchaVerifiedRef.current)
      .then((confirmationResult) => {
        // Increment resendCount after each call
        setResendCount((prevCount) => prevCount + 1);
        if (resendCount === 1) {
          // Hide the resend link after the second hit
          setResendDisabled(true);
        }
        setShowLogin(false);
        setShowOTP(true);
        setCR(confirmationResult);
        setCountdown(60);
        setResendDisabled(true); // Disable Resend link
        const timer = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown === 0) {
              clearInterval(timer);
              setResendDisabled(false); // Enable Resend link
            }
            return prevCountdown - 1;
          });
        }, 1000);
      })
      .catch((error) => {
        setLoginError(
          error?.message === "Firebase: Error (auth/too-many-requests)."
            ? "Excessive requests for OTP. Please try again after some time."
            : error?.message
        );
      }).finally(() => {
        setIsLoading(false); // Stop the loader
      });
  };

  const signInWithCode = () => {
    setIsLoading(true); // Start the loader

    cR &&
      cR
        .confirm(verificationCode)
        .then((userCredential) => {
          setShowOTP(!showOTP);
          setShowLogin(false);
          setCountdown(0);
          setOTPError("");
          setLoginError("");
        })
        .catch((error) => {
          console.error("Error signing in:", error);
          console.log("An error occurred while signing in: " + error.message);
          setOTPError(error?.message);
          handleLoginModalClose();
        })
        .finally(() => {
          setIsLoading(false); // Stop the loader
          handleLoginModalClose();
        });
  };

  return (
    <div>
      <div ref={recaptchaVerifiedRef} id="recaptcha-container"></div>

      {showLogin ? (
        <div id="lgnform">
          <h3 className="login-heading mt-3">Login or Create an account</h3>
          <div>
            <form action="#">
              <div className="form-group mb-0 Flight-telpicker">
                <PhoneInput
                  className="c-code-control"
                  country={"in"}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </div>

              <div className="text-center mt-4">
                <Button
                  onClick={handleLogin}
                  className="login-btn w-100  btn Flight-btn-purple Flight-sm-btn Flight-font16"
                  disabled={phoneNumber?.length < 10 || isLoading} // Disable button when loading
                >
                  {isLoading ? ( // Show loader when loading
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Get OTP"
                  )}
                </Button>

                <p className="Flight-font12 mb-0 mt-4 pt-3 border-top ls-05">
                  By logging in, I understand & agree to OGenietravels{" "}
                  <a className="Flight-theme-txt" href="/termsandconditions">
                    terms of use
                  </a>{" "}
                  and{" "}
                  <a className="Flight-theme-txt" href="/privacypolicy">
                    privacy policy
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {loginError && (
        <p
          variant="danger"
          className="error p-1 text-center"
          style={{
            background: "none",
            color: "red",
            position: "absolute",
            bottom: "calc(100% - 215px)",
            left: "0",
            width: "100%",
            textAlign: "center",
          }}
        >
          {loginError === "Firebase: TOO_SHORT (auth/invalid-phone-number)." ||
          loginError === "Firebase: Error (auth/invalid-phone-number)."
            ? "Invalid phone number."
            : loginError}
        </p>
      )}

      {showOTP ? (
        <div id="otpform" className="pb-4">
          <div
            className="Flight-cursor-pointer bck text-dark "
            onClick={handleOTP}
          >
            <Image
              alt=""
              className="me-2"
              src={renderImage("back-arrow.svg")}
            />
            Back
          </div>
          <div className="text-center">
            <h3 className="login-heading">Enter OTP</h3>
            <p className="Flight-font14">
              <i>
                A 6 digit code sent to
                <br />
                {phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`}
              </i>
            </p>
          </div>

          <form>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                maxLength="6"
                value={verificationCode}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                  setOTPError("");
                  setLoginError("");
                }}
              />

              <div className="d-flex justify-content-between Flight-font12 mt-1">
                {resendDisabled || resendCount >= 2 ? (
                  <a
                    disabled={resendDisabled}
                    className="Flight-theme-txt"
                    
                  >
                    Resend
                  </a>
                ) : (
                  <a 
                    className="Flight-theme-txt resendDisabled"
                    onClick={() => {
                      if (!resendDisabled) {
                        setVerificationCode("");
                        sendVerificationCode();
                        setOTPError("");
                        setLoginError("");
                      }
                    }}
                  >
                    Resend
                  </a>
                )}

                {countdown > 0 ? (
                  <div>
                    <Image
                      alt=""
                      className="me-1"
                      src={renderImage("timer-icon.svg")}
                    />
                    {countdown} Sec
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          <div className="text-center mt-3">
            <Button
              onClick={signInWithCode}
              type="button"
              className="login-btn w-100  btn Flight-btn-purple Flight-sm-btn Flight-font16"
              disabled={verificationCode?.length < 6 || isLoading} // Disable button when loading
            >
              {isLoading ? ( // Show loader when loading
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Verify"
              )}
            </Button>
          </div>
        </div>
      ) : null}
      {OTPError && (
        <p
          variant="danger"
          className="error p-1 text-center"
          style={{
            background: "none",
            color: "red",
            position: "absolute",
            bottom: "calc(100% - 265px)",
            left: "0",
            width: "100%",
            textAlign: "center",
          }}
        >
          {OTPError.includes("code") || OTPError === "invalid"
            ? "Authentication Failed"
            : OTPError}
        </p>
      )}
    </div>
  );
};

export default LoginModal;
