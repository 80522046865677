import React, { useState, createContext } from "react";

export const ReviewContext = createContext();

const ReviewContextProvider = ({
  children
}) => {

    // states from Flight Details

    const [email, setEmail] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [addOnModalContent, setAddonsModalContent] = useState(null);
    const [travellerInfo, setTravellerInfo] = useState([]);
    const [paxValidator, setPaxValidator] = useState([]);

    const [addOnSwitch, setAddOnSwitch] = useState({
        seat: false,
        meal: false,
        bag: false,
      });
      const [paxDeatsComplete, setPaxDeatsComplete] = useState(false);
      const [contactDeatsComplete, setContactDeatsComplete] = useState(false);
    
      const [reviewData, setReviewData] = useState({});
      const [seatMapData, setSeatMapData] = useState({});
    
      const [fareSummary, setFareSummary] = useState({
        passengers: [
          {
            ADULT: 0,
            price: 0,
          },
          {
            CHILD: 0,
            price: 0,
          },
          {
            INFANT: 0,
            price: 0,
          },
        ],
        baseFare: 0,
        feeCharges: 0,
        addons: {
          price: 0,
          detail: "",
        },
      });
    
      const [finalGrandTotal, setFinalGrandTotal] = useState(0);
    
      const [conditions, setConditions] = useState({});
    
      const [showGst,setShowGst]=useState(false);
    
      const [gstInfo,setGstInfo]=useState({
        gstNumber: "",
        email: "",
        registeredName: "",
        mobile: "",
        address: "",
      })

      // states from Flight Details----------------------------------------Ends-----------------------------------------------


    
  
  return (
    <ReviewContext.Provider
      value={{
       email,setEmail,phoneNum,setPhoneNum,addOnModalContent,setAddonsModalContent,travellerInfo,setTravellerInfo,paxValidator,setPaxValidator,addOnSwitch,setAddOnSwitch,
       paxDeatsComplete,setPaxDeatsComplete,contactDeatsComplete,setContactDeatsComplete,reviewData,setReviewData,seatMapData,setSeatMapData,fareSummary,setFareSummary,
       finalGrandTotal,setFinalGrandTotal,conditions,setConditions,showGst,setShowGst,gstInfo,setGstInfo,
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
};

export default ReviewContextProvider;
