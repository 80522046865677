import React from "react";
import { useLocation } from "react-router-dom";

const filters = [
  {
    label: "Price",
    path: "TF",
    options: [
      { order: "asc", text: "Low to High" },
      { order: "desc", text: "High to Low" },
    ],
    extraClass: "price-div",
  },
  {
    label: "Departure Time",
    path: "dt",
    options: [
      { order: "asc", text: "Earliest Flight" },
      { order: "desc", text: "Latest Flight" },
    ],
    extraClass: "dep-div",
  },
  {
    label: "Arrival Time",
    path: "at",
    options: [
      { order: "asc", text: "Earliest Flight" },
      { order: "desc", text: "Latest Flight" },
    ],
    extraClass: "arr-div",
  },
  {
    label: "Duration",
    path: "duration",
    options: [{ order: "asc", text: "Shortest Flight" }],
    extraClass: "dur-div",
  },
];

const SortMob = ({ sortColumn, onChange }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`Flight-home-padding px-0 pb-lg-2 ${
        pathname === "/FlightListRTInt/" ? "int" : ""
      }`}
    >
      {filters.map((filter, i) => (
        <div className={`Flight-filterouter ${filter.extraClass}`} key={i}>
          <div className="Flight-filter-txt mb-2 text-start Flight-font-semibold">
            {filter.label}
          </div>
          <div className="Flight-stops-slot row g-0 filterslot text-center Flight-font14 Flight-font-medium">
            {filter.options.map((option, j) => (
              <div
                key={`${i}-${j}`}
                onClick={() =>
                  onChange({ path: filter.path, order: option.order })
                }
                className="col"
              >
                <div
                  className={`border rounded py-2 Flight-stopp ${
                    sortColumn.path === filter.path &&
                    sortColumn.order === option.order &&
                    "Flight-filter-selected"
                  }`}
                >
                  {option.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SortMob;
