import React from "react";
import { Modal } from "react-bootstrap";
import Image from "../../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../../commons/util/flightUtil";

const RequestModalBody = () => {
  return (
    <Modal.Body className="text-center p-md-5">
      <div className="Flight-loaderGIF">
        <Image src={renderImage("loader.gif")} className="img-fluid"></Image>
      </div>
      <h4 className="Flight-font18 Flight-ln">
        Please wait your request is being processed
      </h4>
      <div className="Flight-font12 Flight-font-semibold">
        Please do not click the refresh, back, or stop button until the
        itinerary page is displayed. It may take up to 45 seconds to validate
        your payment information. Please be patient
      </div>
      <div className="Flight-font12 Flight-font-semibold">Thank you</div>
    </Modal.Body>
  );
};

export default RequestModalBody;
