import React, { useState } from "react";
import Image from "../../../commons/artefacts/elements/input/image";
import SelectionOption from "../../../commons/artefacts/elements/input/selectOption";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Tabs,
  Modal,
  Button,
  Table,
  Stack,
} from "react-bootstrap";
import { Form } from "react-bootstrap";
import countryList from "country-list";
import DatePicker from "react-datepicker";
import EmailInput from "../../../commons/artefacts/elements/input/emailInput";
import TextInput from "../../../commons/artefacts/elements/input/textInput";
import { renderImage } from "../../../commons/util/flightUtil";
import numberToIndCurr from "../../../commons/util/priceUtil";
import string from "../../../commons/util/UtilManager";
import {
  formatTimeToHour,
  getDateByFormat,
  getTimeFromString,
} from "../../../commons/util/dateTimeUtil";
const Profile = (props) => {
  const [profileShow, setProfileShow] = useState(false);
  const [savetravShow, setSavetravShow] = useState(false);
  const [moretravShow, setMoretravShow] = useState(false);
  const [viewdetailShow, setViewdetailShow] = useState(false);
  const [deleteuserShow, setDeleteuserShow] = useState(false);

  const defaultAvatar = "/static/media/avatar1.389ce291b6391c218d40.svg"; // Replace with your default avatar image path
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadLabel, setUploadLabel] = useState("Upload Photo");

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setSelectedImage(imageFile);
    setUploadLabel("Change Photo");
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const countries = countryList.getNames(); // Get an array of country names

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  return (
    <>
      <div className="db-profile p-2">
        <Container fluid>
          <div className="d-flex border-top-0 justify-content-between twoborder">
            <div>
              <div className="d-block">
                <h2 className="d-inline Flight-font16 font-bold">Profile</h2>{" "}
                <span className="Flight-font13 text-secondary text-capitalize">
                  (Primary profile)
                </span>
              </div>
              <div className="d-block Flight-font13">
                Basic info, for a faster booking experience
              </div>
            </div>
            <div onClick={() => setProfileShow(true)}>
              <Image
                className="me-1"
                src={renderImage("edit-purple-icon.svg")}
              />
            </div>
          </div>
          <div className="twoborder border-bottom-0">
            <Row className="g-2">
              <Col xl="3" md="4" sm="6">
                <div>Name</div>
                <span className="Flight-txt-gray Flight-font-semibold">
                  Guest{" "}
                </span>
              </Col>
              <Col xl="2" md="4" sm="6">
                <div>Birthday</div>
                <span className="Flight-txt-gray Flight-font-semibold">
                  22-09-1986
                </span>
              </Col>
              <Col xl="2" md="4" sm="6">
                <div>Gender</div>
                <span className="Flight-txt-gray Flight-font-semibold">
                  Male
                </span>
              </Col>
              <Col xl="2" md="4" sm="6">
                <div>Mobile Number</div>
                <span className="Flight-txt-gray Flight-font-semiboldtext-secondary">
                  910000000000 <Image src={renderImage("verified-user.svg")} />
                </span>
              </Col>
              <Col xl="3" md="8">
                <div>Email ID</div>
                <span className="Flight-txt-gray Flight-font-semibold">
                  support@ogenietravels.com{" "}
                  <Image src={renderImage("verified-user.svg")} />
                </span>
              </Col>
            </Row>
          </div>
          <Modal
            centered
            size="md"
            show={profileShow}
            onHide={() => setProfileShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton className="border-bottom-0">
              <Modal.Title
                id="example-modal-sizes-title-sm"
                className="Flight-font18 h4 text-capitalize"
              >
                <Image
                  src={renderImage("user-profile-icon.svg")}
                  alt=""
                  className="me-2"
                />{" "}
                User profile
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="profile-image text-center mb-3">
                {selectedImage ? (
                  <div>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected"
                      className="rounded-pill"
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={defaultAvatar}
                      alt="Default Avatar"
                      className="rounded-pill"
                    />
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="uploadInput"
                />
                <label
                  className="upload-img"
                  htmlFor="uploadInput"
                  style={{ cursor: "pointer" }}
                >
                  {uploadLabel}
                </label>
              </div>
              <Row className="gx-2 gy-3 profile-modal">
                <Col xs="3">
                  <SelectionOption
                    className="form-control form-select form-home ps-2"
                    options={[
                      { value: "Mr.", label: "Mr." },
                      { value: "Ms.", label: "Ms." },
                      { value: "Mrs.", label: "Mrs." },
                      { value: "Master", label: "Master" },
                    ]}
                  />
                </Col>
                <Col xs="9" className="position-relative">
                  <Image
                    className="profile-icons"
                    src={renderImage("user-icon.svg")}
                    alt="userIcon"
                  />
                  <TextInput
                    maxLength={50}
                    minLength={2}
                    className="form-control form-home"
                    placeholder="First Name"
                  />
                </Col>
                <Col md="12" className="position-relative">
                  <Image
                    src={renderImage("user-icon.svg")}
                    alt="userIcon"
                    className="profile-icons"
                  />
                  <TextInput
                    maxLength={50}
                    minLength={2}
                    className="form-control form-home"
                    placeholder="Last Name"
                  />
                </Col>
                <Col md="12" className="position-relative">
                  <div className="Flight-datepicker dob-dd">
                    <Image
                      className="profile-icons"
                      alt="calender"
                      src={renderImage("dob-icon.svg")}
                    />
                    <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      yearDropdownItemNumber={10}
                      dateFormat="dd/MM/yyyy"
                      className="form-control form-home Flight-font14"
                      placeholderText="Date of Birth"
                    />
                  </div>
                </Col>
                <Col md="12" className="position-relative">
                  <Image
                    src={renderImage("mob-icon.svg")}
                    alt="userIcon"
                    className="profile-icons"
                  />
                  <TextInput
                    id="mobnum"
                    className="form-control form-home"
                    placeholder="Mobile Number"
                  />
                </Col>
                <Col md="12" className="position-relative">
                  <Image
                    src={renderImage("mail-icon1.svg")}
                    alt="userIcon"
                    className="profile-icons"
                  />
                  <EmailInput
                    id="emailadd"
                    className="form-control form-home"
                    placeholder="Email Address*"
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="border-top-0 justify-content-center pt-0 pb-3">
              <Button
                className="Flight-btn-outline-gray popup-btn"
                variant="outlined"
                onClick={() => setProfileShow(false)}
              >
                Cancel
              </Button>
              <Button className="Flight-btn-purple popup-btn">Save</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
      <div className="db-profile p-3 mt-3">
        <div className="db-tab-container d-flex align-items-center justify-content-center">
          <div className="text-center py-3">
            <Image src={renderImage("save-traveller.svg")} alt="userIcon" />
            <h2 className="Flight-font20 Flight-font-bold mt-3 text-capitalize">
              No details saved
            </h2>
            <p>
              Make booking faster and easier by saving traveller Info and
              document
            </p>
            <div className="mt-4" onClick={() => setSavetravShow(true)}>
              <Button className="Flight-btn-purple">Add Traveller</Button>
            </div>
          </div>
        </div>
        <div className="db-tab-container">
          <div className="d-flex justify-content-between border-bottom pb-2 mb-2">
            <div>
              <h2 className="d-inline Flight-font14">Save Traveller(s) </h2>

              <div className="d-block Flight-font13">
                You have x Traveller(s)
              </div>
            </div>
            <div onClick={() => setSavetravShow(true)}>
              <Button className="Flight-btn-purple">Add Traveller</Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-borderless white-space-no mb-0 Flight-save-trav-list">
              <thead>
                <tr>
                  <th
                    className="Flight-txt-gray Flight-font-medium"
                    scope="col"
                  >
                    Name
                  </th>
                  <th
                    className="Flight-txt-gray Flight-font-medium"
                    scope="col"
                  >
                    Birthday
                  </th>
                  <th
                    className="Flight-txt-gray Flight-font-medium"
                    scope="col"
                  >
                    Passport No.
                  </th>
                  <th
                    className="Flight-txt-gray Flight-font-medium"
                    scope="col"
                  >
                    Passport Expiry
                  </th>
                  <th
                    className="Flight-txt-gray Flight-font-medium"
                    scope="col"
                  >
                    Issuing Country
                  </th>
                  <th
                    className="Flight-txt-gray Flight-font-medium text-center"
                    scope="col"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="Flight-font13 Flight-font-semibold">
                <tr>
                  <td>Mr. Ankit Singh Kimtee</td>
                  <td>09/04/1988</td>
                  <td>D23145890</td>
                  <td>12/7/2024</td>
                  <td>India</td>
                  <td className="text-center">
                    <Image
                      onClick={() => setDeleteuserShow(true)}
                      className="me-2 Flight-cursor-pointer"
                      src={renderImage("delete-profile.svg")}
                    />
                    <Image
                      className="Flight-cursor-pointer"
                      src={renderImage("edit-purple-icon.svg")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mr. Ankit Singh Kimtee</td>
                  <td>09/04/1988</td>
                  <td>D23145890</td>
                  <td>12/7/2024</td>
                  <td>India</td>
                  <td className="text-center">
                    <Image
                      className="me-2 Flight-cursor-pointer"
                      src={renderImage("delete-profile.svg")}
                    />

                    <Image
                      className="Flight-cursor-pointer"
                      src={renderImage("edit-purple-icon.svg")}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <Modal
          centered
          size="sm"
          show={deleteuserShow}
          onHide={() => setDeleteuserShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header className="border-bottom-0">
            <Modal.Title
              className="Flight-font16 Flight-font-semibold"
              id="example-modal-sizes-title-sm"
            >
              <Image
                className="me-2 Flight-cursor-pointer"
                src={renderImage("delete-profile.svg")}
              />
              Delete Traveller
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <p className="Flight-font16">
              These details will be permanently removed from your account
            </p>
          </Modal.Body>
          <Modal.Footer className="border-top-0 justify-content-center pt-0 pb-3">
            <Button
              className="Flight-btn-outline-gray"
              variant="outlined"
              onClick={() => setDeleteuserShow(false)}
            >
              Cancel
            </Button>
            <Button variant="outlined" className="delete-btn">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          size="md"
          show={savetravShow}
          onHide={() => setSavetravShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton className="border-bottom-0">
            <Modal.Title
              id="example-modal-sizes-title-sm"
              className="Flight-font18 h4"
            >
              <Image
                src={renderImage("add-traveller-icon.svg")}
                alt=""
                className="me-2"
              />{" "}
              Add Traveller
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="gx-2 gy-3 profile-modal">
              <Col xs="3">
                <SelectionOption
                  className="form-control form-select form-home ps-2"
                  options={[
                    { value: "Mr.", label: "Mr." },
                    { value: "Ms.", label: "Ms." },
                    { value: "Mrs.", label: "Mrs." },
                    { value: "Master", label: "Master" },
                  ]}
                />
              </Col>
              <Col xs="9" className="position-relative">
                <Image
                  className="profile-icons"
                  src={renderImage("user-icon.svg")}
                  alt="userIcon"
                />
                <TextInput
                  maxLength={50}
                  minLength={2}
                  className="form-control form-home"
                  placeholder="First Name"
                />
              </Col>
              <Col md="12" className="position-relative">
                <Image
                  src={renderImage("user-icon.svg")}
                  alt="userIcon"
                  className="profile-icons"
                />
                <TextInput
                  maxLength={50}
                  minLength={2}
                  className="form-control form-home"
                  placeholder="Last Name"
                />
              </Col>
              <Col md="12" className="position-relative">
                <Image
                  className="profile-icons"
                  alt="calender"
                  src={renderImage("passnum-icon.svg")}
                />
                <TextInput
                  className="form-control form-home Flight-form-control"
                  placeholder="Passport Number"
                />
              </Col>
              <Col md="12" className="position-relative">
                <div className="Flight-datepicker dob-dd">
                  <Image
                    className="profile-icons"
                    alt="calender"
                    src={renderImage("dob-icon.svg")}
                  />
                  <DatePicker
                    id="passportdate"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    className="form-control form-home Flight-form-control"
                    placeholderText="Issue Date"
                  />
                </div>
              </Col>
              <Col md="12" className="position-relative">
                <Form.Group>
                  <Image
                    src={renderImage("county-icon.svg")}
                    alt="userIcon"
                    className="profile-icons"
                  />

                  <div className="d-flex align-items-center">
                    <Form.Control
                      className="Flight-font14 form-select form-control form-home"
                      as="select"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      <option value="">Select a country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </Col>
              <Col md="12" className="position-relative">
                <div className="Flight-datepicker dob-dd">
                  <Image
                    className="profile-icons"
                    alt="calender"
                    src={renderImage("dob-icon.svg")}
                  />
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    yearDropdownItemNumber={10}
                    dateFormat="dd/MM/yyyy"
                    className="form-control form-home Flight-font14"
                    placeholderText="Date of Birth"
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="border-top-0 justify-content-center pt-0 pb-3">
            <Button
              className="Flight-btn-outline-gray popup-btn"
              variant="outlined"
              onClick={() => setProfileShow(false)}
            >
              Cancel
            </Button>
            <Button className="Flight-btn-purple popup-btn">Save</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Profile;
