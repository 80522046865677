import { addDays, addMonths } from "date-fns";

import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import Button from "../../../commons/artefacts/elements/button/primary";
import Image from "../../../commons/artefacts/elements/input/image";
import RadioInput from "../../../commons/artefacts/elements/input/radioInput";
import TextInput from "../../../commons/artefacts/elements/input/textInput";
import { DataContext } from "../../../commons/context/dataContext";
import string from "../../../commons/util/UtilManager";
import { renderImage } from "../../../commons/util/flightUtil";
import { onSearchData } from "../../../commons/util/handleNavigate";
import useOutsideDetect from "../../hooks/useOutsideDetect";
import { passengerReducer } from "../../reducers/passengers";
import { getAirportData } from "../../services/airlineService";
import "../styles.css";
import { getDateByFormat } from "../../../commons/util/dateTimeUtil";

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div data-testid="selected-option-value">
        <span className="Flight-font14">{props.data.city}</span>
        <span className="Flight-font12">
          {" "}
          ({props.data.code}){/* ({props.data.country}) */}
        </span>
      </div>
    </components.SingleValue>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        className="d-flex flex-column"
        data-testid={`option-id${props.data.code}`}
      >
        <div>
          <p className="Flight-font-semibold m-0 pb-0 Flight-font14">
            {props.data.city} ({props.data.code})
          </p>
        </div>

        <div className="d-flex justify-content-between gap-2">
          <span className="Flight-font12  d-block Flight-fcls">
            {props.data.name}
          </span>
          <span className="Flight-font10 Flight-txt-gray d-block Flight-fcls">
            {props.data.country}
          </span>
        </div>
      </div>
    </components.Option>
  );
};

function FlightSearchForm() {
  const history = useHistory();

  const passengerRef = useRef(null);
  const flightClassRef = useRef(null);
  const faretypeRef = useRef(null);
  const faretypeRefD = useRef(null);
  const datePickerArrRef = useRef(null);
  const datePickerDepRef = useRef(null);

  const {
    searchDataDispatch,
    formState: {
      departPlace,
      arrivalPlace,
      startDate,
      endDate,
      passengers,
      fareType,
      flightClass,
    },
    setFormState,
    setSortState,
    setLoader,
    onFilterDispatch,
    reFilterDispatch,
    airportData,
    fareData,
    cabinData,
    setJourneyType,
    setShowFareDetail,
  } = useContext(DataContext);

  const [p_fligtClass, setFlightClass] = useState(flightClass);
  const [p_fareType, setFareType] = useState(fareType);
  const [p_startDate, setStartDate] = useState(
    startDate ? new Date(startDate) : startDate
  );
  const [p_endDate, setEndDate] = useState(
    endDate ? new Date(endDate) : endDate
  );
  const [p_departPlace, setDepartPlace] = useState(departPlace);
  const [p_arrivalPlace, setArrivalPlace] = useState(arrivalPlace);
  const [p_airportData, setAirportData] = useState(airportData);
  const [showPassenger, setShowPassenger] = useState(false);
  const [showFlightClass, setShowFlightClass] = useState(false);
  const [showFareType, setShowFareType] = useState(false);
  const [swapState, setSwapState] = useState(true);
  const [p_passengers, dispatch] = useReducer(passengerReducer, passengers);

  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    setAirportData(airportData);
  }, [airportData]);

  const openPassenger = () => {
    setShowPassenger(!showPassenger);
  };

  const openFlightClass = () => {
    setShowFlightClass(!showFlightClass);
  };

  const openFareType = () => {
    setShowFareType(!showFareType);
  };

  const handleReturnDate = () => {
    setEndDate(addDays(p_endDate || p_startDate, p_endDate ? 0 : 2));
  };

  const handleStartDate = (date) => {
    if (p_endDate && date > p_endDate) {
      setEndDate(addDays(date, 2));
    }
    setStartDate(date);
  };

  const handleInput = (val) => {
    if (val.length > 2) {
      const fetchAirport = async () => {
        const airportData = await getAirportData(val);
        setAirportData(airportData);
      };
      fetchAirport();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const bodyClassList = document.body.classList;
    bodyClassList.remove("overflow-hidden");
    setShowSearchBar(false);
    if (!p_startDate || !p_departPlace || !p_arrivalPlace) {
      window.alert(
        !p_departPlace
          ? "Please select departure city."
          : !p_arrivalPlace
          ? "Please select arrival city."
          : ""
      );
    } else {
      setFormState({
        startDate: p_startDate,
        endDate: p_endDate,
        departPlace: p_departPlace,
        arrivalPlace: p_arrivalPlace,
        passengers: p_passengers,
        fareType: p_fareType,
        flightClass: p_fligtClass,
      });
      if (localStorage !== undefined && localStorage !== null) {
        localStorage.setItem(
          "formState",
          JSON.stringify({
            startDate: p_startDate,
            endDate: p_endDate,
            departPlace: p_departPlace,
            arrivalPlace: p_arrivalPlace,
            passengers: p_passengers,
            fareType: p_fareType,
            flightClass: p_fligtClass,
          })
        );
      }

      setLoader(true);
      setSortState({
        onward: { path: "TF", order: "asc" },
        return: { path: "TF", order: "asc" },
      });
      onFilterDispatch({
        type: "ResetState",
        payload: { range: { min: 0, max: 0 } },
      });
      reFilterDispatch({
        type: "ResetState",
        payload: { range: { min: 0, max: 0 } },
      });
      searchDataDispatch({ type: "ResetState", payload: {} });
      setShowFareDetail("");

      history.push(
        onSearchData(
          p_startDate,
          p_endDate,
          p_departPlace,
          p_arrivalPlace,
          p_fareType,
          p_passengers,
          p_fligtClass
        ),
        { replace: true }
      );

      let journey = !p_endDate ? "Onward" : "Return";
      let type =
        p_departPlace.country === "India" && p_arrivalPlace.country === "India"
          ? "Domestic"
          : "International";

      setJourneyType({
        type: type,
        journey: journey,
      });
    }
  };

  const handleDatePickerFocus = (event) => {
    const datePickerName = event.target.getAttribute("name");

    if (datePickerName === "arrivaldate") {
      event.preventDefault();
      datePickerArrRef.current.input.blur();
    } else {
      datePickerDepRef.current.input.blur();
    }
  };

  useOutsideDetect(passengerRef, showPassenger, setShowPassenger);
  useOutsideDetect(flightClassRef, showFlightClass, setShowFlightClass);
  useOutsideDetect(faretypeRef, showFareType, setShowFareType);

  const startDateYearChanged = useMemo(() => {
    const startDateYear = new Date(p_startDate).getFullYear();
    const currentYear = new Date().getFullYear();
    return startDateYear !== currentYear;
  }, [p_startDate]);
  const endDateYearChanged = useMemo(() => {
    if (!p_endDate) {
      return false;
    }
    const endDateYear = new Date(p_endDate).getFullYear();
    const currentYear = new Date().getFullYear();
    return endDateYear !== currentYear;
  }, [p_endDate]);
  return (
    <form>
      <div className="Flight-roundtrip">
        <div className="form-group form-m">
          <Row className="gy-3 gx-5 Flight-row">
            <Col md="6">
              <div className="form-group position-relative Flight-swap-btn pe-md-4">
                <Image
                  alt="swap-icon"
                  src={renderImage("middle-icon1.svg")}
                  className={`swap-icn ${swapState ? "swap-city" : ""}`}
                  id="swap-image"
                  onClick={() => {
                    let temp = p_departPlace;
                    setDepartPlace(p_arrivalPlace);
                    setArrivalPlace(temp);
                    setSwapState(!swapState);
                  }}
                />
                <Select
                  name="departcity"
                  className="basic-single Flight-ctm-slct Flight-depart-slct"
                  classNamePrefix="select"
                  isClearable={true}
                  value={p_departPlace}
                  onInputChange={(val) => handleInput(val)}
                  onChange={(val) => {
                    if (val === null) {
                      setDepartPlace(null);
                    } else {
                      setDepartPlace(val);
                    }
                  }}
                  placeholder="Departure City"
                  isSearchable={true}
                  options={p_airportData}
                  getOptionValue={(option) => `${option.code}`}
                  getOptionLabel={(option) =>
                    `${option.city}{" "}(${option.code})`
                  }
                  isOptionDisabled={(option) =>
                    option.code === p_arrivalPlace?.code
                  }
                  components={{ SingleValue, Option }}
                  styles={{
                    menu: (base, state) => ({
                      ...base,
                      width: "350px",
                    }),
                  }}
                />
                <Image
                  alt="icon2"
                  src={renderImage("home-icon2.svg")}
                  className="pln-icn"
                />
              </div>
            </Col>
            <Col md="6">
              <div className="form-group position-relative ps-md-1">
                <Select
                  name="arrivalcity"
                  className="basic-single Flight-ctm-slct"
                  classNamePrefix="select"
                  value={p_arrivalPlace}
                  onInputChange={(val) => handleInput(val)}
                  onChange={(val) => {
                    if (val === null) {
                      setArrivalPlace(null);
                    } else {
                      setArrivalPlace(val);
                    }
                  }}
                  isClearable={true}
                  placeholder="Arrival City"
                  isSearchable={true}
                  options={p_airportData}
                  getOptionValue={(option) => `${option.code}`}
                  getOptionLabel={(option) =>
                    `${option.city} {" "} (${option.code}) `
                  }
                  isOptionDisabled={(option) =>
                    option.code === p_departPlace?.code
                  }
                  components={{ SingleValue, Option }}
                  styles={{
                    menu: (base, state) => ({
                      ...base,
                      width: "350px",
                    }),
                  }}
                />
                <Image
                  alt="img"
                  src={renderImage("home-icon3.svg")}
                  className="pln-icn"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="form-group form-m">
          <Row className="g-3">
            <Col md="6">
              <div className="Flight-date-block Flight-datepicker position-relative Flight-bd">
                <Row className="g-0 Flight-row">
                  <Col>
                    <div className="form-group">
                      <DatePicker
                        onFocus={handleDatePickerFocus}
                        name="departdate"
                        ref={datePickerDepRef}
                        className="form-control Flight-font14 Flight-ctm-slct Flight-form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={p_startDate}
                        onChange={(date) => handleStartDate(date)}
                        minDate={new Date()}
                        maxDate={addMonths(new Date(), 12)}
                        disabledKeyboardNavigation
                        showDisabledMonthNavigation
                        placeholderText="Onward Date"
                        clearable={false}
                      />
                      <Image
                        className="Flight-ctm-slct"
                        onClick={() => datePickerDepRef.current.setFocus()}
                        alt="calender"
                        src={renderImage("calendar-icon.svg")}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group Flight-datepicker mb-0">
                      <DatePicker
                        onFocus={handleDatePickerFocus}
                        disabled={!p_startDate}
                        name="arrivaldate"
                        ref={datePickerArrRef}
                        className="form-control Flight-font14 Flight-ctm-slct Flight-form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={p_endDate}
                        onChange={(date) => setEndDate(date)}
                        minDate={p_startDate || new Date()}
                        disabledKeyboardNavigation
                        isClearable
                        maxDate={addMonths(new Date(), 12)}
                        placeholderText="Return Date"
                        onInputClick={handleReturnDate}
                      />
                      <Image
                        className="Flight-ctm-slct"
                        onClick={() => datePickerArrRef.current.setFocus()}
                        alt="calendericon"
                        src={renderImage("calendar-icon.svg")}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="6">
              <div className="Flight-date-block Flight-datepicker Flight-phone-date-block position-relative">
                <Row className="g-0 Flight-row">
                  <Col>
                    <div ref={passengerRef} className="form-group">
                      <TextInput
                        name="passengers"
                        className="form-control open-select Flight-font14 Flight-ctm-slct Flight-form-control"
                        id="passenger-input"
                        data-testid="passenger-input"
                        value={`${p_passengers.totalPassenger} Passenger${
                          p_passengers.totalPassenger > 1 ? "s" : ""
                        }`}
                        onClick={openPassenger}
                        readOnly
                      />
                      <span className="Flight-cursor-pointer Flight-pax-arrow open-select Flight-ctm-slct">
                        <Image
                          onClick={openPassenger}
                          alt="arrowdown"
                          src={renderImage("Icon-Arrow-Down.svg")}
                          className={`Flight-ctm-icon Flight-ctm-slct ${
                            showPassenger ? "rotate" : ""
                          }`}
                        />
                      </span>
                      <Image
                        className="open-select"
                        src={renderImage("home-icon1.svg")}
                        alt=""
                      ></Image>
                      <div
                        className={`Flight-selector-box-flight  p-0 ${
                          showPassenger ? "show" : ""
                        }`}
                      >
                        <div className="Flight-room-cls">
                          <div className="Flight-qty-box mt-0">
                            <label name="adult" className="mb-0">
                              ADULTS
                              <span className="d-block Flight-font12 text-capitalize Flight-font-medium">
                                (12+ Years)
                              </span>
                            </label>
                            <div className="input-group">
                              <div className="Flight-pax-calc rounded Flight-val-plus">
                                <Button
                                  name="adult"
                                  className="btn adult-quantity-left-minus border-right border-0"
                                  data-type="minus"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch({
                                      type: "UpdatedAdult",
                                      payload: { value: -1 },
                                    });
                                  }}
                                >
                                  -
                                </Button>
                                <span className="Flight-pax-num">
                                  {"0" + p_passengers.adult}
                                </span>
                                <Button
                                  name="adult"
                                  className="btn quantity-right-plus border-0"
                                  data-type="plus"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch({
                                      type: "UpdatedAdult",
                                      payload: { value: 1 },
                                    });
                                  }}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </div>
                          {p_fareType === "REGULAR" && (
                            <>
                              <div
                                className="Flight-qty-box mt-0"
                                data-testid="child-test-id"
                              >
                                <label name="child" className="mb-0">
                                  CHILDREN
                                  <span className="d-block Flight-font12 text-capitalize Flight-font-medium">
                                    (2-12 Years)
                                  </span>
                                </label>
                                <div className="input-group">
                                  <div
                                    className={`Flight-pax-calc rounded ${
                                      p_passengers.child > 0 &&
                                      "Flight-val-plus"
                                    }`}
                                  >
                                    <Button
                                      name="child"
                                      className="btn quantity-left-minus border-right border-0"
                                      data-type="minus"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch({
                                          type: "UpdatedChild",
                                          payload: { value: -1 },
                                        });
                                      }}
                                    >
                                      -
                                    </Button>
                                    <span className="Flight-pax-num">
                                      {"0" + p_passengers?.child}
                                    </span>
                                    <Button
                                      name="child"
                                      className="btn quantity-right-plus border-0"
                                      data-type="plus"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch({
                                          type: "UpdatedChild",
                                          payload: { value: 1 },
                                        });
                                      }}
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="Flight-qty-box border-bottom-0 mt-0"
                                data-testid="infant-test-id"
                              >
                                <label name="infant" className="mb-0">
                                  INFANTS
                                  <span className="d-block Flight-font12 Flight-font-medium text-capitalize">
                                    (0-2 Years)
                                  </span>
                                </label>
                                <div className="input-group">
                                  <div
                                    className={`Flight-pax-calc rounded ${
                                      p_passengers.infant > 0 &&
                                      "Flight-val-plus"
                                    }`}
                                  >
                                    <Button
                                      name="infant"
                                      className="btn quantity-left-minus  border-0"
                                      data-type="minus"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch({
                                          type: "UpdatedInfant",
                                          payload: { value: -1 },
                                        });
                                      }}
                                    >
                                      -
                                    </Button>
                                    <span className="Flight-pax-num">
                                      {"0" + p_passengers?.infant}
                                    </span>
                                    <Button
                                      name="infant"
                                      className="btn quantity-right-plus  border-0"
                                      data-type="plus"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch({
                                          type: "UpdatedInfant",
                                          payload: { value: 1 },
                                        });
                                      }}
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="pl-md-0 pl-2">
                    <div ref={flightClassRef} className="form-group mb-0">
                      <TextInput
                        name={p_fligtClass}
                        onClick={openFlightClass}
                        className="form-control open-select Flight-font14 Flight-form-control"
                        id="cabin-class"
                        placeholder="1 Cabin Class"
                        value={string.string.transform.capitalizeEachWord(
                          p_fligtClass?.replace("_", " ")
                        )}
                        readOnly
                      />
                      <span className="Flight-cursor-pointer Flight-pax-arrow open-select">
                        <Image
                          onClick={openFlightClass}
                          alt="arrowdown"
                          src={renderImage("Icon-Arrow-Down.svg")}
                          className={`Flight-ctm-icon  ${
                            showFlightClass ? "rotate" : ""
                          }`}
                        />
                      </span>
                      <Image
                        className="open-select"
                        src={renderImage("cabin-class.svg")}
                        alt=""
                      ></Image>
                      <div
                        className={`Flight-selector-box-flight Flight-phone-right0 p-0 ${
                          showFlightClass ? "show" : ""
                        }`}
                      >
                        <div className="flight-class">
                          {(cabinData || []).map((item) => {
                            {
                              /* if (
                              p_fareType !== "REGULAR" &&
                              p_fareType !== "ARMED_FORCES" &&
                              p_fareType !== "DOCTORS_NURSES" &&
                              item.value !== "ECONOMY"
                            )
                              return null; */
                            }
                            return (
                              <div className="form-check" key={item._id}>
                                <RadioInput
                                  className="form-check-input radio_animated"
                                  name="cabin-class"
                                  value={item.value}
                                  id={item._id}
                                  checked={p_fligtClass === item.value}
                                  onChange={() => {
                                    setFlightClass(item.value);
                                    setShowFlightClass(false);
                                  }}
                                />
                                <label
                                  className="form-check-label Flight-font14"
                                  htmlFor={item._id}
                                >
                                  {string.string.transform.capitalizeEachWord(
                                    item.label
                                  )}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <div className="Flight-custom-select align-items-center d-lg-none d-block">
          <div ref={faretypeRef} className="form-group mb-0">
            <TextInput
              name={p_fareType}
              onClick={openFareType}
              className="form-control open-select Flight-font14 Flight-form-control Flight-ph-p"
              id="fare-class"
              placeholder="1 Cabin Class"
              value={string.string.transform.capitalizeEachWord(
                p_fareType?.replace("_", " ")
              )}
              readOnly
            />
            <span className="Flight-cursor-pointer Flight-pax-arrow open-select">
              <Image
                onClick={openFareType}
                alt="arrowdown"
                src={renderImage("Icon-Arrow-Down.svg")}
                className={`Flight-ctm-icon  ${showFareType ? "rotate" : ""}`}
              />
            </span>
            <div
              className={`Flight-selector-box-flight  Flight-w-100 Flight-phone-right0 p-0 ${
                showFareType ? "show" : ""
              }`}
            >
              <div className="flight-class">
                {(fareData || []).map((item) => {
                  const isDisabled =
                    (p_passengers.child > 0 || p_passengers.infant > 0) &&
                    item.value !== "REGULAR";
                  return (
                    <div className="form-check" key={item?._id}>
                      <RadioInput
                        className="form-check-input radio_animated"
                        name="fare-data-mobile"
                        id={item._id + "mobile"}
                        checked={p_fareType === item.value}
                        onChange={() => {
                          setFareType(item.value);
                          // setFlightClass(
                          //   item.value !== "REGULAR"
                          //     ? "ECONOMY"
                          //     : flightClass
                          // );
                          setShowFareType(false);
                        }}
                        disabled={isDisabled}
                      />
                      <label
                        className="form-check-label Flight-font14"
                        htmlFor={item._id + "mobile"}
                      >
                        {string.string.transform.capitalizeEachWord(item.label)}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* for Desktop */}
        {showFareType ? null : (
          <div className="Flight-custom-select align-items-center d-lg-flex d-none">
            <h5 className="Flight-font14 me-2">Select A Fare Type</h5>
            <div
              ref={faretypeRefD}
              className="d-inline-flex Flight-select-fare rounded"
            >
              {(fareData || []).map((item) => {
                const isDisabled =
                  (p_passengers.child > 0 || p_passengers.infant > 0) &&
                  item.value !== "REGULAR";
                return (
                  <div className="form-check" key={item._id}>
                    <RadioInput
                      className="form-check-input radio_animated"
                      name="fare-data-desktop"
                      id={item._id + "desktop"}
                      checked={p_fareType === item.value}
                      onChange={() => {
                        setFareType(item.value);
                        // if (item.value !== "REGULAR") {
                        //   setFlightClass("ECONOMY");
                        // }
                      }}
                      disabled={isDisabled}
                    />
                    <label
                      className="form-check-label hover cursor-pointer"
                      htmlFor={item._id + "desktop"}
                      style={{ cursor: "pointer" }}
                    >
                      {string.string.transform.capitalizeEachWord(item.label)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="text-center position-relative final-btn">
          <>
            <Button
              name="mobilesearch"
              className="btn Flight-search-flight-btn Flight-font20 text-white text-uppercase border-0 btn-big-shadow"
              onClick={handleSearch}
            >
              Search
            </Button>
          </>
        </div>
      </div>
    </form>
  );
}

export default FlightSearchForm;
