import { addDays } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Image from "../../../commons/artefacts/elements/input/image";
import SelectionOption from "../../../commons/artefacts/elements/input/selectOption";
import TextInput from "../../../commons/artefacts/elements/input/textInput";
import { renderImage } from "../../../commons/util/flightUtil";
import { getCountryCode } from "../../services/airlineService";
import { getDateByYYYYMMDDFormate } from "../../../commons/util/dateTimeUtil";

const passportRegex = /^[A-Z]{1,2}[0-9]{7}$/;

const PassengerDetailForm = ({
  detail,
  index,
  state,
  setState,
  onChange,
  type,
  paxValidator,
  passport,
  reviewData,
  minExpDate,
}) => {
  const [validation, setValidation] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [firstNameLength, setFirstNameLength] = useState(0);
  const [lastNameLength, setLastNameLength] = useState(0);
  const [passError, setPassError] = useState("");
  const datePickerARef = useRef(null);
  const datePickerCRef = useRef(null);
  const datePickerIRef = useRef(null);

  const getAgeRange = (type) => {
    const isSeniorCitizen =
      type === "ADULT" &&
      reviewData?.searchQuery?.searchModifiers?.pft === "SENIOR_CITIZEN";
    return type === "ADULT"
      ? isSeniorCitizen
        ? { minAge: 60, maxAge: 100 }
        : { minAge: 12, maxAge: 100 }
      : type === "CHILD"
      ? { minAge: 2, maxAge: 11 }
      : type === "INFANT"
      ? { minAge: 0, maxAge: 2 }
      : { minAge: 0, maxAge: 100 };
  };

  const calculateDateRange = (type) => {
    const { minAge, maxAge } = getAgeRange(type);
    const now = new Date();
    const minDate = new Date(
      now.getFullYear() - maxAge,
      now.getMonth(),
      now.getDate()
    );
    const maxDate = new Date(
      now.getFullYear() - minAge,
      now.getMonth(),
      now.getDate()
    );

    return { minDate, maxDate };
  };

  const { minDate, maxDate } = calculateDateRange(type);
  useEffect(() => {
    let currentValidator = paxValidator.filter((x) => x.pIndex === index);
    setValidation(currentValidator);
  }, [paxValidator, index]);
  const fetchCountryCode = async () => {
    let paylodad = {};
    const nationality = await getCountryCode(paylodad);
    setNationality(nationality);
  };
  useEffect(() => {
    if (passport.hasOwnProperty("pm") && passport.pm) {
      fetchCountryCode();
    }
  }, [passport.pm, passport]);
  const renderOptions = () => {
    switch (type) {
      case "ADULT":
        return [
          { value: "Mr.", label: "Mr." },
          { value: "Ms.", label: "Ms." },
          { value: "Mrs.", label: "Mrs." },
        ];

      case "CHILD":
        return [
          { value: "Master", label: "Master" },
          { value: "Ms.", label: "Ms." },
        ];

      case "INFANT":
        return [
          { value: "Master", label: "Master" },
          { value: "Ms.", label: "Ms." },
        ];

      default:
        return [];
    }
  };
  const docTypeRenderOptions = () => {
    switch (reviewData?.searchQuery?.searchModifiers?.pft) {
      case "STUDENT":
        return [{ value: "studentId", label: "Student Id" }];

      case "ARMED_FORCES":
        return [{ value: "defenceId", label: "Defence Id" }];

      default:
        return [];
    }
  };

  return (
    <div className="pax-detail mx-3 pb-3">
      <div key={`passenger-detail ${index}`}>
        <div className="d-flex flex-column">
          <div className="Flight-add-traveller Flight-box Flight-template mb-1">
            <div className="element" id="div_1">
              <Row className="gy-3 gx-2 Flight-row">
                <Col sm="2" xs="3" className="form-group mb-0">
                  <SelectionOption
                    onChange={(e) =>
                      onChange(
                        index,
                        "ti",
                        e.target.value,
                        state,
                        setState,
                        type
                      )
                    }
                    value={detail?.ti ? detail.ti : ""}
                    className="form-control form-select form-home Flight-form-control"
                    options={renderOptions()}
                  />
                </Col>
                <Col sm xs="9" className="form-group mb-0">
                  <TextInput
                    maxLength={50}
                    minLength={2}
                    onChange={(e) => {
                      setFirstNameLength(e.currentTarget.value.length);

                      const re = /^(?!\.)[a-zA-Z.'\-\s]{0,50}$/;
                      const isValid = re.test(e.currentTarget.value);
                      if (isValid) {
                        onChange(
                          index,
                          "fN",
                          e.currentTarget.value,
                          state,
                          setState,
                          type
                        );
                      }
                    }}
                    value={detail?.fN ? detail.fN : ""}
                    id={`travller-lastname${index}`}
                    className={`form-control form-home Flight-form-control ${
                      validation.length > 0
                        ? validation.filter((x) => x.pKey === "fN")[0]?.result
                          ? ""
                          : "border-danger"
                        : ""
                    }`}
                    placeholder={`First Name* ${
                      validation.length > 0
                        ? validation.filter((x) => x.pKey === "fN")[0]?.result
                          ? ""
                          : ""
                        : ""
                    }`}
                  />
                  {firstNameLength > 50 && (
                    <small className="text-danger">
                      "Maximum length exceeded. Please enter a first name that
                      is no longer than 50 characters."
                    </small>
                  )}
                  {firstNameLength < 2 && firstNameLength > 0 && (
                    <small className="text-danger">
                      "First name should not be blank, please enter at least 2
                      characters."
                    </small>
                  )}
                </Col>
                <Col sm className="form-group mb-0">
                  <TextInput
                    maxLength={50}
                    minLength={2}
                    onChange={({ currentTarget }) => {
                      setLastNameLength(currentTarget.value.length);
                      // Modify the regular expression to disallow special characters
                      const re = /^(?!\.)[a-zA-Z.'\-\s]{0,50}$/;
                      const isValid =
                        currentTarget.value.length === "" ||
                        re.test(currentTarget.value);
                      if (isValid) {
                        onChange(
                          index,
                          "lN",
                          currentTarget.value,
                          state,
                          setState,
                          type
                        );
                      }
                    }}
                    value={detail?.lN ? detail.lN : ""}
                    id={`travller-lastname ${index}`}
                    className={`form-control form-home Flight-form-control ${
                      validation.length > 0
                        ? validation.filter((x) => x.pKey === "lN")[0]?.result
                          ? ""
                          : "border-danger"
                        : ""
                    }`}
                    placeholder={`Last Name * ${
                      validation.length > 0
                        ? validation.filter((x) => x.pKey === "lN")[0]?.result
                          ? ""
                          : ""
                        : ""
                    }`}
                  />
                  {lastNameLength > 49 && (
                    <small className="text-danger">
                      Maximum length exceeded. Please enter a last name that is
                      no longer than 50 characters."
                    </small>
                  )}
                  {lastNameLength < 2 && lastNameLength > 0 && (
                    <small className="text-danger">
                      "Last name should not be blank,please enter at least 2
                      characters."
                    </small>
                  )}
                </Col>
                {passport.hasOwnProperty("pm") && passport.pm ? (
                  <Col sm className="form-group mb-0">
                    <div className="position-relative Flight-datepicker dob-dd">
                      <DatePicker
                        ref={datePickerARef}
                        selected={detail?.dob ? new Date(detail.dob) : null}
                        minDate={minDate}
                        maxDate={maxDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        yearDropdownItemNumber={10}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>
                          onChange(index, "dob", date, state, setState, type)
                        }
                        id={`travller-dob${index}`}
                        className={`form-control form-home Flight-form-control ${
                          validation.length > 0
                            ? validation.filter((x) => x.pKey === "dob")[0]
                                ?.result
                              ? detail?.dob
                                ? ""
                                : "border-danger"
                              : "border-danger"
                            : ""
                        }`}
                        placeholderText={
                          reviewData?.searchQuery?.searchModifiers?.pft ===
                          "SENIOR_CITIZEN"
                            ? `DOB *${
                                validation.length > 0
                                  ? validation.filter(
                                      (x) => x.pKey === "dob"
                                    )[0]?.result
                                    ? ""
                                    : ""
                                  : ""
                              }`
                            : type === "INFANT"
                            ? `DOB* ${
                                validation.length > 0
                                  ? validation.filter(
                                      (x) => x.pKey === "dob"
                                    )[0]?.result
                                    ? ""
                                    : ""
                                  : ""
                              }`
                            : `DOB* ${
                                validation.length > 0
                                  ? validation.filter(
                                      (x) => x.pKey === "dob"
                                    )[0]?.result
                                    ? ""
                                    : ""
                                  : ""
                              }`
                        }
                      />
                      <Image
                        className="Flight-ctm-slct Flight-cal-icon"
                        onClick={() => datePickerARef.current.setFocus()}
                        alt="calender"
                        src={renderImage("calendar-icon.svg")}
                      />
                    </div>
                  </Col>
                ) : type === "INFANT" ||
                  reviewData?.searchQuery?.searchModifiers?.pft ===
                    "SENIOR_CITIZEN" ? (
                  <Col sm className="form-group mb-0">
                    <div className="position-relative Flight-datepicker dob-dd">
                      <DatePicker
                        ref={datePickerARef}
                        selected={detail?.dob ? new Date(detail.dob) : null}
                        minDate={minDate}
                        maxDate={maxDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        yearDropdownItemNumber={10}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>
                          onChange(index, "dob", date, state, setState, type)
                        }
                        id={`travller-dob${index}`}
                        className={`form-control form-home Flight-form-control ${
                          validation.length > 0
                            ? validation.filter((x) => x.pKey === "dob")[0]
                                ?.result
                              ? reviewData?.searchQuery?.searchModifiers
                                  ?.pft === "SENIOR_CITIZEN" && !detail?.dob
                                ? "border-danger"
                                : ""
                              : "border-danger"
                            : ""
                        }`}
                        placeholderText={
                          reviewData?.searchQuery?.searchModifiers?.pft ===
                          "SENIOR_CITIZEN"
                            ? `DOB *${
                                validation.length > 0
                                  ? validation.filter(
                                      (x) => x.pKey === "dob"
                                    )[0]?.result
                                    ? ""
                                    : ""
                                  : ""
                              }`
                            : type === "INFANT"
                            ? `DOB* ${
                                validation.length > 0
                                  ? validation.filter(
                                      (x) => x.pKey === "dob"
                                    )[0]?.result
                                    ? ""
                                    : ""
                                  : ""
                              }`
                            : `DOB ${
                                validation.length > 0
                                  ? validation.filter(
                                      (x) => x.pKey === "dob"
                                    )[0]?.result
                                    ? ""
                                    : ""
                                  : ""
                              }`
                        }
                      />
                      <Image
                        className="Flight-ctm-slct Flight-cal-icon"
                        onClick={() => datePickerARef.current.setFocus()}
                        alt="calender"
                        src={renderImage("calendar-icon.svg")}
                      />
                    </div>
                  </Col>
                ) : null}
              </Row>
              {reviewData?.searchQuery?.searchModifiers?.pft === "STUDENT" ||
              reviewData?.searchQuery?.searchModifiers?.pft ===
                "ARMED_FORCES" ? (
                <Row className="gy-3 gx-2 Flight-row">
                  <div className="Flight-font14 Flight-font-semibold pt-2 mt-3 ">
                    Document Details (please carry the ID proof)
                  </div>
                  <Col sm="6" xs="6" className="form-group mb-0">
                    <div className="Flight-font12 pb-1">Document Type</div>
                    <SelectionOption
                      onChange={(e) => {
                        onChange(
                          index,
                          "diType",
                          e.target.value,
                          state,
                          setState,
                          type
                        );
                      }}
                      value={detail?.diType ? detail.diType : ""}
                      className="form-control form-select form-home Flight-form-control"
                      options={docTypeRenderOptions()}
                    ></SelectionOption>
                  </Col>
                  <Col sm="6" xs="6" className="form-group mb-0">
                    <div className="Flight-font12 pb-1 ">
                      Document ID Number
                    </div>
                    <TextInput
                      maxLength={50}
                      minLength={2}
                      onChange={(e) => {
                        onChange(
                          index,
                          "di",
                          e.currentTarget.value,
                          state,
                          setState,
                          type
                        );
                      }}
                      value={detail?.di ? detail.di : ""}
                      id={`travller-di${index}`}
                      className={`form-control form-home Flight-form-control ${
                        detail?.di ? "" : "border-danger"
                      }`}
                    />
                  </Col>
                </Row>
              ) : null}
            </div>
          </div>

          {passport.hasOwnProperty("pm") && passport.pm ? (
            <div>
              <div className="Flight-font14 Flight-font-semibold mt-3 mb-2">
                Passport details
              </div>
              <div className="">
                <Row className="gy-3 gx-2 Flight-row">
                  <Col sm className="form-group mb-0">
                    <TextInput
                      required
                      className={`form-control form-home Flight-form-control ${
                        validation.length > 0 && !passError
                          ? validation.filter((x) => x.pKey === "pNum")[0]
                              ?.result
                            ? ""
                            : "border-danger"
                          : ""
                      }`}
                      placeholder="Passport Number *"
                      value={detail?.pNum ? detail.pNum : ""}
                      onChange={(num) => {
                        let val = num.target.value;
                        let isValid = passportRegex.test(val);
                        onChange(
                          index,
                          "pNum",
                          num.target.value,
                          state,
                          setState,
                          type
                        );
                        if (isValid) {
                          setPassError("");
                        } else {
                          setPassError("Please enter a valid passport number.");
                        }
                      }}
                    />
                    {passError && (
                      <small className="text-danger">{passError}</small>
                    )}
                  </Col>
                  <Col sm xs="6" className="form-group mb-0">
                    <div className="position-relative Flight-datepicker dob-dd">
                      <DatePicker
                        required
                        ref={datePickerCRef}
                        selected={detail?.pid ? new Date(detail.pid) : null}
                        id="passportdate"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        className={`form-control form-home Flight-form-control ${
                          validation.length > 0
                            ? validation.filter((x) => x.pKey === "pid")[0]
                                ?.result
                              ? ""
                              : "border-danger"
                            : ""
                        }`}
                        placeholderText={`Issue Date * ${
                          validation.length > 0
                            ? validation.filter((x) => x.pKey === "pid")[0]
                                ?.result
                              ? ""
                              : ""
                            : ""
                        }`}
                        onChange={(date) =>
                          onChange(index, "pid", date, state, setState, type)
                        }
                        minDate={detail.dob && detail.dob}
                        maxDate={new Date()}
                      />
                      <Image
                        className="Flight-cal-icon Flight-ctm-slct"
                        alt="calender"
                        onClick={() => datePickerCRef.current.setFocus()}
                        src={renderImage("calendar-icon.svg")}
                      />
                    </div>
                  </Col>
                  <Col sm xs="6" className="form-group mb-0">
                    <div className="position-relative Flight-datepicker dob-dd">
                      <DatePicker
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        yearDropdownItemNumber={10}
                        required
                        ref={datePickerIRef}
                        id="passportdate"
                        selected={detail?.eD ? new Date(detail.eD) : null}
                        dateFormat="dd/MM/yyyy"
                        className={`form-control form-home Flight-form-control ${
                          validation.length > 0
                            ? validation.filter((x) => x.pKey === "eD")[0]
                                ?.result
                              ? ""
                              : "border-danger"
                            : ""
                        }`}
                        placeholderText={`Expiry Date * ${
                          validation.length > 0
                            ? validation.filter((x) => x.pKey === "eD")[0]
                                ?.result
                              ? ""
                              : ""
                            : ""
                        }`}
                        onChange={(date) =>
                          onChange(index, "eD", date, state, setState, type)
                        }
                        minDate={addDays(new Date(minExpDate), 3)}
                      />

                      <Image
                        className="Flight-ctm-slct Flight-cal-icon"
                        onClick={() => datePickerIRef.current.setFocus()}
                        alt="calender"
                        src={renderImage("calendar-icon.svg")}
                      />
                    </div>
                  </Col>
                  <Col sm className="form-group mb-0">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Select
                        required
                        className={`form-control form-home Flight-form-control ${
                          validation.length > 0
                            ? validation.filter((x) => x.pKey === "pNat")[0]
                                ?.result
                              ? ""
                              : "border-danger"
                            : ""
                        }`}
                        placeholder="Nationality*"
                        value={detail?.pNat ? detail.pNat : ""}
                        onChange={(nat) =>
                          onChange(
                            index,
                            "pNat",
                            nat.target.value,
                            state,
                            setState,
                            type
                          )
                        }
                      >
                        <option value="" selected>
                          Select Nationality *
                        </option>
                        {nationality.length > 0 &&
                          nationality.map((item, index) => {
                            if (item?.country !== null) {
                              return (
                                <option value={item?._id} key={index}>
                                  {item?.country}
                                </option>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PassengerDetailForm;
