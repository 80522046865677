import React from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";

const WebCheckIn = () => {
  return (
    <>
      <Container fluid="xxl">
        <Row className="gx-3">
          <Col md="6" className="mt-3">
            <div className="web-checkin-wrap h-100">
              <div className="p-md-3 p-0">
                <div className="text-center py-md-3 pb-2">
                  <Image src={renderImage("airlines/air-asia-logo.png")} />
                </div>
                <Tabs
                  defaultActiveKey="profile"
                  id="wb-chckin1"
                  className="mb-3 font14 webcheckin-navtab justify-content-lg-start justify-content-evenly"
                >
                  <Tab className="font14" eventKey="home" title="Flight Status">
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.airasia.com/flightstatus/en/gb"
                          className="Flight-theme-txt text-decoration-none"
                          target="_new"
                        >
                          www.airasia.com/flightstatus
                        </a>
                      </p>
                      <ol className="font14 list-decimal  text-capitalize">
                        <li>
                          Input the carrier code and flight number, e.g. I5798
                          Or Search by Route
                        </li>
                        <li>Click on Find Flights</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Flight Status can be checked for
                        flights on the previous day, same day and next day.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="profile"
                    title="Web Check-in"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.airasia.com/check-in/v2/en/gb"
                          className="Flight-theme-txt text-decoration-none"
                          target="_new"
                        >
                          www.airasia.com/check-in
                        </a>
                      </p>
                      <ol className="list-decimal text-capitalize">
                        <li>
                          Input the Departure City, PNR and Last Name as per the
                          booking
                        </li>
                        <li>Select the passenger and add Nationality&nbsp;</li>
                        <li>
                          Tick to complete the Covid-19 and other
                          self-declarations
                        </li>
                        <li>
                          Select add ons (Seats, Baggage, etc.) and continue
                        </li>
                      </ol>
                      <p>
                        The AirAsia boarding pass will be generated which is
                        required to access the terminal building
                      </p>
                      <p className="font12">
                        <b>Please Note: </b>Guests are required to report 3 hrs
                        prior to departure with sufficient time to complete all
                        the formalities. To reprint the boarding pass, guests
                        would need to visit www.airasia.com and input the PNR
                        details which they have already checked in.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="contact"
                    title="Update Contact Details"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.airasia.com/member/search"
                          className="Flight-theme-txt text-decoration-none"
                          target="_new"
                        >
                          www.airasia.com/member/search&nbsp;
                        </a>
                        &nbsp;&nbsp;
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the Departure City, PNR, Last Name &amp; Click
                          on Search&nbsp;
                        </li>
                        <li>
                          Click on Manage Booking, Guest and Contact
                          details&nbsp;
                        </li>
                        <li>Scroll and update Contact info&nbsp;</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>It is mandatory to provide a guest
                        contact number and email ID in the booking.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="web-checkin-wrap h-100">
              <div className="p-md-3 p-0">
                <div className="text-center py-md-3 pb-2">
                  <Image src={renderImage("airlines/indigo-logo.png")} />
                </div>
                <Tabs
                  defaultActiveKey="profile"
                  id="wb-chckin1"
                  className="mb-3 font14 webcheckin-navtab justify-content-lg-start justify-content-evenly"
                >
                  <Tab className="font14" eventKey="home" title="Flight Status">
                    <div className="liststyle">
                      <p>
                        <b>Please Visit: </b>
                        <a
                          href="https://www.goindigo.in/check-flight-status.html"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.goindigo.in/check-flight-status
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the Departing, Arrival and flight number, e.g.
                          I5798 Or Search by Route
                        </li>
                        <li>Click on Search Flights</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Flight Status can be checked for
                        flights on the previous day, same day and next day.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="profile"
                    title="Web Check-in"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit: </b>
                        <a
                          href="https://www.goindigo.in/web-check-in.html"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.goindigo.in/web-check-in
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the PNR and Email/Last Name as per the booking
                        </li>
                        <li>Select the passenger and add Nationality</li>
                        <li>
                          Tick to complete the Covid-19 and other
                          self-declarations
                        </li>
                        <li>
                          Select add ons (Seats, Baggage, etc.) and continue
                        </li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Guests are required to report 3 hrs
                        prior to departure with sufficient time to complete all
                        the formalities. To reprint the boarding pass, guests
                        would need to visit www.goindigo.in and input the PNR
                        details which they have already checked in.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="contact"
                    title="Update Contact Details"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit: </b>
                        <a
                          href="https://www.goindigo.in/update-contact-details.html"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.goindigo.in/update-contact-details
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the PNR, Email/Last Name &amp; Click on Update
                          Contact{" "}
                        </li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>It is mandatory to provide a guest
                        contact number and email ID in the booking.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="web-checkin-wrap h-100">
              <div className="p-md-3 p-0">
                <div className="text-center py-md-3 pb-2">
                  <Image src={renderImage("airlines/spicejet-logo.png")} />
                </div>
                <Tabs
                  defaultActiveKey="profile"
                  id="wb-chckin1"
                  className="mb-3 font14 webcheckin-navtab justify-content-lg-start justify-content-evenly"
                >
                  <Tab className="font14" eventKey="home" title="Flight Status">
                    <div className="liststyle">
                      <p>
                        <b>Please Visit: </b>
                        <a
                          href="https://book.spicejet.com/FlightStatus.aspx"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.book.spicejet.com/FlightStatus
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the Origin, Destination, Flight date &amp;
                          flight number, e.g. SG8161 Or Search by Route
                        </li>
                        <li>Click on Find Flights</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Flight Status can be checked for
                        flights on the previous day, same day and next day.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="profile"
                    title="Web Check-in"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://book.spicejet.com/SearchWebCheckin.aspx"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.book.spicejet.com/SearchWebCheckin
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the PNR and Email/Last Name as per the booking
                        </li>
                        <li>Select the passenger and add Nationality</li>
                        <li>
                          Tick to complete the Covid-19 and other
                          self-declarations
                        </li>
                        <li>
                          Select add ons (Seats, Baggage, etc.) and continue
                          &nbsp;
                        </li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Guests are required to report 3 hrs
                        prior to departure with sufficient time to complete all
                        the formalities.{" "}
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="contact"
                    title="Update Contact Details"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://book.spicejet.com/RetrieveBooking.aspx"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.book.spicejet.com/RetrieveBooking
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the PNR, Email/Last Name &amp; Click on Retrieve
                          Booking
                        </li>
                        <li>
                          Click on Manage Booking, Guest and Contact
                          details&nbsp;
                        </li>
                        <li>update Contact info </li>
                      </ol>

                      <p className="font12">
                        <b>Please Note: </b>It is mandatory to provide a guest
                        contact number and email ID in the booking.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="web-checkin-wrap h-100">
              <div className="p-md-3 p-0">
                <div className="text-center py-md-3 py-2">
                  <Image src={renderImage("airlines/goair-logo.png")} />
                </div>
                <Tabs
                  defaultActiveKey="profile"
                  id="wb-chckin1"
                  className="mb-3 font14 webcheckin-navtab justify-content-lg-start justify-content-evenly"
                >
                  <Tab className="font14" eventKey="home" title="Flight Status">
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://www.goair.in/plan-my-trip/flight-status"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.goair.in/plan-my-trip/flight-status
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>Input the Origin, Destination, Flight date</li>
                        <li>Click on Check Now</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Flight Status can be checked for
                        flights on the previous day, same day and next day.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="profile"
                    title="Web Check-in"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://www.goair.in/plan-my-trip/web-check-in"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.goair.in/plan-my-trip/web-check-in
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the PNR and Email/Last Name as per the booking
                        </li>
                        <li>Select the passenger and add Nationality</li>
                        <li>
                          Tick to complete the Covid-19 and other
                          self-declarations
                        </li>
                        <li>
                          Select add ons (Seats, Baggage, etc.) and continue
                        </li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Guests are required to report 3 hrs
                        prior to departure with sufficient time to complete all
                        the formalities.{" "}
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="contact"
                    title="Update Contact Details"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://www.goair.in/plan-my-trip/manage-booking"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.goair.in/plan-my-trip/manage-booking
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the PNR, Email/Last Name &amp; Click on Retrieve
                          Booking
                        </li>
                        <li>
                          Click on Manage Booking, Guest and Contact
                          details&nbsp;
                        </li>
                        <li>Update Contact info </li>
                      </ol>

                      <p className="font12">
                        <b>Please Note: </b>It is mandatory to provide a guest
                        contact number and email ID in the booking.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="web-checkin-wrap h-100">
              <div className="p-md-3 p-0">
                <div className="text-center py-md-3 py-2">
                  <Image src={renderImage("airlines/vistara-logo.png")} />
                </div>
                <Tabs
                  defaultActiveKey="profile"
                  id="wb-chckin1"
                  className="mb-3 font14 webcheckin-navtab justify-content-lg-start justify-content-evenly"
                >
                  <Tab className="font14" eventKey="home" title="Flight Status">
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.airvistara.com/in/en"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.airvistara.com/in/en
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>Click on Flight Status Tab</li>
                        <li>Input the Origin, Flight No. and Date</li>
                        <li>Click on&nbsp;Find Status</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Flight Status can be checked for
                        flights on the previous day, same day and next day.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="profile"
                    title="Web Check-in"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.airvistara.com/in/en"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.airvistara.com/in/en
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>Click on Check-in Tab</li>
                        <li>Input the Booking Reference Number, Last Name</li>
                        <li>Click on Check-in</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Guests are required to report 3 hrs
                        prior to departure with sufficient time to complete all
                        the formalities.{" "}
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="contact"
                    title="Update Contact Details"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.airvistara.com/in/en"
                          className="Flight-theme-txt text-decoration-none"
                          target="_new"
                        >
                          www.airvistara.com/in/en
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>Click on Manage My Booking Tab</li>
                        <li>
                          Input the Booking Refernece Number, Last Name &nbsp;
                        </li>
                        <li>Update Contact Info </li>
                      </ol>

                      <p className="font12">
                        <b>Please Note: </b>It is mandatory to provide a guest
                        contact number and email ID in the booking.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="web-checkin-wrap h-100">
              <div className="p-md-3 p-0">
                <div className="text-center py-md-3 py-2">
                  <Image src={renderImage("airlines/air-india.png")} />
                </div>
                <Tabs
                  defaultActiveKey="profile"
                  id="wb-chckin1"
                  className="mb-3 font14 webcheckin-navtab justify-content-lg-start justify-content-evenly"
                >
                  <Tab className="font14" eventKey="home" title="Flight Status">
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://book.airindia.in/itd/itd/lang/en/travel/advancedFlightStatus"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.book.airindia.in/itd/itd/lang/en/travel/advancedFlightStatus
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>Search By. Flight No/Airport/Flight Route</li>
                        <li>Fill the details and Search</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Flight Status can be checked for
                        flights on the previous day, same day and next day.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="profile"
                    title="Web Check-in"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.airindia.in/ssci.htm"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.airindia.in/ssci.htm
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>
                          Input the E-ticket number or Booking Reference And
                          Last Name
                        </li>
                        <li>Click on Submit</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Guests are required to report 3 hrs
                        prior to departure with sufficient time to complete all
                        the formalities.{" "}
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="contact"
                    title="Update Contact Details"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://bookme.airindia.in/AirIndiaB2C/Manage/Retrieve"
                          target="_new"
                          className="Flight-theme-txt text-decoration-none"
                        >
                          www.bookme.airindia.in/AirIndiaB2C/Manage/Retrieve
                        </a>
                      </p>
                      <ol className="list-decimal  text-capitalize">
                        <li>Input the Booking Refernece Number, Last Name </li>
                        <li>Click on Retrieve Booking&nbsp;</li>
                        <li>Update Contact info </li>
                      </ol>

                      <p className="font12">
                        <b>Please Note: </b>It is mandatory to provide a guest
                        contact number and email ID in the booking.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col md="6" className="mt-3">
            <div className="web-checkin-wrap h-100">
              <div className="p-md-3 p-0">
                <div className="text-center py-md-3 py-2">
                  <Image src={renderImage("airlines/Trujet-lg.png")} />
                </div>
                <Tabs
                  defaultActiveKey="profile"
                  id="wb-chckin1"
                  className="mb-3 font14 webcheckin-navtab justify-content-lg-start justify-content-evenly"
                >
                  <Tab className="font14" eventKey="home" title="Flight Status">
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>
                        <a
                          href="https://www.trujet.com/reservation/ibe/modify"
                          className="Flight-theme-txt text-decoration-none"
                          target="_new"
                        >
                          www.trujet.com/reservation/ibe/modify{" "}
                        </a>
                      </p>
                      <ol className="list-decimal text-capitalize">
                        <li>Input the Last name and Booking Number</li>
                        <li>Click on Retrieve Booking</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Flight Status can be checked for
                        flights on the previous day, same day and next day.
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="profile"
                    title="Web Check-in"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.trujet.com/WebCheckIn/web/checkin?locale=en_US"
                          className="Flight-theme-txt text-decoration-none"
                          target="_new"
                        >
                          www.trujet.com/WebCheckIn/web/checkin
                        </a>
                      </p>
                      <ol className="list-decimal text-capitalize">
                        <li>Choose PNR</li>
                        <li> Input the PNR Number, First name &amp; Surname</li>
                        <li> Click on Check-in</li>
                      </ol>
                      <p className="font12">
                        <b>Please Note: </b>Guests are required to report 3 hrs
                        prior to departure with sufficient time to complete all
                        the formalities.{" "}
                      </p>
                    </div>
                  </Tab>
                  <Tab
                    className="font14"
                    eventKey="contact"
                    title="Update Contact Details"
                  >
                    <div className="liststyle">
                      <p>
                        <b>Please Visit:</b>{" "}
                        <a
                          href="https://www.trujet.com/reservation/ibe/modify"
                          className="Flight-theme-txt text-decoration-none"
                          target="_new"
                        >
                          www.trujet.com/reservation/ibe/modify
                        </a>
                      </p>
                      <ol className="list-decimal text-capitalize">
                        <li>Click on anage Tag &gt; Update Contact Info </li>
                        <li>Input the Last Name &amp; Booking Number</li>
                        <li>Click on etrieve Booking</li>
                      </ol>

                      <p className="font12">
                        <b>Please Note: </b>It is mandatory to provide a guest
                        contact number and email ID in the booking.
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WebCheckIn;
