import { addMonths } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CheckboxInput from "../../../../commons/artefacts/elements/input/checkboxInput";
import Image from "../../../../commons/artefacts/elements/input/image";
import {
  getDateByFormat,
  getTimeFromString,
} from "../../../../commons/util/dateTimeUtil";
import { renderImage } from "../../../../commons/util/flightUtil";

function SubmitReqModalBody(props) {
  const [requestObj1, setRequestObj1] = useState([]);
  const [requestObj2, setRequestObj2] = useState([]);
  const [notee, setNote] = useState("");
  const [sameF, setSameF] = useState(false);
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();

  const {
    data: {
      bookingDetails: { tripInfos, pnrDetails },
      _id: orderId,
    },
    userId,
    error,
  } = props;

  useEffect(() => {
    const apiRequest = {
      userId: userId,
      orderId: props.data._id,
      request: [...requestObj1, ...requestObj2],
      sameFlight: sameF,
      note: notee,
    };

    props.onChangeFormData(apiRequest);
  }, [requestObj1, requestObj1, sameF, notee]);

  return (
    <div className="bookFlightDetail d-block pb-0">
      <div className="px-3 mb-1 Flight-font14 Flight-font-bold">
        Order ID : {orderId && orderId.slice(-5)}
      </div>
      <div className="px-2">
        <div>
          <Table borderless className="Flight-font13 mb-0  text-dark">
            <tbody>
              <tr class="" key={"trip"}>
                <td className="py-0 pe-1">
                  <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                    Flight No.
                  </span>
                  <span>
                    {tripInfos[0]?.sI[0].fD.aI.code} -{" "}
                    {tripInfos[0].sI[0].fD.fN}
                  </span>
                </td>
                <td className="py-0 px-1">
                  <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                    Date
                  </span>
                  <span>{getDateByFormat(tripInfos[0]?.sI[0].dt)}</span>
                </td>
                <td className="py-0 px-1">
                  <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                    From
                  </span>
                  <span>{`${getTimeFromString(tripInfos[0]?.sI[0].dt)} ${
                    tripInfos[0]?.sI[0].da.code
                  }`}</span>
                </td>
                <td className="py-0 ps-1">
                  <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                    To
                  </span>
                  <span>{`${getTimeFromString(tripInfos[0]?.sI.at(-1).at)} ${
                    tripInfos[0]?.sI.at(-1).aa.code
                  }`}</span>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="position-relative Flight-datepicker my-2  dob-dd">
            <DatePicker
              onFocus={(e) => e.target.blur()}
              name="departdate"
              className={`form-control form-home Flight-form-control ${
                error && "border-danger"
              }`}
              dateFormat="dd/MM/yyyy"
              selected={date1}
              disabledKeyboardNavigation
              showDisabledMonthNavigation
              placeholderText="Please select the preferred date"
              minDate={new Date()}
              maxDate={addMonths(new Date(), 12)}
              showMonthDropdown
              onChange={(d) => {
                let pnr =
                  pnrDetails[
                    _.findKey(pnrDetails, (value, key) =>
                      key.startsWith(tripInfos[0]?.sI[0]?.da?.code)
                    )
                  ];
                setDate1(d);
                let date = d?.toISOString();
                setRequestObj1([
                  {
                    pnrNo: pnr,
                    date: date,
                  },
                ]);
              }}
            />
            <Image
              className="Flight-ctm-slct position-absolute Flight-cstcal"
              alt="calender"
              src={renderImage("calendar-icon.svg")}
            />
            <Image
              className="Flight-ctm-slct position-absolute Flight-cstcal"
              alt="calender"
              src={renderImage("calendar-icon.svg")}
            />
          </div>
        </div>

        {tripInfos.length > 1 ? (
          <div>
            <Table borderless className="Flight-font13 mb-0  text-dark">
              <tbody>
                <tr class="" key={"trip"}>
                  <td className="py-0 pe-1">
                    <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                      Flight No.
                    </span>
                    <span>
                      {tripInfos[1]?.sI[0].fD.aI.code} -{" "}
                      {tripInfos[1].sI[0].fD.fN}
                    </span>
                  </td>
                  <td className="py-0 px-1">
                    <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                      Date
                    </span>
                    <span>{getDateByFormat(tripInfos[1]?.sI[0].dt)}</span>
                  </td>
                  <td className="py-0 px-1">
                    <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                      From
                    </span>
                    <span>{`${getTimeFromString(tripInfos[1]?.sI[0].dt)} ${
                      tripInfos[1]?.sI[0].da.code
                    }`}</span>
                  </td>
                  <td className="py-0 ps-1">
                    <span className="Flight-font14 Flight-font-bold py-1 ps-0 d-block">
                      To
                    </span>
                    <span>{`${getTimeFromString(tripInfos[1]?.sI.at(-1).at)} ${
                      tripInfos[1]?.sI.at(-1).aa.code
                    }`}</span>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="position-relative Flight-datepicker my-2  dob-dd">
              <DatePicker
                onFocus={(e) => e.target.blur()}
                name="departdate"
                className={`form-control form-home Flight-form-control ${
                  error && "border-danger"
                }`}
                dateFormat="dd/MM/yyyy"
                selected={date2}
                disabledKeyboardNavigation
                showDisabledMonthNavigation
                placeholderText="Please select the preferred date"
                minDate={new Date()}
                maxDate={addMonths(new Date(), 12)}
                showMonthDropdown
                onChange={(d) => {
                  let pnr =
                    pnrDetails[
                      _.findKey(pnrDetails, (value, key) =>
                        key.startsWith(tripInfos[1]?.sI[0]?.da?.code)
                      )
                    ];
                  setDate2(d);
                  let date = d?.toISOString();
                  setRequestObj2([
                    {
                      pnrNo: pnr,
                      date: date,
                    },
                  ]);

                  // props?.onChangeReq("requestrt", [...requestObj1,
                  //   {
                  //     pnrNo: pnr,
                  //     date: date,
                  //   },
                  // ]);
                }}
              />
              <Image
                className="Flight-ctm-slct position-absolute Flight-cstcal"
                alt="calender"
                src={renderImage("calendar-icon.svg")}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="px-3">
        <div className="Flight-custom-checkbox Flight-custom-control Flight-cursor-pointer my-3">
          <CheckboxInput
            id="reschedule-checkbox"
            className="Flight-custom-control-input"
            checked={sameF}
            onChange={({ currentTarget }) => {
              // props?.onChangeFormData("sameFlight", currentTarget.checked)
              setSameF(currentTarget.checked);
            }}
          />
          <label
            className="Flight-custom-control-label Flight-font14 Flight-font-semibold"
            htmlFor="reschedule-checkbox">
            Book The Same Flight
          </label>
        </div>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            placeholder="Add a comment"
            rows={3}
            value={notee}
            onChange={({ currentTarget }) => {
              //props?.onChangeFormData("note", currentTarget.value)
              setNote(currentTarget.value);
            }}
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default SubmitReqModalBody;
