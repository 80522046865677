import React from "react";
import { Card } from "react-bootstrap";
import Image from "../../elements/input/image";
import { Col } from "react-bootstrap";
import { renderImage } from "../../../util/flightUtil";

const ServerError = () => {
  return (
    <Col xl={{ span: 6, offset: 3 }}>
      <div className="Flight-search-inner">
        <Card className="text-center border-0 box-shadow-none">
          <Card.Body>
            <Image alt="calender" src={renderImage("flight-not-found.svg")} />
            <Card.Title className="mt-3">Technical Error</Card.Title>
            <p className="Flight-font14">
              "Sorry, we are experiencing some technical difficulties with our
              server and are unable to complete your flight booking at this
              time. Please try again later or contact our customer service for
              assistance&nbsp;
              <a className="Flight-theme-txt" href="tel:+91-8884998888">
                +91-8884998888
              </a>
              . We apologize for any inconvenience caused."
            </p>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default ServerError;
