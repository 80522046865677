import _ from "lodash";

const modifiedFares = (fares) => {
  const publishedFares = _.filter(fares, { fareIdentifier: "PUBLISHED" });

  if (publishedFares.length === 1) {
    return _.map(fares, (fare) => {
      fare.fd.ADULT.fC.TF = Math.ceil(fare.fd.ADULT.fC.TF);
      if (fare.fareIdentifier === "PUBLISHED") {
        return {
          ...fare,
          fareIdentifier: "REGULAR",
        };
      }
      return fare;
    });
  }

  const minPublishedFare = publishedFares[0];
  return _.map(fares, (fare) => {
    fare.fd.ADULT.fC.TF = Math.ceil(fare.fd.ADULT.fC.TF);
    if (fare.fareIdentifier === "PUBLISHED") {
      return {
        ...fare,
        fareIdentifier: fare.id === minPublishedFare.id ? "SAVER" : "REGULAR",
      };
    }
    return fare;
  });
};

const getUniqueFlights = (flights) => {
  const uniqueFlights = [];
  flights.forEach((flight) => {
    const {
      fD: {
        aI: { name, code },
      },
    } = flight.sI[0];
    const {
      fd: {
        ADULT: {
          fC: { TF },
        },
      },
    } = flight.totalPriceList[0];
    const existingFlight = uniqueFlights.find((f) => f.name === name);
    if (existingFlight) {
      if (TF < existingFlight.price) existingFlight.price = TF;
    } else uniqueFlights.push({ name, code, price: TF });
  });
  return uniqueFlights
};

const mutateSearchResponse = (inputArray) => {
  let minAmong = Infinity;
  let maxAmong = 0;
  let mutatedData = inputArray.filter((x, ind) => {
    x.totalPriceList = _.reject(x.totalPriceList, (fare) =>
      /CORPORATE|SME|SPECIAL_RETURN|OFFER_FARE_WITH_PNR/i.test(fare.fareIdentifier)
    );

    if (!x.totalPriceList.length) return false;

    x.totalPriceList.sort((a, b) => {
      return a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF;
    });

    x.totalPriceList = modifiedFares(x.totalPriceList);

    x.minFare = x.totalPriceList[0].fd.ADULT.fC.TF;

    if (minAmong > x.minFare) {
      minAmong = x.minFare;
    }

    if (maxAmong < x.minFare) {
      maxAmong = x.minFare;
    }

    x.selectedFare = x.totalPriceList[0];
    x.uniqId = _.uniqueId("flight_");
    x.index = ind;

    return x;
  });
  return { data: mutatedData, minAmong, maxAmong };
};

export { getUniqueFlights, mutateSearchResponse, modifiedFares };
