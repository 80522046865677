import { getDateByMMDDYYYYFormate } from "./dateTimeUtil";

const onSearchData = (
  startDate,
  endDate,
  departPlace,
  arrivalPlace,
  fareType,
  passengers,
  flightClass
) => {
  const searchParams = new URLSearchParams();
  let sDate =getDateByMMDDYYYYFormate(startDate)
  let eDate = endDate ? getDateByMMDDYYYYFormate(endDate) : null;
  let srcParams = `${departPlace.code}-${departPlace.city}-${departPlace.country}|${arrivalPlace.code}-${arrivalPlace.city}-${arrivalPlace.country}|${sDate}`;
  searchParams.append("src", endDate ? `${srcParams}|${eDate}` : srcParams);
  searchParams.append(
    "px",
    `${passengers?.adult}-${passengers?.child}-${passengers?.infant}`
  );
  searchParams.append("cbn", flightClass);
  searchParams.append("frtyp", fareType);
  let decodeUrl = decodeURIComponent(searchParams.toString());
  return `/${
    endDate
      ? departPlace.country !== "India" || arrivalPlace.country !== "India"
        ? "FlightListRTInt"
        : "FlightListRT"
      : "FlightList"
  }/?${decodeUrl}`;
};

const extractParamURL = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const src = searchParams.get("src");
  const cc = searchParams.get("cbn");
  const px = searchParams.get("px");
  const frtyp = searchParams.get("frtyp");
  const paramVal = src.split("|");
  const depart_Detail = paramVal[0]?.split("-");
  const arr_Detail = paramVal[1]?.split("-");
  let depart_date = new Date(paramVal[2]?.toString());
  let arr_date = paramVal[3] ? new Date(paramVal[3]?.toString()) : null;
  let passenger = px.split("-");
  let adult = parseInt(passenger[0]) || 1;
  let child = parseInt(passenger[1]) || 0;
  let infant = parseInt(passenger[2]) || 0;
  const init_departPlace = {
    city: depart_Detail[1] || "Mumbai",
    code: depart_Detail[0] || "BOM",
    country: depart_Detail[2] || "India",
  };
  const init_arrivalPlace = {
    city: arr_Detail[1] || "Delhi",
    code: arr_Detail[0] || "DEL",
    country: arr_Detail[2] || "India",
  };

  return {
    fareType: frtyp,
    init_departPlace,
    init_arrivalPlace,
    depart_date,
    arr_date,
    cabin: cc,
    passengers: {
      totalPassenger: adult + child + infant,
      adult,
      child,
      infant,
    },
  };
};

export { onSearchData, extractParamURL };
