import { INITIAL_SEARCH_DATA } from "../../constants";

const searchDataReducer = (state = INITIAL_SEARCH_DATA, action) => {
  const { type, payload } = action;
  const {
    onwardFlights,
    returnFlights,
    comboFlights,
    fare,
    dataIndex,
    flightType,
  } = payload;
  switch (type) {
    case "UpdatedOneWay":
      return { onwardFlights, returnFlights: [] };
    case "UpdatedRoundTrip":
      return {
        onwardFlights,
        returnFlights,
      };
    case "UpdatedRoundInt":
      return { onwardFlights: comboFlights, returnFlights: [] };
    case "UpdatedSelectedFare":
      let flightToUpdate =
        flightType !== "round" ? "onwardFlights" : "returnFlights";
      let updateFlight = [...state[flightToUpdate]];
      updateFlight[dataIndex].selectedFare = fare;
      return { ...state, [flightToUpdate]: updateFlight };
    case "ResetState":
      return { onwardFlights: [], returnFlights: [] };
    default:
      return state;
  }
};

export { searchDataReducer };
