import React from "react";
import numberToIndCurr from "../../../../util/priceUtil";
import { Table } from "react-bootstrap";

const DetailTab = (props) => {
  const {
    passengers: { adult, child, infant },
    data: {
      fd: {
        ADULT: {
          fC: { BF: aBF } = {},
          afC: { TAF: aTAF } = {},
        } = {},
        CHILD: {
          fC: { BF: cBF = 0 } = {},
          afC: { TAF: cTAF = 0 } = {},
        } = {},
        INFANT: {
          fC: { BF: iBF = 0 } = {},
          afC: { TAF: iTAF = 0 } = {},
        } = {},
      } = {},
    } = {},
  } = props;
  
  // Filter out "MF" and "MFT" from TAF object
  const filteredAdultTAF = { ...aTAF };
  delete filteredAdultTAF["MF"];
  delete filteredAdultTAF["MFT"];

  const filteredChildTAF = { ...cTAF };
  delete filteredChildTAF["MF"];
  delete filteredChildTAF["MFT"];

  const filteredInfantTAF = { ...iTAF };
  delete filteredInfantTAF["MF"];
  delete filteredInfantTAF["MFT"];

  // Calculate the sum of values in the TAF object
  const calculateTAFSum = (TAFObject) => {
    return Object.values(TAFObject).reduce((sum, value) => sum + value, 0);
  };

  const sumAdultTAF = calculateTAFSum(filteredAdultTAF);
  const sumChildTAF = calculateTAFSum(filteredChildTAF);
  const sumInfantTAF = calculateTAFSum(filteredInfantTAF);

  const feeCharges = adult * sumAdultTAF + child * sumChildTAF + infant * sumInfantTAF;
  const totalBaseFare = adult * aBF + child * cBF + infant * iBF;
  return (
    <div
      className="tab-pane"
      id="FareDetails1"
      role="tabpanel"
      aria-labelledby="FareDetails-tab1"
    >
      <div className="Flight-fd-table border-tbl mt-2">
        <Table borderless responsive className="mb-0 Flight-font14 bg-white">
          <tbody>
            <tr>
              <td>Adults X {adult}</td>
              <td className="text-end">{numberToIndCurr(adult * aBF)}</td>
            </tr>
            {child ? (
              <tr>
                <td>Child X {child}</td>
                <td className="text-end">{numberToIndCurr(child * cBF)}</td>
              </tr>
            ) : null}
            {infant ? (
              <tr>
                <td>Infant X {infant}</td>
                <td className="text-end">{numberToIndCurr(infant * iBF)}</td>
              </tr>
            ) : null}
            <tr>
              <td>Total Base Fare</td>
              <td className="text-end">{numberToIndCurr(totalBaseFare)}</td>
            </tr>
            <tr>
              <td>Fee &amp; Surcharges</td>
              <td className="text-end">{numberToIndCurr(feeCharges)}</td>
            </tr>
            <tr>
              <td className="Flight-font16 Flight-font-semibold">
                Grand Total
              </td>
              <td className="text-end Flight-font16 Flight-font-semibold">
                {numberToIndCurr(totalBaseFare + feeCharges)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DetailTab;
