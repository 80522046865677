import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ResultListInt } from "../../../commons/artefacts/components/FlightResultList/ResultListInt";
import DataNotFound from "../../../commons/artefacts/components/errors/DataNotFound";
import NotFilteredData from "../../../commons/artefacts/components/errors/NotFilteredData";
import ServerError from "../../../commons/artefacts/components/errors/ServerError";
import SortMob from "../../../commons/artefacts/components/sort/SortMob";
import SortWeb from "../../../commons/artefacts/components/sort/SortWeb";
import Button from "../../../commons/artefacts/elements/button/primary";
import Image from "../../../commons/artefacts/elements/input/image";
import { DataContext } from "../../../commons/context/dataContext";
import string from "../../../commons/util/UtilManager";
import filterFlight from "../../../commons/util/filter";
import { renderImage } from "../../../commons/util/flightUtil";
import flightSort from "../../../commons/util/sort";
import {
  getUniqueFlights,
  mutateSearchResponse,
} from "../../../commons/util/uniqueFlights";
import { getFlightData } from "../../services/airlineService";
import Filter from "../one-way-search/Filter";
import "../styles.css";
import SelectionBar from "./SelectionBar";
import FlightSearchFormInner from "../home/FlightSearchFormInner";

const RoundTripInt = () => {
  const {
    searchData: { onwardFlights },
    searchDataDispatch,
    formState,
    loader,
    setLoader,
    onFilterState,
    onFilterDispatch,
    reFilterState,
    reFilterDispatch,
    sortState: { onward: sortColumn },
    setSortState,
    showFareDetail,
    setShowFareDetail,
  } = useContext(DataContext);

  const {
    departPlace,
    arrivalPlace,
    startDate,
    endDate,
    passengers,
    fareType,
    flightClass,
  } = formState;

  const [uniqueFlight, setUniqueFlight] = useState([]);
  const [filterOnwardData, setFilterOnwardData] = useState([]);
  const [minAmong, setMinAmong] = useState(0);
  const [maxAmong, setMaxAmong] = useState(0);
  const [error, setError] = useState("");
  const [showMobFilter, setshowMobFilter] = useState("filters");
  const [showMobFilterFlag, setshowMobFilterFlag] = useState(false);

  useEffect(() => {
    setError("");
    getFlightData(
      departPlace.code,
      arrivalPlace.code,
      passengers,
      fareType,
      flightClass,
      startDate,
      endDate
    )
      .then(
        ({
          type,
          message,
          status,
          data: { searchResult: { tripInfos: { COMBO = [] } = {} } = {} } = {},
        }) => {
          if (type === "failure" || type === "error" || status >= 500) {
            setError("Server Error");
          } else if (!COMBO.length) {
            setError("No Data");
          }
          const updatedCombo = COMBO.map(flight => {
            if (flight?.totalPriceList && flight?.totalPriceList?.length > 0) {
              flight.totalPriceList.forEach(price => {
                const originalTF = price?.fd?.ADULT?.fC?.TF;
                const MF = price?.fd?.ADULT?.afC?.TAF?.MF || 0;
                const MFT = price?.fd.ADULT?.afC?.TAF?.MFT || 0;
                price.fd.ADULT.fC.TF = originalTF - (MF + MFT);
              });
            }
            return flight;
          });
          const { data, minAmong, maxAmong } = mutateSearchResponse(updatedCombo);

          setMinAmong(minAmong);
          setMaxAmong(maxAmong);
          setUniqueFlight(getUniqueFlights(data));

          onFilterDispatch({
            type: "RangeUpdated",
            payload: {
              range: {
                min: minAmong,
                max: maxAmong,
              },
            },
          });

          searchDataDispatch({
            type: "UpdatedRoundInt",
            payload: { comboFlights: data },
          });
        }
      )
      .finally(() => setLoader(false));
  }, [formState]);

  useEffect(() => {
    if (onwardFlights.length > 0) {
      setError("");
      let filteredData = filterFlight(onwardFlights, {
        ...onFilterState,
        aircraft: new Set(reFilterState.aircraft),
      });
      if (!filteredData.length) setError("No Filter Data");

      filteredData = flightSort(filteredData, sortColumn);

      setFilterOnwardData(filteredData);
    }
  }, [onFilterState, onwardFlights.length, sortColumn, reFilterState.aircraft]);

  const handleSort = (colName) => {
    let ord =
      sortColumn.path === colName
        ? sortColumn.order === "asc"
          ? "desc"
          : "asc"
        : "desc";
    setSortState((prevState) => ({
      ...prevState,
      onward: { path: colName, order: ord },
    }));
  };

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 30;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      {/* Top search start */}
      <div className="Flight-yellow-gradient Flight-sticky_nav pt-lg-4 pb-lg-3">
        <Container fluid="xxl" className="px-0 px-lg-3 Flight-container-xxl">
          <div className="Flight-widgetSection Flight-inner-page-search">
            <FlightSearchFormInner />
            <div className="sort-filter d-lg-none">
              <Container className="Flight-container">
                <div className="d-flex justify-content-end">
                  <span
                    className="pe-3 d-inline-flex"
                    onClick={() => {
                      setshowMobFilter("sorting");
                      setshowMobFilterFlag(true);
                    }}
                  >
                    <Image
                      className="me-2"
                      src={renderImage("sortingIcon.svg")}
                      alt="sun"
                    />
                    Sort
                  </span>
                  <span
                    className="d-inline-flex"
                    onClick={() => {
                      setshowMobFilterFlag(true);
                      setshowMobFilter("filters");
                    }}
                  >
                    <Image
                      className="me-2"
                      src={renderImage("filterIcon.svg")}
                      alt="sun"
                    />
                    Filter
                  </span>
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </div>
      {/* Top search end  */}

      <Container fluid="xxl" className="pt-lg-3 Flight-container-xxl">
        <Row className="Flight-row">
          {loader ? (
            <div className="loader-wrapper mt-0 pt-0 img-gif">
              <Image src={renderImage("loader.gif")} className="img-fluid" />
            </div>
          ) : error === "Server Error" ? (
            <ServerError />
          ) : error === "No Data" ? (
            <DataNotFound />
          ) : (
            <Fragment>
              <Col lg="9">
                {error === "No Filter Data" ? (
                  <NotFilteredData
                    onClear={() => {
                      setError("");
                      onFilterDispatch({
                        type: "ResetState",
                        payload: {
                          range: { min: minAmong, max: maxAmong },
                        },
                      });
                      reFilterDispatch({
                        type: "ResetState",
                        payload: {
                          range: { min: minAmong, max: maxAmong },
                        },
                      });
                    }}
                  />
                ) : (
                  <Fragment>
                    <Row className="g-lg-2 g-0 Flight-rt-int-sbar Flight-row">
                      {["ARMED_FORCES", "DOCTORS_NURSES"].includes(
                        formState.fareType
                      ) && (
                        <Fragment>
                          <Col xs="12">
                            <div className="Flight-search-result Flight-search-inner m-lg-0  m-md-3 m-2 p-0 border-0">
                              <div className="border-0 box-shadow-none Fligh-alert1 d-flex align-items-center p-md-3 p-2">
                                <Image
                                  className="me-3"
                                  width={30}
                                  alt="calender"
                                  src={renderImage("noflight-info.svg")}
                                />
                                <div>
                                  {/* <Image
                                  alt="calender"
                                  src={renderImage("flight-not-found.svg")}
                                /> */}
                                  {/* <div className="mt-3 card-title h5">
                                  {" "}
                                  Sorry, no flights with{" "}
                                  {string.string.transform.capitalizeEachWord(
                                    fareType.replace(/_/g, " ")
                                  )}{" "}
                                  Fare were found.
                                </div> */}
                                  <>
                                    <p className="Flight-font14 Flight-font-semibold mb-0 text-capitalize">
                                      We could not fetch out any{" "}
                                      <span className="Flight-font-bold">
                                        {string.string.transform.capitalizeEachWord(
                                          fareType.replace(/_/g, " ")
                                        )}{" "}
                                      </span>
                                      fares flight result for this route /
                                      dates! here are some other flight options
                                      you may wish to explore.
                                    </p>
                                  </>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Fragment>
                      )}
                      <Col>
                        <SelectionBar
                          depCity={departPlace.city}
                          arrCity={arrivalPlace.city}
                          date={startDate}
                        />
                      </Col>
                      <Col>
                        <SelectionBar
                          depCity={arrivalPlace.city}
                          arrCity={departPlace.city}
                          date={endDate}
                        />
                      </Col>
                    </Row>
                    <div className="mt-2">
                      <SortWeb sortColumn={sortColumn} onSort={handleSort} />
                    </div>
                    <ResultListInt
                      passengers={passengers}
                      data={filterOnwardData}
                      showFareDetail={showFareDetail}
                      onChangeShowFareDetail={(id) => {
                        setShowFareDetail(id !== showFareDetail ? id : "");
                      }}
                      onFareChange={(index, fare) => {
                        searchDataDispatch({
                          type: "UpdatedSelectedFare",
                          payload: {
                            flightType: "oneway",
                            dataIndex: index,
                            fare,
                          },
                        });
                      }}
                    />
                  </Fragment>
                )}
              </Col>
              <Col className="ps-md-0">
                <div className="d-lg-flex d-none justify-content-between pt-2 Flight-filter-txt Flight-filterdp">
                  <div className=" Flight-font-semibold">FILTERS</div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setError("");
                      onFilterDispatch({
                        type: "ResetState",
                        payload: {
                          range: { min: minAmong, max: maxAmong },
                        },
                      });
                      reFilterDispatch({
                        type: "ResetState",
                        payload: {
                          range: { min: minAmong, max: maxAmong },
                        },
                      });
                    }}
                    className="Flight-font13 text-dark Flight-cursor-pointer Flight-font-semibold border-0 bg-transparent"
                  >
                    CLEAR ALL
                  </Button>
                </div>
                <div
                  className={`Flight-left-sidebar filter-mobile ${
                    showMobFilterFlag && "show"
                  }`}
                >
                  <Container className="Flight-container">
                    <Tabs
                      id="uncontrolled-tab-example"
                      activeKey={showMobFilter}
                      onSelect={(selectedTab) => {
                        setshowMobFilter(selectedTab);
                        setshowMobFilterFlag(true);
                      }}
                      className="mb-0 d-lg-none Flight-sf-tabs Flight-box-stretch"
                    >
                      <Tab eventKey="filters" title="Filters">
                        <div className="Flight-filterblock">
                          {/* <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onFilterDispatch({
                                type: "ResetState",
                                payload: {
                                  range: {
                                    min: minAmong,
                                    max: maxAmong,
                                  },
                                },
                              });
                            }}
                            className="flight-font13 text-dark Flight-cursor-pointer  d-lg-none border-0 bg-transparent Flight-clear-filter"
                          >
                            CLEAR ALLAAAAA
                          </Button> */}
                          <Filter
                            flightType="Onward"
                            filterState={onFilterState}
                            onStop={(ind) =>
                              onFilterDispatch({
                                type: "StopUpdated",
                                payload: { index: ind },
                              })
                            }
                            onDepartTime={(val) =>
                              onFilterDispatch({
                                type: "DepartureUpdated",
                                payload: { departureTime: val },
                              })
                            }
                            onArriveTime={(val) =>
                              onFilterDispatch({
                                type: "ArrivalUpdated",
                                payload: { arrivalTime: val },
                              })
                            }
                            onPriceChange={(val) =>
                              onFilterDispatch({
                                type: "PriceUpdated",
                                payload: { range: val },
                              })
                            }
                          />
                          <div className="Flight-return-filter">
                            <Filter
                              flightType="Return"
                              filterState={reFilterState}
                              hidePriceRange={true}
                              airplanes={uniqueFlight}
                              onStop={(ind) =>
                                reFilterDispatch({
                                  type: "StopUpdated",
                                  payload: { index: ind },
                                })
                              }
                              onDepartTime={(val) =>
                                reFilterDispatch({
                                  type: "DepartureUpdated",
                                  payload: { departureTime: val },
                                })
                              }
                              onArriveTime={(val) =>
                                reFilterDispatch({
                                  type: "ArrivalUpdated",
                                  payload: { arrivalTime: val },
                                })
                              }
                              onAircraftSelect={(val) =>
                                reFilterDispatch({
                                  type: "AircraftUpdated",
                                  payload: { aircraftName: val },
                                })
                              }
                            />
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="sorting" title="Sort By">
                        <div className="Flight-filterblock">
                          {/* <Button
                            onClick={() =>
                              setSortState((prevState) => ({
                                ...prevState,
                                onward: { path: "TF", order: "asc" },
                              }))
                            }
                            className="Flight-font13 text-dark Flight-cursor-pointer d-lg-none border-0 bg-transparent Flight-clear-filter"
                          >
                            CLEAR ALLaaaa
                          </Button> */}

                          <SortMob
                            sortColumn={sortColumn}
                            onChange={(col) =>
                              setSortState((prevState) => ({
                                ...prevState,
                                onward: col,
                              }))
                            }
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </Container>
                  <div className="Flight-aply-fltr-btn d-flex justify-content-around">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        showMobFilter === "filters"
                          ? onFilterDispatch({
                              type: "ResetState",
                              payload: {
                                range: { min: minAmong, max: maxAmong },
                              },
                            })
                          : setSortState((prevState) => ({
                              ...prevState,
                              onward: { path: "TF", order: "asc" },
                            }));
                        setshowMobFilterFlag(false);
                        setshowMobFilter("");
                      }}
                      className="w-50 Flight-cursor-pointer d-lg-none border-0 Flight-clear-filter"
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={() => {
                        setshowMobFilterFlag(false);
                        setshowMobFilter("");
                      }}
                      className="btn Flight-btn-purple w-50 d-lg-none py-2 rounded-0 border-0"
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Col>
            </Fragment>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default RoundTripInt;
