import React, { useState, useEffect, useContext } from "react";
import Image from "../../../commons/artefacts/elements/input/image";
import { Row, Col, Modal, Button, Stack } from "react-bootstrap";
import { renderImage } from "../../../commons/util/flightUtil";
import numberToIndCurr from "../../../commons/util/priceUtil";
import string from "../../../commons/util/UtilManager";
import { getDateByHHdddDDMMMFormate } from "../../../commons/util/dateTimeUtil";
import { useHistory } from "react-router-dom";
import { getBookListingByUserId } from "../../services/orderCancellationService";
import BookingsSSRModal from "./BookingsSSRModal";
import BookingsViewDetailModal from "./BookingsViewDetailModal";
import Cancellation from "../fight-cancellation/cancellation";
import SubmitReqModalBody from "../fight-cancellation/reschedule/SubmitReqModalBody";
import { INITIAL_RESCHEDULE_DATA } from "../../../constants";
import { DataContext } from "../../../commons/context/dataContext";
import { REQUEST_RESCHEDULE } from "../../../constants/endpoints";
import ExternalsManager from "../../../commons/externals/ExternalsManager";
let encodedUserId;
const Bookings = (tabVal) => {
  const history = useHistory();
  const [moretravShow, setMoretravShow] = useState(false);
  const [viewdetailShow, setViewdetailShow] = useState(false);
  const [viewDetailData, setViewDetailData] = useState([]);
  const [ssrModalData, setSSRModalData] = useState([]);
  const [bookedTicket, setBookedTicket] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState();
  const [action, setAction] = useState(false);
  const [actionResch, setActionResch] = useState(false);
  const [cancellationModalData, setCancellationModalData] = useState({});
  const [rescheduleData, setRescheduleData] = useState(INITIAL_RESCHEDULE_DATA);
  const [error, setError] = useState(false);
  const { uid } = useContext(DataContext);

  useEffect(() => {
    setLoader(true);

    if (uid) {
      encodedUserId = btoa(uid?.uid); //only for bial.genietalk.ai purpose
      setUserId(encodedUserId);

      getBookListingByUserId(
        userId || encodedUserId,
        tabVal?.filter || "active"
      )
        .then((response) => {
          setBookedTicket(response);
          setLoader(false);
        })
        .catch((e) => {
          setBookedTicket([]);
          window.alert(`Status Code: 500 \n${"something went wrong"}`);
        })
        .finally(() => {
          setLoader(false);
        });
    }
    return null;
  }, []);
  const handleRescheduleData = (key, value) => {
    setError("date" === key && value === null);
    setRescheduleData(key);
  };

  const submitRequest = async (orderId) => {
    if (rescheduleData?.request[0]?.date) {
      let response = await ExternalsManager?.API.post(
        REQUEST_RESCHEDULE,
        rescheduleData
      );
      if (response?.type === "success") {
        window.alert(`Your request has been submitted.`);
        //window.reload()
        history.push("/dashboard");
        setAction("");
        setActionResch(null);
      } else {
        window.alert(
          `Status: Something went wrong. Please contact customer support.`
        );
      }
    }
  };

  return (
    <>
      {loader ? (
        <div className="db-tab-container d-flex align-items-center justify-content-center">
          <div className="py-3 tab-wrapper">
            <div className="Flight-loaderGIF">
              <Image
                alt="loader"
                src={renderImage("loader.gif")}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="db-tab-container">
          <div className="py-3 tab-wrapper">
            {bookedTicket?.length > 0 ? (
              bookedTicket.map((data, i) => {
                const {
                  bookingDetails: {
                    cabinClass,
                    isOneWay,
                    paxInfo,
                    tripInfos,
                    totalAmount,
                    convFee,
                    pnrDetails,
                    bookingStatusCode,
                    displayAmount,
                  },
                  _id: orderId,
                  currentStatus,
                } = data;

                return (
                  <React.Fragment key={orderId}>
                    {tripInfos.length > 0 &&
                      tripInfos.map((trips, index) => {
                        const { sI } = trips;
                        return (
                          index !== 1 && (
                            <div
                              className="booking1 border rounded"
                              key={trips?._id}
                            >
                              <div className="border-bottom mb-2 py-md-3 py-2">
                                <Row className="g-2">
                                  <Col xs="auto">
                                    <div className="text-left d-flex align-items-center">
                                      <div className="Flight-logo-sec d-flex flight-rt-logo">
                                        <Image
                                          className="me-1"
                                          src={renderImage(
                                            tripInfos[0]?.sI[0]?.fD?.aI?.code
                                          )}
                                        />
                                        {!isOneWay && (
                                          <Image
                                            className="me-1"
                                            src={renderImage(
                                              tripInfos[1]?.sI[0]?.fD?.aI?.code
                                            )}
                                          />
                                        )}
                                      </div>
                                      <div className="Flight-font16 ms-2 Flight-font-semibold">
                                        <span>{sI[0]?.da?.city}</span>
                                        <Image
                                          className="mx-2"
                                          src={renderImage(
                                            isOneWay
                                              ? "oneway-arrow.svg"
                                              : "rt-arrow.svg"
                                          )}
                                        />
                                        <span>
                                          {sI[sI?.length - 1]?.aa?.city}
                                        </span>
                                        <div className="Flight-font14">
                                          {`${getDateByHHdddDDMMMFormate(
                                            sI[0]?.dt
                                          )},${" "}${getDateByHHdddDDMMMFormate(
                                            sI[sI?.length - 1]?.at
                                          )} `}
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col className="justify-content-end d-flex flex-column text-end">
                                    <span className="Flight-font-bold text-gray2">
                                      You Paid
                                    </span>
                                    <span className="Flight-font16 Flight-font-bold">
                                      {numberToIndCurr(displayAmount)}
                                    </span>
                                  </Col>
                                </Row>
                              </div>

                              {tripInfos.map((val) => {
                                const {
                                  da: { code: depcode },
                                  dt: depdate,
                                } = val?.sI[0];

                                return (
                                  <div className="sector-info">
                                    <Row className="g-2">
                                      <Col xs="6" lg="3">
                                        <span className="text-gray2 Flight-font12 Flight-font-bold">
                                          Departure ({depcode})
                                        </span>
                                        <div className="Flight-font15 Flight-font-semibold">
                                          <span>
                                            {getDateByHHdddDDMMMFormate(
                                              depdate
                                            )}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col xs="6" lg="3">
                                        <span className="text-gray2 Flight-font12 Flight-font-bold">
                                          Arrival (
                                          {
                                            val?.sI[val?.sI?.length - 1].aa
                                              ?.code
                                          }
                                          )
                                        </span>
                                        <div className="Flight-font15 Flight-font-semibold">
                                          <span>
                                            {getDateByHHdddDDMMMFormate(
                                              val?.sI[val?.sI?.length - 1].at
                                            )}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col xs="4" lg="2">
                                        <span className="text-gray2 Flight-font12 Flight-font-bold">
                                          Trip ID
                                        </span>
                                        <div className="Flight-font15 Flight-font-semibold">
                                          <span>
                                            {" "}
                                            {orderId && orderId?.slice(-5)}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col xs="4" lg="2">
                                        <span className="text-gray2 Flight-font12 Flight-font-bold">
                                          Class
                                        </span>
                                        <div className="Flight-font15 ellipsis-text Flight-font-semibold">
                                          <span className="text-capitalize">
                                            {string.string.transform
                                              .capitalizeEachWord(cabinClass)
                                              ?.replace("_", " ")}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col xs="4" lg="2">
                                        <span className="text-gray2 Flight-font12 Flight-font-bold">
                                          Fare Type
                                        </span>
                                        <div className="Flight-font15 Flight-font-semibold  ellipsis-text">
                                          <span>
                                            {string.string.transform.capitalizeEachWord(
                                              val?.fare
                                            )}{" "}
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="my-2 pb-2">
                                      <div className="Flight-font14 Flight-font-bold mb-1 text-gray2">
                                        Sector
                                      </div>

                                      <Stack gap={3}>
                                        {val?.sI.map((item) => {
                                          return (
                                            <Row className="g-2">
                                              <Col xs="4" lg="3">
                                                <div className="Flight-logo-sec text-left d-flex align-items-center">
                                                  <div className="d-md-block d-none me-2">
                                                    <img
                                                      alt=""
                                                      src={renderImage(
                                                        item?.fD?.aI?.code
                                                      )}
                                                      className="img-fluid blur-up lazyloaded"
                                                    />
                                                  </div>
                                                  <div>
                                                    <span className="title Flight-font15 Flight-font-semibold">
                                                      {`${item.da.code}-${item?.aa?.code}`}
                                                    </span>
                                                    <div className="Flight-font13 Flight-font-semibold">
                                                      {getDateByHHdddDDMMMFormate(
                                                        item?.dt
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col xs="4" lg="3">
                                                <span className="text-gray2 Flight-font12 Flight-font-bold">
                                                  Airline no.
                                                </span>
                                                <div className="Flight-font15 Flight-font-semibold">
                                                  <span>
                                                    {`${item?.fD?.aI?.code}-${item?.fD?.fN}`}
                                                  </span>
                                                </div>
                                              </Col>
                                              <Col xs="4" lg="6">
                                                <span className="text-gray2 Flight-font12 Flight-font-bold">
                                                  PNR
                                                </span>
                                                <div className="Flight-font15 Flight-font-semibold">
                                                  <span>
                                                    {" "}
                                                    {pnrDetails
                                                      ? pnrDetails[
                                                          `${item.da.code}-${item?.aa?.code}`
                                                        ]
                                                      : "N/A"}
                                                  </span>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </Stack>
                                    </div>
                                  </div>
                                );
                              })}

                              <Row className="g-2">
                                <Col xs="4" lg="3">
                                  <span className="text-gray2 Flight-font12 Flight-font-bold">
                                    Traveller{" "}
                                    {paxInfo?.length > 1 ? (
                                      <span
                                        onClick={() => {
                                          setMoretravShow(true);
                                          setSSRModalData(paxInfo);
                                        }}
                                        className="Flight-theme-txt Flight-cursor-pointer text-decoration-underline">
                                        + {paxInfo?.length-1}{" "}
                                        <span className="d-sm-inline d-none">
                                          more
                                        </span>
                                      </span>
                                      
                                    ) : null}
                                  </span>
                                  <div className="Flight-font15 Flight-font-semibold">
                                    <span>
                                      {paxInfo[0]?.fN} {paxInfo[0]?.lN}
                                    </span>
                                  </div>
                                </Col>
                                <Col xs="4" lg="3">
                                  <span className="text-gray2 Flight-font12 Flight-font-bold">
                                    Seat
                                  </span>
                                  <div className="Flight-font15 Flight-font-semibold">
                                    {paxInfo[0]?.ssrSeatInfos?.length > 0 ? (
                                      <span>
                                        {paxInfo[0]?.ssrSeatInfos.map(
                                          (seat, index) => {
                                            return (
                                              <span key={index}>
                                                {seat?.code}
                                                {index !==
                                                paxInfo[0]?.ssrSeatInfos
                                                  .length -
                                                  1
                                                  ? ", "
                                                  : ""}
                                              </span>
                                            );
                                          }
                                        )}
                                      </span>
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </div>
                                </Col>
                                <Col xs="4" lg="6">
                                  <span className="text-gray2 Flight-font12 Flight-font-bold">
                                    Meal
                                  </span>
                                  <div className="Flight-font15 Flight-font-semibold">
                                    {paxInfo[0]?.ssrMealInfos?.length > 0 ? (
                                      <span>
                                        {paxInfo[0]?.ssrMealInfos.map(
                                          (meal, index) => {
                                            return (
                                              <span key={index}>
                                                {meal?.code}
                                                {index !==
                                                paxInfo[0]?.ssrMealInfos
                                                  .length -
                                                  1
                                                  ? ", "
                                                  : ""}
                                              </span>
                                            );
                                          }
                                        )}
                                      </span>
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <div className="border-top mt-3 py-md-3 py-2 Flight-font-semibold">
                                <div className="Flight-theme-txt d-lg-flex db-btm-btns justify-content-between">
                                  {bookingStatusCode === "Cancelling" ||
                                  bookingStatusCode ===
                                    "ConfirmedNotCancelled" ||
                                  bookingStatusCode === "Cancelled" ||
                                  bookingStatusCode === "Rescheduled" ? (
                                    <div
                                      style={{
                                        pointerEvents: "none",
                                        opacity: "0.6",
                                      }}
                                    >
                                      <span className="Flight-cursor-pointer d-flex">
                                        <Image
                                          className="me-1"
                                          src={renderImage("icon-cancel.svg")}
                                        />
                                        {bookingStatusCode}
                                      </span>
                                    </div>
                                  ) : currentStatus === "failed" ||
                                    currentStatus === "cancelled" ||
                                    currentStatus === "past" ? null : (
                                    <div>
                                      <span
                                        className="Flight-cursor-pointer d-flex"
                                        onClick={() => {
                                          setAction(true);
                                          setCancellationModalData(data);
                                        }}
                                      >
                                        <Image
                                          className="me-1"
                                          src={renderImage("icon-cancel.svg")}
                                        />
                                        Cancel&nbsp;{" "}
                                        <span className="d-md-inline d-none">
                                          Ticket
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                  {bookingStatusCode === "Rescheduling" ||
                                  bookingStatusCode === "Rescheduled" ||
                                  bookingStatusCode ===
                                    "ConfirmedNotRescheduled" ? (
                                    <div
                                      style={{
                                        pointerEvents: "none",
                                        opacity: "0.6",
                                      }}
                                    >
                                      <span className="Flight-cursor-pointer d-flex">
                                        <Image
                                          className="me-1"
                                          src={renderImage(
                                            "icon-reschedule.svg"
                                          )}
                                        />
                                        {bookingStatusCode}
                                      </span>
                                    </div>
                                  ) : currentStatus === "failed" ||
                                    currentStatus === "cancelled" ||
                                    currentStatus === "past" ? null : (
                                    <div>
                                      <span
                                        className="Flight-cursor-pointer d-flex"
                                        onClick={() => {
                                          setActionResch("confirmation");
                                          setCancellationModalData(data);
                                        }}
                                      >
                                        <Image
                                          className="me-1"
                                          src={renderImage(
                                            "icon-reschedule.svg"
                                          )}
                                        />
                                        Reschedule
                                      </span>
                                    </div>
                                  )}
                                  <div>
                                    <a
                                      className="Flight-theme-txt text-decoration-none d-flex align-items-baseline"
                                      target="_blank"
                                      href="/webcheckin"
                                    >
                                      <Image
                                        className="me-1"
                                        src={renderImage("icon-wc.svg")}
                                      />
                                      <span className="d-md-inline d-none">
                                        Web&nbsp;
                                      </span>{" "}
                                      Check-in
                                    </a>
                                  </div>
                                  <div>
                                    <a
                                      className="Flight-theme-txt text-decoration-none  d-flex"
                                      target="_blank"
                                      href="https://www.flightstats.com/v2/"
                                    >
                                      <Image
                                        className="me-1"
                                        src={renderImage("icon-status.svg")}
                                      />
                                      <span className="d-md-inline d-none">
                                        Flight&nbsp;
                                      </span>{" "}
                                      Status
                                    </a>
                                  </div>
                                  <div>
                                    <span
                                      className="Flight-cursor-pointer d-flex"
                                      onClick={() => {
                                        setViewDetailData(data);
                                        setViewdetailShow(true);
                                      }}
                                    >
                                      View Details
                                      <Image
                                        className="ms-1"
                                        src={renderImage("icon-viewdeail.svg")}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                  </React.Fragment>
                );
              })
            ) : (
              <div className="db-tab-container d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <Image className="" src={renderImage("db-booking.svg")} />
                  <h2 className="Flight-font-bold text-gray2 Flight-font20 mt-3 mb-1">
                    {tabVal?.filter === "active"
                      ? "No Booking"
                      : tabVal?.filter === "past"
                      ? "No Past Booking"
                      : "No Cancelled Bookings"}
                  </h2>
                  <p className="Flight-font-semibold text-gray2">
                    Looks like You don't have any bookings.
                  </p>
                  <Button
                    className="Flight-btn-purple px-5 mt-2"
                    onClick={() => history.push("/")}
                  >
                    Book a Flight
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        centered
        size="md"
        show={moretravShow}
        onHide={() => setMoretravShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title
            id="example-modal-sizes-title-sm"
            className="Flight-font18 h4"
          >
            <Image
              src={renderImage("add-traveller-icon.svg")}
              alt=""
              className="me-2"
            />{" "}
            Traveller Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-2 pt-0">
          <BookingsSSRModal data={ssrModalData} />
        </Modal.Body>
      </Modal>
      <Modal
        className="viewdetail-modal"
        centered
        size="md"
        show={viewdetailShow}
        onHide={() => setViewdetailShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header
          closeButton
          className="border-bottom-0 justify-content-end pb-0"
        ></Modal.Header>
        <Modal.Body className="pt-0 dtl-modal-body px-0">
          <BookingsViewDetailModal data={viewDetailData} />
        </Modal.Body>
      </Modal>

      <Modal
        className="Flight-modal"
        show={action}
        onHide={() => setAction(false)}
        style={{ zIndex: 1111 }}
        centered
      >
        <Cancellation data={cancellationModalData} />
      </Modal>
      <Modal
        className="Flight-modal"
        show={actionResch}
        onHide={() => setActionResch(false)}
        centered
      >
        <Modal.Header
          closeButton
          className="justify-content-between border-bottom-0 pb-0"
        >
          <Modal.Title className="Flight-font16 Flight-font-bold">
            Reschedule Flight
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 pb-0">
          {actionResch === "confirmation" && (
            <SubmitReqModalBody
              data={cancellationModalData}
              error={error}
              formData={rescheduleData}
              onChangeFormData={handleRescheduleData}
              onBack={() => setActionResch("confirmation")}
              userId={userId}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="border-top-0 justify-content-start">
          <Button
            className="Flight-btn-purple btn Flight-font13 Flight-font-semibold"
            onClick={() => submitRequest(cancellationModalData?._id)}
          >
            Request Reschedule
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Bookings;
