import React, { Fragment, memo, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import ResultList from "../../../commons/artefacts/components/FlightResultList/ResultList";
import DataNotFound from "../../../commons/artefacts/components/errors/DataNotFound";
import NotFilteredData from "../../../commons/artefacts/components/errors/NotFilteredData";
import ServerError from "../../../commons/artefacts/components/errors/ServerError";
import SortMob from "../../../commons/artefacts/components/sort/SortMob";
import SortWeb from "../../../commons/artefacts/components/sort/SortWeb";
import Button from "../../../commons/artefacts/elements/button/primary";
import Image from "../../../commons/artefacts/elements/input/image";
import { DataContext } from "../../../commons/context/dataContext";
import string from "../../../commons/util/UtilManager";
import filterFlight from "../../../commons/util/filter";
import { renderImage } from "../../../commons/util/flightUtil";
import flightSort from "../../../commons/util/sort";
import {
  getUniqueFlights,
  mutateSearchResponse,
} from "../../../commons/util/uniqueFlights";
import { getFlightData } from "../../services/airlineService";
import "../styles.css";
import Filter from "./Filter";
import FlightSearchFormInner from "../home/FlightSearchFormInner";

const OneWay = () => {
  const { pathname } = useLocation();

  const [uniqFlight, setUniqueFlight] = useState([]);
  const [filterFlightData, setFilterFlightData] = useState([]);
  const [minAmong, setMinAmong] = useState(0);
  const [maxAmong, setMaxAmong] = useState(0);
  const [error, setError] = useState("");
  const [showMobFilter, setshowMobFilter] = useState("filters");
  const [showMobFilterFlag, setshowMobFilterFlag] = useState(false);

  const {
    selectedOnward,
    setSelectedOnward,
    searchData: { onwardFlights },
    searchDataDispatch,
    formState,
    loader,
    setLoader,
    onFilterState,
    onFilterDispatch,
    sortState: { onward: sortColumn },
    setSortState,
    showFareDetail,
    setShowFareDetail,
  } = useContext(DataContext);

  const {
    departPlace,
    arrivalPlace,
    startDate,
    passengers,
    fareType,
    flightClass,
  } = formState;

  useEffect(() => {
    setError("");
    getFlightData(
      departPlace.code,
      arrivalPlace.code,
      passengers,
      fareType,
      flightClass,
      startDate
    )
      .then(
        ({
          type,
          message,
          status,
          data: { searchResult: { tripInfos: { ONWARD = [] } = {} } = {} } = {},
        }) => {
          if (type === "failure" || type === "error" || status >= 500) {
            setError("Server Error");
          } else if (!ONWARD.length) {
            setError("No Data");
          }



          const updatedOnwardData = ONWARD.map(flight => {
            if (flight?.totalPriceList && flight?.totalPriceList?.length > 0) {
              flight.totalPriceList.forEach(price => {
                const originalTF = price?.fd?.ADULT?.fC?.TF;
                const MF = price?.fd?.ADULT?.afC?.TAF?.MF || 0;
                const MFT = price?.fd.ADULT?.afC?.TAF?.MFT || 0;
                price.fd.ADULT.fC.TF = originalTF - (MF + MFT);
              });
            }
            return flight;
          });

          const { data, minAmong, maxAmong } = mutateSearchResponse(updatedOnwardData);

          setMinAmong(minAmong);
          setMaxAmong(maxAmong);
          setUniqueFlight(getUniqueFlights(data));

          onFilterDispatch({
            type: "RangeUpdated",
            payload: {
              range: {
                min: minAmong,
                max: maxAmong,
              },
            },
          });

          searchDataDispatch({
            type: "UpdatedOneWay",
            payload: { onwardFlights: data },
          });
        }
      )
      .finally(() => setLoader(false));
  }, [formState]);

  useEffect(() => {
    if (onwardFlights.length > 0) {
      setError("");
      let filteredData = filterFlight(onwardFlights, onFilterState);

      if (!filteredData.length) setError("No Filter Data");
      setShowFareDetail("");

      filteredData = flightSort(filteredData, sortColumn);

      setFilterFlightData(filteredData);
    }
  }, [onFilterState, onwardFlights.length, sortColumn]);

  const handleSort = (colName) => {
    let ord =
      sortColumn.path === colName
        ? sortColumn.order === "asc"
          ? "desc"
          : "asc"
        : "desc";
    setSortState((prevState) => ({
      ...prevState,
      onward: { path: colName, order: ord },
    }));
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 30;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Top search start */}
      <div
        className="Flight-yellow-gradient Flight-sticky_nav pt-lg-4 pb-lg-3"
        // className={
        //   scrolled
        //     ? "Flight-yellow-gradient Flight-sticky_nav pt-lg-4 pb-lg-3"
        //     : "Flight-yellow-gradient pt-lg-4 pb-lg-3 mb-lg-2"
        // }
      >
        <Container fluid="xxl" className="px-0 px-lg-3 Flight-container-xxl">
          <div className="Flight-widgetSection Flight-inner-page-search">
            <FlightSearchFormInner />
            <div className="sort-filter d-lg-none">
              <Container className="Flight-container">
                <div className="d-flex justify-content-end ">
                  <span
                    className="pe-3 d-inline-flex"
                    onClick={() => {
                      setshowMobFilter("sorting");
                      setshowMobFilterFlag(true);
                    }}
                  >
                    <Image
                      className="me-2"
                      src={renderImage("sortingIcon.svg")}
                      alt="sun"
                    />
                    Sort
                  </span>
                  <span
                    className="d-inline-flex"
                    onClick={() => {
                      setshowMobFilterFlag(true);
                      setshowMobFilter("filters");
                    }}
                  >
                    <Image
                      className="me-2"
                      src={renderImage("filterIcon.svg")}
                      alt="sun"
                    />
                    Filter
                  </span>
                </div>
              </Container>
            </div>
          </div>
        </Container>
      </div>
      {/* Top search end  */}

      <Container fluid="xxl" className="pt-3 Flight-container-xxl">
        <Row className="g-3 Flight-row">
          {loader ? (
            // <div className="Flight-loaderGIF search-loader">
            //   <Image src={renderImage("loader.gif")} className="img-fluid" />
            // </div>
            <div className="loader-wrapper mt-0 pt-0 img-gif">
              <Image src={renderImage("loader.gif")} className="img-fluid" />
            </div>
          ) : error === "Server Error" ? (
            <ServerError />
          ) : error === "No Data" ? (
            <DataNotFound />
          ) : (
            <Fragment>
              <Col lg="9">
                {error === "No Filter Data" ? (
                  <NotFilteredData
                    onClear={() => {
                      setError("");
                      onFilterDispatch({
                        type: "ResetState",
                        payload: { range: { min: minAmong, max: maxAmong } },
                      });
                    }}
                  />
                ) : (
                  <Fragment>
                    {["ARMED_FORCES", "DOCTORS_NURSES"].includes(fareType) && (
                      <Fragment>
                        <div className="Flight-search-result Flight-search-inner mb-3 p-0 border-0">
                          <div className="border-0 box-shadow-none Fligh-alert1 d-flex align-items-center p-md-3 p-2">
                            <Image
                              className="me-3"
                              width={30}
                              alt="calender"
                              src={renderImage("noflight-info.svg")}
                            />
                            <div>
                              {/* <Image
                                alt="calender"
                                src={renderImage("flight-not-found.svg")}
                              /> */}
                              {/* <div className="mt-3 card-title h5">
                                {" "}
                                Sorry, no flights with{" "}
                                {string.string.transform.capitalizeEachWord(
                                  fareType.replace(/_/g, " ")
                                )}{" "}
                                Fare were found.
                              </div> */}
                              <>
                                <p className="Flight-font14 Flight-font-semibold mb-0 text-capitalize">
                                  We could not fetch out any{" "}
                                  <span className="Flight-font-bold">
                                    {string.string.transform.capitalizeEachWord(
                                      fareType.replace(/_/g, " ")
                                    )}{" "}
                                  </span>
                                  fares flight result for this route / dates!{" "}
                                  here are some other flight options you may
                                  wish to explore.
                                </p>
                              </>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <SortWeb sortColumn={sortColumn} onSort={handleSort} />
                    <ResultList
                      data={filterFlightData}
                      path={pathname}
                      passengers={passengers}
                      selectedCard={selectedOnward}
                      showFareDetail={showFareDetail}
                      onChangeShowFareDetail={(id) => {
                        setShowFareDetail(id !== showFareDetail ? id : "");
                      }}
                      onFareChange={(index, fare) => {
                        searchDataDispatch({
                          type: "UpdatedSelectedFare",
                          payload: {
                            flightType: "oneway",
                            dataIndex: index,
                            fare,
                          },
                        });
                        setSelectedOnward({
                          ...onwardFlights[index],
                          totalPriceList: [fare],
                        });
                      }}
                      onSelectCard={(data) => {}}
                    />
                  </Fragment>
                )}
              </Col>
              <Col md="3">
                {/* Filter start */}
                <div className="d-lg-flex d-none justify-content-between Flight-filter-txt Flight-filterdp">
                  <div className="d-inline-block Flight-font13 Flight-font-semibold text-decoration-none text-dark">
                    FILTERS
                  </div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setError("");
                      onFilterDispatch({
                        type: "ResetState",
                        payload: { range: { min: minAmong, max: maxAmong } },
                      });
                    }}
                    className="d-inline-block Flight-font13 text-dark Flight-font-semibold Flight-cursor-pointer border-0 bg-transparent"
                  >
                    {" "}
                    CLEAR ALL
                  </Button>
                </div>
                <div
                  className={`Flight-left-sidebar filter-mobile ${
                    showMobFilterFlag && "show"
                  }`}
                >
                  <Container className="Flight-container">
                    <Tabs
                      id="uncontrolled-tab-example"
                      className="mb-0 d-lg-none Flight-sf-tabs Flight-box-stretch"
                      activeKey={showMobFilter}
                      onSelect={(selectedTab) => {
                        setshowMobFilter(selectedTab);
                        setshowMobFilterFlag(true);
                      }}
                    >
                      <Tab eventKey="filters" title="Filters">
                        <div className="Flight-filterblock">
                          <Filter
                            flightType="Oneway"
                            filterState={onFilterState}
                            airplanes={uniqFlight}
                            onStop={(ind) =>
                              onFilterDispatch({
                                type: "StopUpdated",
                                payload: { index: ind },
                              })
                            }
                            onDepartTime={(val) =>
                              onFilterDispatch({
                                type: "DepartureUpdated",
                                payload: { departureTime: val },
                              })
                            }
                            onArriveTime={(val) =>
                              onFilterDispatch({
                                type: "ArrivalUpdated",
                                payload: { arrivalTime: val },
                              })
                            }
                            onPriceChange={(val) =>
                              onFilterDispatch({
                                type: "PriceUpdated",
                                payload: { range: val },
                              })
                            }
                            onAircraftSelect={(val) =>
                              onFilterDispatch({
                                type: "AircraftUpdated",
                                payload: { aircraftName: val },
                              })
                            }
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="sorting" title="Sort By">
                        <div className="Flight-filterblock">
                          <SortMob
                            sortColumn={sortColumn}
                            onChange={(col) =>
                              setSortState((prevState) => ({
                                ...prevState,
                                onward: col,
                              }))
                            }
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </Container>
                  <div className="Flight-aply-fltr-btn d-flex justify-content-around">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        showMobFilter === "filters"
                          ? onFilterDispatch({
                              type: "ResetState",
                              payload: {
                                range: { min: minAmong, max: maxAmong },
                              },
                            })
                          : setSortState((prevState) => ({
                              ...prevState,
                              onward: { path: "TF", order: "asc" },
                            }));
                        setshowMobFilterFlag(false);
                        setshowMobFilter("");
                      }}
                      className="w-50 Flight-cursor-pointer d-lg-none border-0 Flight-clear-filter"
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={() => {
                        setshowMobFilterFlag(false);
                        setshowMobFilter("");
                      }}
                      className="btn Flight-btn-purple w-50 d-lg-none py-2 rounded-0 border-0"
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Col>
            </Fragment>
          )}
        </Row>
      </Container>
    </>
  );
};

export default memo(OneWay);
