import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";
const Footer = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [platform, setPlatform] = useState("");
  useEffect(() => {
    const currentPlatform = getPlatform();
    setPlatform(currentPlatform);
  }, []);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/manageheaderfooterwebview/.test(userAgent) && !window.MSStream) {
      return "mobileinapp";
    }
    return "web";
  };

  return (
    <>
      {platform === "mobileinapp" ? null : (
        <footer>
          <div className="footer">
            <Container fluid="xxl">
              <Row className="footer-wrapper pt-md-5 pt-3 pb-4">
                {/* <Col md="3" sm="6" className="mobile-footer-menu">
              <div className="footer-heading pb-md-2 text-dark  neulist-sb-italic">
                Our services
                <Image alt="" src={renderImage("Icon-Arrow-Down.svg")} />
              </div>
              <ul className="footer-links  footer-menu-items">
                <li className="mb-1">
                  <a href="#">Flights</a>
                </li>
                <li className="mb-1">
                  <a target="_blank" href="https://www.flightstats.com/v2/">
                    Flight Status
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/webcheckin">Web Check-in</a>
                </li>
              </ul>
            </Col> */}
                <Col md="3" sm="6" className="mobile-footer-menu">
                  <div
                    className="footer-heading pb-md-2 text-dark neulist-sb-italic"
                    onClick={toggleDropdown}
                  >
                    Our services
                    <Image alt="" src={renderImage("Icon-Arrow-Down.svg")} />
                  </div>
                  <ul
                    className={`footer-links footer-menu-items ${
                      isDropdownOpen ? "open" : ""
                    }`}
                  >
                    <li className="mb-1">
                      <a href="#">Flights</a>
                    </li>
                    <li className="mb-1">
                      <a target="_blank" href="https://www.flightstats.com/v2/">
                        Flight Status
                      </a>
                    </li>
                    <li className="mb-1">
                      <a href="/webcheckin">Web Check-in</a>
                    </li>
                  </ul>
                </Col>
                <Col md="3" sm="6" className="mobile-footer-menu mt-sm-0 mt-2">
                  <div className="footer-heading pb-md-2 text-dark  neulist-sb-italic">
                    Company
                    <Image alt="" src={renderImage("Icon-Arrow-Down.svg")} />
                  </div>
                  <ul className="footer-links footer-menu-items">
                    <li className="mb-1">
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li className="mb-1">
                      <a href="/termsandconditions">Terms &amp; Conditions</a>{" "}
                    </li>
                    <li className="mb-1">
                      <a href="/privacypolicy">Privacy &amp; Policy</a>{" "}
                    </li>

                    <li className="mb-1">
                      <a href="/refundandcancellation">
                        Cancellation &amp; Refund Policy{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/faqs">FAQs</a>
                    </li>
                  </ul>
                </Col>
                <Col md="3" sm="6" className="mobile-footer-menu mt-md-0 mt-2">
                  <div className="footer-heading pb-md-2 text-dark  neulist-sb-italic">
                    Safe and Secure Booking
                    <Image alt="" src={renderImage("Icon-Arrow-Down.svg")} />
                  </div>
                  <div className="text-center">
                    <div className="d-flex mt-2">
                      <Image
                        alt=""
                        className="mx-1"
                        src={renderImage("card-1.png")}
                      />
                      <Image
                        alt=""
                        className="mx-1"
                        src={renderImage("card-2.png")}
                      />
                      <Image
                        alt=""
                        className="mx-1"
                        src={renderImage("card-3.png")}
                      />
                      <Image
                        alt=""
                        className="mx-1"
                        src={renderImage("card-4.png")}
                      />
                    </div>
                    <div className="d-flex mt-2">
                      <Image
                        alt=""
                        className="mx-1"
                        src={renderImage("iata.svg")}
                      />
                      <Image
                        alt=""
                        className="mx-1"
                        src={renderImage("pcidss.svg")}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="3" sm="6" className="mobile-footer-menu mt-md-0 mt-2">
                  <div className="footer-heading pb-md-2 text-dark  neulist-sb-italic">
                    Download Now
                    <Image alt="" src={renderImage("Icon-Arrow-Down.svg")} />
                  </div>

                  <div className="footer-menu-items">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.ogenie.app"
                      target="_blank"
                    >
                      <Image alt="" src={renderImage("our-qr.png")} />
                    </a>
                  </div>

                  {/* <div className="footer-title font24 neu-medium text-center text-dark">
                Subscribe to our Newsletter
              </div>
              <div className="px-4">
                <div className="input-group border rounded-pill my-2">
                  <input
                    type="text"
                    className="form-control font13 border-0"
                    placeholder="Enter your email address"
                    aria-label="Recipient's username"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn text-end"
                      type="button"
                      id="button-addon"
                    >
                      <Image alt="" src={IMG("subscribe-btn.svg")} />
                    </button>
                  </div>
                </div>
              </div> */}
                </Col>
              </Row>
              <div className="sub-footer py-3 d-flex justify-content-between">
                <div className="font14">
                  Copyright 2016-2023 - OgenieTravels
                </div>
                <div className="d-flex">
                  <a href="https://www.facebook.com/OGenieTravels/">
                    <Image
                      alt=""
                      src={renderImage("fb-icon.png")}
                      className="img-fluid mx-1"
                    />
                  </a>
                  <a href="https://www.instagram.com/ogenietravels/">
                    <Image
                      alt=""
                      src={renderImage("instagram-icon.png")}
                      className="img-fluid mx-1"
                    />
                  </a>
                  <a href="https://twitter.com/ogenietravels">
                    <Image
                      alt=""
                      src={renderImage("twitter-icon.png")}
                      className="img-fluid mx-1"
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/sayogenie/">
                    <Image
                      alt=""
                      src={renderImage("linkedin-icon.png")}
                      className="img-fluid mx-1"
                    />
                  </a>
                </div>
              </div>
            </Container>
          </div>
        </footer>
      )}
      {/* {platform === "web" ? null : <div className="height"></div>} */}
    </>
  );
};

export default Footer;
