import React, { useState } from "react";
import Image from "../../../commons/artefacts/elements/input/image";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import ExternalsManager from "../../../commons/externals/ExternalsManager";
import { renderImage } from "../../../commons/util/flightUtil";

const Booking = () => {
  const { state } = useLocation();
  const [currentWindow] = useState(0);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBookingStatus = async (val) => {
    let data = state?.finalPayload;

    // Remove dob key if it is an empty string
    data?.travellerInfo.forEach((traveller) => {
      traveller.pt = traveller.type;
      delete traveller.type;
      traveller.ti = traveller.ti.replace(/\./g, ""); //modify the "ti" key

      if (traveller.eD) {
        const edDate = new Date(
          traveller?.eD?.split(".")?.reverse()?.join("-")
        );
        traveller.eD = edDate?.toISOString()?.split("T")[0];
      }

      // Convert the date format of "pid" key to "YYYY-MM-DD"
      if (traveller.pid) {
        const pidDate = new Date(
          traveller?.pid?.split(".")?.reverse()?.join("-")
        );
        traveller.eD = pidDate?.toISOString()?.split("T")[0];
      }

      if (traveller.dob === "") {
        delete traveller.dob;
      } else {
        const dateB = new Date(
          traveller?.dob?.split(".")?.reverse()?.join("-")
        );
        traveller.dob = dateB?.toISOString()?.split("T")[0];
      }

      // update the ti key for infant and child travellers
      if (traveller.pt === "INFANT" || traveller.pt === "CHILD") {
        if (traveller.ti !== "Ms." && traveller.ti !== "Master") {
          traveller.ti = "Master";
        }
      }
    });

    let payload = {
      bookingId: data?.bookingId,
      paymentInfos: [
        {
          amount: data?.totalAmount,
        },
      ],
      travellerInfo: data?.travellerInfo,
      gstInfo: data?.FootergstInfo,
      deliveryInfo: {
        emails: [data?.contactInfo?.email],
        contacts: [data?.contactInfo?.contact],
      },
    };

    if (val === "success") {
      payload.paymentStatus = "SUCCESS";
      let response = await ExternalsManager?.API.post("/bookflight", payload);
      if (response?.type === "failure") {
        window.alert(
          `Status Code: ${response.status}\n ${response.message.errorMsg}`
        );
      } else {
        window.alert(
          "Success! Your action has been submitted with a status of success."
        );
      }
      // navigate to order list page
    } else {
      payload.paymentStatus = "FAILURE";
      let response = await ExternalsManager?.API.post("/bookflight", payload);
      if (response?.type === "failure") {
        window.alert(
          `Status Code: ${response.status}\n ${response.message.errorMsg}`
        );
      } else {
        window.alert(
          "Success! Your action has been submitted with a status of failure."
        );
        // navigate to order list page
      }
    }
  };
  if (currentWindow === 0) {
    return (
      <Container
        className="mt-5 p-3 Flight-container"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <Row className="Flight-row">
          <Col className="d-flex justify-content-center">
            <h4 Flight-ln>Status</h4>
          </Col>
        </Row>
        <Row className="Flight-row">
          <Col>
            <hr />
          </Col>
        </Row>
        <Row className="Flight-row">
          <Col>
            <Button
              name="success"
              id="success"
              className="w-100"
              style={{ borderRadius: "0px" }}
              onClick={() => {
                handleBookingStatus("success");
                //setCurrentWindow(1)
              }}
              variant="success"
              size="lg"
            >
              Success
            </Button>
          </Col>
          <Col>
            <Button
              name="Failure"
              id="Failure"
              className="w-100"
              style={{ borderRadius: "0px" }}
              onClick={() => {
                handleBookingStatus("Failure");
                //setCurrentWindow(2)
              }}
              variant="danger"
              size="lg"
            >
              Failure
            </Button>
          </Col>
          <Col>
            <Button
              className="w-100"
              style={{ borderRadius: "0px" }}
              onClick={() => {
                //setCurrentWindow(3)

                window.location.replace(state?.redirectionURL);
              }}
              variant="primary"
              size="lg"
            >
              Payment Gateway
            </Button>
          </Col>
        </Row>
      </Container>
    );
  } else if (currentWindow === 1) {
    return (
      <Container className="Flight-container">
        <Row className="Flight-row">
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="booking-msg py-3 mx-md-5 text-center position-relative mt-5">
              <Image
                width={120}
                src={renderImage("booking-checked.svg")}
                alt="booking"
              />
              <div className="pmt-msg py-3">
                <h2 className="Flight-font20 mb-1 Flight-ln">
                  Payment Successful
                </h2>
                <h4 className="fw-normal pmt-pnr Flight-font13 Flight-ln">
                  PNR Number: <span>123456789</span>
                </h4>
                <p className="after-pmt-msg text-center Flight-font13  px-xl-5 px-lg-4 px-3 mx-3 mx-lg-5  mb-0">
                  Your Payment has successful complete. You can check your
                  booking in your registered email
                </p>
              </div>
              <div className="dshd-sprtr "></div>
              <div className="amt text-xl-center  px-xxl-5 px-xl-4 px-3  pt-3 mx-xxl-3">
                <Row className="amt-paid align-items-center justify-content-center Flight-row">
                  <Col xs="4">
                    <h5 className="Flight-font13 m-0 text-start mb-0 Flight-ln">
                      Amount Paid
                    </h5>
                  </Col>
                  <Col xs="1" className="p-0">
                    :
                  </Col>
                  <Col xs="7" className="text-start">
                    <p className="mb-0 Flight-font13">&#x20B9;33.995.00</p>
                  </Col>
                </Row>
                <Row className="amt-paid align-items-center justify-content-center Flight-row">
                  <Col xs="4">
                    <h5 className="Flight-font13 m-0 text-start mb-0 Flight-ln">
                      Payment Mode
                    </h5>
                  </Col>
                  <Col xs="1" className="p-0">
                    :
                  </Col>
                  <Col xs="7" className="text-start">
                    <p className="m-0 Flight-font13">
                      XXXX XXXX XXXX 1234{" "}
                      <span>
                        <Image
                          className="img-fluid h-100 ms-2"
                          width={20}
                          src={renderImage("credit-card.png")}
                          alt="cc"
                        />
                      </span>{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="text-center Flight-font14 mt-xl-4 mt-3">
              <p className="mb-0">
                If you have any queries or suggestion, please get in touch with
                us:{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="mailto:support@ogenietravels.com"
                >
                  support@ogenietravels.com
                </a>{" "}
                or (WhatsApp Chat Only)
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+91-7898911333"
                >
                  +91-7898911333
                </a>{" "}
                /{" "}
                {/* <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 22012001"
                >
                  080-22012001
                </a>{" "}
                /{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 66785555"
                >
                  080-66785555
                </a> */}
              </p>
              <Button className="btn mt-4 Flight-search-flight-btn Flight-font20 text-white  border-0">
                Done
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else if (currentWindow === 2) {
    return (
      <Container className="Flight-container">
        <Row className="Flight-row">
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="booking-msg py-3 mx-md-5 text-center position-relative mt-5">
              <Image
                alt="bookingfailed"
                width={120}
                src={renderImage("booking-failed.svg")}
              />{" "}
              <h2 className="Flight-font20 mb-1 mt-3 Flight-ln">
                Payment Failed
              </h2>
              <p className="Flight-font13 mb-2">
                Transaction number : <span>123456789</span>
                <br />
                Your ticket booking is failed please try again
              </p>
            </div>
            <div className="text-center Flight-font14 mt-xl-4 mt-3">
              <p className="mb-0">
                If you have any queries or suggestion, please get in touch with
                us:{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="mailto:support@ogenietravels.com"
                >
                  support@ogenietravels.com
                </a>{" "}
                or (WhatsApp Chat Only)
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+91-7898911333"
                >
                  +91-7898911333
                </a>{" "}
                /{" "}
                {/* <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 22012001"
                >
                  080-22012001
                </a>{" "}
                /{" "}
                <a
                  className="text-decoration-none Flight-font14 Flight-theme-txt"
                  href="telto:+080 66785555"
                >
                  080-66785555
                </a> */}
              </p>
              <Button className="btn mt-4 Flight-search-flight-btn Flight-font20 text-white  border-0">
                Done
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } else if (currentWindow === 3) {
    return (
      <Container className="Flight-container">
        <Row className="Flight-row">
          <Col xl="4" md="6" sm="8" className="m-auto mt-5">
            <div className="Flight-ticket-main py-3  position-relative">
              <Container className="position-relative p-4 Flight-container">
                <Row className="Flight-row">
                  <Col>
                    <div className="Flight-logo-sec text-left d-flex">
                      <div>
                        <Image
                          alt="ak"
                          width={120}
                          src={renderImage("AK.png")}
                        />
                      </div>
                      <div className="ms-3 text-center">
                        <span className="title">AirAsia</span>
                        <span className="Flight-font12 Flight-lght-txt d-block Flight-font-semibold Flight-fnum">
                          15 - 745
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <div className="d-flex align-items-center gap-2">
                        <h6 className="Flight-font16 Flight-font-semibold m-0 Flight-ln">
                          BOM
                        </h6>
                        <Image
                          alt="plain"
                          width={25}
                          src={renderImage("tck_plane.svg")}
                        />{" "}
                        <h6 className="Flight-font16 Flight-font-semibold m-0">
                          DEL
                        </h6>
                      </div>
                      <div>
                        <span className="Flight-font12 Flight-lght-txt d-block Flight-font-semibold Flight-fnum">
                          Tuesday 28 Mar 2023
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="dshd-sprtr "></div>
              <div className="Flight-ticket-bg">
                <Image
                  alt="ticket"
                  width={190}
                  src={renderImage("ticket-bg.png")}
                />{" "}
              </div>
              <Container className="position-relative p-4 Flight-container">
                <Row className="mb-2 Flight-row">
                  <Col>
                    <span className="d-block Flight-lght-txt">
                      Departure (BOM)
                    </span>
                    <span className="d-block Flight-drk-txt">
                      23:45 Tue 28 Mar
                    </span>
                  </Col>
                  <Col>
                    <span className="d-block Flight-lght-txt">
                      Arrival (DEL)
                    </span>
                    <span className="d-block Flight-drk-txt">
                      13:00 Tue 28 Mar
                    </span>
                  </Col>
                </Row>
                <Row className="mb-2 Flight-row">
                  <Col>
                    <span className="d-block Flight-lght-txt">Trip ID</span>
                    <span className="d-block Flight-drk-txt">6493720660</span>
                  </Col>
                  <Col>
                    <span className="d-block Flight-lght-txt">PNR</span>
                    <span className="d-block Flight-drk-txt">B8LT7Z</span>
                  </Col>
                </Row>
                <Row className="mb-2 Flight-row">
                  <Col>
                    <span className="d-block Flight-lght-txt">Traveller</span>
                    <span className="d-block Flight-drk-txt">
                      Pankaj Sharma
                    </span>
                  </Col>
                  <Col>
                    <Row className="Flight-row">
                      <Col>
                        <span className="d-block Flight-lght-txt">Seat</span>
                        <span className="d-block Flight-drk-txt">E3</span>
                      </Col>
                      <Col>
                        <span className="d-block Flight-lght-txt">Meal</span>
                        <span className="d-block Flight-drk-txt">AVML</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="Flight-row">
                  <Col>
                    <span className="d-block Flight-lght-txt">Class</span>
                    <span className="d-block Flight-drk-txt">Economy</span>
                  </Col>
                  <Col>
                    <span className="d-block Flight-lght-txt">Fare Type</span>
                    <span className="d-block Flight-drk-txt">
                      Regular Fares
                    </span>
                  </Col>
                </Row>
              </Container>
              <div className="dshd-sprtr "></div>
              <Container className="p-4 Flight-container">
                <Row className="Flight-row">
                  <Col>
                    <span className="d-block Flight-lght-txt">Trip ID</span>
                    <span className="d-block Flight-drk-txt">₹6,909</span>
                  </Col>
                  <Col className="text-end">
                    {/* <div className="me-2 d-inline-block Flight-cursor-pointer">
                          <Image src={IMG("share-ticket-icon.svg")} />{" "}
                        </div> */}
                    <div className="Flight-cursor-pointer d-inline-block">
                      <Image
                        alt="ticket"
                        src={renderImage("ticket-info-icon.svg")}
                        variant="primary"
                        onClick={handleShow}
                        type="button"
                      />
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="itinerary Flight-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            {" "}
                            <p className="Flight-font16 fw-semi-bold m-0">
                              Your Itinerary
                            </p>{" "}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p className="Flight-font16 fw-bold m-0">Bangalore</p>
                          <span className="Flight-font12 Flight-lght-txt d-block Flight-font-semibold ">
                            From Mumbai
                          </span>
                          <Badge className="Flight-font12 text-white bg-dark Flight-font-semibold ">
                            Tue 23 Mar 2023
                          </Badge>
                          <div className="d-flex gap-3">
                            <span className="d-block Flight-lght-txt travel-time">
                              02h 45m
                            </span>
                            <span className="d-block Flight-lght-txt ps-5">
                              Non-stop
                            </span>
                          </div>
                          <div>
                            <div className="Flight-ticket-main Flight-itinerary-main py-3  position-relative">
                              <div className="booking-ctnt  d-flex flex-column justify-content-center">
                                <Container className="position-relative p-4 Flight-container">
                                  <Row className="Flight-row">
                                    <Col>
                                      <div className="Flight-logo-sec text-left d-flex">
                                        <div>
                                          <Image
                                            alt="airline"
                                            width={120}
                                            src={renderImage("6E.png")}
                                          />
                                        </div>
                                        <div className="ms-3 text-start">
                                          <span className="title">Indigo</span>
                                          <span className="Flight-font12 Flight-lght-txt ms-2 Flight-font-semibold Flight-fnum">
                                            6E-5258
                                          </span>
                                          <div className=" p-0 d-flex">
                                            <span className="Flight-font12 Flight-lght-txt border-end pe-2 Flight-font-semibold Flight-fnum">
                                              Economy
                                            </span>
                                            <span className="Flight-font12 Flight-lght-txt  Flight-font-semibold ps-2 Flight-fnum">
                                              1 Stop
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                                <div className="dshd-sprtr "></div>
                                <Container className="position-relative p-4 Flight-container">
                                  <Row className="mb-2 Flight-row">
                                    <Col>
                                      <div className="d-flex position-relative">
                                        <div className="before-seprator-flt-dtl "></div>
                                        <div>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>03:10</span> Chennai{" "}
                                          </p>
                                          <span className="d-block fw-normal Flight-drk-txt">
                                            Chennai International Airport
                                            Chennai, Tamil Nadu, India
                                          </span>
                                          <span className="d-block Flight-lght-txt travel-time py-4">
                                            01h 45m
                                          </span>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>04:10</span> Hyderabad{" "}
                                          </p>
                                          <span className="d-block fw-normal Flight-drk-txt">
                                            Rajiv Gandhi International Airport
                                            Telangana Hyderabad, India
                                          </span>
                                        </div>
                                        <div></div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                                <div className="dshd-sprtr "></div>
                                <Container className="Flight-container">
                                  <Row className="Flight-row">
                                    <Col>
                                      <p className="d-block  p-3 fw-normal text-center Flight-drk-txt m-0">
                                        {" "}
                                        <span className="pe-2 fw-semi-bold">
                                          03h 10m
                                        </span>
                                        connecting in airport
                                      </p>
                                    </Col>
                                  </Row>
                                </Container>
                                <div className="dshd-sprtr "></div>
                                <Container className="position-relative p-4 Flight-container">
                                  <Row className="mb-2 Flight-row">
                                    <Col>
                                      <div className="d-flex position-relative">
                                        <div className="before-seprator-flt-dtl "></div>
                                        <div>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>03:10</span> Chennai{" "}
                                          </p>
                                          <span className="d-block fw-normal Flight-drk-txt">
                                            Chennai International Airport
                                            Chennai, Tamil Nadu, India
                                          </span>
                                          <span className="d-block Flight-lght-txt travel-time py-4">
                                            01h 45m
                                          </span>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>04:10</span> Hyderabad{" "}
                                          </p>
                                          <span className="d-block fw-normal Flight-drk-txt">
                                            Rajiv Gandhi International Airport
                                            Telangana Hyderabad, India
                                          </span>
                                        </div>
                                        <div></div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                                <div className="dshd-sprtr "></div>
                                <Container className="position-relative p-4 Flight-container">
                                  <Row className="mb-2 Flight-row">
                                    <Col>
                                      <span className="Flight-font12 Flight-lght-txt pe-2 Flight-font-semibold Flight-fnum">
                                        Booking Summary
                                      </span>
                                      <div className="">
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Base Fare
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 3463
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Total Taxes
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 568
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Convenience Fee
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 299
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Discount
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 0
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Seat Charges
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 0
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Meal Charges
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 0
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Baggage Charges
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 0
                                          </p>
                                        </div>
                                        <div className="d-flex total-amt border-top pt-3 justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Total
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 04330
                                          </p>
                                        </div>
                                      </div>
                                      <div></div>
                                    </Col>
                                  </Row>
                                </Container>
                                <div className="dshd-sprtr "></div>
                                <Container className="position-relative p-4 Flight-container">
                                  <Row className="mb-2 Flight-row">
                                    <Col>
                                      <span className="Flight-font12 Flight-lght-txt pe-2 Flight-font-semibold Flight-fnum">
                                        Cancellation Details
                                      </span>
                                      <div className="">
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Cancellation Fee
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 3000
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Cancellation Fee
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 0
                                          </p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Data Change Fee
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            <span>&#8377;</span> 0
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                                <div className="dshd-sprtr "></div>
                                <Container className="position-relative p-4 Flight-container">
                                  <Row className="mb-2 Flight-row">
                                    <Col>
                                      <div className="">
                                        <div className="d-flex justify-content-between">
                                          <p className="Flight-font16 fw-bold m-0">
                                            Payment Method
                                          </p>
                                          <p className="Flight-font16 fw-bold m-0">
                                            {" "}
                                            Net Banking
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  <Image
                    alt="bar"
                    width={240}
                    src={renderImage("ticket-barcode.svg")}
                  />
                </div>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default Booking;
