import MultiRangeSlider from "multi-range-slider-react";
import React, { useEffect, useState } from "react";
import CheckboxInput from "../../../commons/artefacts/elements/input/checkboxInput";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";
import numberToIndCurr from "../../../commons/util/priceUtil";

const classes = "py-2 Flight-stopp Flight-cursor-pointer";

const Filter = ({ filterState, airplanes = [], ...rest }) => {
  const { stops, departureTime, arrivalTime, priceRange, aircraft } =
    filterState;
  const {
    flightType,
    onPriceChange,
    onStop,
    onDepartTime,
    onArriveTime,
    onAircraftSelect,
  } = rest;

  const [min] = useState(priceRange.min);
  const [max] = useState(priceRange.max);
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);

  const handleInput = (e) => {
    setMinVal(e.minValue);
    setMaxVal(e.maxValue);
  };

  useEffect(() => {
    if (flightType !== "Return") {
      onPriceChange({ min: minVal, max: maxVal });
    }
  }, [minVal, maxVal, flightType]);

  const handleTime = (time, isDepart) => {
    isDepart
      ? onDepartTime(
          departureTime === time ? (!departureTime ? time : "") : time
        )
      : onArriveTime(arrivalTime === time ? (!arrivalTime ? time : "") : time);
  };

  return (
    <div className="Flight-home-padding px-0 pb-lg-2">
      {flightType !== "Return" && (
        <div className="Flight-filterouter">
          <label
            htmlFor="price"
            className="Flight-filter-txt mb-2 Flight-font-semibold"
          >
            Price
          </label>
          <div className="position-relative">
            <div className="price-range-bg"></div>
            <MultiRangeSlider
              min={min}
              max={max}
              minValue={min}
              maxValue={max}
              ruler={false}
              labels={[min, max]}
              onInput={(e) => handleInput(e)}
            />
          </div>
        </div>
      )}

      {flightType !== "Oneway" && (
        <div className="Flight-font14 Flight-font-bold pt-2">
          {`${flightType} Journey`}
        </div>
      )}

      <div className="Flight-filterouter">
        <div className="Flight-filter-txt mb-2 Flight-font-semibold">Stops</div>
        <div className="Flight-stops-slot Flight-box-stretch filterslot text-center Flight-font-semibold Flight-font12">
          <div
            onClick={() => onStop(0)}
            className={`border rounded py-1 Flight-stopp ${
              stops[0] ? "Flight-filter-selected" : ""
            }`}
          >
            0<br />
            Non-Stop
          </div>
          <div
            data-state={stops[1]}
            onClick={() => onStop(1)}
            className={`border rounded py-1 Flight-stopp ${
              stops[1] ? "Flight-filter-selected" : ""
            }`}
          >
            1<br />
            Stop
          </div>
          <div
            data-state={stops[2]}
            onClick={() => onStop(2)}
            className={`border rounded py-1 Flight-stopp ${
              stops[2] ? "Flight-filter-selected" : ""
            }`}
          >
            2+
            <br />
            Stops
          </div>
        </div>
      </div>

      <div className="Flight-filterouter">
        <div className="Flight-filter-txt mb-2 Flight-font-semibold">
          Departure Time
        </div>
        <div className="Flight-timeblock Flight-box-stretch filterslot border rounded Flight-font-semibold Flight-font12 text-center">
          <div
            onClick={() => handleTime("0-6", true)}
            className={`border-end ${classes} ${
              departureTime === "0-6" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/Before6AM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/Before6AM-white.svg")}
              />
            </div>
            <span className="d-block">
              Before
              <br />6 AM
            </span>
          </div>
          <div
            onClick={() => handleTime("6-12", true)}
            className={`border-end ${classes} ${
              departureTime === "6-12" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/6AM12PM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/6AM12PM-white.svg")}
              />
            </div>
            <span className="d-block">
              6 AM
              <br />
              12 PM
            </span>
          </div>
          <div
            onClick={() => handleTime("12-18", true)}
            className={`border-end ${classes} ${
              departureTime === "12-18" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/12PM6PM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/12PM6PM-white.svg")}
              />
            </div>
            <span className="d-block">
              12 PM
              <br />6 PM
            </span>
          </div>
          <div
            onClick={() => handleTime("18-24", true)}
            className={`${classes} ${
              departureTime === "18-24" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/After6PM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/After6PM-white.svg")}
              />
            </div>
            <span className="d-block">
              After
              <br />6 PM
            </span>
          </div>
        </div>
      </div>

      <div className="Flight-filterouter">
        <div className="Flight-filter-txt mb-2 Flight-font-semibold">
          Arrival Time
        </div>
        <div className="Flight-timeblock Flight-box-stretch filterslot border rounded Flight-font12 Flight-font-semibold text-center">
          <div
            onClick={() => handleTime("0-6", false)}
            className={`border-end ${classes} ${
              arrivalTime === "0-6" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/Before6AM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/Before6AM-white.svg")}
              />
            </div>
            <span className="d-block">
              Before
              <br />6 AM
            </span>
          </div>
          <div
            onClick={() => handleTime("6-12", false)}
            className={`border-end ${classes} ${
              arrivalTime === "6-12" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/6AM12PM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/6AM12PM-white.svg")}
              />
            </div>
            <span className="d-block">
              6 AM
              <br />
              12 PM
            </span>
          </div>
          <div
            onClick={() => handleTime("12-18", false)}
            className={`border-end ${classes} ${
              arrivalTime === "12-18" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/12PM6PM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/12PM6PM-white.svg")}
              />
            </div>
            <span className="d-block">
              12 PM
              <br />6 PM
            </span>
          </div>
          <div
            onClick={() => handleTime("18-24", false)}
            className={`${classes} ${
              arrivalTime === "18-24" ? "Flight-filter-selected" : ""
            }`}
          >
            <div className="Flight-w-icon">
              <Image
                className="Flight-g-icon"
                src={renderImage("search-result/After6PM.svg")}
              />
              <Image
                className="Flight-wht-icon"
                src={renderImage("search-result/After6PM-white.svg")}
              />
            </div>
            <span className="d-block">
              After
              <br />6 PM
            </span>
          </div>
        </div>
      </div>

      {flightType !== "Onward" && (
        <div className="Flight-filterouter">
          <div className="Flight-filter-txt mb-2 Flight-font-semibold">
            Airlines
          </div>
          {airplanes.map((airplane, ind) => (
            <div
              className="d-flex justify-content-between Flight-filter-txt Flight-font13 mb-2"
              key={ind}
            >
              <div className="Flight-custom-control Flight-custom-checkbox Flight-cursor-pointer">
                <CheckboxInput
                  onChange={({ currentTarget }) =>
                    onAircraftSelect(currentTarget.value)
                  }
                  className="Flight-custom-control-input"
                  name="airlinename"
                  id={airplane.name + ind}
                  value={airplane.name}
                  checked={aircraft.has(airplane.name)}
                />
                <label
                  className="Flight-custom-control-label"
                  htmlFor={airplane.name + ind}
                >
                  <Image
                    src={renderImage(airplane.code)}
                    className="img-fluid blur-up lazyloaded Flight-filter-al-img"
                  />
                  {airplane.name}
                </label>
              </div>
              <div className="Flight-font-semibold">
                {numberToIndCurr(airplane.price)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Filter;
