import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TermsandConditions = () => {
  return (
    <div className="abt-wrap">
      <Container>
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <h2 className="text-center my-md-5 my-4">Terms And Conditions</h2>
            <div className="single-line-tc mb-3">
              <span className="mallory-mediuam font12">
                <b>Last Updated in Aug 2022</b>
              </span>
            </div>
            <div className="single-line-tc">
              <h3>General</h3>

              <p>
                OGenie "GenieTalk Private Limited" (the "<b>Company</b>"),
                including its Subsidiaries, Affiliates, Associate/ Group
                Companies and Licensors (collectively, "<b>GenieTalk</b>", "
                <b>we</b>", "<b>us</b>" or "<b>our</b>") welcomes you,
                irrespective of whether you are a mere visitor or a
                member/subscriber (the "<b>Customer</b>", "<b>Subscriber</b>", "
                <b>User</b>", "<b>you</b>" or "<b>your</b>") to our Platform (as
                defined below).
              </p>

              <p>
                These terms and conditions, together with: (i) GenieTalk’s
                privacy policy, located at "<b>Privacy Policy</b>"; and (ii) any
                other agreements and terms and conditions entered into with the
                Company (collectively, "<b>Terms and Conditions</b>") govern:
                (a) your relationship with GenieTalk; and (b) your use of the
                Platform and the Services (as defined below).
              </p>

              <p>
                These Terms and Conditions constitute an electronic record
                within the meaning of applicable law and do not require any
                physical or digital signatures. These Terms and Conditions are
                published in compliance with, and are governed by the provisions
                of Indian law, including but not limited to: (i) the Indian
                Contract Act, 1872; and (ii) the (Indian) Information Technology
                Act, 2000 and the rules, regulations, guidelines and
                clarifications framed there under, including the (Indian)
                Information Technology (Reasonable Security Practices and
                Procedures and Sensitive Personal Information) Rules, 2011 and
                the (Indian) Information Technology (Intermediaries Guidelines)
                Rules, 2011.
              </p>

              <p>
                Please read the Terms and Conditions carefully before accessing,
                browsing, registering on, downloading or using the Platform or
                the Services.
              </p>

              <p>
                For the avoidance of doubt, it is clarified that these Terms and
                Conditions shall apply to all Services, whether offered by
                GenieTalk, its Subsidiaries or its Affiliates.
              </p>

              <p>
                By accessing our Platform or purchasing/utilizing our Services,
                you agree and understand that GenieTalk is an "Intermediary" as
                defined under the provisions of the (Indian) Information
                Technology Act, 2000.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Definitions</h3>

              <p>
                In these Terms and Conditions, unless the context otherwise
                requires, the following words and expressions shall bear the
                meanings ascribed to them below:
              </p>

              <ul className="terms-innerconditions">
                <li>
                  "<b>Affiliate(s)</b>" means persons that control, are
                  controlled by, or are under common control with the Company,
                  where “control” means the power to direct the management or
                  policies of any persons, whether through: (i) the ownership of
                  over 50% (fifty percent) of the voting power of such persons;
                  (ii) the power to appoint more than half of the constitution
                  of the board of directors or similar governing body of such
                  persons; and/or (iii) contractual arrangements or otherwise;
                </li>
                <li>
                  "<b>Content</b>" includes, without limitation, information,
                  data, text, written posts and comments, images, software,
                  scripts, graphics, and interactive features generated,
                  provided or otherwise made accessible on the Platform or
                  through the Services;
                </li>
                <li>
                  "<b>O Genie App</b>" means the mobile applications available
                  for Android, iOS and such other platform, developed and
                  provided by GenieTalk that provides access to the Services;
                </li>
                <li>
                  "<b>GenieTalk Marks</b>" shall mean trademarks, service marks,
                  trade names, service names, brand names, brand marks, internet
                  domain names, identifying symbols, logos, emblems, signs or
                  insignia, owned by GenieTalk;
                </li>
                <li>
                  "<b>GenieTalk Network</b>" shall mean the systems, hardware,
                  software, web properties and communication pathways and
                  interfaces owned or operated by or on behalf of GenieTalk that
                  are used to provide all or any part of the Services;
                </li>
                <li>
                  "<b>GenieTalk Representatives</b>" means the respective
                  officers, directors, employees, consultants, agents,
                  representatives, contractors, content providers, partners,
                  service providers, vendors and suppliers of each of the
                  GenieTalk entities;
                </li>
                <li>
                  "<b>Losses</b>" shall mean and include all direct losses,
                  expenses, claims, damages, liabilities, penalties, judgments,
                  costs, expenses (including without limitation reasonable legal
                  costs), charges, actions, proceedings and demands;
                </li>
                <li>
                  "<b>Orders</b>" means an order or booking made by a user on
                  the Platform for purchase of the Products;
                </li>
                <li>
                  "<b>Platform</b>" means{" "}
                  <a
                    className="Flight-theme-txt"
                    href="https://ogenietravels.com/"
                  >
                    https://ogenietravels.com/
                  </a>
                  , its sub-domains and all other internet domains, the O Genie
                  App and all other mobile applications, widgets, and other
                  downloadable applications developed, owned, operated and
                  controlled by GenieTalk, and branded or licensed exclusively
                  under the GenieTalk Marks, whether now existing or hereinafter
                  developed, through which GenieTalk provides the Services;
                </li>
                <li>
                  "<b>Products</b>" means the goods and services provided by the
                  Third-Party Providers to the Customers through the Platform;
                </li>
                <li>
                  "<b>Services</b>" means the services, content, features,
                  applications, tools, data, documents and information provided
                  or made available by GenieTalk through the Platform, from time
                  to time, but does not include any services provided by the
                  Third-Party Providers;
                </li>
                <li>
                  "<b>Subsidiary(ies)</b>" has the meaning ascribed to such term
                  under the (Indian) Companies Act, 2013; and,
                </li>
                <li>
                  "<b>Third Party Provider(s)</b>" means third-party merchants,
                  vendors or service providers who are registered with GenieTalk
                  for providing the Products.
                </li>
              </ul>
            </div>
            <div className="single-line-tc">
              <h3>Acceptance Of Terms And Conditions</h3>

              <p>
                Before you are permitted to use the Platform or the Services,
                you are required to accept these Terms and Conditions.
              </p>

              <ul className="terms-innerconditions">
                <li>
                  Accessing, browsing or using the Platform, or agreeing to use
                  the Services by clicking "Sign up" or registering with us, or
                  clicking or tabbing the "accept" button if such button or
                  function is available or present via the user interface for
                  the respective Service or ordering the Services on the
                  Platform, or engaging in any transaction with respect to the
                  Services, or ordering the Products through the Platform,
                  constitutes your acceptance and agreement to be bound by these
                  Terms and Conditions, which may be modified by us, at any time
                  and from time to time, without notice to you.
                </li>
                <li>
                  If, at any time, you do not accept or agree with any of these
                  Terms and Conditions or do not wish to be bound by these Terms
                  and Conditions, you may: (i) not access, browse or use the
                  Platform and the Services and immediately terminate your
                  access or use of the Platform and the Services; or (ii) send
                  an email to us at{" "}
                  <a
                    className="Flight-theme-txt"
                    href="mailto:support@ogenietravels.com"
                  >
                    support@ogenietravels.com
                  </a>
                  , indicating your intent to terminate your Account.
                </li>
                <li>
                  Accepting or agreeing to the Terms and Conditions shall
                  constitute a legal contract between you and GenieTalk.
                </li>
              </ul>
            </div>
            <div className="single-line-tc">
              <h3>Eligibility And Registration</h3>

              <p>
                This clause sets forth the eligibility criteria for entering
                into a legally binding agreement with GenieTalk in order to
                access the Platform or avail the Services.
              </p>

              <p>
                Use of the Platform and the Services is available only to
                persons who can enter into legally binding contracts under
                applicable law. The Services are not targeted towards, nor
                intended for use by, anyone under the age of 18 (eighteen) or
                anyone who is disqualified or not competent to enter into a
                contract under the Indian Contract Act, 1872 or any other
                applicable law. By using the Services, you represent and warrant
                that you are 18 (eighteen) years of age or older and competent
                to enter into a contract. In addition, you represent and warrant
                that you have the right, authority and capacity to enter into
                this agreement and to abide by all these Terms and Conditions.
                If you are not competent to enter into a contract, you shall
                not, under any circumstances or for any reason, access the
                Platform or use the Services. If you are a minor i.e. under the
                age of 18 years, you shall not register as a user of the
                Platform and shall not transact on or use the Platform or the
                Services. As a minor, if you wish to use or transact on
                Platform, such use or transaction may be made by your legal
                guardian or parents on the Platform. We may, in our sole
                discretion, refuse to offer the Services to any person or entity
                and/or change its eligibility criteria at any time.
              </p>

              <p>
                You are solely responsible for ensuring that these Terms and
                Conditions are in compliance with all laws, rules and
                regulations applicable to you and the right to access the
                Platform and Services is revoked where these Terms and
                Conditions or use of the Platform or the Services is prohibited
                or to the extent offering, sale or provision of the Services
                conflicts with any applicable law, rule or regulation.
              </p>

              <p>
                The Platform is operated and controlled by us in India, and the
                Services provided through the Platform is not directed to any
                other jurisdiction or country, including any jurisdiction or
                country where such distribution or use would be contrary to law
                or regulation or that would subject GenieTalk to any
                registration or other requirements within such jurisdiction or
                country. Access to the Platform/ Services from countries,
                territories and jurisdictions where such access is illegal, is
                prohibited.
              </p>

              <p>
                If you are using the Services on behalf of an entity, company or
                other organization/association, you represent and warrant that
                you: (i) have the authority to bind such entity, company or
                organization/association to these Terms and Conditions; and (ii)
                agree to be bound by these Terms and Conditions on behalf of
                such entity, company or organization/association.
              </p>

              <p>
                If you are using the Services on behalf of any other person
                (whether under his/her authority or otherwise), you represent
                and warrant that you: (i) have the absolute authority to act for
                such person; (ii) have the absolute authority to bind such
                person to these Terms and Conditions; and (iii) agree to be
                bound by these Terms and Conditions on behalf of such person.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Registered Users</h3>

              <p>
                To sign up for the Services, you must register for opening a
                user account on the Platform ("<b>Account</b>"). You must
                provide accurate and complete information and keep your Account
                information updated. You shall not select or use as a username:
                (i) a name which belongs to another user; (ii) the name of
                another person with the intent to impersonate that person; (iii)
                a name which is subject to the rights of a person for which you
                do not have an appropriate authorization; or (iv) a name that is
                otherwise offensive, vulgar or obscene. You agree that if you
                provide any information that is untrue, inaccurate, not current
                or incomplete or we have reasonable grounds to suspect that such
                information is untrue, inaccurate, not current or incomplete, or
                not in accordance with these Terms and Conditions, we shall have
                the right to indefinitely suspend or terminate or block your
                access to the Platform and the Services. Notwithstanding
                anything contained herein, we may, in our sole discretion,
                refuse to offer or suspend the Services to any person or entity
                for any reason.
              </p>

              <p>
                Subject to these Terms and Conditions, we grant each User of the
                Services a non-exclusive, revocable, non-sublicensable,
                non-transferable and limited license to use the Platform and the
                Services through the Account, solely for the purpose of using
                the Services in terms hereof.
              </p>

              <p>
                You shall: (i) be responsible for maintaining the
                confidentiality of your user name and password; (ii) not share
                your username and password with third parties; (iii) never
                publish, distribute or post login information for your Account;
                and (iv) notify GenieTalk promptly of any unauthorized use of
                your Account and of any loss, theft or disclosure of your
                password. You will not create nor have more than one active
                Account. Additionally, you are prohibited from transferring your
                Account to any other person. You are solely responsible for the
                activity that occurs on your Account, regardless of whether the
                activities are undertaken by you, your employees or a
                Third-Party (including your contractors or agents), and for
                keeping your Account password secure. You shall never use
                another user’s account or registration information for the
                Services without permission. You must notify us immediately of
                any change in your eligibility to use the Services (including
                any changes to or revocation of any licenses from any state,
                provincial, territorial or other authorities), breach of
                security or unauthorized use of your Account. You shall have the
                ability to disable your Account by making a request to us.
              </p>

              <p>
                You shall not distribute, sell, license, rent or otherwise
                exploit your username and password for accessing your Account in
                any manner whatsoever.
              </p>

              <p>
                Failure to comply with these requirements shall constitute a
                breach of these Terms and Conditions and shall constitute
                grounds for immediate termination of your Account and your right
                to use the Platform.
              </p>

              <p>
                GenieTalk shall not be liable for any loss or damage as a result
                of your failure to provide us with accurate information or to
                keep your Account secure.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>General Users</h3>

              <p>
                Certain portions of the Platform will be visible/ available to
                Users who have not registered with GenieTalk. Such Users,
                nevertheless, are bound by these Terms and Conditions.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Use Of The Platform And Services</h3>

              <p>
                You agree to use the Platform and the Services only for purposes
                that are permitted by: (i) these Terms and Conditions; and (ii)
                any applicable law, regulation or generally accepted practices
                or guidelines in the relevant jurisdictions.
              </p>

              <p>
                The Platform is an artificial intelligence-based platform to
                help you: (i) manage and/or remind you of your daily activities;
                and (ii) place Orders seamlessly for the Products from
                Third-Party Providers.
              </p>

              <p>
                You specifically understand, acknowledge and agree that
                GenieTalk is not the manufacturer, supplier, distributor or
                provider of the Products but only enables a Customer to place an
                Order for the Products using the Services. The Platform and the
                Services have been designed for informational purposes only. The
                Platform is a medium that: (i) can be utilized by Users to reach
                a larger base to buy and sell products or services; and (ii)
                Customers and Third-Party Providers use for their transactions.
                GenieTalk acts as an introduction company only and as such it
                functions within the introducing capacity and is only providing
                a platform for communication and it is agreed that the contract
                for the sale of any of the Products shall be a strictly
                bipartite contract between the Third-Party Provider and the
                Customer.
              </p>

              <p>
                You specifically understand, acknowledge and agree that: (i) the
                Platform and the Services are not: (a) promoting any Third-Party
                Provider; (b) an invitation to offer to buy or subscribe for any
                Products provided by the Third-Party Provider; or (c)
                recommending any person to enter into any transaction of
                whatsoever nature; and (ii) the Platform and the Services is not
                intended to provide any professional advice or opinions on any
                Products. GenieTalk provides information on brands, goods and
                services for informational purposes and for the purposes of
                enhancing choice and customer experience. Customers shall
                research upon the Third-Party Providers and avail their Products
                based on such due diligence. GenieTalk strongly recommends you
                to use the information found on the Platform for conducting your
                own research and due diligence on the profiled/featured
                Third-Party Providers in order to determine your own personal
                opinion about such Third-Party Providers before initiating,
                undertaking and concluding a transaction with such Third-Party
                Providers. Given that GenieTalk does not research, verify,
                endorse, represent, warrant or guarantee the truthfulness,
                completeness, accuracy, reliability, or veracity of any content
                and information on the Platform, including User Content (as
                defined below), and that you are not relying on GenieTalk for
                any User Content, you are solely responsible for determining
                whether any transaction or any other product or service, is
                appropriate or suitable for you based on your objectives,
                circumstance and independent judgement.
              </p>

              <p>
                GenieTalk is not and cannot be a party to or control, in any
                manner, any transaction between the Customers and Third-Party
                Providers. Please note that the purchase of any Products with
                assistance from the Services is a private contract between the
                Customers and the Third-Party Providers. GenieTalk is merely an
                online intermediary facilitating the transaction in a limited
                capacity and does not bear any responsibility for delivery,
                authentication, performance or any other kind of logistical
                support. All commercial/contractual terms are offered by and
                agreed to between Customers and Third-Party Providers alone. The
                commercial/contractual terms include without limitation price,
                shipping costs, payment methods, payment terms, date, period and
                mode of delivery, warranties related to Products and after-sales
                services related to the Products. GenieTalk does not have any
                control and does not determine or advise or in any way involve
                itself in the offering or acceptance of such
                commercial/contractual terms between the Customers and
                Third-Party Providers. All discounts, offers (including exchange
                offers) are by the Third-Party Providers and not by GenieTalk.
                You shall independently agree upon the manner and terms and
                conditions of delivery, payment, insurance etc. with the
                Third-Party Provider that you transact with.
              </p>

              <p>
                GenieTalk does not at any point of time during any transaction
                between Customer and Third-Party Provider on the Platform come
                into or take possession of any of the Products offered by the
                Third-Party Providers nor does it at any point gain title to or
                have any rights or claims over the Products offered by
                Third-Party Provider to Customer. At no time shall GenieTalk
                hold any right, title or interest over the Products nor shall
                GenieTalk have any obligations or liabilities in respect of such
                contract.
              </p>

              <p>
                Placement of an Order by a Customer with a Third-Party Provider
                on the Platform is an offer to buy the Product(s) in the Order
                by the Customer to the Third-Party Provider and it shall not be
                construed as Third-Party Provider's acceptance of Customer's
                offer to buy the Product(s) ordered. All Orders accepted by
                Third-Party Provider are subject to confirmation from the
                Third-Party Provider and we shall communicate to you about the
                confirmation or cancellation of an Order once we receive the
                communication from the Third-Party Provider. The Third-Party
                Provider retains the right to cancel any such Order placed by
                the Customer, at its sole discretion and the Customer shall be
                intimated of the same by way of an email/SMS or notification on
                the Platform or other electronic communication. Any alternation,
                change or cancellation in the Order is between you and the
                Third-Party Provider and GenieTalk is not involved in such
                decision. Any transaction price paid by the Customer in case of
                such cancellation by the Third-Party Provider will be refunded
                to the Customer as per the terms of the Third-Party Provider’s
                refund policies and subject to the terms hereof. You agree to
                take proper care of providing the correct, accurate and complete
                information about yourself, the Order details and the delivery
                information to ensure seamless fulfilment. You shall not use any
                illegal means for making payments for transactions through the
                Platform. You warrant that the credit or debit card used during
                the transaction or any other form of payment used whether
                internet banking or use of any internet wallets are owned by the
                user or the user has necessary permission from the owner of the
                card holder to use it for making payments. The user also
                warrants that the associated card, bank account or wallet
                account has sufficient amount to make the payment. The
                fulfilment of the Products ordered through the Platform is the
                responsibility of the Third-Party Provider. GenieTalk shall
                accept Orders for the Products offered on behalf of the
                Third-Party Provider only in the capacity of an intermediary and
                accepting Orders on behalf of the Third-Party Provider. In the
                event payments are collected by GenieTalk on behalf of the
                Third-Party Provider, in the mode/s as conveyed to the
                Customers, the same will be collected on behalf of the
                Third-Party Provider with the sole intent of facilitating the
                transactions between the Customers and the Third-Party Provider.
              </p>

              <p>
                GenieTalk reserves the right to cancel any Order in
                circumstances of failure due to reasons beyond its control or
                attributable to the Third-Party Provider.
              </p>

              <p>
                GenieTalk does not make any assurances, representations or
                warranties as to specifics (such as title, quality, value,
                saleability, suitability etc.) of the Products proposed to be
                sold or offered to be sold or purchased from the Third-Party
                Provider through the Platform. GenieTalk does not implicitly or
                explicitly support or endorse the sale or purchase of any
                Products on the Platform. GenieTalk accepts no liability for any
                errors or omissions, whether on behalf of itself or
                Third-Parties.
              </p>

              <p>
                GenieTalk may be under no contractual or other obligation with
                the Third-Party Providers in relation to the provision of the
                Services. We expressly disclaim any relationship of agency,
                employment or other arrangements with the Third-Party Providers,
                and do not act for or on behalf of the Third-Party Providers.
                Under no circumstances shall GenieTalk be liable for any
                deficiency of the Products provided by the Third-Party Providers
                or due to any misrepresentation by the Third-Party Providers.
                You expressly agree that your use of the Services does not
                result in a contractual relationship with Third-Party Providers,
                and in the event that you use the Third-Party Providers
                Products, the terms of such arrangement will be determined by
                your agreement with the Third-Party Providers.
              </p>

              <p>
                GenieTalk is not responsible for any non-performance or breach
                of any contract entered into between Customers and Third-Party
                Providers. GenieTalk cannot and does not guarantee that the
                concerned Customers and/or Third-Party Providers will perform
                any transaction concluded on the Platform. GenieTalk is not
                responsible for the unsatisfactory or delayed performance of
                services or damages or delays as a result of Products which are
                out of stock, unavailable or back-ordered. GenieTalk bears no
                responsibility or liability to any user or person for any
                failure of a user/person to fulfil his/her/its obligations under
                any transaction or arrangement. GenieTalk shall not and is not
                required to mediate or resolve any dispute or disagreement
                between Customers and Third-Party Providers.
              </p>

              <p>
                GenieTalk: (a) does not take responsibility or control the User
                Content (as defined below) and other information provided by
                Users which is made available on the Platform; and (b) does not
                examine, verify, research, endorse, validate or certify the User
                Content and any other information submitted/uploaded by Users on
                the Platform nor assumes that all User Content and information
                will be complete, accurate, truthful, trustworthy and reliable.
                Further: (i) GenieTalk cannot and does not warrant or guarantee
                the accuracy of the User Content and any other information
                submitted/uploaded by Users on the Platform; (ii) none of the
                information submitted or available on the Platform constitutes a
                solicitation, offer, opinion, or recommendation by GenieTalk to
                buy or sell any products or services of whatsoever nature; (iii)
                GenieTalk does not offer any advice with respect to any
                Products; (iv) GenieTalk does not and has not made any
                recommendations regarding the merit of any user, person,
                products or services appearing on the Platform, or endorsed or
                sponsored any user, person or entity appearing on the Platform;
                and (v) GenieTalk does not take part in the negotiation or
                execution of any transaction related to or in connection with
                any deal, transaction or other arrangements between the Users,
                specifically between the Customer and the Third-Party Provider.
              </p>

              <p>
                You understand that you may be exposed to Content, including
                User Content, on the Platform and/or by using the Service, that
                may be offensive, harmful, untruthful, inappropriate,
                misleading, false, inaccurate and that has not been updated and
                you further understand GenieTalk shall not be liable to you for
                any loss or damage which you may suffer as a result of your
                viewing, using or relying on such User Content and information.
                It is your responsibility to evaluate the accuracy, completeness
                and usefulness of any User Content, data and information
                provided on the Platform. With respect to any User Content,
                data, listing, posting or information available on the Platform
                or while interacting with other Users on the Platform, we
                strongly encourage and recommend that you exercise adequate
                diligence. You are strongly advised to independently verify the
                bona fides of any particular Users that you chose to deal with
                and use your best judgement in that behalf. All Users shall be
                responsible for their own due diligence and the legal and
                regulatory compliance of any transaction they enter into and
                GenieTalk assumes no liability whatsoever in connection with
                your use of the Service. GenieTalk shall not have any obligation
                to participate or assist in any way in the event of any dispute
                between Users or third parties. In no event shall GenieTalk be
                liable for any direct, indirect, special, incidental or
                consequential damages arising out of the use of the Services.
              </p>

              <p>
                Some of the Services can be used or are particularly useful when
                used over mobile networks. You should be aware that your network
                provider may charge you for access to its network, the duration
                of your mobile phone's/mobile device's connection to the network
                and the data volume used to use the Services. You are entirely
                responsible to check with your network provider whether any such
                costs may apply before using the Services in this respect.
              </p>

              <p>
                While GenieTalk shall continuously work towards keeping the
                Platform functional, you hereby acknowledge that technical
                failures are inevitable. We shall take adequate steps to keep
                the Platform running and in the event of any unforeseen problems
                or technical malfunction of our network, servers, computer
                systems or equipment, or software on account of technical
                problems or traffic congestion on the internet or combination
                thereof, we shall take immediate measures to remedy the problem.
                However, we do not assume any liability whatsoever in respect of
                any loss or damage arising directly or indirectly to you,
                including but not limited to loss of profit, loss of data, or
                loss of opportunity arising out of such unforeseen events and/or
                technical malfunction, or otherwise.
              </p>

              <p>
                GenieTalk cannot and does not guarantee or warrant that files
                available for download through the Platform will be free of
                viruses, worms or other code that may be damaging. You are
                responsible for implementing procedures to satisfy your
                particular requirements of Internet security and for accuracy of
                data input and output.
              </p>

              <p>
                Use of the Services requires a supporting environment, including
                internet access, compatible hardware and software, and may also
                require periodic updates to your browser, mobile application,
                hardware, and/or other software. You agree that meeting these
                requirements is your sole responsibility and such requirements
                may change from time to time.
              </p>

              <p>
                GenieTalk may make changes to any Services offered on the
                Platform, or to the applicable terms for any such Services, at
                any time, without notice.
              </p>

              <p>
                The Content and materials on the Platform with respect to the
                Services may be out of date, and GenieTalk makes no commitment
                to update the Content and materials on the Platform with respect
                to the Services.
              </p>

              <p>
                GenieTalk shall not be liable for any deficiencies in the
                performance of the Services and such deficiency in performance
                shall not constitute GenieTalk’s failure to meet the
                requirements detailed in these Terms and Conditions, to the
                extent that any such failure is attributable to: (i) force
                majeure events; (ii) Customer’s acts or omissions (including,
                among other things, violations of law, wilful misconduct,
                negligent acts or breach of these Terms and Conditions); (iii)
                acts or omissions of third parties related to the Customer; (iv)
                restrictions/constraints imposed by applicable laws; (v)
                deficiencies or defects in User’s network, computer systems,
                servers, software and other resources utilised by the User
                accessing the Platform or for availing the Services; (v) acts or
                omissions of the Third-Party Providers; (vi) a failure by the
                user to provide GenieTalk, within a reasonable time, with any
                information or other material reasonably requested by GenieTalk
                for the performance of the Services; (vii) any inaccurate or
                misleading information supplied by the user to GenieTalk and
                upon which GenieTalk relies in performing the Services
                hereunder; (viii) user’s failure to take such corrective action
                which forms a part of the user’s responsibility, as may be
                reasonably requested and identified by GenieTalk to the user;
                (xi) infringement by the user of any Third-Party rights; and
                (xi) such other reasons not attributable to GenieTalk.
              </p>

              <p>
                Notwithstanding anything contained in these Terms and
                Conditions, GenieTalk shall not be required to provide a Service
                to the extent the provision thereof would violate or contravene
                any applicable law.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Additional Terms And Conditions For Specific Services</h3>

              <p>
                Additional terms and conditions may apply in order for you to
                avail specific Services and to specific portions or features of
                the Platform, including contests, promotions or other similar
                features, all of which terms are made a part of these Terms and
                Conditions by reference. You agree to abide by such other terms
                and conditions, including where applicable representing.
              </p>

              <p>
                If there is a conflict between these Terms and Conditions and
                the terms posted for or applicable to a specific portion of the
                Platform or for any specific Service offered on or through the
                Platform, the latter terms shall control with respect to your
                use of that portion of the Platform or the specific Service.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Charges</h3>

              <p>
                We reserve the right to charge convenience charges on the
                Services we offer at our discretion. Customers will be intimated
                about the same while doing the transaction. GenieTalk reserves
                the right to change its fee policy from time to time. In
                particular, GenieTalk may at its sole discretion introduce new
                services and modify some or all of the existing Services offered
                on the Platform. In such an event, GenieTalk reserves the right
                to introduce fees for the new Services offered or
                amend/introduce fees for existing Services, as the case may be.
                Changes to the fee policy shall be posted on the Platform and
                such changes shall automatically become effective immediately
                after they are posted on the Platform. Unless otherwise stated,
                all fees shall be quoted in Indian Rupees. You shall be solely
                responsible for compliance of all applicable laws including
                those in India for making payments to GenieTalk.
              </p>

              <p>
                The Customer shall make the payment, in full, if Customer has
                availed any Services from GenieTalk. The Customer will pay for
                the Services as per the mode of payment prescribed by the
                GenieTalk from time to time.
              </p>

              <p>
                The Customer shall be liable to pay all applicable taxes with
                respect to Services provided by GenieTalk.
              </p>

              <p>
                Any payment made by the Customer for availing the Services is
                non-refundable. However, at GenieTalk’s sole discretion, refunds
                or credits may be granted in extenuating circumstances, as or to
                correct any errors made by GenieTalk. In no event shall
                GenieTalk’s aggregate liability in such events exceed the fee
                received by it from the concerned Customer for the relevant
                Service.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Pricing, Coupon Code, Discounts And Referral Program</h3>

              <p>
                GenieTalk strives to get the best deals and provide the most
                competitive prices and we try to provide you with the best
                pricing for the Products. All prices mentioned on the Platform
                are received from the Third-Party Providers who have offered to
                fulfil the Products.
              </p>

              <p>
                We try to keep the prices of the Products on the Platform
                updated. We also try to keep suggested plans or prices based on
                our information but at times, they may not be the latest pricing
                of the Products. While we strive to provide accurate pricing
                information, typographical and other errors may occur. In the
                event that a Product is listed at an incorrect price or with
                incorrect information due to an error in pricing or service
                information, we will have the right to modify the price of the
                Product and contact the Customer for further instructions, or
                cancel the Product and notify the Customer of such cancellation.
                Further, in such a condition, the Customer will be required to
                make the additional payment of the Products to the Third-Party
                Provider.
              </p>

              <p>
                Coupons are tickets that can be redeemed for a financial
                discount when purchasing a Product and are issued on behalf of
                respective Third-Party Providers. In addition to these Terms and
                Conditions, coupon redemption is subject to the standard and
                specified terms and conditions mentioned by respective
                Third-Party Providers. Any damages, losses and liabilities
                incurred by you as a result of using the coupon is not the
                responsibility of GenieTalk. Payments received after the expiry
                of the coupon code will not be applicable for any kind of
                incentive on an Order. Coupon codes can be used only on specific
                Orders as deemed fit by GenieTalk. GenieTalk has the right to
                refuse the usage of any coupon code at any point in time. Coupon
                codes that have expired cannot be used on the Platform.
                GenieTalk can at any time ask you for the proof of having
                received the coupon code.
              </p>

              <p>
                Any discounts offered by GenieTalk will cover deduction in
                amount during the process of making the payment to GenieTalk. No
                claims later will be entertained. The discounts or coupons
                cannot be applied later once the order is confirmed and no
                reason or queries will be entertained in this respect. Any
                discounts are subject to Genie Talk’s discretion and can be
                changed, altered or withdrawn anytime without providing any
                prior information to the User. The User cannot hold GenieTalk
                responsible for any losses arising from the usage or non-usage
                of these discounts.
              </p>

              <p>
                GenieTalk may also facilitate referral program wherein a unique
                number is assigned to your Account. You can use this code to
                refer your friends to GenieTalk and get rewarded. When you refer
                a friend to GenieTalk, you as well as your friend receives
                reward points when they set up their free account with us. This
                referral program is subjected to specific terms and conditions.
                To share your referral code, sign in and then visit our refer
                and earn page where you can share your custom link on WhatsApp,
                Messenger, Facebook, Twitter, LinkedIn, Google , SMS, or via
                email.
              </p>

              <p>
                You must make sure to use a debit or credit card or bank account
                or internet wallet that you can legally use and either own or
                have the authorization to use. During the transaction and
                processing of the payment, you must provide the correct,
                accurate and complete details. GenieTalk cannot be held
                responsible for any illegal use of any debit or credit card or
                bank account or internet wallet. It is solely the responsibility
                of the user to use authorized payment details and ensure
                sufficient balance, limit or payment capacity is present in the
                card or bank account or internet wallet.
              </p>

              <p>
                For further information regarding pricing and payment, please
                contact GenieTalk at{" "}
                <a
                  className="Flight-theme-txt"
                  href="mailto:support@ogenietravels.com"
                >
                  support@ogenietravels.com
                </a>
                .
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Payments</h3>

              <p>
                All fees and other charges are payable in Indian Rupees to the
                entity and in the manner determined by GenieTalk. You shall be
                solely responsible for compliance of all applicable laws for
                making payments to GenieTalk.
              </p>

              <p>
                All payments for Services and the Products will be made through:
                (i) an electronic and automated collection and remittance
                service (the "<b>Payment Gateway</b>"), either hosted on the
                Platform through payment gateway service providers or payment
                links of service providers provided by GenieTalk (the "
                <b>Service Providers</b>"); or (ii) wire/bank transfer to a bank
                account designated by GenieTalk; or (iii) through payment links
                provided by GenieTalk. The Payment Gateway service is provided
                to you in order to facilitate your purchase of the Products and
                Services. The processing of payments may be subject to the
                terms, conditions and privacy policies of the Service Providers
                in addition to these Terms and Conditions. The terms of your
                payment may also be determined by agreements between you and the
                financial institution, card issuer or other providers of your
                chosen payment method. By choosing to use paid Services, you
                agree to pay us, through the Service Providers, all charges at
                the prices then in effect for any use of such paid Services in
                accordance with the applicable payment terms. You represent that
                you have the legal right to use any payment method that you
                submit to us. GenieTalk makes no representation of any kind,
                express or implied, as to the operation of the Payment Gateway.
                GenieTalk is not responsible for any errors by the Service
                Providers. GenieTalk reserves the right to correct any errors or
                mistakes that it makes even if it has already requested or
                received payment. GenieTalk assumes no liability whatsoever for
                any monetary or other damage suffered by you on account of: (i)
                access to, use of, or reliance on the Payment Gateway services;
                (ii) the delay, failure, interruption, or corruption of any data
                or other information transmitted in connection with use of the
                Payment Gateway; or (iii) any interruption or errors in the
                operation of the Payment Gateway. You expressly agree that your
                use of the Payment Gateway is entirely at your own risk.
                GenieTalk does not collect and store any payment details
                provided by you for transacting through the Payment Gateway. You
                agree, understand and confirm that personal data including
                without limitation details relating to debit card/credit card
                stored and transmitted over the internet is susceptible to
                misuse, theft and/or fraud and that GenieTalk has no control
                over such matters and shall not be liable in any manner
                whatsoever. GenieTalk does not represent or guarantee that the
                use of the Payment Gateway will not result in theft and/or
                unauthorized use of data over the internet.
              </p>

              <p>
                The records of GenieTalk and/or the Service Provider, generated
                by the transactions arising out of the use of Service, including
                the time the transaction is recorded, shall be conclusive proof
                of the genuineness and accuracy of the transaction. The details
                provided by you for use of the Service Provider are correct and
                accurate and you shall not use a debit/credit card/net banking
                which are not lawfully owned by you. You further agree and
                undertake to provide correct and valid debit/credit card/net
                banking details. In default of the above conditions, GenieTalk
                shall be entitled to recover the amount of the transaction from
                you. Further, GenieTalk also reserves the right to initiate any
                legal action for recovery of cost, penalty or any other
                measures, as it may deem fit.
              </p>

              <p>
                GenieTalk will not be responsible or assume any liability
                whatsoever in respect of any loss or damage arising directly or
                indirectly to you while making payments to GenieTalk.
              </p>

              <p>
                All commercial terms with respect to a transaction or deal are
                on a principal-to-principal basis between the Customer and the
                Third-Party Provider and use of the payment facility shall not
                render GenieTalk liable or responsible for the non-completion of
                any deal or transaction or any loss or damage suffered by the
                Customers such regards the deals/transactions.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Refunds</h3>

              <p>
                All refunds for the Products ordered by a Customer shall be
                subject to the concerned Third-Party Provider’s policy. Further,
                the time required to process the refunds and the refund amount
                is also subject to the Third-Party Provider’s refund policy. In
                case the Third-Party Provider’s policy does not provide for a
                refund for particular Product, GenieTalk shall not be liable to
                refund the amount paid for the Product in the event the Customer
                wishes to cancel the Order or return the relevant Product.
              </p>

              <p>
                The Customers shall be entitled to raise a request for a refund
                only if: (i) they pre-pay online for the Order at the time of
                placing the Order or the amount has been deducted from the
                Customers debit card/ account/ credit card, but they have not
                received the Product as per the Order; and (ii) the transaction
                for which the refund is requested, or any other activity by the
                Customer on the Platform does not violate any of these Terms and
                Conditions.
              </p>

              <p>
                In case the Product purchased by the Customer has not been
                received by the Customer, then the Customer is requested to
                raise a complaint on the Platform. On receiving such complaint,
                the matter shall be examined by GenieTalk and if the complaint
                is found to be correct, then a full or partial refund (as
                applicable in the scenario) shall be made to the Customer within
                a reasonable period of time.
              </p>

              <p>
                Notwithstanding anything contained herein, our decision on
                refunds shall be at our sole discretion and shall be final and
                binding on all Users.
              </p>

              <p>
                The Customer has an option to transfer the refund money back to
                the debit card/ credit card/ bank account or wallet that was
                used to make the payment.
              </p>

              <p>
                If the Third-Party Provider approves the Customer’s refund
                request, the refund will be initiated within 48 (forty-eight)
                hours and may take up to 7-10 working days to reflect on the
                Customer’s account.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Communication Policy</h3>

              <p>
                When you use the Platform and/or Services or send emails or
                other data, information or communication to us, you agree and
                understand that you are communicating with us through electronic
                records and you consent to receive communications via electronic
                records (including SMS) from us periodically and as and when
                required, irrespective of whether you are registered under the
                Do Not Disturb registry under Telecom Regulatory Authority of
                India, Government of India (TRAI) or not.
              </p>

              <p>
                Your act of accessing, browsing or using the Platform and/or the
                Services or by contacting us through any means or medium, or
                engaging us to provide the Services constitutes a “verifiable
                request” by you authorising us to call or send messages to your
                telephone numbers provided to or registered with us, with
                respect to the Services.
              </p>

              <p>
                We reserve the right to communicate with you by email or by such
                other mode of communication, electronic or otherwise.
              </p>

              <p>
                GenieTalk may send alerts to the mobile phone number provided by
                you while registering with the Platform for opening an Account
                or on any updated mobile number subsequently provided by you on
                the Platform, or via e-mail or push notifications. GenieTalk
                will make best efforts to provide alerts via SMS/e-mail/push
                notifications and it shall be deemed that you shall have
                received the information sent by GenieTalk as an alert on the
                mobile phone number or e-mail ID provided during the course of
                or in relation to, using the Platform or availing any Services.
              </p>

              <p>
                GenieTalk shall not be under any obligation to confirm the
                authenticity of the person(s) receiving the alert. You cannot
                hold GenieTalk liable for non-availability of the SMS/email
                alert/push notifications service in any manner whatsoever. The
                SMS/e-mail alert/push notification service provided by GenieTalk
                is an additional facility provided for your convenience and that
                it may be susceptible to error, omission and/or inaccuracy.
              </p>

              <p>
                Your access to the Services may also be through a secured
                one-time password (OTP) sent to your registered mobile number.
                It is your responsibility to protect your OTP and you shall not
                share your OTP with others and GenieTalk will not be responsible
                for the same.
              </p>

              <p>
                In the event that you observe any error in the information
                provided in the alert, GenieTalk shall be immediately informed
                about the same by you and GenieTalk will make best possible
                efforts to rectify the error as early as possible. You shall not
                hold GenieTalk liable for any Losses that may be
                incurred/suffered by you on account of the SMS/e-mail alert/push
                notification facility.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Content Of The Website</h3>

              <p>
                The Services shall contain Content specifically provided by us
                and/or our partners or licensors and such Content is protected
                by copyrights, trademarks, trade secrets or other proprietary
                and intellectual property rights and laws. Your use of the
                Content shall at all times be subject to these Terms and
                Conditions and you shall abide by and maintain all copyright
                notices, information, and restrictions contained in any Content
                accessed through the Services.
              </p>

              <p>
                While GenieTalk uses reasonable efforts to include accurate and
                up-to-date information on the Platform, GenieTalk makes no
                warranties or representations as to its accuracy. GenieTalk does
                not assume any liability or responsibility for any errors or
                omissions in the Content.
              </p>

              <p>
                Given the nature of the Platform and the Services provided by
                us, it is not possible for us to be aware of the content that is
                uploaded or displayed on the Platform. Accordingly, GenieTalk
                operates on a “notice and takedown” basis. If you believe that
                any Content, including User Content on the Platform, is illegal,
                offensive or otherwise in breach of these Terms and Conditions
                ("
                <b>Objectionable Content</b>"), please notify us immediately.
                Once this procedure has been followed, we will make all
                reasonable endeavours to remove such Objectionable Content
                complained about within a reasonable time, and in any event
                within 36 (thirty-six) hours or such longer period provided
                under applicable law.
              </p>

              <p>
                GenieTalk may also take down Content, including User Content,
                upon receipt of a takedown notice received from any law
                enforcement agencies or upon the order of any judicial
                authority.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>User Content</h3>

              <p>
                All content created, added, uploaded, submitted, distributed,
                communicated or posted on the Platform by Users (collectively "
                <b>User Content</b>"), whether publicly posted or privately
                transmitted, is the sole responsibility of the User/person who
                originated such User Content. The Platform permits the
                submission of User Content and the hosting, sharing, and/or
                publishing of such User Content. Notwithstanding anything
                contained herein, the User shall be solely responsible for
                User’s own User Content and the consequences of posting or
                publishing them. You represent that all User Content provided by
                you is accurate, complete, up-to-date, and in compliance with
                all applicable laws, rules and regulations. Any and all
                feedback, review or content in any form provided by you will be
                non-confidential and GenieTalk can make use of this information
                in an unrestricted manner. GenieTalk may periodically review the
                User Content and may, with or without notice, take down such
                User Content that is not compliant with these Terms and
                Conditions.
              </p>

              <p>
                You acknowledge that all Content and User Content, accessed by
                you using the Services, is at your own risk and you will be
                solely responsible for any damage or loss to you or any other
                party resulting therefrom. We do not guarantee that any Content
                or User Content you access on the Platform or through the
                Services is or will continue to be accurate. GenieTalk does not
                and will not claim any ownership over or rights to the User
                Content, except to the extent stated herein.
              </p>

              <p>
                In connection with the User Content, the User affirms,
                represents, and/or warrants that: (i) the User owns or has the
                necessary licenses, rights, consents, permissions and
                authorizations to use and authorise GenieTalk to use, all User
                Content, including to enable GenieTalk to include and use the
                User Content for the purposes and in the manner contemplated by
                these Terms and Conditions; (ii) the User has the written
                consent, authorisation, release and/or permission of each and
                every identifiable individual person in the User Content to use
                the name or likeness of each and every such identifiable
                individual person to enable inclusion and use of the User
                Content in the manner contemplated by these Terms of Use; and
                (iii) GenieTalk will not be subjected to any claim, actions,
                liabilities, loss and damage as a result of its use of the User
                Content.
              </p>

              <p>
                By submitting any User Content to the Platform, you hereby do
                and shall grant us a worldwide, non-exclusive, perpetual,
                irrevocable, royalty-free, fully paid, sub-licensable and
                transferable license to use, aggregate, reproduce, distribute,
                prepare derivative works of, display, perform, and otherwise
                fully exploit such User Content in connection with the Platform,
                the Services and our (and our successors' and assigns')
                businesses, including without limitation for promoting and
                redistributing, part or all of the Platform or the Services (and
                derivative works thereof) in any media formats and through any
                media channels (including, without limitation, Third-Party
                websites and feeds), and including after your termination of
                your arrangement with us or the Services.
              </p>

              <p>
                By submitting any User Content to the Platform, you also hereby
                do and shall grant each User (only to the extent such User
                Content is accessible to other Users) a non-exclusive and
                perpetual license to access any of your User Content that is
                available to such User on the Platform, and to use, reproduce,
                distribute, prepare derivative works of, display and perform
                such User Content, including after the termination of your
                arrangement with us or the Services.
              </p>

              <p>
                For clarity, the foregoing licenses granted to us and our Users
                does not affect your other ownership or license rights in your
                User Content, including the right to grant additional licenses
                to your User Content, unless otherwise agreed in writing. You
                represent and warrant that you have all rights to grant such
                licenses to us without infringement or violation of any
                Third-Party rights, including without limitation, any privacy
                rights, publicity rights, copyrights, trademarks, contractual
                rights, or any other intellectual property or proprietary
                rights.
              </p>

              <p>
                GenieTalk: (i) does not examine, verify, research, endorse,
                validate or certify the User Content and any other information
                submitted/uploaded by Users on the Platform nor assumes that all
                User Content and information will be complete, accurate,
                truthful, trustworthy and reliable; and (ii) expressly disclaims
                any and all liability in connection with User Content.
              </p>

              <p>
                As mandated by Regulation 3(2) of the Information Technology
                (Intermediaries Guidelines) Rules, 2011, GenieTalk hereby
                informs Users that they are not permitted to host, display,
                upload, modify, publish, transmit, update or share any
                information that:
              </p>

              <ul className="terms-conditions">
                <li>
                  <ul className="terms-innerconditions">
                    <li>
                      Belongs to another person and to which the User does not
                      have any right to
                    </li>
                    <li>
                      Is grossly harmful, harassing, blasphemous, defamatory,
                      obscene, pornographic, pedophilic, libellous, invasive of
                      another's privacy, hateful, or racially, ethnically
                      objectionable, disparaging, relating or encouraging money
                      laundering or gambling, or otherwise unlawful in any
                      manner whatever;
                    </li>
                    <li>Harm minors in any way;</li>
                    <li>
                      Infringes any patent, trademark, copyright or other
                      proprietary rights;
                    </li>
                    <li>Violates any law for the time being in force;</li>
                    <li>
                      Deceives or misleads the addressee about the origin of
                      such messages or communicates any information which is
                      grossly offensive or menacing in nature;
                    </li>
                    <li>Impersonate another person;</li>
                    <li>
                      Contains software viruses or any other computer code,
                      files or programs designed to interrupt, destroy or limit
                      the functionality of any computer resource; and
                    </li>
                    <li>
                      Threatens the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or causes incitement to the
                      commission of any cognisable offence or prevents
                      investigation of any offence or is insulting any other
                      nation.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-line-tc">
              <h3>Mandatory Rules Of Conduct</h3>

              <p>
                As a condition of use of the Platform and the Services, you
                promise and undertake not to use the Services for any purpose
                that is prohibited by these Terms and Conditions. You are
                responsible for all of your activities in connection with the
                Services and the activities of any person who uses your Account
                or who uses your computers, systems, mobiles,
                machines,communication devices, network and others’ applications
                and resources.
              </p>

              <p>
                By using the Platform and the Services, you agree specifically
                that: (i) you will use your own independent and informed
                judgement before making any decision to buy or sell any Products
                and GenieTalk is not providing you with any advice or making any
                recommendation with respect to any Product, deal or transaction;
                (ii) you do not expect GenieTalk to, and understand that
                GenieTalk does not evaluate, confirm, verify or endorse any
                content, whether viewed on the Platform or otherwise conduct its
                own independent due diligence; (iii) you are not relying on the
                Content, or any data and information that appears on the
                Platform to make any decision related to your purchase of the
                Products; (iv) you are solely responsible for complying with any
                applicable law regarding any transaction carried out with the
                Third-Party Provider; (v) you will obtain such advice as is
                appropriate to protect your interest, including legal,
                accounting, tax, financial and other advice, before transacting
                through the Platform; (vi) there is a risk of incurring losses
                and damages as a result of any activity (purchase and/or sale of
                Products) and that you are willing to undertake this risk at
                your own will; and (vii) any decision taken by you pursuant to
                the use of the Services are at your own will and risk and you
                will be solely liable for the Loss incurred as result of any
                transaction undertaken by you pursuant to the Services.
              </p>

              <p>
                GenieTalk strictly prohibits the submission, uploading, sharing,
                or distribution of content, information, data, and/or materials
                (either directly or indirectly) of the nature described below:
              </p>

              <ul className="terms-innerconditions">
                <li>
                  <b>Sexually Explicit Content:</b> GenieTalk does not allow
                  actions that facilitate or promote sexual gratification or
                  sexually explicit content. This includes: (i) pornographic,
                  sexually explicit, or erotic content; (ii) content that
                  describes sexual acts or sex toys; (iii) escort services or
                  other services that may be interpreted as providing sexual
                  acts in exchange for compensation; (iv) content that describes
                  or encourages bestiality; and (v) child endangerment,
                  including child pornography. GenieTalk has a zero-tolerance
                  policy against child sexual abuse content. If we become aware
                  of content facilitating or promoting the distribution of child
                  sexual abuse content, we will report it to the appropriate
                  authorities and delete the Accounts of those involved with the
                  distribution.
                </li>
                <li>
                  <b>Violence And Dangerous Activities:</b> GenieTalk does not
                  allow actions that facilitate or promote gratuitous violence
                  or dangerous activities. This includes: (i) graphic
                  descriptions of realistic violence or violent threats to any
                  person or animal; (ii) terrorist groups documenting their
                  attacks anything that amounts to endangering national
                  security; (iii) instructions for engaging in or facilitating
                  violent activities, including bomb-making or weapon-making;
                  (iv) self-harm, including instructions to carry out self-harm;
                  and (v) bullying and harassment. We do not allow actions that
                  facilitate threats, harassment, or bullying. This includes
                  content primarily intended to harass or single out another
                  person for abuse, malicious attack, or ridicule.
                </li>
                <li>
                  <b>Hate Speech:</b> GenieTalk does not allow actions that
                  facilitate or promote content that advocates hate or violence
                  against groups of people based on their race or ethnic origin,
                  religion, disability, gender, age, nationality, veteran
                  status, sexual orientation, or gender identity. Actions that
                  facilitate or promote near-hate negativity towards a protected
                  group are prohibited. This includes actions that make
                  inflammatory or excessively negative statements about: (i)
                  intelligence; (ii) appearance or hygiene; (iii) socio-economic
                  status; (iv) ethics or morality; (v) disability or medical
                  condition; (vi) criminal history; (vii) sexual activity; and
                  (viii) sensitive events. We do not allow actions that lack
                  reasonable sensitivity towards or capitalize on, a natural
                  disaster, atrocity, conflict, death, or other tragic events.
                </li>
                <li>
                  <b>Gambling And Other Illegal Activities:</b> GenieTalk does
                  not allow actions that promote online gambling services,
                  including but not limited to, online casinos, sports betting,
                  lotteries, or games of skill if they offer prizes of cash or
                  other value. Further, we do not allow actions that facilitate
                  or promote illegal activities. You are solely responsible for
                  determining the legality of your action in its targeted
                  locale. Actions determined to be unlawful in locations where
                  they are published will be removed.
                </li>
                <li>
                  <b>Alcohol &amp; Tobacco:</b> Any actions that facilitate or
                  promote the sale of alcohol or tobacco, or related products,
                  must: (i) comply with all restrictions or procedures required
                  under applicable law; and (ii) implement account linking and
                  verify user meets legal age requirements. Alcohol and tobacco
                  branded actions must include age verification at the beginning
                  of the action conversation. These requirements apply to all
                  types of alcohol beverage and tobacco products. We do not
                  allow actions that promote excessive use of alcohol or tobacco
                  or use by minors.
                </li>
                <li>
                  <b>Recreational Drugs:</b> GenieTalk does not allow actions
                  that facilitate the sale or production of recreational drugs.
                </li>
                <li>
                  <b>Emergency Services:</b> GenieTalk does not allow actions
                  that enable users to contact emergency responders. For
                  example, 100.
                </li>
                <li>
                  <b>Financial Services:</b> GenieTalk does not allow actions
                  that provide services or store sensitive financial data in
                  violation of applicable legal obligations. GenieTalk is not
                  able to commit that the actions on its Platform can meet all
                  requirements set by financial regulations. Financial data or
                  authentication data cannot be collected via the conversational
                  interface. This includes, for example, bank account or credit
                  card numbers, or PIN/passwords. Actions that provide bank
                  account information, such as credit balances, must implement
                  account linking.
                </li>
              </ul>

              <p>
                In addition to the above, you agree that you shall not (directly
                or indirectly) submit, upload, provide, transmit, distribute,
                post, store, link, or otherwise share content, information,
                data, software, and/or materials on or through the Services
                that: (i) you do not have the right, authority or license to
                make available, whether under law, contract or otherwise; (ii)
                is unlawful, threatening, abusive, harassing, defamatory,
                libelous, deceptive, fraudulent, tortious, offensive, profane or
                is otherwise inappropriate as determined by us, in our sole
                discretion; (iii) is false, misleading, untruthful or
                inaccurate; (iv) is invasive of another’s privacy; (v) violates
                the publicity or proprietary or intellectual property rights of
                third parties; (vi) impersonates any person or entity, including
                any of our employees, agents, representatives or sub-contractors
                or falsely states or otherwise misrepresents your affiliation
                with any person or entity or expresses or implies that GenieTalk
                endorses any statement you make; (vii) includes anyone’s
                identification documents or sensitive financial information or
                any personal information about another individual or any
                information that may be used to track, contact or impersonate
                that individual; (viii) discloses or provides information
                protected under any law, agreement or fiduciary relationship,
                including but not limited to proprietary or confidential
                information of others, insider information or material, or
                non-public information; (ix) constitutes unauthorized or
                unsolicited advertising; (x) violates any law, statute,
                ordinance or regulation; (xi) would give rise to criminal or
                civil liability (under tort or otherwise); (xii) encourages
                conduct that constitute an offense or that encourages or
                provides instructional information about illegal activities;
                (xiii) has the potential to create liability for us; (xiv) could
                cause us to lose, in whole or in part, our relationship with our
                Users, clients, business partners, licensors, services providers
                or other suppliers; (xv) interferes with or disrupts the
                operation of the Platform/Services or the servers or networks
                used to make the Platform/Services available, or violate any
                requirements, procedures, policies or regulations of such
                networks; and (xvi) threatens the unity, integrity, defense,
                security or sovereignty of any country, friendly relations with
                foreign states, or public order or causes incitement to the
                commission of any cognizable offence or prevents investigation
                of any offence or is insulting any other nation.
              </p>

              <p>
                You shall not (directly or indirectly): (i) disclose any
                sensitive, proprietary or confidential information, about
                yourself or anyone else or knowingly disseminate information
                that you know, reasonably ought to know, to be confidential in
                nature, in violation of any duty of confidentiality which you
                are subject to; (ii) misrepresent the source of your content;
                (iii) use the Platform or the Service in violation of any local,
                state or central law, rule or regulation or otherwise in
                violation of any law, rule or regulation which you may be
                subject to; (iv) use the Service in a manner that would violate
                the lawful privacy rights of any person or to publish or
                republish defamatory or libelous statements, or to harass or
                embarrass any person; (v) use the Service in a manner which
                could cause loss, damage or personal injury to any person; (vi)
                use the Service for any fraudulent or unlawful purpose or for
                the promotion of illegal activities; (vii) use the Services if
                you are a competitor of GenieTalk or for reasons that are in
                competition with GenieTalk; (viii) use the Services, the Content
                or its features and services in any manner that could interrupt,
                damage, disable, overburden or impair the Services or interfere
                with any other persons use and enjoyment of the Services; (ix)
                bypass, circumvent or attempt to bypass or circumvent any
                measures we may use to prevent or restrict access to the
                Services (or other computer systems, resources or networks
                connected to the Services); (x) run any form of auto-responder
                or “spam” on the Services; (xi) use manual or automated
                software, device, or other processes to “crawl” or “spider” any
                page of the Platform; (xii) obtain or attempt to gain
                unauthorized access to other computer systems, resources,
                materials, information or any services available on or through
                the Platform through any means, including through means not
                intentionally made publically available or provided for through
                the Platform; (xiii) take any action that imposes or may impose
                (as determined by us in our sole discretion) an unreasonable or
                disproportionately large load on our (or our Third-Party service
                providers’) infrastructure; (xiv) interfere or attempt to
                interfere with the proper working of the Platform and Services
                or any activities conducted on the Platform; (xv) use the
                Services to distribute, receive communications or data gleaned
                from, or execute any action directed by any type of injuries
                code, including but not limited to: (a) trojans, (b) key
                loggers, (c) viruses, (d) malware, (e) botnets, (f) denial of
                service attacks,(g) flood or mail bombs,(h) logic bombs, or (i)
                other actions which GenieTalk reserves the sole right to
                determine to be malicious in intent; (xvi) make improper use of
                our Services or submit false reports of abuse or misconduct;
                (xvii) disparage, tarnish or otherwise harm GenieTalk, including
                the Platform and the Services; (xviii) offer, attempt to offer,
                trade or attempt to trade in any item, the dealing of which is
                prohibited or restricted in any manner under the provisions of
                any applicable law, rule, regulation or guideline for the time
                being in force; and (xix) use the Platform or the Service in
                violation of these Terms and Conditions or otherwise take any
                action in violation of our guidelines and policies.
              </p>

              <p>
                In using the Services, you must comply with, and refrain from
                violations of, any right of any other persons, entity, law or
                contractual duty, including without limitation laws forbidding:
                (i) forgery, identity theft, misdirection or interference with
                electronic communications; (ii) invasion of privacy; (iii)
                unlawful sending of commercial electronic message or other
                marketing or electronic communications; (iv) securities
                violations, wire fraud, money laundering, or terrorist
                activities; and (v) false advertising, propagating or profiting
                from fraudulent and unfair schemes.
              </p>

              <p>
                You shall not (directly or indirectly): (i) decipher, decompile,
                disassemble, reverse engineer or otherwise attempt to derive any
                source code or underlying ideas or algorithms of any part of the
                Platform or Services (including without limitation the O Genie
                App); (ii) modify, translate, or otherwise create derivative
                works of any part of the Services; and/or (iii) copy, rent,
                lease, distribute, or otherwise transfer any of the rights that
                you receive hereunder.
              </p>

              <p>
                You shall abide by all applicable local, state, national and
                international laws and regulations with respect to the use of
                the Platform and Services.
              </p>

              <p>
                You shall not use the Services in violation of the copyrights,
                trademarks, patents, trade secrets or other intellectual
                property rights of third parties, nor shall you utilize the
                Services to publish such materials in a manner that would expose
                them to public view in violation of the law. The applicable laws
                governing intellectual property will apply to issues of
                copyrights violations. GenieTalk will, in appropriate
                circumstances, terminate access to the Platform/Services of
                violators. If a Third-Party believes that you have violated
                their intellectual property rights and notifies us, we will
                forthwith take all necessary action, including but not limited
                to immediately terminating your access to the Platform/Services.
              </p>

              <p>
                Any activity that you are prohibited from performing under these
                Terms of Use is equally prohibited to anyone using your Account,
                computers, systems, mobiles, machines, communication devices,
                network and others’ applications and resources to access the
                Services.
              </p>

              <p>
                You shall: (i) be fully responsible for the acts of others
                utilizing your access to the Services, and will be held
                responsible for violations of the Services by your sub-Users or
                persons who gain access to the Services using your Account,
                computers, systems, mobiles, machines, communication devices,
                network and others applications and resources; (ii) utilize
                proper security protocols, such as setting strong passwords and
                access control mechanisms, safeguarding access to all logins and
                passwords, and verifying the trustworthiness of persons who are
                entrusted with access to your Account, computers, systems,
                mobiles, machines, communication devices, network and others
                applications and resources and information; (iii) notify all
                persons who receive access to the Services of the provisions of
                these Terms and Conditions, and shall inform them that the terms
                of these Terms and Conditions are binding upon them; and (iv)
                notify GenieTalk if and when you learn of security breaches
                regarding the Services, and shall aid in any investigation or
                legal action that is taken by authorities and/or GenieTalk to
                cure the security breach.
              </p>

              <p>
                You shall assume full legal responsibility for any access and
                use of the Platform and the Services, and in the event of any
                breach of this duty resulting in a legal claim against
                GenieTalk, you shall defend, indemnify and hold GenieTalk
                harmless from all claims and damages arising therefrom.
              </p>

              <p>
                We also reserve the right to access, read, preserve, and
                disclose any information as we reasonably believe is necessary
                to: (i) satisfy any applicable law, regulation, legal process or
                governmental request; (ii) enforce these Terms and Conditions,
                including investigation of potential violation hereof, (iii)
                detect, prevent, or otherwise address fraud, security or
                technical issues; (iv) respond to your support request; and (v)
                protect the rights, property or safety of us, our Users and the
                public.
              </p>

              <p>
                You are solely responsible for any reliance by you on the
                Services or other use you make of the Services. Comments,
                suggestion, feedback, ideas, material or other information sent
                or transmitted to GenieTalk, either through the Platform, email
                or otherwise (collectively "<b>Feedback</b>"), shall be deemed
                to be and treated as non-proprietary and non-confidential.
                Subject to the conditions described in GenieTalk’s Privacy
                Policy, GenieTalk shall have no obligation of any kind with
                respect to such Feedback and shall be free to use and distribute
                the Feedback to others without limitation, including but not
                limited to developing and marketing the Services. GenieTalk
                reserves the right to publish or use the Feedback for
                promotional or other purposes without any further permission,
                notice or payment of any kind to the sender. All such Feedback
                will be the property of GenieTalk. GenieTalk reserves the right
                to copy, use, reproduce, modify, adapt, translate, publish,
                license, distribute, sell or assign the Feedback in any way as
                it deems fit, including but not limited to copying in whole or
                in part, creating derivative works from, distributing and
                displaying any Feedback in any form, media, or technology,
                whether now known or hereafter developed, alone or as part of
                other works, or using the Feedback within or in connection with
                GenieTalk’s products or services. If you provide a Feedback, you
                represent and warrant that you own or otherwise control the
                rights to the Feedback. You further represent and warrant that
                such Feedback does not constitute or contain software viruses,
                commercial solicitation, chain letters, mass mailings, or any
                form of “spam”. You shall not use a false email, address,
                impersonate any person or entity, or otherwise mislead GenieTalk
                as to the origin of any Feedback. You agree to indemnify
                GenieTalk for all claims arising from any claim to any rights in
                any Feedback or any damages arising from any Feedback.
              </p>

              <p>
                The enumeration of violations in this Section is not meant to be
                exclusive, and GenieTalk has and will exercise its authority to
                take any action which is necessary to protect the Services,
                Users and third-parties from acts that would be inimical to the
                purpose of this Section.
              </p>

              <p>
                If GenieTalk does take any legal action against you as a result
                of your violation of these Terms and Conditions, GenieTalk is
                entitled to fully recover from you all attorney’s fees and costs
                as a result of such activity by you. You agree to fully pay any
                such fees incurred in the process. GenieTalk reserves complete
                right to seize your Account in whatever condition it is and
                freeze it without doing anything about any information contained
                in the Account or pending Orders.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Intellectual Property Rights And Ownership</h3>

              <p>
                The Platform, GenieTalk Network and all information and Content
                available on the Platform and its “look and feel”, including but
                not limited to the GenieTalk Marks, copyrighted works, text,
                graphics, logos, button icons, images, audio/video clips, data
                complications and software, and the compilation and organization
                thereof is the property of GenieTalk, or is used with consent of
                the owner and is protected by laws, including laws governing
                copyrights and trademarks. The reproduction and use of any of
                these by you are prohibited unless specific written permission
                is provided by us. Any unauthorized use shall violate copyright
                laws, trademark laws and other applicable intellectual property
                laws.
              </p>

              <p>
                GenieTalk Marks referenced in the Platform are the intellectual
                property rights of GenieTalk. Any unauthorized use of the same
                is strictly prohibited and all rights in same are reserved by
                GenieTalk. No use of any GenieTalk Marks may be made by any
                Third-Party without the express written consent of GenieTalk.
              </p>

              <p>
                You agree not to engage in the use, copying, or distribution of
                any of the Content other than expressly permitted herein,
                including any use, copying, or distribution of Content obtained
                through the Platform for any commercial purposes. If you
                download or print any Content for personal use, you must retain
                all copyright and other proprietary notices contained therein.
              </p>

              <p>
                Subject to these Terms and Conditions, we grant each User a
                non-exclusive, revocable, non-sublicensable, non-transferable
                and limited license to access the Platform solely for the
                purposes availing the Services.
              </p>

              <p>
                Nothing contained on this Platform should be construed as
                granting, by implication or otherwise, any right, license or
                title to any of GenieTalk’s intellectual property and GenieTalk
                Marks, without the advance written permission of GenieTalk or
                such Third-Party as may be appropriate. All rights are expressly
                reserved and retained by GenieTalk. Your misuse of any of
                GenieTalk Marks displayed on the Platform, or any other Content
                on the Platform, except as provided in these Terms and
                Conditions, is strictly prohibited. You are also advised that
                GenieTalk considers its intellectual property to be among its
                most valuable assets and will aggressively enforce its
                intellectual property rights to the fullest extent of the law.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Policy</h3>

              <p>
                We view the protection of your privacy as a very important
                principle. We understand clearly that you and your personal
                information is one of our most important assets. Our{" "}
                <a
                  className="Flight-theme-txt"
                  href="https://ogenietravels.com/terms_and_conditions/#privacy-policy"
                >
                  Privacy Policy
                </a>{" "}
                governs our collection, use and disclosure of your personal
                information and is incorporated into and forms an integral part
                of these Terms and Conditions. Please read it carefully. It
                describes what information we collect from you and when, how and
                why we collect information from you, whom we share your
                information with and when and how you can opt-out. This is
                important information. By using our Services, you specifically
                consent to our Privacy Policy.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Confidentiality</h3>

              <p>
                The Services may contain information which is confidential to
                GenieTalk including, without limitation, all information
                designated as confidential or recognisable as trade or business
                secrets or for other reasons recognisable or made available to
                you as confidential. Each User shall use commercially reasonable
                efforts to keep confidential, any and all data and information
                about GenieTalk ("
                <b>Confidential Information</b>"), whether written, oral or
                visual, disclosed to it by GenieTalk or which comes into its
                possession or knowledge, from time to time.
              </p>

              <p>
                You shall not disclose the Confidential Information to any
                others, except as may be required by law, in which this case and
                to the extent permissible by applicable law, you will notify
                GenieTalk immediately of any disclosure of any information which
                is confidential to GenieTalk.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Disclaimer</h3>

              <p>
                You acknowledge that we have no duty to take any action
                regarding: (i) which Users gain access to the Services; (ii)
                what Content you access via the Services; or (iii) how you may
                interpret or use the Content.
              </p>

              <p>
                The Platform, the Services, the Content, including without
                limitation any information delivered as part of the foregoing
                are provided on an “as is” and “as available” basis, without
                warranty of any kind, express or implied, including, but not
                limited to the implied warranties of title, non-infringement,
                merchantability and fitness for a particular purpose,
                non-interference with data, availability, accuracy, that you
                will have continuous, uninterrupted or secure access to our
                Platform or the Services or that the Platform or the Services
                are error-free and any warranties implied by any course of
                performance or usage of trade, all of which are expressly
                disclaimed.
              </p>

              <p>
                GenieTalk and GenieTalk Representatives do not warrant that: (i)
                the Platform or the Services will be secure or available at any
                particular time or location; (ii) any defects or errors will be
                corrected; (iii) any Content or software available through the
                Platform or Services is free of viruses or other harmful
                components; and/or (iv) the result of using the Platform or the
                Services will meet your requirements. Your use of the Platform
                or the Services is solely at your own risk. The
                Platform/Services contain information provided by one or more
                Third-Party data providers. GenieTalk does not control and is
                not responsible for the information provided by any such
                Third-Party provider. You acknowledge and agree that neither
                GenieTalk nor any such Third-Party provider has any obligation
                to correct such information except as required by applicable
                law. Information you request may not be available or may not be
                provided, and GenieTalk has no liability for such failure. In no
                event will GenieTalk warrant or guarantee the correctness,
                comprehensiveness, completeness, accuracy, timeliness of any
                information, the Contents and/or the Services. The Information,
                Contents, Products and Services available on the Platform may
                include technical inaccuracies or typographical errors.
                Therefore, you agree that your access to and use of our
                Platform, the Services and Content are at your own risk.
              </p>

              <p>
                GenieTalk does not authorize anyone to make a warranty on its
                behalf and you may not rely on any statement of warranty as a
                warranty made by GenieTalk.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Indemnification</h3>

              <p>
                You shall defend, indemnify, and hold harmless GenieTalk and
                GenieTalk Representatives from all losses, claims, damages,
                liabilities, penalties, judgments, costs, expenses (including
                without limitation reasonable legal costs), charges, actions,
                proceedings and demands brought against or suffered by any of
                them resulting from, arising out of or relating to: (a) your (or
                any Third-Party using your identity or your Account, computers,
                systems, mobiles, machines, communication devices, network and
                others applications and resources);(b) use or misuse of, or
                access to, the Services, Content, or otherwise from your User
                Content; (c) violation or breach of any term of these Terms and
                Conditions or any applicable law or regulation, whether or not
                referenced herein; and (d) violation of any rights of any
                Third-Party.
              </p>

              <p>
                We reserve the right to assume the exclusive defense and control
                of any matter otherwise subject to indemnification by you, in
                which event you will assist and cooperate with us in asserting
                any available defenses. GenieTalk reserves the right, at your
                expense, to assume the exclusive defence and control of any
                matter for which You are required to indemnify GenieTalk,
                including rights to settle, and You agree to cooperate with
                GenieTalk’s defense and settlement of these claims. GenieTalk
                will use reasonable efforts to notify You of any claim, action,
                or proceeding brought by a Third-Party that is subject to the
                foregoing indemnification upon becoming aware of it. This
                Section shall survive termination of this Agreement.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Limitation Of Liability</h3>

              <p>
                The downloading and/or use of the O Genie App is entirely at
                your own risk and in no event, shall GenieTalk be liable for
                Losses relating to the O Genie App. Your sole and exclusive
                remedy for dissatisfaction with the O Genie App is to stop using
                the O Genie App.
              </p>

              <p>
                GenieTalk disclaims any and all liability to you for enabling
                the purchase of the Products. This does not affect your
                statutory rights as a consumer against the Third-Party Provider.
                If we are found liable for any loss or damage to you, such
                liability is limited to the amount you have paid for the
                relevant Product to GenieTalk.
              </p>

              <p>
                In no event shall GenieTalk and GenieTalk Representatives be
                liable under contract, tort, strict liability, negligence or any
                other legal or equitable theory: (i) with respect to the
                Platform and the Services; (ii) for any lost profits or other
                economic losses, loss of data and other programs, loss of
                opportunity, business interruption, cost of procurement of
                substitute goods or services, or special, indirect, incidental,
                punitive, compensatory or consequential damages of any kind
                whatsoever; (ii) for any bugs, viruses, trojan horses, or the
                like (regardless of the sources of origination), or (iii) for
                any direct damages in excess of the fees paid to us for the
                Services, even if GenieTalk has been advised of, knew, or should
                have known, the possibility thereof. You hereby waive any and
                all claims against GenieTalk arising out of your use of the
                Platform or the Services, or any conduct of GenieTalk
                Representatives. Your sole and exclusive right and remedy in
                case of dissatisfaction with the Services or any other grievance
                shall be your termination and discontinuation of access to or
                use of the Services.
              </p>

              <p>
                In addition, you agree that GenieTalk is not responsible for any
                data compiled by our Services and that GenieTalk will not be
                liable, in any manner, as a result of your exposure to any
                defamatory, libellous, threatening, unlawfully harassing,
                obscene or otherwise unlawful content or data. In no event shall
                GenieTalk, or any Third-Party provider of any component of the
                Services or of any information delivered as part of the
                Services, be liable to you and/or any party for any damages of
                any kind, including but not limited to direct, indirect,
                special, exemplary, punitive, consequential or similar damages
                arising out of or related to the Services, the Content, the use
                or inability to use this Platform, or any linked website,
                including without limitation, lost profits or other economic
                losses, business interruption, loss of programs or other data,
                whether in an action of contract, negligence or other tortious
                action, even if GenieTalk is advised of the possibility of such
                damage, including liability associated with any viruses which
                may infect your computer equipment.
              </p>

              <p>
                GenieTalk shall not be liable for damages resulting from the use
                of (or the inability to use) electronic means of communication
                with the Platform, including, but not limited to damages
                resulting from failure or delay in delivery of electronic
                communications, interception or manipulation of electronic
                communications by third parties or by computer programs used for
                electronic communications and transmission of viruses. We assume
                no responsibility for any error, omission, interruption,
                deletion, defect, delay in operation or transmission,
                communication line failure, theft or destruction or unauthorised
                access to or alteration of data/information of a property or a
                project. GenieTalk shall not be responsible for any problem or
                technical malfunction on-line-systems, servers or providers,
                computer equipment, software, failure of email or players on
                account of technical problem or traffic congestion on the
                Internet or at any website or combination thereof, including
                injury or damage to any customer and/or user or to any other
                person's computer related to or resulting from participating or
                downloading materials/information from the O Genie App. We do
                not accept any liability for any delays, failures, errors or
                omissions or loss of transmitted information, viruses or other
                contamination or destructive properties transmitted to you or
                your mobile system via GenieTalk.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Termination</h3>

              <p>
                GenieTalk reserves the right, in its sole discretion, to
                terminate your access to all or any part of the Platform or the
                Services at any time, with or without notice, effective
                immediately.
              </p>

              <p>
                If you wish to terminate your Account, you may do so by
                following the instructions on the Platform or through the
                Services or writing to us at{" "}
                <a
                  className="Flight-theme-txt"
                  href="mailto:support@ogenietravels.com"
                >
                  support@ogenietravels.com
                </a>
                .
              </p>

              <p>
                GenieTalk is entitled to terminate these Terms and Conditions at
                any time and without any prior notice if you violate any of
                these Terms and Conditions.
              </p>

              <p>
                Any such termination may result in the forfeiture and
                destruction of information associated with your Account.
              </p>

              <p>
                Upon termination of your access to the Platform or Services for
                any reason, you shall destroy and remove from all computers,
                devices, equipment and other storage media all copies of any
                intellectual property owned by GenieTalk that you acquired
                through the Services.
              </p>

              <p>
                Except as otherwise set forth hereunder, any and all fees paid
                hereunder are non-refundable and any and all fees owed to the
                GenieTalk before such termination shall become immediately due
                and payable, including any liabilities that may have been
                incurred prior to termination such as, GenieTalk’s costs for
                collection (including attorneys’ fees) of any such charges or
                other liabilities.
              </p>

              <p>
                Upon termination, any and all rights granted to the user will
                immediately be terminated, and the user shall promptly
                discontinue all use of the Services.
              </p>

              <p>
                All provisions of these Terms and Conditions which, by their
                nature should survive termination shall survive termination,
                including, without limitation, the provisions governing licenses
                to the User Content, ownership of intellectual property,
                warranty disclaimers, indemnity and limitation of liability.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Governing Law And Jurisdiction</h3>

              <p>
                These Terms and Conditions will be governed by the laws of
                India. Further, the courts at Mumbai, India shall have exclusive
                jurisdiction for all matters arising out of or in connection
                with these Terms and Conditions and you agree to irrevocably
                submit to the exclusive jurisdiction of such courts.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Dispute Resolution</h3>

              <p>
                If any dispute, controversy or claim arises under this Agreement
                or in relation to the Platform or Service, including any
                question regarding the existence, validity or termination of
                this Agreement (hereinafter “Dispute”), the concerned parties
                shall use all reasonable endeavours to resolve such Dispute
                amicably.
              </p>

              <p>
                If the parties are unable to resolve the Dispute amicably within
                30 (thirty) days of the notice of such Dispute, GenieTalk may
                elect to resolve such Dispute by binding arbitration in
                accordance with the provisions of the Indian Arbitration and
                Conciliation Act, 1996. The parties will refer the dispute to a
                sole arbitrator to be appointed by GenieTalk. The seat of the
                arbitration shall be Mumbai or Indore and the{" "}
                <span>language of arbitration shall be English</span>.
              </p>

              <p>
                Nothing contained in this clause will preclude a party from
                applying for and obtaining any injunctive, prohibitory or other
                similar urgent or interim relief.
              </p>

              <p>
                Any arbitration shall be confidential, and neither you nor
                GenieTalk may disclose the existence, content or results of any
                arbitration, except as may be required by law or for purposes of
                enforcing the arbitration award.
              </p>

              <p>
                All administrative fees and expenses of arbitration will be
                divided equally between you and GenieTalk. This paragraph shall
                survive termination of these Terms and Conditions.
              </p>
            </div>
            <div className="single-line-tc">
              <h3>Miscellaneous Provisions</h3>

              <p>
                <b>Interpretation:</b> The section and paragraph headings in
                these Terms and Conditions are for convenience, solely for the
                purpose of reference and shall not, in any way, affect the
                meaning or interpretation of these Terms and Conditions. Where a
                word or phrase is defined, other parts of speech and grammatical
                forms and the cognate variations of that word or phrase will
                have corresponding meanings. Further, where the context so
                admits, the singular shall include the plural and words in the
                masculine gender shall include the feminine gender and/or
                neutral gender and vice-versa. Any reference to a party, person
                or User in these Terms and Conditions shall include a reference
                to its successors in title/interest and assigns. All references
                to “laws”, “rules”, or “regulations” shall be to such laws,
                rules and regulations as amended, supplemented, or re-enacted
                from time to time and any reference to a statutory provision
                shall include any subordinate legislation made from time to time
                under that provision.
              </p>

              <p>
                <b>Force Majeure:</b> GenieTalk shall not have any liability for
                failure to fulfill any obligation on account of force majeure
                reasons or reasons beyond its control, including, but not
                limited to any act of God, an act of government or any
                authorities, malicious Third-Party attacks on the Platform,
                electrical power fluctuations or failures, electrical or
                electromagnetic stress, environmental factors, failure of public
                utilities, labour unrest, hostilities between nations, war,
                riot, civil commotions, civil war, insurrection, blockades,
                import or export regulations or embargoes, national emergency,
                explosion, rainstorms, earthquake, fire, flooding, or other
                exceptional weather conditions or natural disasters, acts of
                terrorism, accidents, sabotages, strikes, shortages in supply,
                destruction of service facilities, infectious diseases,
                epidemics, utility or telecommunication outages, internet
                disturbance or any unforeseen change in circumstances.
              </p>

              <p>
                <b>Modification:</b> GenieTalk reserves the right, at any time
                and from time to time, to amend, modify, revise, update,
                suspend, remove and/or otherwise change these Terms and
                Conditions without prior notice to you, provided that if any
                such alterations constitute a material change to these Terms and
                Conditions (as determined by GenieTalk in its sole discretion),
                GenieTalk will notify you by posting an announcement on the
                Platform. All such amendments and modifications shall take
                effect immediately when posted on the Platform. By continuing to
                access the Platform or use the Services after any such
                amendments or modifications, you agree to be bound by such
                amended or modified Terms and Conditions. For this reason, we
                encourage you to review the Terms and Conditions whenever you
                use the Services. If you do not agree to any change to these
                Terms and Conditions, then you must immediately stop accessing
                the Platform or using the Services.
              </p>

              <p>
                <b>Waiver:</b> Failure of GenieTalk to require performance of
                any provision of these Terms and Conditions shall not affect
                GenieTalk’s right to full performance thereof at any time
                thereafter, and any waiver by GenieTalk of a breach of any
                provision hereof shall not constitute a waiver of a similar
                breach in the future or of any other breach. No waiver shall be
                effective unless in writing and duly executed by an authorized
                representative of GenieTalk.
              </p>

              <p>
                <b>Entire Agreement:</b> These Terms and Conditions, the Privacy
                Policy,{" "}
                <a
                  className="Flight-theme-txt"
                  href="https://ogenietravels.com/terms_and_conditions/#cancellation-refund-policy"
                >
                  Cancellation &amp; Refund Policy
                </a>
                , including all related agreement and policies incorporated by
                reference herein, constitutes the entire agreement between you
                and GenieTalk related to the subject matter hereof.
              </p>

              <p>
                <b>Independent Rights:</b> Each of the rights of GenieTalk are
                independent, cumulative and without prejudice to all other
                rights available to it under law, equity or otherwise, and the
                exercise or non-exercise of any such rights shall not prejudice
                or constitute a waiver of any other right by GenieTalk, whether
                under these Terms and Conditions or otherwise. The rights of
                GenieTalk with respect to any matter conferred under any
                provision of these Terms and Conditions shall be in addition to
                any other rights conferred under any other provision of these
                Terms and Conditions, in law or in equity.
              </p>

              <p>
                <b>Severability:</b> If any provision of these Terms and
                Conditions is held to be illegal, invalid or unenforceable under
                present or future laws such provision shall be fully severable.
                Thereafter, these Terms and Conditions shall be construed and
                enforced as if such illegal, invalid, or unenforceable provision
                had never comprised a part of these Terms and Conditions and the
                remaining provisions of these Terms and Conditions shall remain
                in full force and effect and shall not be affected by the
                illegal, invalid or unenforceable provision or by its severance
                from these Terms and Conditions. Furthermore, in lieu of each
                such illegal, invalid or unenforceable provision, we shall add
                as a part of these Terms and Conditions, a provision as similar
                in terms to such illegal, invalid or unenforceable provision as
                may be possible and be legal, valid and enforceable.
              </p>

              <p>
                <b>Evidence:</b> Subject to the applicable laws of evidence, you
                hereby agree not to object to the admission of these Terms and
                Conditions as evidence in legal proceedings.
              </p>

              <p>
                <b>Assignment:</b> These Terms and Conditions and agreement
                evidenced by it is personal to you and is not assignable or
                transferable by you. We may assign, transfer or delegate any of
                our rights and obligations hereunder to any person without your
                consent.
              </p>

              <p>
                <b>No Agency:</b> No agency, partnership, joint venture, or
                employment relationship is created as a result of these Terms
                and Conditions and neither party has any authority of any kind
                to bind the other in any respect.
              </p>

              <p>
                <b>Notices:</b> Any notices, requests and other communications
                required or permitted hereunder to be provided by the User to
                GenieTalk shall be in writing, in English language, and shall be
                given by hand against written acknowledgement of receipt, or
                sent by registered mail, or by facsimile followed by a
                confirmation letter by registered mail, or by email followed by
                a confirmation of receipt. All notices will be deemed to have
                been duly given when received by GenieTalk. Unless otherwise
                specified in these Terms and Conditions, electronic notices
                should be sent to GenieTalk at{" "}
                <a
                  className="Flight-theme-txt"
                  href="mailto:support@ogenietravels.com"
                >
                  support@ogenietravels.com
                </a>
                . GenieTalk will send its notices to the Users by way of an
                email at the email address provided by the Users.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsandConditions;
