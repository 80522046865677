import React, { useEffect } from "react";
import numberToIndCurr from "../../../commons/util/priceUtil";
import { renderImage } from "../../../commons/util/flightUtil";
import Image from "../../../commons/artefacts/elements/input/image";
import { Popover } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
const FareSummary = ({ data, setFinalGrandTotal,mgtFee }) => {
  const {
    addons,
    baseFare,
    passengers,
    feeCharges,
    vendorCharges,
    feeBreakup,
  } = data;
    const isFeeBreakupAvailable =
    feeBreakup && Object.keys(feeBreakup)?.length > 0;

  const feeBreakupFullForms = {
    //if extra keys updated by service provider then added here
    OT: "Other Charges",
    MF: "Management Fee",
    MFT: "Management Fee Tax",
    AGST: "Airline GST",
    YQ: "Fuel Surcharge",
    YR: "Carrier Misc Fee",
  };

  useEffect(() => {
    setFinalGrandTotal(baseFare + feeCharges + addons.price);
  }, [passengers]);

  if (!passengers || passengers.length === 0) {
    return null; // Render nothing if passengers is null or an empty array
  }
  const adultPassenger = passengers.find((passenger) => passenger.ADULT);
  const childPassenger = passengers.find((passenger) => passenger.CHILD);
  const infantPassenger = passengers.find((passenger) => passenger.INFANT);
  const handlePopoverClick = () => {
    if (isFeeBreakupAvailable) {
      const popoverContent = (
        <div className="Flight-fd-table Flight-font13">
        <div className="p-3">
        {Object.keys(feeBreakup).map((key) => (
          key !== "MFT" && key !== "MF"  && (
            <div className="d-flex justify-content-between" key={key}>
              <div className="Flight-font-medium me-3">
                {feeBreakupFullForms[key]}
              </div>
              <div className="text-end Flight-font-medium Flight-font-medium">
                {numberToIndCurr(feeBreakup[key])}
              </div>
            </div>
          )
        ))}
      </div>
         
        </div>
      );

      const popover = (
        <Popover
          className="popover-dark"
          id="fee-breakup-popover"
          title="Fee & Surcharges Breakdown"
        >
          {popoverContent}
        </Popover>
      );
      return popover;
    } else {
      return null;
    }
  };
  return (
    <div>
      <div className="Flight-font16 Flight-font-bold mb-lg-2 mb-3">
        Fare Summary
      </div>
      <div className="Flight-left-sidebar2 mb-3">
        <div className="Flight-fd-table Flight-font14">
          <div className="p-3 pt-4 pb-2">
            {adultPassenger ? (
              <div className="d-flex justify-content-between mb-3">
                <div className="Flight-font-medium">
                  {`Adults X ${adultPassenger?.ADULT}`}{" "}
                </div>
                <div className="text-end Flight-font-bold">
                  {numberToIndCurr(passengers[0]?.price)}
                </div>
              </div>
            ) : null}
            {childPassenger ? (
              <div className="d-flex justify-content-between mb-3">
                <div className="Flight-font-medium">
                  {`Childrens X ${childPassenger?.CHILD}`}{" "}
                </div>
                <div className="text-end Flight-font-bold">
                  {numberToIndCurr(passengers[1]?.price)}
                </div>
              </div>
            ) : null}
            {infantPassenger ? (
              <div className="d-flex justify-content-between mb-3">
                <div className="Flight-font-medium">
                  {`Infants X ${infantPassenger?.INFANT}`}{" "}
                </div>
                <div className="text-end Flight-font-bold">
                  {numberToIndCurr(passengers[2]?.price)}
                </div>
              </div>
            ) : null}

            {/* <div className="d-flex justify-content-between mb-3">
              <div className="Flight-font-medium">Total Base Fare </div>
              <div className="text-end Flight-font-bold">
                {numberToIndCurr(baseFare)}{" "}
              </div>
            </div> */}
            <div className="d-flex justify-content-between mb-3">
              <div className="Flight-font-medium">
                Addons
                <span className="Flight-font10 Flight-txt-gray d-block">
                  {addons?.detail}
                </span>
              </div>
              <div className="text-end Flight-font-bold">
                {numberToIndCurr(addons?.price)}{" "}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <div className="Flight-font-medium ">
                {" "}
                Fee & Surcharges
                <OverlayTrigger
                  trigger="click"
                  overlay={handlePopoverClick()}
                  rootClose
                >
                  <Image
                    className="mx-1 Fligh-gray-img op-2"
                    src={renderImage("search-result/info-icon.svg")}
                  />
                </OverlayTrigger>
              </div>
              <div className="text-end Flight-font-bold">
                {numberToIndCurr(feeCharges-mgtFee)}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <div className="Flight-font-medium">Convenience Fee</div>
              <div className="text-end Flight-font-bold">
                {numberToIndCurr(
                  vendorCharges *
                    (adultPassenger?.ADULT + (childPassenger?.CHILD ?? 0))
                )}
              </div>
            </div>
            <div className="Flight-font16 Flight-font-bold d-flex justify-content-between Flight-total-fare pt-3 pb-2">
              <div>Total</div>
              <div className="text-end">
                {numberToIndCurr(
                  baseFare +
                    feeCharges +
                    addons.price +
                    vendorCharges *
                      (adultPassenger?.ADULT + (childPassenger?.CHILD ?? 0))-mgtFee
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FareSummary;
