import _ from "lodash";
import React from "react";
import { Modal, Table } from "react-bootstrap";
import numberToIndCurr from "../../../../commons/util/priceUtil";

const RefundModalBody = ({
  data,
  passengers: { adultCount, childCount, infantCount },
  vendorCharges,
  bookingConv,
  displayAmount,
}) => {
  const { refundAmount, charges, totalFare } = _.reduce(
    data,
    (
      acc,
      {
        amendmentInfo: {
          ADULT: {
            refundAmount: aRefund = 0,
            amendmentCharges: aAmendCharge = 0,
            totalFare: atotalFare = 0,
          } = {},
          CHILD: {
            refundAmount: cRefund = 0,
            amendmentCharges: cAmendCharge = 0,
            totalFare: ctotalFare = 0,
          } = {},
          INFANT: {
            refundAmount: iRefund = 0,
            amendmentCharges: iAmendCharge = 0,
            totalFare: itotalFare = 0,
          } = {},
        },
      }
    ) => {
      return {
        refundAmount:
          acc.refundAmount +
          aRefund * adultCount +
          cRefund * childCount +
          iRefund * infantCount,
        charges:
          acc.charges +
          aAmendCharge * adultCount +
          cAmendCharge * childCount +
          iAmendCharge * infantCount,
        totalFare:
          acc.totalFare +
          atotalFare * adultCount +
          ctotalFare * childCount +
          itotalFare * infantCount,
      };
    },
    { refundAmount: 0, charges: 0, totalFare: 0 }
  );

  let totalDisplayAmt = parseInt(displayAmount)

  let totalChareges = charges  - (totalFare - displayAmount);
  return (
    <div className="Flight-fd-table tbl-rounded">
      <Table responsive className="mb-0 Flight-font14">
        <tbody>
          <tr>
            <td>Total Fare</td>
            <td className="text-end">{numberToIndCurr(totalDisplayAmt)}</td>
          </tr>
          <tr>
            <td>Cancel Change Fee</td>
            <td className="text-end">{numberToIndCurr(totalChareges)}</td>
          </tr>

          {vendorCharges ? (
            <tr>
              <td>Convenience Fee</td>
              <td className="text-end">
                {numberToIndCurr(vendorCharges * (adultCount + childCount))}
              </td>
            </tr>
          ) : null}
          <tr>
            <td className="Flight-font16 Flight-font-semibold">
              Refund Amount
            </td>
            <td className="text-end Flight-font16 Flight-font-semibold">
              {numberToIndCurr(
                refundAmount -
                  (vendorCharges
                    ? vendorCharges * (adultCount + childCount)
                    : 0)
              )}
            </td>
          </tr>
          {/* <tr>
            <td className="Flight-font16 Flight-font-semibold">
              Total Deductions
            </td>
            <td className="text-end Flight-font16 Flight-font-semibold">
              {numberToIndCurr(
                totalFare -
                  refundAmount +
                  vendorCharges * (adultCount + childCount)
              )}
            </td>
          </tr> */}
        </tbody>
      </Table>
    </div>
  );
};

export default RefundModalBody;
