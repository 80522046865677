import React, { useEffect, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Button from "../../../commons/artefacts/elements/button/primary";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";

const SeatLayout = ({
  sData,
  segmentId,
  travellerInfo,
  setTravellerInfo,
  closeModal,
  trip,
  type,
}) => {
  const [seatMatrix, setSeatMatrix] = useState([]);
  const [colLetters, setColLetters] = useState([]);
  const [tempPaxInfo, setTempPaxInfo] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [summary, setSummary] = useState({
    totalAmount: 0,
    seatCount: 0,
  });
  const [paxSeatSummary, setPaxSeatSummary] = useState([]);
  const [displayCard, setDisplayCard] = useState(true);
  const [seatNos, setSeatNos] = useState([]);

  useEffect(() => {
    if (!sData.hasOwnProperty("nt")) {
      let sm = [];

      let orederedAlphabets = [];

      Array.from(Array(sData?.sData?.column)).map((cElem, cIndex) => {
        let column = sData?.sInfo?.filter((elem) =>
          elem.seatPosition.column === cIndex + 1 ? elem : null
        );
        if (column.length > 1) {
          let doIt = 0;
          while (doIt == 0) {
            let targetIndex;
            let targetSno;
            for (let x = 0; x < column.length; x++) {
              let curr = Number(column[x].seatNo.slice(0, -1));
              if (x == column.length - 1) {
                doIt = 1;
              } else {
                let next = Number(column[x + 1].seatNo.slice(0, -1));
                curr++;
                if (curr !== next) {
                  targetIndex = x + 1;
                  targetSno = curr;
                  break;
                }
              }
            }

            if (doIt == 0) {
              column.splice(targetIndex, 0, {
                seatNo: `${targetSno}${
                  column[0]?.seatNo[column[0]?.seatNo?.length - 1]
                }`,
              });
            }
          }
        }
        sm.push(column); // sm is having array of arrays[each array have a same column]

        if (column.length > 0) {
          orederedAlphabets.push(
            // row
            column[0]?.seatNo[column[0]?.seatNo?.length - 1]
          );
        } else orederedAlphabets.push("");
        return null;
      });

      setColLetters([...orederedAlphabets]);

      setSeatMatrix(() => [...seatMatrix, ...sm]);

      let seatNumerics = [];
      sm[0].forEach((x) => {
        seatNumerics.push(x.seatNo.slice(0, -1));
      });

      setSeatNos([...seatNumerics]);

      setTempPaxInfo(JSON.parse(JSON.stringify(travellerInfo)));

      let selSeats = [];
      for (let i = 0; i < travellerInfo.length; i++) {
        if (
          travellerInfo[i].type !== "INFANT" &&
          travellerInfo[i].hasOwnProperty("ssrSeatInfos")
        ) {
          travellerInfo[i].ssrSeatInfos.forEach((j) => {
            if (j.key === segmentId) selSeats.push(j.code);
          });
          break;
        }
      }

      setSelectedSeats([...selSeats]);
    } else {
      setDisplayCard(false);
    }
  }, []);

  const seatSelectHandler = (seat, colIndex, rowIndex) => {
    colIndex = seatMatrix.length - 1 - colIndex;
    let eligibePaxCount = tempPaxInfo.filter((f) => f.type !== "INFANT").length;

    let currentSeatCount = 0;
    for (let i = 0; i < tempPaxInfo.length; i++) {
      if (
        tempPaxInfo[i].type !== "INFANT" &&
        tempPaxInfo[i].hasOwnProperty("ssrSeatInfos")
      ) {
        tempPaxInfo[i].ssrSeatInfos.forEach((j) => {
          if (j.key === segmentId) currentSeatCount++;
        });
        break;
      }
    }

    const updatedPaxInfo = tempPaxInfo.map((pax, paxIndex) => {
      if (pax.type !== "INFANT") {
        if (pax.hasOwnProperty("ssrSeatInfos")) {
          let seatFlag = false; // flag to mark if seat is already there (default false)
          let ssrSeatInfo = pax.ssrSeatInfos.filter((ssrSeat, ssrSeatIndex) => {
            if (ssrSeat.key === segmentId && ssrSeat.code === seat.code) {
              setSelectedSeats((selectedSeats) =>
                selectedSeats.filter((x) => x !== seat.code)
              );
              //seatMatrixClone[colIndex][rowIndex]['checked']=false;
              seatFlag = true; //seat found and removed
              return null;
            } else {
              return ssrSeat;
            }
          });

          if (seatFlag === false) {
            // adding the seat
            //  console.log(currentSeatCount + "anshhhhhhhhhhhhhhh" + eligibePaxCount );
            if (currentSeatCount < eligibePaxCount) {
              setSelectedSeats((selectedSeats) => [
                ...selectedSeats,
                seat.code,
              ]);
              //seatMatrixClone[colIndex][rowIndex]['checked']=true;
              ssrSeatInfo.push({
                key: segmentId,
                code: seat.code,
                seatNo: seat.seatNo,
                amount: seat.amount,
                deparrCode: trip,
                ride: type,
              });
            }
          }
          pax["ssrSeatInfos"] = ssrSeatInfo;
        } else {
          setSelectedSeats((selectedSeats) => [...selectedSeats, seat.code]);
          //seatMatrixClone[colIndex][rowIndex]['checked']=true;
          pax["ssrSeatInfos"] = [
            {
              key: segmentId,
              code: seat.code,
              seatNo: seat.seatNo,
              amount: seat.amount,
              deparrCode: trip,
              ride: type,
            },
          ];
        }
        return pax;
      } else {
        return pax;
      }
    });
    //setSeatMatrix([...seatMatrixClone])
    setTempPaxInfo([...updatedPaxInfo]);
    saveSelection();
  };

  const saveSelection = () => {
    let updatedTravellerInfo = [];

    for (let i = 0; i < travellerInfo.length; i++) {
      let obj = { ...travellerInfo[i] };

      if (travellerInfo[i].type !== "INFANT") {
        if (tempPaxInfo[i].hasOwnProperty("ssrSeatInfos")) {
          if (
            tempPaxInfo[i].ssrSeatInfos.filter((z) => z.key === segmentId)
              .length > 0
          ) {
            let temp = tempPaxInfo[i].ssrSeatInfos.filter(
              (z) => z.key === segmentId
            );
            if (obj.hasOwnProperty("ssrSeatInfos")) {
              obj["ssrSeatInfos"] = obj["ssrSeatInfos"].filter(
                (y) => y.key !== segmentId
              );
              obj["ssrSeatInfos"] = [...obj["ssrSeatInfos"], ...temp];
            } else obj["ssrSeatInfos"] = temp;
          } else {
            obj["ssrSeatInfos"] = obj["ssrSeatInfos"].filter(
              (y) => y.key !== segmentId
            );
          }
        }
      }

      updatedTravellerInfo.push(obj);
    }

    // console.log(JSON.stringify(updatedTravellerInfo) + "billlllllllllll");

    setTravellerInfo([...updatedTravellerInfo]);
  };
  useEffect(() => {
    if (!sData.hasOwnProperty("nt")) {
      let currSSR = [];

      let summaryAmount = 0;
      let summarySeat = 0;
      tempPaxInfo
        .filter((x) => x !== "INFANT")[0]
        ?.ssrSeatInfos?.map((y) => {
          if (y.key === segmentId) {
            summaryAmount = summaryAmount + y.amount;
            summarySeat = summarySeat + 1;
            currSSR.push(y);
          }
          return null;
        });
      setSummary({
        seatCount: summarySeat,
        totalAmount: summaryAmount,
      });

      let seatSummary = [];
      tempPaxInfo.forEach((x, xIndex) => {
        let obj = {};
        if (x.type !== "INFANT") {
          obj.fN = x.fN;
          obj.lN = x.lN;
          obj.seat =
            xIndex <= currSSR.length - 1 ? currSSR[xIndex].seatNo : "--";
          obj.amount =
            xIndex <= currSSR.length - 1 ? currSSR[xIndex].amount : "--";
        } else {
          obj.fN = x.fN;
          obj.lN = x.lN;
          obj.seat = "--";
          obj.amount = 0;
        }
        seatSummary.push(obj);
      });

      setPaxSeatSummary([...seatSummary]);
    } else {
      setDisplayCard(false);
    }
  }, [tempPaxInfo]);

  if (displayCard)
    return (
      <>
        <div className="Flight-custom-modal-bg position-relative pt-0 p-lg-4 p-3">
          <div className="mb-3">
            <ul className="d-flex ps-0 justify-content-center Flight-list-unstyled Flight-font12 text-center">
              <li className="px-3">
                <Image src={renderImage("available.svg")} alt="avail" />
                <div>Available</div>
              </li>
              <li className="px-3">
                <Image src={renderImage("occupied.svg")} alt="occp" />
                <div>Occupied</div>
              </li>
              <li className="px-3">
                <Image src={renderImage("selected.svg")} alt="slct" />
                <div>Selected</div>
              </li>
              <li className="px-3">
                <Image src={renderImage("free.svg")} alt="ffree" />
                <div>Free</div>
              </li>
            </ul>
          </div>
          <div className="Flight-table-div Flight-addon-scroll">
            <table className="mx-auto Flight-seat-lout">
              {colLetters.filter((x) => x == "").length > 1 ? ( // if true then render international JSX else render domestic JSX
                <tbody>
                  <tr>
                    <td />
                    {seatNos.map((_elem, index) => {
                      return (
                        <td
                          key={"sdata" + _elem}
                          className="pe-1 writing-mode-hori Flight-font14 Flight-font-semibold text-center"
                        >
                          {_elem}
                        </td>
                      );
                    })}
                  </tr>
                  {seatMatrix.map((colElem, colIndex) => {
                    let currentSeatAlpha = colLetters[colIndex];
                    if (currentSeatAlpha !== "")
                      return (
                        <tr key={"seatMat" + colIndex}>
                          <td>{currentSeatAlpha}</td>
                          {colElem.map((rowElem, rowIndex) => {
                            if (rowElem.hasOwnProperty("isBooked")) {
                              if (rowElem?.isBooked)
                                return (
                                  <td
                                    className="p-2"
                                    key={colIndex + "seatMat" + rowIndex}
                                  >
                                    <Image
                                      src={renderImage(
                                        rowElem.isBooked
                                          ? "occupied.svg"
                                          : rowElem.amount === 0
                                          ? "free.svg"
                                          : "available.svg"
                                      )}
                                      alt="free"
                                    />
                                  </td>
                                );
                              else
                                return (
                                  <OverlayTrigger
                                    key={rowIndex}
                                    placement={"top"}
                                    overlay={
                                      <Tooltip id={`tooltip-${rowIndex}`}>
                                        {`₹${rowElem?.amount}`}
                                      </Tooltip>
                                    }
                                  >
                                    <td
                                      className="p-2"
                                      onClick={() =>
                                        seatSelectHandler(
                                          rowElem,
                                          colIndex,
                                          rowIndex
                                        )
                                      }
                                    >
                                      <Image
                                        src={renderImage(
                                          selectedSeats.includes(
                                            `${rowElem.code}`
                                          )
                                            ? "selected.svg"
                                            : rowElem.isBooked
                                            ? "occupied.svg"
                                            : rowElem.amount === 0
                                            ? "free.svg"
                                            : "available.svg"
                                        )}
                                        alt="selected"
                                      />
                                    </td>
                                  </OverlayTrigger>
                                );
                            } else {
                              return (
                                <td
                                  className="p-2"
                                  key={colIndex + "seatMat" + rowIndex}
                                >
                                  <Image
                                    src={renderImage("nullSeat.svg")}
                                    alt="free"
                                  />
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    else
                      return (
                        <tr>
                          <td>
                            <span>&nbsp;&nbsp;</span>
                          </td>
                          {colElem.map((rowElem, rowIndex) => {
                            return (
                              <td
                                className="pe-1"
                                key={colIndex + "seatmat" + rowIndex}
                              >
                                <Image
                                  alt="occupied"
                                  src={renderImage(
                                    rowElem.isBooked
                                      ? "occupied.svg"
                                      : rowElem.amount === 0
                                      ? "free.svg"
                                      : "available.svg"
                                  )}
                                />
                              </td>
                            );
                          })}
                        </tr>
                      );
                  })}
                </tbody>
              ) : (
                <tbody>
                  {seatMatrix.map((colElem, colIndex) => {
                    let currentSeatAlpha = colLetters[colIndex];
                    if (currentSeatAlpha !== "")
                      return (
                        <tr key={"seatMat" + colIndex}>
                          <td>{currentSeatAlpha}</td>
                          {colElem.map((rowElem, rowIndex) => {
                            if (rowElem.hasOwnProperty("isBooked")) {
                              if (rowElem?.isBooked)
                                return (
                                  <td
                                    className="p-2"
                                    key={colIndex + "seatMat" + rowIndex}
                                  >
                                    <Image
                                      src={renderImage(
                                        rowElem.isBooked
                                          ? "occupied.svg"
                                          : rowElem.amount === 0
                                          ? "free.svg"
                                          : "available.svg"
                                      )}
                                      alt="free"
                                    />
                                  </td>
                                );
                              else
                                return (
                                  <OverlayTrigger
                                    key={rowIndex}
                                    placement={"top"}
                                    overlay={
                                      <Tooltip id={`tooltip-${rowIndex}`}>
                                        {`₹${rowElem?.amount}`}
                                      </Tooltip>
                                    }
                                  >
                                    <td
                                      className="p-2"
                                      onClick={() =>
                                        seatSelectHandler(
                                          rowElem,
                                          colIndex,
                                          rowIndex
                                        )
                                      }
                                    >
                                      <Image
                                        src={renderImage(
                                          selectedSeats.includes(
                                            `${rowElem.code}`
                                          )
                                            ? "selected.svg"
                                            : rowElem.isBooked
                                            ? "occupied.svg"
                                            : rowElem.amount === 0
                                            ? "free.svg"
                                            : "available.svg"
                                        )}
                                        alt="selected"
                                      />
                                    </td>
                                  </OverlayTrigger>
                                );
                            } else {
                              return (
                                <td
                                  className="p-2"
                                  key={colIndex + "seatMat" + rowIndex}
                                >
                                  <Image
                                    src={renderImage("nullSeat.svg")}
                                    alt="free"
                                  />
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    else
                      return (
                        <tr>
                          <td className="pe-3" />
                          {seatNos.map((_elem, index) => {
                            return (
                              <td
                                key={"sdata" + _elem}
                                className="pe-1 writing-mode-hori Flight-font14 Flight-font-semibold text-center"
                              >
                                {_elem}
                              </td>
                            );
                          })}
                        </tr>
                      );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div className="Flight-border-dashed py-3 px-2">
          <Table borderless className="Flight-font14 Flight-trav-list mb-0">
            <tbody>
              <tr>
                <th>Traveller</th>
                <th>Seat</th>
                <th>Amount</th>
              </tr>
              {paxSeatSummary.map((elem, index) => {
                return (
                  <tr className="Flight-font12" key={"paxsum" + index}>
                    <td>{`${elem.fN} ${elem.lN}`}</td>
                    <td>{elem.seat}</td>
                    <td>{`₹${elem.amount}`}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className=" d-flex justify-content-between align-items-center pt-3 px-3">
          <div className="Flight-font14 Flight-font-semibold">
            <div>{`₹${summary.totalAmount}`} </div>
            <div className="Flight-txt-gray Flight-font13">{`Seat(s): ${summary.seatCount}`}</div>
          </div>
          <div className="Flight-font13">
            <Button
              onClick={() => closeModal(null)}
              className="btn Flight-btn-purple Flight-sm-btn Flight-font14"
            >
              Done
            </Button>
          </div>
        </div>
      </>
    );
  else
    return (
      <>
        <div className="card  border-0">
          <div className="card-body">
            <blockquote className="blockquote mb-0">
              <p className="Flight-font16 text-capitalize Flight-font-semibold text-center pt-3">
                This Flight doesn't Provide the option to choose the seats.
              </p>
            </blockquote>
          </div>
        </div>
      </>
    );
};

export default SeatLayout;
