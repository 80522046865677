import React from "react";
import ResultCard from "./ResultCard";

const ResultList = React.memo(
  ({ data = [], path, ...rest }) => {
    const { 
        onSelectCard, 
        selectedCard, 
        passengers, 
        onFareChange, 
        showFareDetail,
        onChangeShowFareDetail
    } = rest;
    return (
      <div className="Flight-search-rslt">
        {data.map((flightData, ind) => (
          <ResultCard
            key={ind}
            flightResult={flightData}
            path={path}
            passengers={passengers}
            selected={selectedCard}
            onSelect={onSelectCard}
            onFareChange={onFareChange}
            showFareDetail={showFareDetail}
            onChangeShowFareDetail={onChangeShowFareDetail}
          />
        ))}
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.selectedCard === nextProps.selectedCard &&
    prevProps.showFareDetail === nextProps.showFareDetail
);

export default ResultList;
