import React from "react";
import { useLocation } from "react-router-dom";
import { renderImage } from '../../../../commons/util/flightUtil';
import Image from "../../elements/input/image";

const sortOptions = [
  { label: "AIRLINES", value: "name", extraClass: "air-div" },
  { label: "DEPARTURE", value: "dt", extraClass: "dep-div" },
  { label: "DURATION", value: "duration", extraClass: "dur-div" },
  { label: "ARRIVAL", value: "at", extraClass: "arr-div" },
  { label: "PRICE", value: "TF", extraClass: "price-div" },
];

const SortWeb = ({ sortColumn: { path, order }, onSort }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`d-none g-0 d-lg-grid Flight-font13 Flight-font-semibold mb-2 ${
        pathname === "/FlightList/"
          ? "Flight-oneway"
          : pathname === "/FlightListRT/"
          ? "Flight-twoway"
          : "Flight-int"
      }`}
    >
      {sortOptions.map(({ label, value, extraClass = "" }) => (
        <div className={extraClass} key={value}>
          <div
            onClick={() => onSort(value)}
            className={`Flight-sorting Flight-cursor-pointer ${
              path === value ? "sorted" : ""
            }`}
          >
            {label}
            {path === value && (
              <Image
                alt='sorticon'
                className={`rotate ${order === "asc" ? "rotate180" : ""}`}
                src={renderImage("sorting-icon.svg")}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SortWeb;
