import _ from "lodash";

const filterFlight = (flights, filterState) => {
  let { priceRange, stops, aircraft, departureTime, arrivalTime } = filterState;

  if (stops[0] || stops[1] || stops[2]) {
    flights = _.filter(flights, (flight) => {
      const stop = flight.sI.length - 1;
      return (
        (stops[0] && stop === 0) ||
        (stops[1] && stop === 1) ||
        (stops[2] && stop >= 2)
      );
    });
  }
  if (aircraft.size > 0) {
    flights = _.filter(flights, (flight) => {
      const {
        fD: {
          aI: { name },
        },
      } = flight.sI[0];
      return aircraft.has(name);
    });
  }
  flights = _.filter(flights, (flight) => {
    return flight.totalPriceList.some((fare) => {
      const {
        fd: {
          ADULT: {
            fC: { TF },
          },
        },
      } = fare;
      return priceRange.min <= TF && TF <= priceRange.max;
    });
  });
  if (departureTime) {
    flights = _.filter(flights, (flight) => {
      const { dt } = flight.sI[0];
      let d_Time = parseInt(dt.split("T")[1].substr(0, 5).split(":")[0]);
      let [in_Time, out_Time] = departureTime.split("-");
      return d_Time >= parseInt(in_Time) && d_Time < parseInt(out_Time);
    });
  }
  if (arrivalTime) {
    flights = _.filter(flights, (flight) => {
      const { at } = flight.sI[flight.sI.length - 1];
      let a_Time = parseInt(at.split("T")[1].substr(0, 5).split(":")[0]);
      let [in_Time, out_Time] = arrivalTime.split("-");
      return parseInt(in_Time) <= a_Time && a_Time < parseInt(out_Time);
    });
  }
  return flights;
};

export default filterFlight;
