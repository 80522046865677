import _ from "lodash";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { renderImage } from "../../../../commons/util/flightUtil";
import string from "../../../util/UtilManager";
import {
  formatTimeToHour,
  getTimeFromString,
} from "../../../util/dateTimeUtil";
import numberToIndCurr, { calculateFare } from "../../../util/priceUtil";
import Button from "../../elements/button/primary";
import Image from "../../elements/input/image";
import FlightDetails from "./FlightDetails/FlightDetails";
import { getDateByFormat } from "../../../util/dateTimeUtil";
const FlightSubCard = ({ sI }) => {
  const duration = sI.reduce((acc, curr) => acc + curr.duration, 0);
  const layOverTime = sI.reduce((acc, curr) => {
    if ("cT" in curr) {
      acc += curr.cT;
    }
    return acc;
  }, 0);
  const {
    da: { city: depCity, code: depCode },
    dt,
  } = sI[0];
  const {
    aa: { city: arrCity, code: arrCode },
    at,
  } = sI[sI.length - 1];
  return (
    <div className="Flight-rt-int-flights p-3">
      <div className="Flight-airport-part d-flex justify-content-between align-items-center h-100">
        <div className="Flight-airport-name">
          <h4 className="Flight-font14 Flight-font-bold Flight-ln">
            {depCity}
          </h4>
          <h6 className="Flight-font14 Flight-font-semibold Flight-ln">
            {`${getTimeFromString(dt)} ${depCode}`}
          </h6>
          <span className="Flight-font12 mt-2 Flight-txt-gray d-block Flight-font-semibold Flight-fcls">
            {getDateByFormat(dt)}
          </span>
        </div>
        <div className="Flight-airport-progress text-center">
          {/* <div className="IATA-code Flight-font12 Flight-txt-gray">&nbsp;</div> */}
          <div className="Flight-IATA-code Flight-font11 Flight-txt-gray">
            {formatTimeToHour(duration + layOverTime)}
          </div>
          <div className="position-relative Flight-fliStopsSep">
            <p className="Flight-fliStopsSepLine Flight-flistop-points" />
            {_.range(sI.length - 1).map((i) => (
              <span className="Flight-fliStopsDisc" key={i} />
            ))}
            <span className="Flight-fliStopsDisc Flight-disc-none"></span>
          </div>
          <div className="stop Flight-font12 Flight-txt-gray">
            {sI.length > 1
              ? sI
                  .map((flg) => flg?.da?.code)
                  .filter((_flg, ind) => ind !== 0)
                  .join(`->`)
              : "0 Stop"}
          </div>
        </div>
        <div className="Flight-airport-name Flight-arrival">
          <h4 className="Flight-font14 Flight-font-bold Flight-ln">
            {arrCity}
          </h4>
          <h6 className="Flight-font14 Flight-font-semibold Flight-ln">
            {getTimeFromString(at)} {arrCode}
          </h6>
          <span className="Flight-font12 mt-2 Flight-txt-gray d-block Flight-font-semibold Flight-fcls">
            {getDateByFormat(at)}
          </span>
        </div>
      </div>
    </div>
  );
};

const ResultCardInt = React.memo(({ flightResult, ...rest }) => {
  const history = useHistory();

  const { sI, totalPriceList, selectedFare, uniqId } = flightResult;
  const {
    path,
    passengers,
    onFareChange,
    showFareDetail,
    onChangeShowFareDetail,
  } = rest;

  const isReview = useMemo(() => path?.includes("review"), [path]);
  const returnSiIndex = _.findIndex(sI, (item) => item.isRs && item.sN === 0);

  return (
    <div className="Flight-search-result Flight-search-result-int">
      <div className="Flight-search-inner">
        <div className="d-flex mb-2 justify-content-between align-items-center">
          <div className="d-flex">
            <div className="Flight-logo-sec text-left align-items-center d-flex me-4">
              <div>
                <Image
                  alt="airlinelogo"
                  src={renderImage(sI[0].fD.aI.code)}
                  className="img-fluid blur-up lazyloaded"
                />
              </div>
              <div className="ms-2">
                <span className="title">{sI[0].fD.aI.name}</span>
                <span className="Flight-font12 Flight-txt-gray ms-1 Flight-font-semibold Flight-fnum">
                  {sI[0].fD.aI.code}-{sI[0].fD.fN}
                </span>
                <span className="Flight-font12 Flight-txt-gray d-block Flight-font-semibold Flight-fcls">
                  {string.string.transform.capitalizeEachWord(
                    totalPriceList[0].fd.ADULT.cc
                  )}
                </span>
              </div>
            </div>
            <div className="Flight-logo-sec text-left d-none d-lg-flex">
              <div>
                <Image
                  alt="airlinelogo"
                  src={renderImage(sI[sI.length - 1].fD.aI.code)}
                  className="img-fluid blur-up lazyloaded"
                />
              </div>
              <div className="ms-2">
                <span className="title">{sI[sI.length - 1].fD.aI.name}</span>
                <span className="Flight-font12 Flight-txt-gray ms-1 Flight-font-semibold Flight-fnum">
                  {sI[sI.length - 1].fD.aI.code}-{sI[sI.length - 1].fD.fN}
                </span>
                <span className="Flight-font12 Flight-txt-gray d-block Flight-font-semibold Flight-fcls">
                  {string.string.transform.capitalizeEachWord(
                    totalPriceList[0].fd.ADULT.cc
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="d-md-flex justify-content-end align-items-center">
            <h4 className="Flight-font18 mb-0 text-md-left text-center Flight-font-bold Flight-ln">
              {numberToIndCurr(calculateFare(selectedFare, passengers))}
            </h4>
            {!isReview && (
              <Button
                className="ms-md-2 mt-1 mt-md-0 btn Flight-btn Flight-btn-purple Flight-phn-book-btn Flight-font-semibold"
                onClick={() =>
                  history.push({
                    pathname: "/review",
                    state: {
                      id: [selectedFare.id],
                      data: [
                        {
                          flightResult: {
                            sI,
                            totalPriceList: [selectedFare],
                            selectedFare: selectedFare,
                          },
                        },
                      ],
                      passengers,
                      isIntRd: true,
                    },
                  })
                }
              >
                Book
              </Button>
            )}
          </div>
        </div>
        <Row className="g-2 Flight-row">
          <Col lg="6">
            <FlightSubCard sI={sI.slice(0, returnSiIndex)} />
          </Col>
          <Col lg="6">
            <div className="Flight-logo-sec text-left d-flex d-lg-none mb-2">
              <div>
                <Image
                  alt="airlinelogo"
                  src={renderImage(sI[sI.length - 1].fD.aI.code)}
                  className="img-fluid blur-up lazyloaded"
                />
              </div>
              <div className="ms-2">
                <span className="title">{sI[sI.length - 1].fD.aI.name}</span>
                <span className="Flight-font12 Flight-txt-gray ms-1 Flight-font-semibold Flight-fnum">
                  {sI[sI.length - 1].fD.aI.code}-{sI[sI.length - 1].fD.fN}
                </span>
                <span className="Flight-font12 Flight-txt-gray d-block Flight-font-semibold Flight-fcls">
                  {string.string.transform.capitalizeEachWord(
                    totalPriceList[0].fd.ADULT.cc
                  )}
                </span>
              </div>
            </div>
            <FlightSubCard sI={sI.slice(returnSiIndex)} />
          </Col>
        </Row>
        <div className="bookFlightDetail  align-items-center justify-content-between mt-3">
          <ul className="d-md-flex Flight-font12 Flight-btn Flight-btn-detail mb-0 ps-0 Flight-font-semibold">
            <li>
              <span className="Flight-txt-gray">Fare Type &nbsp;</span>
              <span className="Flight-regulartype rounded-pill Flight-font12 px-md-3 px-2 ms-md-0">
                {string.string.transform.capitalizeEachWord(
                  selectedFare.fareIdentifier.replace(/_/g, " ")
                )}
              </span>
            </li>
          </ul>
          <div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                onChangeShowFareDetail(uniqId);
              }}
              className={`Flight-cursor-pointer Flight-theme-txt Flight-show-detail ${
                !(showFareDetail === uniqId) ? "collapsed" : ""
              } Flight-font14 Flight-font-semibold position-relative Flight-viewdetail-arrow`}
            >
              <span
                className={
                  showFareDetail === uniqId ? "Flight-hidedetail" : "showdetail"
                }
              >
                {showFareDetail === uniqId
                  ? isReview
                    ? "Hide Details"
                    : "Hide Fare & Details"
                  : isReview
                  ? "View Details"
                  : "View Fare & Details"}
              </span>
            </div>
          </div>
        </div>
        <FlightDetails
          isIntPage={true}
          show={showFareDetail}
          passengers={passengers}
          flightResult={flightResult}
          onFareChange={onFareChange}
        />
      </div>
    </div>
  );
});

const ResultListInt = React.memo(
  ({ data = [], ...rest }) => {
    const { passengers, onFareChange, showFareDetail, onChangeShowFareDetail } =
      rest;
    return (
      <>
        <div className="Flight-search-rslt">
          {data.map((flightData, ind) => (
            <ResultCardInt
              key={ind}
              flightResult={flightData}
              passengers={passengers}
              onFareChange={onFareChange}
              showFareDetail={showFareDetail}
              onChangeShowFareDetail={onChangeShowFareDetail}
            />
          ))}
        </div>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.showFareDetail === nextProps.showFareDetail
);

export { ResultCardInt, ResultListInt };
