import React, { lazy, Suspense } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import BaggageTab from "./BaggageTab";
import DetailTab from "./DetailTab";
import FareList from "./FareList";
import { InfoTab, IntInfoTab } from "./InfoTab";

const Lazy_CancellationTab = lazy(() => import("./CancellationTab"));

const FlightDetails = ({
  show,
  passengers,
  onFareChange,
  flightResult,
  selectedFare,
  ...rest
}) => {
  const { pathname } = useLocation();
  const { classMob, isIntPage } = rest;
  const showFareList = pathname.includes("review");
  const isIntNatPage = pathname.includes("FlightListRTInt") || isIntPage;
  if (show === flightResult.uniqId || selectedFare?.id)
    return (
      <div
        className={`${classMob ? `` : "collapse"} ${
          show === flightResult.uniqId ? "show" : ""
        }`}
      >
        {!showFareList && (
          <>
            <FareList
              index={flightResult.index}
              fares={flightResult.totalPriceList}
              selectedFare={flightResult.selectedFare}
              onChange={onFareChange}
            />
            <p className="Flight-font-semibold bookFlightDetail m-0 pb-0 Flight-font14">
              Details
            </p>
          </>
        )}
        <div className="Flight-detail-wrap border-top-0">
          <Accordion className="my-2 d-md-none d-block">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Flight Information</Accordion.Header>
              <Accordion.Body className="pt-0 pb-2 px-2">
                {isIntNatPage ? (
                  <IntInfoTab data={flightResult} />
                ) : (
                  <InfoTab data={flightResult} />
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Fare Details</Accordion.Header>
              <Accordion.Body className="pt-0 pb-2 px-2">
                <DetailTab
                  data={flightResult.selectedFare}
                  passengers={passengers}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Baggage Rules</Accordion.Header>
              <Accordion.Body className="pt-0 pb-2 px-2">
                <BaggageTab
                  data={flightResult.sI}
                  selectedPriceList={flightResult.selectedFare}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Cancellation Rules</Accordion.Header>
              <Accordion.Body className="pt-0 pb-2 px-2">
                <Suspense fallback="Loading...">
                  <Lazy_CancellationTab
                    infoData={flightResult.sI}
                    selectedPriceList={
                      flightResult.selectedFare
                        ? flightResult.selectedFare
                        : selectedFare?.id
                    }
                    priceId={selectedFare?.id ? selectedFare?.id : null}
                  />
                </Suspense>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="flight-fd-inner d-md-block d-none">
            <Tabs
              id="mydetailTab1"
              className="nav Flight-nav-tabs border-0 Flight-font13"
            >
              <Tab
                id="FlightInformation-tab1"
                eventKey="information"
                title="Flight Information"
              >
                {isIntNatPage ? (
                  <IntInfoTab data={flightResult} />
                ) : (
                  <InfoTab data={flightResult} />
                )}
              </Tab>
              <Tab id="FareDetails-tab1" eventKey="detail" title="Fare Details">
                <DetailTab
                  data={flightResult.selectedFare}
                  passengers={passengers}
                />
              </Tab>
              <Tab
                id="BaggageRules-tab1"
                eventKey="baggage"
                title="Baggage Rules"
              >
                <BaggageTab
                  data={flightResult.sI}
                  selectedPriceList={flightResult.selectedFare}
                />
              </Tab>
              <Tab
                id="CancellationRules-tab1"
                eventKey="cancellation"
                title="Cancellation Rules"
              >
                <Suspense fallback="Loading...">
                  <Lazy_CancellationTab
                    infoData={flightResult.sI}
                    selectedPriceList={
                      flightResult.selectedFare
                        ? flightResult.selectedFare
                        : selectedFare?.id
                    }
                    priceId={selectedFare?.id ? selectedFare?.id : null}
                  />
                </Suspense>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  else return null;
};

export default FlightDetails;