import React from "react";
import { Table } from "react-bootstrap";
import { renderImage } from "../../../../../commons/util/flightUtil";
import Image from "../../../elements/input/image";

const BaggageCard = ({ data, selectedPriceList }) => {
  const {
    aa: { city: aaCity },
    da: { city: daCity },
    fD: {
      fN,
      aI: { name: aIName, code: aICode },
    },
    rT,
  } = data;

  const {
    fd: {
      ADULT: {
        bI: { iB: checkInBag, cB: cabinBag },
      },
    },
  } = selectedPriceList;
  return (
    <div className="mt-md-3 mt-1">
      <div className="Flight-logo-sec text-left d-flex align-items-center mb-2">
        <Image
          alt="airlinelogo"
          src={renderImage(aICode)}
          className="img-fluid blur-up lazyloaded"
        />
        <div className="ms-2">
          <span className="title">{aIName}</span>
          <span className="Flight-font12 Flight-txt-gray ms-1">
            {aICode}-{fN}
          </span>
        </div>
      </div>
      <div>
        {rT && (
          <span className="ref-tag rounded-pill px-3 py-1 Flight-font10 text-white">
            {rT === 1 ? "Refundable" : "Partial Refundable"}
          </span>
        )}
      </div>
      <div className="border-tbl">
        <Table
          borderless
          responsive
          className="mb-0 Flight-font13 Flight-font-medium bg-white Flight-fb-table Flight-baggage-table"
        >
          <tbody>
            <tr className="roboto-medium">
              <td>Sector</td>
              <td>Airline</td>
              <td>Cabin</td>
              <td>Check-in</td>
            </tr>
            <tr className="roboto-regular">
              <td>
                {daCity} - {aaCity}
              </td>
              <td>
                {aICode} - {fN}
              </td>
              <td>{cabinBag || "N/A"}</td>
              <td>{checkInBag || "N/A"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const BaggageTab = ({ data, selectedPriceList }) => {
  return (
    <div className="tab-pane">
      {data.map((bagData, ind) => (
        <BaggageCard
          data={bagData}
          key={ind}
          selectedPriceList={selectedPriceList}
        />
      ))}
    </div>
  );
};

export default BaggageTab;
