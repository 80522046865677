import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Button from "../../../commons/artefacts/elements/button/primary";
import Image from "../../../commons/artefacts/elements/input/image";
import { renderImage } from "../../../commons/util/flightUtil";

const ViewBaggage = ({
  bags,
  travellerInfo,
  setTravellerInfo,
  segmentId,
  closeModal,
  trip,
  type,
}) => {
  const [tempPaxInfo, setTempPaxInfo] = useState(
    JSON.parse(JSON.stringify(travellerInfo))
  );
  const [currentBags, setCurrentBags] = useState([]);

  const [bagStats, setBagStats] = useState({
    currBag: 0,
    totalBag: 0,
  });

  const [summary, setSummary] = useState({
    totalAmount: 0,
    bagCount: 0,
  });
  const [paxBagSummary, setPaxBagSummary] = useState([]);

  const [displayCard, setDisplayCard] = useState(true);

  useEffect(() => {
    if (bags.length > 0) {
      //count the travellers with SSR
      let ssrBagTravellers = travellerInfo.filter(
        (x) => x.hasOwnProperty("ssrBaggageInfos") && x.type !== "INFANT"
      );
      // if SSR traveller found
      if (ssrBagTravellers.length > 0) {
        let ssrBagTraveller = ssrBagTravellers[0]; //get the 1st SS3 traveller
        //logic to find the SSR bags already existing or selected and store its code in selectedBags array
        let selectedBags = [];
        ssrBagTraveller?.ssrBaggageInfos.forEach((x) => {
          if (x.key === segmentId) {
            selectedBags.push(x.code);
          }
        });
        //logic to find the selected keys inisde bags array and increment their taken key by 1;
        let bagArray = bags.map((x) => {
          if (selectedBags.filter((y) => y === x.code).length) {
            x.taken = selectedBags.filter((y) => y === x.code).length;
          } else {
            x.taken = 0;
          }
          return x;
        });

        setCurrentBags([...bagArray]); // store the bags with updated taken key in currentBags;
      } else {
        // if SSR traveller NOT found
        let bagArray = bags.map((x) => {
          //make copy of bags with all bags having key taken as 0
          x.taken = 0;

          return x;
        });
        setCurrentBags([...bagArray]); // store the bags with taken 0 in currentBags;
      }
    } else {
      setDisplayCard(false);
    }
  }, []);

  useEffect(() => {
    if (bags.length > 0) {
      // LOGIC TO GENERATE SUMMARY DATA FOR SEATS CARD

      let currSSR = [];

      let summaryAmount = 0;
      let summaryBag = 0;
      tempPaxInfo
        .filter((x) => x !== "INFANT")[0]
        ?.ssrBaggageInfos?.map((y) => {
          if (y.key === segmentId) {
            summaryAmount = summaryAmount + y.amount;
            summaryBag = summaryBag + 1;
            currSSR.push(y);
          }
          return null;
        });
      setSummary({
        bagCount: summaryBag,
        totalAmount: summaryAmount,
      });

      let bagSummary = [];
      tempPaxInfo.forEach((x, xIndex) => {
        let obj = {};
        if (x.type !== "INFANT") {
          obj.fN = x.fN;
          obj.lN = x.lN;
          obj.bag = xIndex <= currSSR.length - 1 ? currSSR[xIndex].desc : "--";
          obj.amount =
            xIndex <= currSSR.length - 1 ? currSSR[xIndex].amount : "0";
        } else {
          obj.fN = x.fN;
          obj.lN = x.lN;
          obj.bag = "--";
          obj.amount = 0;
        }
        bagSummary.push(obj);
      });

      setPaxBagSummary([...bagSummary]);
    } else {
      setDisplayCard(false);
    }
  }, [tempPaxInfo]);

  useEffect(() => {
    if (bags.length > 0) {
      // logic for generating the bagStats everytime any bag is selected so that  + or - button can be enabled or disabled
      let totalBagCount = 0;
      currentBags.forEach((x) => {
        totalBagCount = totalBagCount + x.taken;
      });
      let eligibePaxCount = tempPaxInfo.filter(
        (f) => f.type !== "INFANT"
      ).length;

      setBagStats({
        currBag: totalBagCount,
        totalBag: eligibePaxCount,
      });
    } else {
      setDisplayCard(false);
    }
  }, [currentBags]);

  const bagSelectHandler = (bag, action) => {
    if (action === "plus") {
      //logic to add the SSR in local tempPaxInfo State
      let t = tempPaxInfo.map((x) => {
        if (x.type !== "INFANT") {
          if (x.hasOwnProperty("ssrBaggageInfos")) {
            x.ssrBaggageInfos.push({
              key: segmentId,
              code: bag.code,
              amount: bag.amount ? bag.amount : 0,
              desc: bag.desc,
              deparrCode: trip,
              ride: type,
            });
          } else {
            let ssrBags = [];
            ssrBags.push({
              key: segmentId,
              code: bag.code,
              amount: bag.amount ? bag.amount : 0,
              desc: bag.desc,
              deparrCode: trip,
              ride: type,
            });
            x.ssrBaggageInfos = ssrBags;
          }

          return x;
        } else {
          return x;
        }
      });
      setTempPaxInfo([...t]);

      //logic toupdate the currentBags state with incremented taken
      let b = currentBags.map((x) => {
        if (x.code === bag.code) x.taken = x.taken + 1;

        return x;
      });
      setCurrentBags([...b]);
    } else if (action === "minus") {
      //logic to delete from tempPaxInfo State
      let t = tempPaxInfo.map((x) => {
        if (x.type !== "INFANT") {
          let ssrBag = [];
          let deletedOne = false;

          for (let i = 0; i < x.ssrBaggageInfos.length; i++) {
            if (
              x.ssrBaggageInfos[i].code === bag.code &&
              x.ssrBaggageInfos[i].key === segmentId &&
              deletedOne === false
            ) {
              deletedOne = true;
            } else {
              ssrBag.push(x.ssrBaggageInfos[i]);
            }
          }
          x.ssrBaggageInfos = ssrBag;
          return x;
        } else {
          return x;
        }
      });
      setTempPaxInfo([...t]);

      //logic to update the currentBags state with decremented taken key
      let b = currentBags.map((x) => {
        if (x.code === bag.code) x.taken = x.taken - 1;

        return x;
      });
      setCurrentBags([...b]);
    }

    saveSelection();
  };

  const saveSelection = () => {
    let updatedTravellerInfo = [];

    for (let i = 0; i < travellerInfo.length; i++) {
      let obj = { ...travellerInfo[i] };

      if (travellerInfo[i].type !== "INFANT") {
        if (tempPaxInfo[i].hasOwnProperty("ssrBaggageInfos")) {
          if (
            tempPaxInfo[i].ssrBaggageInfos.filter((z) => z.key === segmentId)
              .length > 0
          ) {
            let temp = tempPaxInfo[i].ssrBaggageInfos.filter(
              (z) => z.key === segmentId
            );
            if (obj.hasOwnProperty("ssrBaggageInfos")) {
              obj["ssrBaggageInfos"] = obj["ssrBaggageInfos"]?.filter(
                (y) => y.key !== segmentId
              );
              obj["ssrBaggageInfos"] = [...obj["ssrBaggageInfos"], ...temp];
            } else obj["ssrBaggageInfos"] = temp;
          } else {
            obj["ssrBaggageInfos"] = obj["ssrBaggageInfos"]?.filter(
              (y) => y.key !== segmentId
            );
          }
        }
      }

      updatedTravellerInfo.push(obj);
    }

    setTravellerInfo([...updatedTravellerInfo]);
  };
  if (displayCard)
    return (
      <>
        <div className="circle-crop position-relative">
          <ul className="Flight-addon-scroll px-3 mb-3">
            {currentBags.map((bag, mealIndex) => {
              return (
                <li className="d-flex justify-content-between py-2">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <Image
                        alt="baggage"
                        src={renderImage("search-result/baggage-icon.svg")}
                      />
                    </div>
                    <div className="Flight-font14 Flight-font-semibold">
                      {bag.desc}
                      <div className="Flight-font13 me-3">{`₹${Number(
                        bag.amount ? bag.amount : 0
                      )}`}</div>
                    </div>
                  </div>
                  <div>
                    <div className="input-group ms-2 w-auto addon-count">
                      <div
                        className={
                          bag.taken
                            ? "Flight-pax-calc rounded Flight-val-plus"
                            : "Flight-pax-calc rounded"
                        }
                      >
                        <Button
                          className="btn adult-quantity-left-minus border-right border-0"
                          data-type="minus"
                          disabled={bag.taken === 0 ? true : false}
                          onClick={() => bagSelectHandler(bag, "minus")}
                        >
                          -
                        </Button>
                        <span className="Flight-pax-num">{bag.taken}</span>
                        <Button
                          className="btn quantity-right-plus border-0"
                          data-type="plus"
                          disabled={
                            bagStats.currBag === bagStats.totalBag
                              ? true
                              : false
                          }
                          onClick={() => bagSelectHandler(bag, "plus")}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="Flight-border-dashed  py-3 px-2">
          <Table borderless className="Flight-font14 Flight-trav-list mb-0">
            <tbody>
              <tr>
                <th>Traveller</th>
                <th>Bag</th>
                <th>Amount</th>
              </tr>
              {paxBagSummary.map((elem, index) => {
                return (
                  <tr className="Flight-font12">
                    <td>{`${elem.fN} ${elem.lN}`}</td>
                    <td>{elem.bag}</td>
                    <td>{`₹${elem.amount ? elem.amount : 0}`}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className=" d-flex justify-content-between align-items-center pt-3 px-3">
          <div className=" Flight-font14 Flight-font-semibold">
            <div>{`₹${summary.totalAmount}`} </div>
            <div className="Flight-txt-gray  Flight-font13">{`Bags: ${summary.bagCount}`}</div>
          </div>
          <div className="Flight-font13">
            <Button
              onClick={() => closeModal(null)}
              className="btn Flight-btn-purple Flight-sm-btn Flight-font14"
            >
              Done
            </Button>
          </div>
        </div>
      </>
    );
  else
    return (
      <>
        <div className="card  border-0">
          <div className="card-body">
            <blockquote className="blockquote mb-0">
              <p className="Flight-font16 text-capitalize Flight-font-semibold text-center pt-3">
                This Flight doesn't Provide Baggages add-on
              </p>
            </blockquote>
          </div>
        </div>
      </>
    );
};

export default ViewBaggage;
