import React, { useContext, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Button from "../../../../commons/artefacts/elements/button/primary";
import { DataContext } from "../../../../commons/context/dataContext";

import { renderImage } from "../../../../commons/util/flightUtil";
import VendorCharges, {
  PassengerCounter,
} from "../../../../commons/util/helper";
import {
  getRefundByBookingId,
  submitCancellationByBookingId,
} from "../../../services/orderCancellationService";
import Image from "./../../../../commons/artefacts/elements/input/image";
import RadioInput from "./../../../../commons/artefacts/elements/input/radioInput";
import ConfirmationModalBody from "./ConfirmationModalBody";
import RefundModalBody from "./RefundModalBody";
import RequestModalBody from "./RequestModalBody";

const remarkOption = [
  {
    id: 1,
    value: 0,
    label: "I want to cancel my flight (Normal Cancellation)",
  },
  {
    id: 2,
    value: 1,
    label:
      "I have already cancelled my tickets from airline (Already Cancelled from Airline)",
  },
  { id: 3, value: 2, label: "I miss my flight (No Show)" },
  {
    id: 4,
    value: 3,
    label: "My flight was cancelled by the airline (Full Refund)",
  },
];

const Cancellation = (data) => {
  const {
    bookingDetails: {
      bookingId,

      paxInfo,
      isOneWay,

      isDomestic,
      displayAmount,
      convFee,
    },
    _id: fbId,
  } = data?.data;

  const history = useHistory();

  const [show, setShow] = useState(false);
  const { convenienceFee } = useContext(DataContext);
  const [remark, setRemark] = useState(remarkOption[0]);
  const [actionType, setActionType] = useState("");
  const [refundAmountData, setRefundAmountData] = useState([]);
  const [submitAction, setSubmitAction] = useState("");
  const [loader, setLoader] = useState(false);

  const getCancellationAmount = (action) => {
    setShow(true);
    setLoader(true);
    getRefundByBookingId(bookingId, action, fbId)
      .then((response) => {
        if (response.type === "failure") {
          setShow(false);

          // Show a window alert with code and message
          alert(`Error: ${response.status} - ${response.message?.errorMsg}`);
        } else {
          setRefundAmountData(response.data?.trips);
        }
      })
      .catch((error) => {
        setShow(false);
      })
      .finally(() => setLoader(false));
  };

  const getVendorCharges = useMemo(() => {
    return new VendorCharges(isDomestic, isOneWay, convenienceFee);
  }, [convenienceFee, isDomestic, isOneWay]);

  const passengerCountObj = useMemo(() => {
    return new PassengerCounter(paxInfo).passengers;
  }, [paxInfo]);

  const submitCancellation = (action) => {
    setActionType("Submit");
    submitCancellationByBookingId(bookingId, action, remark.label, fbId)
      .then(({ data }) => {
        if (!data) throw Error;
        window.location.reload(); 
        setShow(false);
        history.push("/dashboard");
         setActionType("");
         window.location.reload(); 
      })
      .catch(({ status }) => {
        setShow(false);

        window.alert(
          `Status Code: ${
            status || 500
          }\nMessage: ${"Something went wrong. Please contact support for assistance."}`
        );
      })
      .finally(() => setLoader(false));
  };

  const handleClose = () => {
    setActionType("");
    setSubmitAction("");
    setRefundAmountData([]);
    setShow(false);
  };

  return (
    <div>
      <Modal.Header closeButton className="border-bottom-0 pb-0">
        <Modal.Title>
          {actionType === "Submit" ? null : (
            <h4 className="Flight-font16 Flight-font-bold Flight-ln mb-0">
              {/* <Image
                className="me-2"
                src={renderImage(
                  !show ? "cancel-flight.svg" : "cancel-flight-icon.svg"
                )}
                alt="cancel flight"
              /> */}
              {!show ? "Cancellation Mode" : "Cancellation Charges Details"}
            </h4>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 pb-0">
        {!show ? (
          <div className="bookFlightDetail d-block pb-0">
            <div className="px-3 pb-3">
              {remarkOption.map((remarkOp) => (
                <div
                  className="form-check mb-1"
                  key={"remark" + remarkOp.value}
                >
                  <RadioInput
                    name="cabindata"
                    className="form-check-input radio_animated"
                    id={`cancel-reason-${remarkOp.value}`}
                    onChange={() => setRemark(remarkOption[remarkOp.value])}
                    checked={remark.value === remarkOp.value}
                  />
                  <label
                    className="form-check-label Flight-font-semibold Flight-font13"
                    htmlFor={`cancel-reason-${remarkOp.value}`}
                  >
                    {remarkOp.label}
                  </label>
                </div>
              ))}

              <div className="mt-3">
                <Button
                  variant="primary"
                  name="createModal"
                  className="Flight-btn-purple btn rounded-pill Flight-font13 me-2 Flight-font-semibold"
                  onClick={() => {
                    setActionType("Refund");
                    setSubmitAction("Complete");
                    getCancellationAmount("Complete");
                    setShow(true);
                  }}
                >
                  Cancel Booking
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {loader ? (
              <div>
                <div className="Flight-fd-table">
                  <div className="Flight-loaderGIF">
                    <Image
                      src={renderImage("loader.gif")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            ) : actionType === "Refund" ? (
              <>
                <RefundModalBody
                  data={refundAmountData}
                  passengers={passengerCountObj}
                  vendorCharges={getVendorCharges.getCancellationCharges}
                  bookingConv={convFee}
                  displayAmount={displayAmount}
                />
                <ConfirmationModalBody />
              </>
            ) : actionType === "Submit" ? (
              <RequestModalBody />
            ) : null}

            {actionType !== "Submit" && !loader && actionType !== "Partial" && (
              <Modal.Footer className="border-top-0 px-0 pb-0">
                <Button
                  className="btn Flight-font13 Flight-btn Flight-font-semibold btn-secondary rounded-pill"
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  className="Flight-btn-purple Flight-btn btn Flight-font13 Flight-font-semibold rounded-pill"
                  disabled={loader}
                  onClick={() => {
                    submitCancellation(submitAction);
                  }}
                >
                  Yes
                </Button>
              </Modal.Footer>
            )}
          </div>
        )}
      </Modal.Body>
    </div>
  );
};

export default Cancellation;
