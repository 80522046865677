import React from "react";
import { Card } from "react-bootstrap";
import Image from "../../elements/input/image";
import { Col } from "react-bootstrap";
import Button from "../../elements/button/primary";
import { renderImage } from "../../../util/flightUtil";

const NotFilteredData = ({ onClear }) => {
  return (
    <Col xl={{ span: 6, offset: 3 }}>
      <div className="Flight-search-inner">
        <Card className="text-center border-0 box-shadow-none">
          <Card.Body>
            <Image alt="calender" src={renderImage("many-fliter.svg")} />
            <Card.Title className="mt-3">Too Many Filters Applied!</Card.Title>
            <p className="Flight-font14">
              Looks like you have applied too many filters, Please clear some to
              get better search results
            </p>
            <Button
              className="btn Flight-btn Flight-btn-purple font20 text-white border-0"
              onClick={onClear}
            >
              Clear Filters
            </Button>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default NotFilteredData;
