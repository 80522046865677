import ExternalsManager from "../../commons/externals/ExternalsManager";
import {
  GET_BOOKING,
  SUBMIT_CANCELLATION,
  GET_AMENDMENT_CHARGES,
  REQUEST_RESCHEDULE,
} from "../../constants/endpoints";

const getBookListingByUserId = async (userId,filter) => {
  let payload = { userId: userId, filter:filter,page:1 };
  let { data } = await ExternalsManager?.API.post(GET_BOOKING, payload);
  return data;
};

const getRefundByBookingId = async (id, action,fbId) => {
  let payload = { bookingId: id, action: action.toUpperCase(),fbId };
  return ExternalsManager?.API.post(GET_AMENDMENT_CHARGES, payload);
};

const submitCancellationByBookingId = async (id, action, remarks,fbId) => {
  let payload = { bookingId: id, action: action.toUpperCase(), remarks,fbId };
  return ExternalsManager.API.post(SUBMIT_CANCELLATION, payload);
};

const submitRescheduleRequest = async (
  { rescheduleData },
  orderId
) => {
  console.log();
  let payload = {
    rescheduleData
  };

  return ExternalsManager.API.post(REQUEST_RESCHEDULE, payload);
};

export {
  getBookListingByUserId,
  getRefundByBookingId,
  submitCancellationByBookingId,
  submitRescheduleRequest,
};
