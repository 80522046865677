class StringTransforms {
  static capitalizeEachWord(text) {
    if (!text) return null;
    return text
      .split(" ")
      .map((y) => {
        return (
          (y.charAt(0) || "").toUpperCase() +
          (y.substring(1) || "").toLowerCase()
        );
      })
      .join(" ");
  }

  static replaceUnderscore(text) {
    if (!text) return ''
    return text.replace('_', '')
  }

  static textToPascalCase(text) {
    if (!text) return null;
    return text
      .split(" ")
      .map((y) => {
        return (
          (y.charAt(0) || "").toUpperCase() +
          (y.substring(1) || "").toLowerCase()
        );
      })
      .join("");
  }

  // static textToCamelCase(text) {
  //   if(!text) return null;
  //   const PascalCase = StringUtils.prototype.textToPascalCase(text);
  //   return PascalCase.charAt(0).toLowerCase() + PascalCase.substring(1)
  // }

  static keyToSentence(text) {
    if (!text) return null;
    const Sentence = text.replace(/([A-Z])/g, " $1");
    return (Sentence.charAt(0).toUpperCase() + Sentence.substring(1)).trim();
  }

  // static info(text, isMuted = false) {
  //   new Noty({
  //     type: "info",
  //     text,
  //     ...notifConfig,
  //   }).show();
  //   !isMuted && PushAlert.play.sound.info();
  // }
}

export default StringTransforms;
