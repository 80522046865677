import React, { useState } from "react";
import { Container, Row, Col, Tab, Nav, Dropdown } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const isMobile = window.innerWidth < 768;
  return (
    <div className="abt-wrap">
      <Container fluid="lg">
        <h2 className="text-center my-lg-5 my-4">Frequently Asked Questions</h2>
        <Tab.Container id="left-tabs-example" defaultActiveKey="Mobile App">
          <Row className="g-4">
            <Col md={4} lg={3}>
              <div className="tc-leftbar faq-leftbar h-100 py-md-3 ps-1 rounded">
                {isMobile ? (
                  <Dropdown
                    className="mobile-faq"
                    defaultActiveKey="Mobile App"
                    onSelect={(eventKey) => setSelectedOption(eventKey)}
                  >
                    <Dropdown.Toggle
                      className="w-100 text-dark py-2 text-start text-decoration-none text-dark"
                      variant="link"
                      id="dropdown-basic"
                    >
                      {selectedOption || "Mobile App"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="Mobile App">
                        Mobile App
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="General Queries">
                        General Queries
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Baggage">Baggage</Dropdown.Item>

                      <Dropdown.Item eventKey="Booking Or Re-Scheduling Queries">
                        Booking or Re-scheduling
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Cancellation/Refund">
                        Cancellation/Refund
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Payment">Payment</Dropdown.Item>

                      <Dropdown.Item eventKey="Web Check-In">
                        Web Check-In
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Nav variant="pills" className="flex-column db-nav">
                    <Nav.Item>
                      <Nav.Link eventKey="Mobile App">Mobile App</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="General Queries">
                        General Queries
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Baggage">Baggage</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Booking Or Re-Scheduling Queries">
                        Booking or Re-scheduling
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Cancellation/Refund">
                        Cancellation/Refund{" "}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Payment">Payment</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="100% Refund Policy">
                        100% Refund Policy
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="Web Check-In">Web Check-In</Nav.Link>
                    </Nav.Item>
                  </Nav>
                )}
              </div>
            </Col>
            <Col md={8} lg={9}>
              <Tab.Content className="fad-content">
                <Tab.Pane eventKey="Mobile App">
                  <h3 className="Flight-font18 font-bold mb-3">
                    Mobile App Queries
                  </h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>What is OGenie?</Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Genie is India's first AI-based virtual travel buddy,
                        which responds to voice commands.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>
                        What services does OGenie provide?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Genie serves you with, Flight Search, Flight Booking,
                        Flight Cancellation, Flight Rescheduling, Flight Status,
                        add-ons and Web Check-in”.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>How to book a flight?</Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Try saying "Book me a flight from to with a ", select
                        your flight from the 'Best of OGenie', 'Fastest' and
                        'Cheapest' and go ahead with your preference.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="mb-2">
                      <Accordion.Header>
                        How to Cancel my flight with OGenie?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Try saying "Cancel my flight" or "Do my cancellation".
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className="mb-2">
                      <Accordion.Header>
                        How to do Web Check-in with OGenie?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Try saying "Do my web check-in" or "Web Check-in",
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className="mb-2">
                      <Accordion.Header>
                        How to do the Rescheduling with OGenie?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        For rescheduling your flight, reach us on:{" "}
                        <a
                          className="Flight-theme-txt"
                          href="mailto:support@ogenietravels.com"
                        >
                          support@ogenietravels.com
                        </a>
                        or call on{" "}
                        <a
                          className="Flight-theme-txt"
                          href="tel:+917780066666"
                        >
                          +91-7780066666
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6" className="mb-2">
                      <Accordion.Header>
                        My payment is deducted but the ticket is not booked.
                        What should I do?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        In case if your payment is deducted but ticket is not
                        booked, immediately reach us on{" "}
                        <a
                          className="Flight-theme-txt"
                          href="mailto:support@ogenietravels.com"
                        >
                          support@ogenietravels.com
                        </a>{" "}
                        or call on{" "}
                        <a
                          className="Flight-theme-txt"
                          href="tel:+917780066666"
                        >
                          +91-7780066666
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" className="mb-2">
                      <Accordion.Header>
                        There are a lot of search results. How do I find the
                        best one for me?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        You can ask Genie to show you the results according to
                        your preferences. For example: non-stop flights, morning
                        flights etc.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8" className="mb-2">
                      <Accordion.Header>
                        Why does the airline website give me a different price
                        than the one shown on OGenie?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        OGenie provides standard airline fare. If you face any
                        such issues please feel free to contact us on{" "}
                        <a
                          className="Flight-theme-txt"
                          href="mailto:support@ogenietravels.com"
                        >
                          support@ogenietravels.com
                        </a>{" "}
                        or call on{" "}
                        <a
                          className="Flight-theme-txt"
                          href="tel:+917780066666"
                        >
                          +917780066666
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="General Queries">
                  <h3 className="Flight-font18 font-bold mb-3">
                    General Queries
                  </h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>
                        How early should I arrive at the airport before my
                        flight?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        We recommend arriving 2 hours prior to the departure of
                        your domestic flights and 3 hours prior to the departure
                        for your international flights.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>
                        Can I change the Origin or Destination while
                        rescheduling through OGenie?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        All our tickets cannot be re-routed, hence sector
                        changes are not permitted.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>
                        Will I be charged a fee for changing my flight?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Yes, you will be charged a change fee for the same.
                        Airline change fee + OGenie Service Fee
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="mb-2">
                      <Accordion.Header>
                        Will I be contacted in advance if my flight is delayed,
                        preponed or canceled?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        We always try to keep our customers updated on their
                        flight status. However we strongly recommend contacting
                        airlines for the latest updates.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className="mb-2">
                      <Accordion.Header>
                        What if my flight got canceled by the airlines?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        In case your flight got canceled by airlines, you will
                        get a refund as per the airline's policy.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className="mb-2">
                      <Accordion.Header>
                        What is the definition of an Unaccompanied Minor
                        Passenger? Is a Parent allowed to accompany a child
                        during check-in?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        An unaccompanied minor is a child without the presence
                        of a legal guardian. This term is used in immigration
                        law and in airline policies. The specific definition
                        varies from country to country and from airline to
                        airline. Please check with the airline for further
                        information.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6" className="mb-2">
                      <Accordion.Header>
                        What is the age group of an infant?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        For safety reasons, Children under the age of 2 years
                        (on the date of travel) can travel as Infant.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" className="mb-2">
                      <Accordion.Header>
                        What are the Valid ID proof for Infants:
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        - Birth Certificate
                        <br />
                        - Mother's hospital discharge summary
                        <br />
                        - Vaccination certificate
                        <br />- Passport
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8" className="mb-2">
                      <Accordion.Header>
                        Do I need to book a separate seat for an Infant?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        No extra seat is required for an Infant. Infant cannot
                        travel in their own seat and must be seated on an
                        adult's lap.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9" className="mb-2">
                      <Accordion.Header>
                        I saw a lower airfare advertised today. Why can't I get
                        that airfare using OGenie?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Genie displays the real time airfare from the airlines.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10" className="mb-2">
                      <Accordion.Header>
                        What is the maximum number of seats I can book?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        A maximum of 9 seats can be booked on a flight. If you
                        need to book for more than 9 travelers you will have to
                        re-complete the booking process for the additional
                        travelers.
                        <br />
                        <b>
                          IMP: Infants can be added in the same booking as they
                          do not require a separate seat.
                        </b>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="Baggage">
                  <h3 className="Flight-font18 font-bold mb-3">
                    Baggage Queries
                  </h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>
                        What if my baggage does not arrive at the final
                        destination?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Kindly contact the airline you have flown and register a
                        complaint with them. It is the airlines’ responsibility
                        to assist in such a matter.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>
                        What is the baggage size & weight allowed to be carried
                        on a flight?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Each airline has a definitive guideline with regards to
                        baggage. Kindly contact your airline for all questions
                        related to cabin or check-in baggage.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>
                        Can a transit passenger buy Liquor and carry it on
                        board?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Yes, but according to the guidelines of the
                        countries/airlines.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="mb-2">
                      <Accordion.Header>
                        How many liters of liquor are passengers allowed to
                        carry when onboard?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Depend upon the guidelines of the country/airlines.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className="mb-2">
                      <Accordion.Header>
                        How do I purchase additional baggage?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        You can purchase additional baggage while processing
                        your booking through application, with applicable
                        charges, as per the airline guidelines or you can
                        contact the airline.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className="mb-2">
                      <Accordion.Header>
                        Can I carry 2 hand baggage on board?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Each guest occupying their own seat is permitted to
                        carry up to 7kg of cabin baggage on board, limited to a
                        maximum of two (2) pieces, including a laptop, briefcase
                        or handbag.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="Booking Or Re-Scheduling Queries">
                  <h3 className="Flight-font18 font-bold mb-3">
                    Booking Or Re-Scheduling Queries
                  </h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>
                        Can I make a reservation for someone else?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Yes, you can make a reservation for someone else also.
                        You just need to enter all the mandatory information of
                        the passenger who is traveling while making the booking.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>
                        How do I know my reservation is confirmed?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        After we receive your reservation, we will send you an
                        email confirmation along with whatsapp and SMS. This
                        will include your itinerary details and ticket. You may
                        also check the status of your booking on OGenie under
                        the My Bookings section.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>
                        Why did the fare change at the time of making payment ?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        OGenie uses a real-time reservation database.If the
                        airline changes fares, the server immediately reflects
                        those changes. Always be sure to double-check prices
                        when reserving or booking tickets, as the changes are
                        dynamic.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="mb-2">
                      <Accordion.Header>
                        Can I reschedule my journey? If yes, what are the
                        charges?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Most airlines provide an option of rescheduling your
                        journey with normal charges as applicable.
                        <br /> <b>Note:</b>
                        Rescheduling window depends from airline to airline.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className="mb-2">
                      <Accordion.Header>
                        How many days in advance can I book my flight?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        You can book your tickets up to 330 days before
                        departure. Remember to book in good time, because the
                        cheapest tickets sell out quickly.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className="mb-2">
                      <Accordion.Header>
                        Can I travel on someone else's flight ticket?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        No. Airlines will not allow tickets to be transferred to
                        another name. If you are unable to travel, you may
                        cancel or reschedule your flight.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="Cancellation/Refund">
                  <h3 className="Flight-font18 font-bold mb-3">
                    Cancellation/Refund Queries
                  </h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>
                        What are the cancellation charges?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Cancellation charges are as per the Airline rules and
                        policies; these are mentioned in the 'fare rules'
                        section in the search result. OGenie charge is just Rs.
                        499/- per person per sector for domestic flights and Rs.
                        800/- per person per sector for international flights.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>
                        How much time will it take to get the refund processed?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        The refund will be processed by OGenie within 7-10
                        working days from the time of cancellation. It would
                        take additional 4-5 working days for the bank to credit
                        it into your original mode of payment.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>
                        Can I get a refund if I miss my flight?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        If you miss your flight, it would be considered as “No
                        Show” and the refund would depend on the airline policy.
                        No show charges will be applicable depending on the
                        policy set by the airlines.
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="3" className="mb-2">
                      <Accordion.Header>
                        Will OGenie provide any additional benefits to
                        passengers in case their flight is canceled or
                        rescheduled?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        No, Currently, we would recommend you to experience
                        Genie ELITE/PRO membership and enjoy the exclusive
                        benefits.
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="Payment">
                  <h3 className="Flight-font18 font-bold mb-3">
                    Payment Queries
                  </h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>
                        What payment modes are accepted for bookings?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        We accept payments through Debit Card, Credit Card, Net
                        Banking, UPI Transfers, PayTM wallet.
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>What is Genie Wallet?</Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Genie wallet stores your rewards points, refunds and
                        promotional cashbacks which can be used for your future
                        bookings.
                      </Accordion.Body>
                    </Accordion.Item> */}
                    {/* <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>
                        What happens if I fall short of funds in my Wallet when
                        making a fresh booking?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        If your booking amount is higher than the amount
                        available in your Genie Wallet, in this case, you can
                        pay the remaining amount from your desired mode of
                        payment.
                      </Accordion.Body>
                    </Accordion.Item> */}
                    {/* <Accordion.Item eventKey="3" className="mb-2">
                      <Accordion.Header>
                        Can I transfer funds to or from my Wallet?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Funds can only be transferred out while making a booking
                        online on OGenie Mobile App. You can receive a refund on
                        a cancellation or rescheduling amount in your Genie
                        Wallet.
                      </Accordion.Body>
                    </Accordion.Item> */}
                    {/* <Accordion.Item eventKey="4" className="mb-2">
                      <Accordion.Header>
                        How can I check my genie wallet balance?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        You can check your Genie wallet balance in easy steps:
                        <br />
                        Go to Profile -&gt; Click on Rewards Points /
                        Transaction -&gt;
                        <br />
                        These will reflect your available genie wallet balance.
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="100% Refund Policy">
                  <h3 className="Flight-font18 font-bold mb-3">
                    100% Refund Policy
                  </h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>
                        What does '100% Refundable Booking' do for me?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        When you choose '100% Refundable Booking' at the time of
                        booking, you can cancel your flight without bearing the
                        burden of the ticket cancellation charges levied by the
                        airline. You can avail of the benefit if you cancel your
                        booking at least 24 hours prior to the scheduled
                        departure of the flight. The entire refund amount will
                        be processed by OGenie Travels to the source account
                        from which the booking was made as per the terms and
                        conditions of '100% Refundable Booking'.
                        <br />
                        <b>Note:</b> '100% Refundable Booking' is also
                        applicable if the booking has been rescheduled.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>
                        What is the '100% Refundable Booking' process?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Once you have initiated ticket cancellation on OGenie
                        Travels, OGenie Travels will refund the entire amount as
                        per the terms and conditions of{" "}
                        <b>'100% Refundable Booking'</b>. 100% Refund on Travel
                        Booking(s) is provided with hassle-free processing in 5
                        easy steps:
                        <br />
                        <b>
                          Go to OGenie app -&gt; Purchases -&gt; Select Flight
                          -&gt; Cancel Ticket -&gt; Fill Up Refund Application
                        </b>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>
                        What happens if the airline cancels my flight?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        You will receive a full refund of the airline fare
                        (exclusive of convenience fees) as per the terms and
                        conditions listed below in case the airline cancels the
                        flight. Please initiate the ticket cancellation process
                        on OGenie Travels.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="mb-2">
                      <Accordion.Header>
                        Is '100% Refundable Booking' useful if I want to
                        reschedule my tickets?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        '100% Refundable Booking' is only for flight ticket
                        cancellation, all rescheduling done by the customer will
                        be chargeable in line with the respective airline
                        policy.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className="mb-2">
                      <Accordion.Header>
                        Up until when can I cancel my tickets?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        '100% Refundable Booking' allows you to cancel your
                        flight booking 24 hours before the original departure
                        time, to be eligible for a full refund.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className="mb-2">
                      <Accordion.Header>
                        I have purchased '100% Refundable Booking' tickets for 3
                        passengers in a single booking; can I cancel for all of
                        them together? What if I want to cancel only one
                        passenger's flight and not the other two?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        '100% Refundable Booking' allows you to cancel your
                        flight ticket for all or any one particular passenger
                        traveling. You can initiate the ticket cancellation on
                        the OGenie Travels platform.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6" className="mb-2">
                      <Accordion.Header>
                        If I have booked a round trip, can I cancel both the
                        onward and return flights?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Yes, you can cancel tickets for both your onward and
                        return journey together; in one request, or separately
                        (provided you have opted for '100% Refundable Booking'
                        for both).
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" className="mb-2">
                      <Accordion.Header>
                        What if I cancelled my flight directly with the airline?
                        Do I still get a refund if I booked with '100%
                        Refundable Booking'?
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Yes, you will still be eligible for a ticket
                        cancellation refund under '100% Refundable Booking'.
                        However, we would urge all '100% Refundable Booking'
                        customers to make any cancellations, directly on OGenie
                        Travels.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="Web Check-In">
                  <h3 className="Flight-font18 font-bold mb-3">Web Check-In</h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-2">
                      <Accordion.Header>
                        I want to do web check-in.
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Web check-ins were never as easy as this. Tap on mic and
                        say “I want to do web check-in for my upcoming flight”
                        and then follow the process. Your boarding pass will be
                        available on your email and WhatsApp before 24 hours of
                        journey or as soon as the check-in is accepted by the
                        airlines depending on the airline's policy.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-2">
                      <Accordion.Header>
                        I have initiated the web check, but my boarding pass has
                        not been received.
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        A boarding pass will be sent 24 hours before the
                        departure on your registered email address. If it is
                        less than 24 hours for your flight to depart, reach out
                        to my support desk now.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-2">
                      <Accordion.Header>
                        I could not select my preferred seat during web
                        check-in.
                      </Accordion.Header>
                      <Accordion.Body className="px-3 pt-0 font14">
                        Seats are subject to availability from the airlines. We
                        would recommend adding your seat by tapping 'Add-ons'
                        while making your booking. You could also reach your
                        airline if you wish to purchase seats.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Faqs;
