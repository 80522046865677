import { useEffect } from "react";

const useOutsideDetect = (ref, state, stateFn) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target))
      if (state) stateFn(false);
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, state]);
};

export default useOutsideDetect;
