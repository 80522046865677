import React from "react";
import { Container, Row, Col } from "react-bootstrap";
const RefundandCancellation = () => {
  return (
    <div>
      <div className="abt-wrap">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <div className="tnc-wrap">
                <h2 className="text-center my-lg-5 my-4">
                  Cancellation &amp; Refund Policy
                </h2>
                <div className="single-line-tc mb-3">
                  <span className="mallory-mediuam font12">
                    <b>Last Updated in Aug 2023</b>
                  </span>
                </div>
              </div>
              <div className="single-line-tc">
                <h3>Cancellation</h3>
                <p>
                  Bookings made by the Customer (s) may be confirmed (“Confirmed
                  Booking”) once OGenie receives payment towards such booking
                  and issues a valid confirmation number. Users can cancel the
                  booking once the transaction is complete.
                </p>
                <p>
                  Every transaction done on OGenie App is subject to
                  cancellation charges as provided by the third-party vendors
                  which may vary.
                </p>
                <h5>Cancellation Policies For COVID Based Scenarios -</h5>
                <ul className="terms-innerconditions">
                  <li>
                    No airlines cancellation fee or OGenie fee will be charged
                    for cancellation if the airline cancels the flight.
                  </li>
                  <li>
                    Customers will not be eligible for a refund if the airline
                    has issued a credit shell against the canceled
                    booking/flight service.
                  </li>
                  <li>
                    OGenie convenience fee is non-refundable in case of
                    cancellation.
                  </li>
                  <li>
                    We suggest making all cancellation/modification requests
                    before 12 hours prior to flight departure in case of
                    domestic flights. For International, please report any
                    changes before 48 hours of departure.
                  </li>
                  <li>
                    Airline rules and regulations are subject to change without
                    prior notice and at their discretion. We also request you to
                    please keep a check with the relevant airline as well,
                    before raising any such requests.
                  </li>
                </ul>
              </div>
              <div className="single-line-tc">
                <p>You can cancel any booking by,</p>
                <h3>Online cancellations:</h3>
                <ul className="terms-innerconditions">
                  <li>
                    Cancellations can be made within the OGenie app in 'My
                    Purchases’ section.
                  </li>
                </ul>
                {/* <p>
                  You could refer to the GENIE ELITE terms and conditions
                  section in our website for information related to your ELITE
                  membership.
                </p> */}
              </div>
              <div className="single-line-tc">
                <h3>Refund</h3>
                <p>User shall be entitled to refund only if:</p>
                <ul className="terms-innerconditions">
                  <li>
                    The payment amount has been deducted from the User's Debit
                    Card/ Account/ Credit Card, yet the user fail to receive a
                    valid service/product as requested by the User on the App.
                  </li>

                  <li>
                    The transaction for which the refund is requested, or any
                    other activity by the user on the OGenie App, does not
                    violate any of our terms and conditions.
                  </li>

                  <li>
                    In case the service/ product paid for has not been received
                    by the User then the User is requested to raise a complaint
                    on the Application. On receiving such Complaint, the matter
                    shall be examined by the support team and then a full or
                    partial refund (as applicable in the scenario) shall be made
                    to the User.
                  </li>

                  <li>
                    Our decision on refunds shall be at our sole discretion and
                    would be final and binding.
                  </li>

                  <li>
                    The refund will be initiated and may take up to 10 working
                    days to reflect on the User’s account.
                  </li>

                  <li>
                    The refund amount is subjected to the Third-party Vendor/
                    Merchant’s refund policy.
                  </li>

                  <li>
                    In case the Merchant’s policy doesn’t have a refund for a
                    particular product/service, OGenie is not liable to pay if
                    you wish to cancel the product/ service.
                  </li>

                  <li>
                    All promo/special costs & OGenie convenience fee are
                    restrictive and are non-refundable. Certain promo/special
                    costs do not permit changes to the service/product. Kindly
                    check restrictions while booking. Under all promo/special
                    costs, limited inventory is available on selected
                    product/service on a first-come, first-served basis. OGenie
                    or its service providers reserves the right to withdraw
                    and/or amend the promo/special costs without any prior
                    notice.
                  </li>

                  <li>
                    OGenie should not be held liable for any loss or damages
                    arising out of force Majeure events. We reserve the sole
                    right to cancel any order in circumstances of failure due to
                    reasons beyond our control or attributable to the Vendor/
                    Merchant.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RefundandCancellation;
