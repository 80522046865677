import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from "react-router-dom";
import ResultList from "../../../commons/artefacts/components/FlightResultList/ResultList";
import DataNotFound from "../../../commons/artefacts/components/errors/DataNotFound";
import NotFilteredData from "../../../commons/artefacts/components/errors/NotFilteredData";
import ServerError from "../../../commons/artefacts/components/errors/ServerError";
import SortMob from "../../../commons/artefacts/components/sort/SortMob";
import SortWeb from "../../../commons/artefacts/components/sort/SortWeb";
import Button from "../../../commons/artefacts/elements/button/primary";
import Image from "../../../commons/artefacts/elements/input/image";
import { DataContext } from "../../../commons/context/dataContext";
import string from "../../../commons/util/UtilManager";
import filterFlight from "../../../commons/util/filter";
import { renderImage } from "../../../commons/util/flightUtil";
import numberToIndCurr from "../../../commons/util/priceUtil";
import flightSort from "../../../commons/util/sort";
import {
  getUniqueFlights,
  mutateSearchResponse,
} from "../../../commons/util/uniqueFlights";
import { getFlightData } from "../../services/airlineService";
import Filter from "../one-way-search/Filter";
import "../styles.css";
import SelectionBar from "./SelectionBar";
import FlightSearchFormInner from "../home/FlightSearchFormInner";

const init_IsSelected = {
  onward: false,
  return: false,
};

const BottomPanel = ({ data, passengers }) => {
  const { sI, totalPriceList } = data;
  const priceList = totalPriceList[0];

  const {
    da: { code: daCode },
    dt,
    fD: {
      fN,
      aI: { name: aIName, code: aICode },
    },
  } = sI[0];

  const {
    aa: { code: aaCode },
    at,
  } = sI[sI.length - 1];

  const {
    fd: {
      ADULT: {
        fC: { TF: aTF },
      },
      CHILD: { fC: { TF: cTF = 0 } = {} } = {},
      INFANT: { fC: { TF: iTF = 0 } = {} } = {},
    },
  } = priceList;
  const { adult, child, infant } = passengers;

  return (
    <div className="d-lg-block d-none">
      <div className="Flight-detail-bar">
        <div className="Flight-detail-wrap border-top-0" id="getobdataforbook">
          <Row className="g-0 Flight-row">
            <Col md="2" xs="6">
              <div className="Flight-logo-sec">
                <Image
                  src={renderImage(aICode)}
                  className="img-fluid blur-up lazyloaded"
                />
              </div>
            </Col>
            <Col md="7" className="d-md-block d-none">
              <div className="Flight-airport-part">
                <div className="Flight-airport-name pl-2 text-start">
                  <h4 className="Flight-ln text-white">{aIName}</h4>
                  <h6 className="Flight-ln text-white">
                    {aICode}-{fN}
                  </h6>
                </div>

                <div className="Flight-airport-name departure ">
                  <h4 className="Flight-ln text-white">{dt.split("T")[1]}</h4>
                  <h6 className="Flight-ln text-white">{daCode}</h6>
                </div>
                <div className="Flight-airport-progress">
                  <div className="position-relative Flight-fliStopsSep">
                    <p className="Flight-fliStopsSepLine Flight-flistop-points flistop-lr0"></p>
                  </div>
                </div>
                <div className="Flight-airport-name Flight-arrival">
                  <h4 className="Flight-ln text-white">{at.split("T")[1]}</h4>
                  <h6 className="Flight-ln text-white">{aaCode}</h6>
                </div>
              </div>
            </Col>
            <Col md="3" xs="6">
              <div className="Flight-price">
                <div>
                  <h4 className="Flight-font16 Flight-ln text-white">
                    {numberToIndCurr(aTF * adult + cTF * child + iTF * infant)}
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const RoundTrip = () => {
  const history = useHistory();
  const {
    selectedOnward,
    setSelectedOnward,
    selectedReturn,
    setSelectedReturn,
    searchData: { onwardFlights, returnFlights },
    searchDataDispatch,
    formState,
    loader,
    setLoader,
    onFilterState,
    onFilterDispatch,
    reFilterState,
    reFilterDispatch,
    sortState: { onward: onwardSortCol, return: returnSortCol },
    setSortState,
    showFareDetail,
    setShowFareDetail,
  } = useContext(DataContext);
  const {
    departPlace,
    arrivalPlace,
    startDate,
    endDate,
    passengers,
    fareType,
    flightClass,
  } = formState;

  const { pathname } = useLocation();
  const [uniqFlightReturn, setUniqueFlightReturn] = useState([]);
  const [filterOnwardData, setFilterOnwardData] = useState([]);
  const [filterReturnData, setFilterReturnData] = useState([]);
  const [priceRange, setPriceRange] = useState({
    onMinAmong: 0,
    reMinAmong: 0,
    onMaxAmong: 0,
    reMaxAmong: 0,
  });
  const [error, setError] = useState("");
  const [isSelected, setIsSelected] = useState(init_IsSelected);
  const [showMobFilter, setshowMobFilter] = useState("filters");
  const [showMobFilterFlag, setshowMobFilterFlag] = useState(false);

  const getTotalFare = () => {
    const { adult, child, infant } = passengers;
    const { totalPriceList: onwardPriceList } = selectedOnward;
    const { totalPriceList: returnPriceList } = selectedReturn;
    const onPriceList = onwardPriceList[0];
    const rePriceList = returnPriceList[0];

    const {
      fd: {
        ADULT: {
          fC: { TF: on_aTF },
        },
        CHILD: { fC: { TF: on_cTF = 0 } = {} } = {},
        INFANT: { fC: { TF: on_iTF = 0 } = {} } = {},
      },
    } = onPriceList;
    const {
      fd: {
        ADULT: {
          fC: { TF: re_aTF },
        },
        CHILD: { fC: { TF: re_cTF = 0 } = {} } = {},
        INFANT: { fC: { TF: re_iTF = 0 } = {} } = {},
      },
    } = rePriceList;

    return numberToIndCurr(
      adult * (on_aTF + re_aTF) +
        child * (on_cTF + re_cTF) +
        infant * (on_iTF + re_iTF)
    );
  };

  useEffect(() => {
    setError("");
    setIsSelected(init_IsSelected);
    getFlightData(
      departPlace.code,
      arrivalPlace.code,
      passengers,
      fareType,
      flightClass,
      startDate,
      endDate
    )
      .then(
        ({
          type,
          message,
          status,
          data: {
            searchResult: { tripInfos: { ONWARD = [], RETURN = [] } = {} } = {},
          } = {},
        }) => {
          setFilterOnwardData([]);
          setFilterReturnData([]);

          if (type === "failure" || type === "error" || status >= 500) {
            setError("Server Error");
          } else if (!ONWARD.length || !RETURN.length) {
            setError("No Data");
          }


          const updatedOnwardData = ONWARD.map(flight => {
            if (flight?.totalPriceList && flight?.totalPriceList?.length > 0) {
              flight.totalPriceList.forEach(price => {
                const originalTF = price?.fd?.ADULT?.fC?.TF;
                const MF = price?.fd?.ADULT?.afC?.TAF?.MF || 0;
                const MFT = price?.fd.ADULT?.afC?.TAF?.MFT || 0;
                price.fd.ADULT.fC.TF = originalTF - (MF + MFT);
              });
            }
            return flight;
          });
          const updatedReturnData = RETURN.map(flight => {
            if (flight?.totalPriceList && flight?.totalPriceList?.length > 0) {
              flight.totalPriceList.forEach(price => {
                const originalTF = price?.fd?.ADULT?.fC?.TF;
                const MF = price?.fd?.ADULT?.afC?.TAF?.MF || 0;
                const MFT = price?.fd.ADULT?.afC?.TAF?.MFT || 0;
                price.fd.ADULT.fC.TF = originalTF - (MF + MFT);
              });
            }
            return flight;
          });
         const {
            data: onData,
            minAmong: onMinAmong,
            maxAmong: onMaxAmong,
          } = mutateSearchResponse(updatedOnwardData);
          const {
            data: reData,
            minAmong: reMinAmong,
            maxAmong: reMaxAmong,
          } = mutateSearchResponse(updatedReturnData);
          setPriceRange({
            onMinAmong: onMinAmong,
            reMinAmong: reMinAmong,
            onMaxAmong: onMaxAmong,
            reMaxAmong: reMaxAmong,
          });

          setUniqueFlightReturn(getUniqueFlights(reData));

          onFilterDispatch({
            type: "RangeUpdated",
            payload: {
              range: {
                min: Math.min(onMinAmong, reMinAmong),
                max: Math.max(onMaxAmong, reMaxAmong),
              },
            },
          });

          searchDataDispatch({
            type: "UpdatedRoundTrip",
            payload: { onwardFlights: onData, returnFlights: reData },
          });
        }
      )
      .finally(() => setLoader(false));
  }, [formState]);

  useEffect(() => {
    if (onwardFlights.length > 0) {
      setError("");
      let filteredData = filterFlight(onwardFlights, {
        ...onFilterState,
        aircraft: new Set(reFilterState.aircraft),
      });

      if (!filteredData.length) setError("No Filter Data");
      // setShowFareDetail("");

      filteredData = flightSort(filteredData, onwardSortCol);

      if (!isSelected.return) {
        setIsSelected({ ...isSelected, onward: true });
        setSelectedOnward(filteredData[0]);
      }
      setFilterOnwardData(filteredData);
    }
  }, [
    onFilterState,
    onwardFlights.length,
    onwardSortCol,
    reFilterState.aircraft,
  ]);

  useEffect(() => {
    if (returnFlights.length > 0) {
      let filteredData = filterFlight(returnFlights, {
        ...reFilterState,
        priceRange: onFilterState.priceRange,
      });

      if (!filteredData.length) setError("No Filter Data");
      //setShowFareDetail("");

      filteredData = flightSort(filteredData, returnSortCol);

      if (!isSelected.return) {
        setIsSelected({ ...isSelected, return: true });
        setSelectedReturn(filteredData[0]);
      }
      setFilterReturnData(filteredData);
    }
  }, [
    reFilterState,
    returnFlights.length,
    returnSortCol,
    onFilterState.priceRange,
  ]);

  const handleOnwardSort = (colName) => {
    let ord =
      onwardSortCol.path === colName
        ? onwardSortCol.order === "asc"
          ? "desc"
          : "asc"
        : "desc";
    setSortState((prevState) => ({
      ...prevState,
      onward: { path: colName, order: ord },
    }));
  };

  const handleReturnSort = (colName) => {
    let ord =
      returnSortCol.path === colName
        ? returnSortCol.order === "asc"
          ? "desc"
          : "asc"
        : "desc";

    setSortState((prevState) => ({
      ...prevState,
      return: { path: colName, order: ord },
    }));
  };

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 30;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
    <>
      <div>
        {/* Top search start */}
        <div className="Flight-yellow-gradient Flight-sticky_nav pt-lg-4 pb-lg-3">
          <Container fluid="xxl" className="px-0 px-lg-3 Flight-container-xxl">
            <div className="Flight-widgetSection Flight-inner-page-search">
              <FlightSearchFormInner />
              <div className="sort-filter d-lg-none">
                <Container className="Flight-container">
                  <div className="d-flex justify-content-end ">
                    <span
                      className="pe-3 d-inline-flex"
                      onClick={() => {
                        setshowMobFilterFlag(true);
                        setshowMobFilter("sorting");
                      }}
                    >
                      <Image
                        className="me-2"
                        src={renderImage("sortingIcon.svg")}
                        alt="sun"
                      />
                      Sort
                    </span>
                    <span
                      className="d-inline-flex"
                      onClick={() => {
                        setshowMobFilter("filters");
                        setshowMobFilterFlag(true);
                      }}
                    >
                      <Image
                        className="me-2"
                        src={renderImage("filterIcon.svg")}
                        alt="sun"
                      />
                      Filter
                    </span>
                  </div>
                </Container>
              </div>
            </div>
          </Container>
        </div>
        {/* Top search end  */}
        {loader ? (
          // <div className="loader-wrapper img-gif">
          //   <Image src={renderImage("loader.gif")} className="img-fluid" />
          // </div>
          <div className="loader-wrapper img-gif mt-0 pt-0">
            <Image src={renderImage("loader.gif")} className="img-fluid" />
          </div>
        ) : error === "Server Error" ? (
          <ServerError />
        ) : error === "No Data" ? (
          <DataNotFound />
        ) : (
          <Fragment>
            <div className="Flight-roundtrip-result">
              <Container
                fluid="xxl"
                className="pt-lg-3 rt-container Flight-container-xxl"
              >
                <Row className="g-lg-3 g-0 rt-row Flight-row">
                  <Col lg="9">
                    {error === "No Filter Data" ? (
                      <NotFilteredData
                        onClear={() => {
                          setError("");
                          onFilterDispatch({
                            type: "ResetState",
                            payload: {
                              range: {
                                min: priceRange.onMinAmong,
                                max: priceRange.onMaxAmong,
                              },
                            },
                          });
                          reFilterDispatch({
                            type: "ResetState",
                            payload: {
                              range: {
                                min: priceRange.reMinAmong,
                                max: priceRange.reMaxAmong,
                              },
                            },
                          });
                        }}
                      />
                    ) : (
                      <Row className="g-xl-3 g-lg-2 g-0 Flight-row">
                        {["ARMED_FORCES", "DOCTORS_NURSES"].includes(
                          formState.fareType
                        ) && (
                          <Fragment>
                            <Col xs="12">
                              <div className="Flight-search-result Flight-search-inner m-lg-0  m-md-3 m-2 p-0 border-0">
                                <div className="border-0 box-shadow-none Fligh-alert1 d-flex align-items-center p-md-3 p-2">
                                  <Image
                                    className="me-3"
                                    width={30}
                                    alt="calender"
                                    src={renderImage("noflight-info.svg")}
                                  />
                                  <div>
                                    <>
                                      <p className="Flight-font14 Flight-font-semibold mb-0 text-capitalize">
                                        We could not fetch out any{" "}
                                        <span className="Flight-font-bold">
                                          {string.string.transform.capitalizeEachWord(
                                            fareType.replace(/_/g, " ")
                                          )}{" "}
                                        </span>
                                        fares flight result for this route /
                                        dates! here are some other flight
                                        options you may wish to explore.
                                      </p>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Fragment>
                        )}
                        <Col xs={6} className="rt-border-end">
                          <SelectionBar
                            depCity={departPlace.city}
                            depCode={departPlace.code}
                            arrCity={arrivalPlace.city}
                            arrCode={arrivalPlace.code}
                            date={startDate}
                          />
                          <SortWeb
                            sortColumn={onwardSortCol}
                            onSort={handleOnwardSort}
                          />
                          <ResultList
                            path={pathname}
                            passengers={passengers}
                            selectedCard={selectedOnward}
                            data={filterOnwardData}
                            showFareDetail={showFareDetail}
                            onChangeShowFareDetail={(id) => {
                              setShowFareDetail(
                                id !== showFareDetail ? id : ""
                              );
                            }}
                            onFareChange={(index, fare) => {
                              searchDataDispatch({
                                type: "UpdatedSelectedFare",
                                payload: {
                                  flightType: "oneway",
                                  dataIndex: index,
                                  fare,
                                },
                              });
                              setSelectedOnward({
                                ...onwardFlights[index],
                                totalPriceList: [fare],
                              });
                            }}
                            onSelectCard={(data) => setSelectedOnward(data)}
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectionBar
                            depCity={arrivalPlace.city}
                            depCode={arrivalPlace.code}
                            arrCity={departPlace.city}
                            arrCode={departPlace.code}
                            date={endDate}
                          />
                          <SortWeb
                            sortColumn={returnSortCol}
                            onSort={handleReturnSort}
                          />
                          <ResultList
                            path={pathname}
                            passengers={passengers}
                            selectedCard={selectedReturn}
                            data={filterReturnData}
                            showFareDetail={showFareDetail}
                            onChangeShowFareDetail={(id) => {
                              setShowFareDetail(
                                id !== showFareDetail ? id : ""
                              );
                            }}
                            onFareChange={(index, fare) => {
                              searchDataDispatch({
                                type: "UpdatedSelectedFare",
                                payload: {
                                  flightType: "round",
                                  dataIndex: index,
                                  fare,
                                },
                              });
                              setSelectedReturn({
                                ...returnFlights[index],
                                totalPriceList: [fare],
                              });
                            }}
                            onSelectCard={(data) => setSelectedReturn(data)}
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md="3">
                    <div className="d-lg-flex d-none justify-content-between Flight-filter-txt Flight-filterdp">
                      <div className="d-inline-block Flight-font13 Flight-font-semibold text-decoration-none text-dark">
                        FILTERS
                      </div>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setError("");
                          onFilterDispatch({
                            type: "ResetState",
                            payload: {
                              range: {
                                min: priceRange.onMinAmong,
                                max: priceRange.onMaxAmong,
                              },
                            },
                          });
                          reFilterDispatch({
                            type: "ResetState",
                            payload: {
                              range: {
                                min: priceRange.reMinAmong,
                                max: priceRange.reMaxAmong,
                              },
                            },
                          });
                        }}
                        className="d-inline-block text-dark Flight-font13 Flight-font-semibold Flight-cursor-pointer border-0 bg-transparent"
                      >
                        CLEAR ALL
                      </Button>
                    </div>
                    <div
                      className={`Flight-left-sidebar filter-mobile ${
                        showMobFilterFlag && "show"
                      }`}
                    >
                      <Container className="Flight-container">
                        <Tabs
                          id="uncontrolled-tab-example"
                          activeKey={showMobFilter}
                          onSelect={(selectedTab) => {
                            setshowMobFilter(selectedTab);
                            setshowMobFilterFlag(true);
                          }}
                          className="mb-0 d-lg-none Flight-sf-tabs Flight-box-stretch"
                        >
                          <Tab eventKey="filters" title="Filters">
                            <div className="Flight-filterblock">
                              <Filter
                                flightType="Onward"
                                filterState={onFilterState}
                                onStop={(ind) =>
                                  onFilterDispatch({
                                    type: "StopUpdated",
                                    payload: { index: ind },
                                  })
                                }
                                onDepartTime={(val) =>
                                  onFilterDispatch({
                                    type: "DepartureUpdated",
                                    payload: { departureTime: val },
                                  })
                                }
                                onArriveTime={(val) =>
                                  onFilterDispatch({
                                    type: "ArrivalUpdated",
                                    payload: { arrivalTime: val },
                                  })
                                }
                                onPriceChange={(val) =>
                                  onFilterDispatch({
                                    type: "PriceUpdated",
                                    payload: { range: val },
                                  })
                                }
                                onAircraftSelect={(val) =>
                                  onFilterDispatch({
                                    type: "AircraftUpdated",
                                    payload: { aircraftName: val },
                                  })
                                }
                              />
                              <div className="Flight-return-filter">
                                <Filter
                                  flightType="Return"
                                  filterState={reFilterState}
                                  hidePriceRange={true}
                                  airplanes={uniqFlightReturn}
                                  onStop={(ind) =>
                                    reFilterDispatch({
                                      type: "StopUpdated",
                                      payload: { index: ind },
                                    })
                                  }
                                  onDepartTime={(val) =>
                                    reFilterDispatch({
                                      type: "DepartureUpdated",
                                      payload: { departureTime: val },
                                    })
                                  }
                                  onArriveTime={(val) =>
                                    reFilterDispatch({
                                      type: "ArrivalUpdated",
                                      payload: { arrivalTime: val },
                                    })
                                  }
                                  onPriceChange={(val) =>
                                    reFilterDispatch({
                                      type: "PriceUpdated",
                                      payload: { range: val },
                                    })
                                  }
                                  onAircraftSelect={(val) =>
                                    reFilterDispatch({
                                      type: "AircraftUpdated",
                                      payload: { aircraftName: val },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="sorting" title="Sort By">
                            <Container className="Flight-filterblock pb-3 Flight-container">
                              {/* <Button
                                onClick={() =>
                                  setSortState({
                                    onward: { path: "TF", order: "asc" },
                                    return: { path: "TF", order: "asc" },
                                  })
                                }
                                className="Flight-font13 text-dark Flight-cursor-pointer border-0  d-lg-none bg-transparent Flight-clear-filter"
                              >
                                CLEAR ALLaaaa
                              </Button> */}

                              <p className="Flight-font14 Flight-font-bold pt-2">
                                Onward Journey
                              </p>
                              <SortMob
                                sortColumn={onwardSortCol}
                                onChange={(col) =>
                                  setSortState((prevState) => ({
                                    ...prevState,
                                    onward: col,
                                  }))
                                }
                              />
                              <p className="Flight-font14 Flight-font-bold pt-2">
                                Return Journey
                              </p>
                              <SortMob
                                sortColumn={returnSortCol}
                                onChange={(col) =>
                                  setSortState((prevState) => ({
                                    ...prevState,
                                    return: col,
                                  }))
                                }
                              />
                            </Container>
                          </Tab>
                        </Tabs>
                      </Container>
                      <div className="Flight-aply-fltr-btn d-flex justify-content-around">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setError("");

                            if (showMobFilter === "filters") {
                              onFilterDispatch({
                                type: "ResetState",
                                payload: {
                                  range: {
                                    min: priceRange.onMinAmong,
                                    max: priceRange.onMaxAmong,
                                  },
                                },
                              });
                              reFilterDispatch({
                                type: "ResetState",
                                payload: {
                                  range: {
                                    min: priceRange.reMinAmong,
                                    max: priceRange.reMaxAmong,
                                  },
                                },
                              });
                            } else {
                              setSortState((prevState) => ({
                                ...prevState,
                                onward: { path: "TF", order: "asc" },
                                return: { path: "TF", order: "asc" },
                              }));
                            }
                            setshowMobFilterFlag(false);
                            setshowMobFilter("");
                          }}
                          className="w-50 Flight-cursor-pointer d-lg-none border-0 Flight-clear-filter"
                        >
                          Reset
                        </Button>
                        <Button
                          onClick={() => {
                            setshowMobFilterFlag(false);
                            setshowMobFilter("");
                          }}
                          className="btn Flight-btn-purple w-50 d-lg-none py-2 rounded-0 border-0"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            {!error && (
              <div className="book_panel flight-detail-sec">
                <Container fluid="xxl" className="Flight-container-xxl">
                  <Row className="g-0 Flight-row">
                    <Col xxl="10">
                      <div className="Flight-box-stretch">
                        <BottomPanel
                          data={selectedOnward}
                          passengers={passengers}
                        />
                        <BottomPanel
                          data={selectedReturn}
                          passengers={passengers}
                        />

                        <div className="mob-deta d-flex d-lg-none align-items-center text-white">
                          <div className="Flight-price">
                            <div className="py-2">
                              <h4 className="Flight-font16 Flight-font-semibold Flight-fnum m-0 Flight-ln text-white">
                                {selectedOnward?.sI[0]?.fD?.aI?.code}-
                                {selectedOnward?.sI[0]?.fD?.fN}
                              </h4>
                            </div>
                          </div>
                          <div className="Flight-trip_icon">
                            {" "}
                            <Image
                              className="mx-3"
                              src={renderImage("rtrip_arrow.png")}
                            />{" "}
                          </div>
                          <div className="Flight-price">
                            <div className="py-2">
                              <h4 className="Flight-font16 Flight-font-semibold Flight-fnum m-0 Flight-ln  text-white">
                                {selectedReturn?.sI[0]?.fD?.aI?.code}-
                                {selectedReturn?.sI[0]?.fD?.fN}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="grand-total d-flex align-items-center justify-content-lg-start justify-content-end">
                            <h5 className="total text-white Flight-ln">
                              <span className="Flight-font10">
                                Grand total{" "}
                              </span>
                              <span
                                className="d-block Flight-font16"
                                id="bookfprice"
                              >
                                {getTotalFare(passengers)}
                              </span>
                            </h5>
                            <div>
                              <Button
                                onClick={() =>
                                  history.push("/review", {
                                    id: [
                                      selectedOnward.totalPriceList[0].id,
                                      selectedReturn.totalPriceList[0].id,
                                    ],
                                    data: [
                                      {
                                        flightResult: selectedOnward,
                                        fareType,
                                      },
                                      {
                                        flightResult: selectedReturn,
                                        fareType,
                                      },
                                    ],
                                    passengers: passengers,
                                    isIntRd: false,
                                  })
                                }
                                className="ms-2 btn Flight-btn-purple Flight-sm-btn Flight-font16"
                              >
                                Book
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default RoundTrip;
