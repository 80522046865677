import _ from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import { renderImage } from "../../../../../commons/util/flightUtil";
import {
  formatTimeToHour,
  getDateByFormat,
  getDateFromString,
  getTimeFromString,
} from "../../../../util/dateTimeUtil";
import string from "./../../../../../commons/util/UtilManager";
import Image from "../../../elements/input/image";

const InfoCard = ({ info, cc }) => {
  const {
    fD: {
      aI: { code: airlineCode, name },
      fN,
    },
    duration,
    cT,
    da: { city: depCity, code: depCode },
    aa: { city: ariCity, code: ariCode },
    dt,
    at,
  } = info;
  return (
    <div className="flight-info">
      <div className="Flight-inner-detail Flight-box-stretch">
        <div className="Flight-logo-sec text-left d-flex  align-items-center">
          <div>
            <Image
              alt="airlinelogo"
              src={renderImage(airlineCode)}
              className="img-fluid blur-up lazyloaded"
            />
          </div>
          <div className="ms-2">
            <span className="title">{name}</span>
            <span className="Flight-font12 Flight-fnum Flight-txt-gray Flight-font-semibold ms-1">
              {`${airlineCode}-${fN}`}
            </span>
            <span className="Flight-font12 Flight-txt-gray Flight-font-semibold d-block">
              {string.string.transform
                .capitalizeEachWord(cc?.selectedFare?.fd?.ADULT?.cc)
                ?.replace("_", " ")}
            </span>
          </div>
        </div>
        <div className="mt-md-0 mt-2">
          <div className="Flight-airport-part d-flex justify-content-between justify-content-sm-center align-items-center h-100">
            <div className="Flight-airport-name">
              <h4 className="Flight-font14 Flight-ln my-1">{depCity}</h4>
              <h6 className="Flight-font14 Flight-ln my-1">
                {getTimeFromString(dt)}{" "}
                <span className="rt-ita-code">{depCode}</span>
              </h6>
              <div className="Flight-font12">{getDateByFormat(dt)}</div>
            </div>
            <div className="Flight-airport-progress text-center">
              <div className="Flight-IATA-code Flight-font11 Flight-txt-gray">
                &nbsp;{formatTimeToHour(duration)}
              </div>
              <div className="position-relative Flight-fliStopsSep">
                <p className="Flight-fliStopsSepLine Flight-flistop-points" />
                <span className="Flight-fliStopsDisc Flight-disc-none"></span>
              </div>
              <div className="stop Flight-font10 Flight-txt-gray mt-1">
                (Non Stop)
              </div>
            </div>
            <div className="Flight-airport-name Flight-arrival">
              <h4 className="Flight-font14 Flight-ln">{ariCity}</h4>
              <h6 className="Flight-font14 Flight-ln my-1">
                {getTimeFromString(at)}{" "}
                <span className="rt-ita-code">{ariCode}</span>
              </h6>
              <div className="Flight-font12">{getDateByFormat(at)}</div>
            </div>
          </div>
        </div>
      </div>
      {cT && (
        <div className="Flight-layover-time Flight-font10 position-absolute text-center">
          <span className="rounded-pill px-3 py-1 Flight-font-semibold">
            {"Layover " + formatTimeToHour(cT)}
          </span>
        </div>
      )}
    </div>
  );
};

const InfoTab = ({ data }) => {
  const { pathname } = useLocation();
  return (
    <div
      className="tab-pane"
      id="FlightInformation1"
      role="tabpanel"
      aria-labelledby="FlightInformation-tab1"
    >
      <div
        className={`flight-info-wrap mt-md-3 mt-1 ${
          pathname === "/FlightList/" ? "int-rslt" : ""
        }`}
      >
        {data?.sI.map((info, ind) => (
          <div
            className={data?.sI.length > 1 ? "Flight-reqClass " : ""}
            key={ind}
          >
            <InfoCard info={info} cc={data} />
          </div>
        ))}
      </div>
    </div>
  );
};

const IntInfoTab = ({ data }) => {
  const returnSiIndex = _.findIndex(data, (item) => item.isRs && item.sN === 0);
  return (
    <>
      <div className="d-flex mt-2">
        <div className="d-flex align-items-center">
          <h6 className="Flight-font14 Flight-font-semibold m-0 Flight-ln">
            {data?.sI[0]?.da.city}
          </h6>
          <span className="px-2">
            <Image
              width={14}
              src={renderImage("oneway-arrow1.svg")}
              alt="oneway-arrow"
            />
          </span>
          <h6 className="Flight-font14 Flight-font-semibold m-0 Flight-ln">
            {data?.sI[returnSiIndex - 1]?.aa?.city}
          </h6>
        </div>
        <span className="Flight-font14 Flight-font-semibold px-2">|</span>
        <div className="d-flex align-items-center">
          <h6 className="Flight-font14 Flight-font-semibold m-0 Flight-ln">
            {getDateFromString(data[0]?.dt)}
          </h6>
        </div>
      </div>
      <div className="flight-info-wrap  mt-2">
        {data?.sI.slice(0, returnSiIndex).map((info, ind) => (
          <InfoCard info={info} key={ind} cc={data} />
        ))}
      </div>
      <div className="d-flex mt-2">
        <div className="d-flex align-items-center">
          <h6 className="Flight-font14 Flight-font-semibold m-0 Flight-ln">
            {data?.sI[returnSiIndex]?.da?.city}
          </h6>
          <span className="px-2">
            <Image
              width={14}
              src={renderImage("oneway-arrow1.svg")}
              alt="oneway-arrow"
            />
          </span>
          <h6 className="Flight-font14 Flight-font-semibold m-0 Flight-ln">
            {data?.sI[0].da.city}
          </h6>
        </div>
        <span className="Flight-font14 Flight-font-semibold px-2">|</span>
        <div className="d-flex align-items-center">
          <h6 className="Flight-font14 Flight-font-semibold m-0 Flight-ln">
            {getDateFromString(data?.sI[returnSiIndex]?.dt)}
          </h6>
        </div>
      </div>
      <div className="flight-info-wrap mt-2">
        {data.sI.slice(returnSiIndex).map((info, ind) => (
          <InfoCard info={info} key={ind} cc={data} />
        ))}
      </div>
    </>
  );
};

export { InfoTab, IntInfoTab };
