import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmationModalBody = () => {
  return (
    <div className="Flight-font14 Flight-font-semibold mt-2 text-center">
      Are you sure you want to cancel the selected flight?
    </div>
  );
};

export default ConfirmationModalBody;
