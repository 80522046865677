import _ from "lodash";
import React, { Fragment, useState, useMemo, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { renderImage } from "../../../../commons/util/flightUtil";
import string from "../../../util/UtilManager";
import {
  formatTimeToHour,
  getTimeFromString,
} from "../../../util/dateTimeUtil";
import numberToIndCurr, { calculateFare } from "../../../util/priceUtil";
import Button from "../../elements/button/primary";
import Image from "../../elements/input/image";
import FlightDetails from "./FlightDetails/FlightDetails";
import { getDateByFormat } from "../../../util/dateTimeUtil";
const ResultCard = React.memo(({ flightResult, ...rest }) => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const { sI, totalPriceList, selectedFare, uniqId } = flightResult;
  const {
    path,
    passengers,
    selected,
    onFareChange,
    onSelect,
    showFareDetail,
    onChangeShowFareDetail,
    mgtFee,
  } = rest;

  const isOneWay = useMemo(() => path?.includes("FlightList/"), [path]);
  const isReview = useMemo(() => path?.includes("review"), [path]);
  const isRoundTrip = useMemo(() => path?.includes("FlightListRT"), [path]);

  const item = sI[0];
  const destination = sI[sI.length - 1];
  const duration = sI.reduce((acc, curr) => acc + curr.duration, 0);

  const layOverTime = sI.reduce((acc, curr) => {
    if ("cT" in curr) {
      acc += curr.cT;
    }
    return acc;
  }, 0);

  const {
    fd: {
      ADULT: {
        bI: { iB: checkInBag, cB: cabinBag },
        cc,
      },
    },
  } = totalPriceList[0];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top position when the component rerenders
  }, [sI]);

  return (
    <div
      className={`Flight-search-result  ${
        selected ? selected.uniqId === uniqId && "selected" : ""
      }`}
      onClick={selected ? () => onSelect(flightResult) : () => {}}>
      <div className="Flight-search-inner">
        <Row className="gx-2 Flight-row">
          <Col
            md="3"
            xs="7"
            className="d-flex align-items-center order-md-1 order-1 rt-col-7">
            <div className="Flight-logo-sec text-left d-flex align-items-center">
              <div>
                <Image
                  src={renderImage(item?.fD?.aI?.code)}
                  className="img-fluid blur-up lazyloaded"
                  alt="airlinelogo"
                />
              </div>
              <div className="ms-2">
                <span className="title">{item?.fD?.aI?.name}</span>
                <span className="Flight-font12 Flight-txt-gray Flight-font-semibold Flight-fnum">
                  {item?.fD?.aI?.code}-{item?.fD?.fN}
                </span>
                <div className="Flight-font12 detail-date Flight-font-semibold Flight-fcls">
                  {getDateByFormat(item?.dt)}
                </div>

                {!isRoundTrip && (
                  <span className="Flight-font12 Flight-txt-gray d-block Flight-font-semibold Flight-fcls">
                    {string.string.transform.capitalizeEachWord(
                      cc.replace("_", " ")
                    )}
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col md="6" className="mt-md-0 mt-3 order-md-2 order-3 rt-order-3">
            <div className="Flight-airport-part d-flex justify-content-between justify-content-sm-center align-items-center h-100">
              <div className="Flight-airport-name">
                <h4 className="Flight-font14 Flight-font-bold Flight-ln">
                  {item?.da?.city}
                </h4>
                <h6 className="Flight-font16 Flight-font-semibold Flight-ln">
                  {`${getTimeFromString(item?.dt)}`}

                  <span className="rt-ita-code"> {`${item?.da?.code}`}</span>
                </h6>
              </div>
              <div className="Flight-airport-progress text-center">
                <div className="Flight-IATA-code Flight-font11 Flight-txt-gray">
                  &nbsp;
                  {formatTimeToHour(duration + layOverTime)}
                </div>
                <div className="position-relative Flight-fliStopsSep">
                  <p className="Flight-fliStopsSepLine Flight-flistop-points" />
                  {_.range(sI.length - 1).map((i) => (
                    <span className="Flight-fliStopsDisc" key={i} />
                  ))}
                  <span className="Flight-fliStopsDisc Flight-disc-none"></span>
                </div>
                <div className="stop Flight-font11 Flight-txt-gray mt-1">
                  {sI.length > 1
                    ? sI
                        .map((flg) => flg?.da?.code)
                        .filter((_flg, ind) => ind !== 0)
                        .join(`-`)
                    : "0 Stop"}
                </div>
              </div>

              <div className="Flight-airport-name Flight-arrival">
                <h4 className="Flight-font14 Flight-font-bold Flight-ln">
                  {destination?.aa?.city}
                </h4>
                <h6 className="Flight-font16 Flight-font-semibold Flight-ln">
                  {`${getTimeFromString(destination?.at)}`}
                  <span className="rt-ita-code">
                    {" "}
                    {`${destination?.aa?.code}`}
                  </span>
                </h6>
              </div>
            </div>
          </Col>
          <Col
            md="3"
            xs="5"
            className="d-flex align-items-center justify-content-end order-md-3 order-2 rt-col-5">
            <div className="Flight-book-flight text-center d-inline-block">
              {selectedFare?.id ? (
                <Fragment>
                  <h3 className="Flight-font18 Flight-font-bold mb-0 Flight-ln">
                    {numberToIndCurr(
                      calculateFare(selectedFare, passengers)
                    )}
                  </h3>
                  {isOneWay && (
                    <Button
                      id="fare id"
                      className="btn Flight-btn Flight-btn-purple Flight-phn-book-btn Flight-font-semibold mt-1"
                      onClick={() =>
                        history.push({
                          pathname: "/review",
                          state: {
                            id: [selectedFare.id],
                            data: [
                              {
                                flightResult: {
                                  sI,
                                  totalPriceList: [selectedFare],
                                  selectedFare: selectedFare,
                                  uniqId: uniqId,
                                },
                              },
                            ],
                            passengers,
                            isIntRd: false,
                          },
                        })
                      }>
                      Book
                    </Button>
                  )}
                </Fragment>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <div className="bookFlightDetail align-items-center justify-content-between mt-3">
          <ul className="d-sm-flex Flight-font12 align-items-center Flight-btn Flight-btn-detail mb-0 ps-0 Flight-font-bold">
            <li>
              <span className="Flight-txt-gray">Fare Type &nbsp;</span>
              <span className="Flight-regulartype rounded-pill d-inline-block Flight-font12 px-md-3 px-2 ms-md-0">
                {string.string.transform.capitalizeEachWord(
                  selectedFare?.fareIdentifier?.replace(/_/g, " ")
                )}
              </span>
            </li>
            {!isRoundTrip && (
              <li className="d-flex mt-2 mt-md-0 d-md-block d-none detail-baggage">
                <span className="Flight-txt-gray">Baggage :</span>
                <span className="ms-1">
                  <span className="Flight-bgg-info">
                    Check In {checkInBag || "- N/A"}
                  </span>{" "}
                  Cabin {cabinBag || "- N/A"}
                </span>
              </li>
            )}
            {!isRoundTrip && sI.length > 1 && (
              <li className="d-flex mt-2 mt-sm-0 d-sm-block d-none detail-baggage">
                <span className="Flight-txt-gray">Layovers :</span>
                <span className="ms-1">
                  <span className=""> {formatTimeToHour(layOverTime)}</span>
                </span>
              </li>
            )}
          </ul>
          <div className="detail-dnone">
            <div
              variant="primary"
              onClick={handleShow}
              className="Flight-rt-mob-show Flight-theme-txt Flight-font14 Flight-font-semibold">
              View Fare & Details
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                onChangeShowFareDetail(uniqId);
              }}
              className={`Flight-cursor-pointer Flight-theme-txt Flight-show-detail ${
                !(showFareDetail === uniqId) ? "collapsed" : ""
              } Flight-font14 Flight-font-semibold position-relative Flight-viewdetail-arrow`}>
              <span
                className={
                  showFareDetail === uniqId ? "Flight-hidedetail" : "showdetail"
                }>
                {showFareDetail === uniqId
                  ? isReview
                    ? "Hide Details"
                    : "Hide Fare & Details"
                  : isReview
                  ? "View Details"
                  : "View Fare & Details"}
              </span>
            </div>
          </div>
        </div>

        <FlightDetails
          show={showFareDetail}
          passengers={passengers}
          flightResult={flightResult}
          onFareChange={onFareChange}
        />
        <Modal className="Flight-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton>Flight Details</Modal.Header>
          <Modal.Body className="Flight-mob-detail">
            <FlightDetails
              classMob={true}
              show={showFareDetail}
              passengers={passengers}
              flightResult={flightResult}
              onFareChange={onFareChange}
              selectedFare={selectedFare}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
});

export default ResultCard;
