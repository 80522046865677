import React from "react";
import { Table } from "react-bootstrap";

const BookingsSSRModal = (props) => {
  console.log(props);
  return (
    <div className="table-responsive">
      <Table className="table table-borderless mb-0 Flight-font14">
        <thead>
          <tr>
            <th className="py-1" scope="col">
              Traveller
            </th>
            <th className="py-1" scope="col">
              Seat
            </th>
            <th className="py-1" scope="col">
              Meal
            </th>
          </tr>
        </thead>
        <tbody className="Flight-font-semibold">
          {props?.data.length > 0 &&
            props?.data.map((val) => {
              console.log(
                val?.ssrBaggageInfos,
                val?.ssrMealInfos,
                val?.ssrSeatInfos
              );
              return (
                <tr key={val._id}>
                  <td className="py-1">{`${val?.ti}. ${val?.fN} ${val?.lN}`}</td>
                  <td className="py-1">
                    {val?.ssrSeatInfos.length > 0 ? (
                      val?.ssrSeatInfos.map((seat, index) => (
                        <span key={index}>
                          {seat?.code}
                          {index !== val?.ssrSeatInfos.length - 1 ? ", " : ""}
                        </span>
                      ))
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                  <td className="py-1">
                    {val?.ssrMealInfos?.length > 0 ? (
                      val?.ssrMealInfos.map((meal, index) => (
                        <span key={index}>
                          {meal?.code}
                          {index !== val?.ssrMealInfos.length - 1 ? ", " : ""}
                        </span>
                      ))
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default BookingsSSRModal;
